import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useContentful from "../../hooks/useContentful";
import { useConfig } from "../../configuration/useConfig";
import { IModalFields } from "../../@types/generated/contentful";
import { contentfulConfig } from "../../client";
import { convertToModal } from "../../utility/contentful";
import { CustomModal } from "../../types";
const CustomContentfulModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { getContentById } = useContentful(useConfig().config);
  const [modalContent, setModalContent] = useState<CustomModal | null>(null);
  useEffect(() => {
    (async () => {
      getContentById<IModalFields>(contentfulConfig.ids.luxuryGymModal).then(
        (res) => {
          if (res) {
            setModalContent(convertToModal(res));
          }
        }
      );
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal centered show={show} onHide={() => onClose()}>
      <Modal.Header closeButton style={{ fontSize: "2rem", color: "gray" }}>
        <Modal.Title>{modalContent?.headerTitle}</Modal.Title>
      </Modal.Header>
      <hr style={{ alignSelf: `center`, margin: `0px`, width: `90%` }} />
      <Modal.Body className="custom-modal-ul">
        {modalContent?.body.content}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: `rgba(66, 66, 66, 0.05)` }}>
        {modalContent?.footerButton && (
          <Button
            variant="secondary"
            style={{ width: `100%` }}
            onClick={() => onClose()}
          >
            {modalContent?.footerButtonText}
          </Button>
        )}
        {modalContent?.footerButtonTwo && (
          <Button
            variant="secondary"
            style={{ width: `100%` }}
            onClick={() => onClose()}
          >
            {modalContent?.footerButtonText}!
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomContentfulModal;
