import { Form, Formik } from "formik";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Step, Steps, Wizard as WizardAlbus } from "../wizardAlbus";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Route } from "react-router-dom";
import Stepper, { WizardStepperStep } from "./components/Stepper";
import { EligibilityFlow, Flow, FormikWizardState } from "./types";
import * as yup from "yup";
import { Flows, getModel, getSteps, getValidation } from "./model/Flows";
import SmartyStreetsProvider from "../../context/SmartyStreetsProvider";
import SummaryProvider from "../../context/SummaryProvider";
import useEligibilityStart from "../../hooks/useEligibilityStart";
import { Persist } from "./components/FormikPersistence";
import { UserContext, USER_STATUS } from "../../context/UserProvider";
import NavBar from "../navigation/NavBar";
import { useConfig } from "../../configuration/useConfig";
import BasicNavHeader from "../navigation/BasicNavHeader";

export const stripTypename = (object: Record<string, unknown>) => {
  const mutable = { ...object };
  if (mutable.__typename) {
    delete mutable.__typename;
  }
  return mutable;
};

const mergeExisitingProperties = (
  from: { [key: string]: unknown },
  to: { [key: string]: unknown }
): void => {
  for (const key of Object.keys(from)) {
    if (key in to) {
      switch (key) {
        default: {
          if (from[key] !== null) to[key] = from[key];
        }
      }
    }
  }
};

interface WizardProps {
  footerOffset: number;
}

/*
  Generates a multi step wizard with formik and yup validations,
  Steps are configurable and interchangeable
  initialState.ts has the form flows that correspond to a given
  eligibility flow on the backend.
  TODO: update eligibilityValues type
*/
const Wizard: React.FC<WizardProps> = ({ footerOffset }) => {
  const [initialState, setInitialState] = useState<
    FormikWizardState<EligibilityFlow>
  >({});
  const { user } = useContext(UserContext);

  const [validation, setValidation] = useState<yup.AnyObjectSchema>();
  const [steps, setSteps] = useState<WizardStepperStep[]>([]);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [triggerError] = useState<null | Error>(null);

  const { config } = useConfig();

  useEffect(() => {
    //for error boundary to catch async errors
    if (triggerError) {
      throw triggerError;
    }
  }, [triggerError]);

  const { loading, currentEligibility } = useEligibilityStart();

  useEffect(() => {
    if (!loading) {
      setFlow(Flows[`client:${config["client"]}`]);
    }
  }, [loading]);

  useEffect(() => {
    if (flow && !loading) {
      const model = getModel(flow);
      const validation = getValidation(flow);
      const steps = getSteps(flow);
      //merge current eligibility from backend into our initial state
      let splitDob = ["", "", ""];
      if (currentEligibility !== null) {
        const dob: string = (
          currentEligibility?.profile as { [key: string]: string }
        ).dob;
        splitDob =
          dob !== undefined && dob !== null ? dob.split("-") : ["", "", ""];
      }
      //merge current eligibility from backend into our initial state
      mergeExisitingProperties(
        {
          ...(currentEligibility?.profile as { [key: string]: unknown }),
          day: splitDob[2],
          month: splitDob[1],
          year: splitDob[0],
          locked:
            (currentEligibility?.profileStatus as string)?.toUpperCase() ===
            "LOCKED"
              ? true
              : false,
          completed: user.status === USER_STATUS.ELIGIBLE,
        },
        model.eligibility as { [key: string]: unknown }
      );
      setInitialState(model);
      setValidation(yup.object().shape(validation));
      setSteps(steps);
    }
  }, [flow, currentEligibility, loading]);

  return (
    <>
      {config["client"] === "walmart" && (
        <NavBar logo id={true} enrollment={true} />
      )}
      {config["client"] !== "walmart" && <BasicNavHeader />}
      <SmartyStreetsProvider>
        <SummaryProvider>
          <Fragment>
            {Object.keys(initialState).length === 0 ? (
              <div className="center-loading">
                <Spinner animation="border" />
              </div>
            ) : (
              <div>
                <Container className="wizard-container">
                  <Row>
                    <Col>
                      <Route
                        render={({ history, match: { url } }) => (
                          <Formik
                            validationSchema={validation}
                            initialValues={initialState}
                            enableReinitialize
                            onSubmit={() => {
                              return;
                            }}
                          >
                            <Fragment>
                              <Stepper
                                steps={steps}
                                flow={flow}
                                step={
                                  steps.find((s) =>
                                    history.location.pathname.includes(
                                      s.label.toLowerCase()
                                    )
                                  ) ?? steps[0]
                                }
                              />
                              <Form>
                                <WizardAlbus history={history} basename={url}>
                                  <Steps>
                                    {steps.map((s: WizardStepperStep) => (
                                      <Step
                                        key={`wizard-${s.label}`}
                                        id={s.label.toLowerCase()}
                                        // eslint-disable-next-line
                                        render={({ next, previous }: any) => {
                                          return React.createElement(
                                            s.component!,
                                            {
                                              next,
                                              previous,
                                            }
                                          );
                                        }}
                                      />
                                    ))}
                                  </Steps>
                                </WizardAlbus>
                                <Persist
                                  name="enrollment-flow"
                                  isSessionStorage
                                />
                              </Form>
                            </Fragment>
                          </Formik>
                        )}
                      />
                    </Col>
                  </Row>
                </Container>
                <div style={{ height: `${footerOffset + 120}px` }}></div>
              </div>
            )}
          </Fragment>
        </SummaryProvider>
      </SmartyStreetsProvider>
    </>
  );
};

export default Wizard;
