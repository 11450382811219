import { Entry, EntryCollection } from "contentful";
import { IFeatureFields, ITabPaneFields } from "../@types/generated/contentful";
import React, { Fragment, useEffect, useState } from "react";

import FeatureFactory from "../components/features/FeatureFactory";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import PoweredBy from "../components/shared/PoweredBy";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";

const HowItWorks = () => {
  const [features, setFeatures] =
    useState<EntryCollection<IFeatureFields> | null>(null);
  const [tabContent, setTabContent] =
    useState<EntryCollection<ITabPaneFields> | null>(null);
  const [step, setStep] = useState<number>(0);
  const config = useConfig();
  const { getHowItWorksFeatures, getHowItWorksTabPanes } = useContentful(
    config.config
  );

  useEffect(() => {
    (async () => {
      getHowItWorksFeatures().then((res) => {
        if (res) {
          setFeatures(res);
        }
      });
      getHowItWorksTabPanes().then((res) => {
        if (res) {
          setTabContent(res);
        }
      });
    })();
    // eslint-disable-next-line
  }, []);

  const handleStep = (s: number) => () => {
    setStep(s);
  };
  return (
    <Fragment>
      {features !== null &&
        tabContent !== null &&
        features?.items.map((item: Entry<IFeatureFields>) => (
          <FeatureFactory
            type={item.fields.type ?? ""}
            data={item}
            key={item.fields.type ?? ""}
            params={{
              onStep: handleStep,
              step: step,
              tabContent: tabContent?.items,
            }}
          />
        ))}
      <LegalDisclaimer />
      <PoweredBy />
    </Fragment>
  );
};

export default HowItWorks;
