const friendlySearchName = (programCode, isPlural = true) => {
  switch (programCode) {
    case "prime_lte":
    case "prime_std":
    case "prime_lgcy":
    case "flip50":
      return `Location${isPlural ? "s" : ""}`;
    case "studio":
      return `Studio${isPlural ? "s" : ""}`;
    case "classic_gym":
      return `Classic Gym${isPlural ? "s" : ""}`;
    case "luxury_gym":
      return `Luxury Gym${isPlural ? "s" : ""}`;
    default:
      return `Gym${isPlural ? "s" : ""}`;
  }
};

export default friendlySearchName;
