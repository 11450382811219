import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Header from "../components/Header";
import useContentful from "../../../hooks/useContentful";
import { contentfulConfig } from "../../../client";
import { useConfig } from "../../../configuration/useConfig";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountSummaryMember } from "../../../utility/member-portal-api-types";

type BooleanCallback = (b: boolean) => unknown;

interface Props {
  open: boolean;
  hasRendered: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  member: AccountSummaryMember | null;
  checkoutDisabled: BooleanCallback;
}

const PayrollDeductionModal: React.FC<Props> = ({
  hasRendered,
  member,
  checkoutDisabled,
}) => {
  const date = new Date();
  const initialData = {
    firstName: {
      value: member?.firstName,
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
    lastName: {
      value: member?.lastName,
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
    month: {
      value: date.getMonth() + 1,
      isValid: true,
      isFocus: false,
      isBlur: false,
    },
    day: {
      value: date.getDate(),
      isValid: true,
      isFocus: false,
      isBlur: false,
    },
    year: {
      value: date.getFullYear(),
      isValid: true,
      isFocus: false,
      isBlur: false,
    },

    termsAccepted: {
      value: false,
      isValid: true,
    },
  };
  const [data, setData] = useState(initialData);
  const [attestations, setAttestations] = useState<
    Array<{ priority: number; text: string }>
  >([]);
  const config = useConfig();
  const { getAttetestions } = useContentful(config.config);
  const [attestationOpen, setAttestationOpen] = useState(false);

  const handleChangeData = (e) => {
    if (e.target.name === "termsAccepted") {
      setData({
        ...data,
        [e.target.name]: {
          ...data[e.target.name],
          value: e.target.checked,
          isValid: e.target.checked,
        },
      });
    } else {
      setData({
        ...data,
        [e.target.name]: {
          ...data[e.target.name],
          value: e.target.value,
          isValid: e.target.value !== "",
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      getAttetestions(contentfulConfig.ids.attestation).then((res) => {
        if (res?.fields?.attestation) {
          const attest: Array<{ priority: number; text: string }> =
            res.fields.attestation.map((att) => {
              return {
                priority: att.fields?.priority as number,
                text: att.fields?.text as string,
              };
            });
          setAttestations(attest);
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (data.termsAccepted.value === true) {
      checkoutDisabled(false);
    } else {
      checkoutDisabled(true);
    }
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: `60%`,
        paddingRight: `30px`,
      }}
    >
      <Header
        style={{ margin: "5px 0 40px" }}
        title="Authorization For Payroll Deduction"
        subtitle="Please agree to our terms and fill out the info below to complete checkout."
      />
      <h5>Personal Info</h5>
      <Card
        style={{
          marginBottom: 40,
          boxShadow: "0 2px 8px #ddd",
          border: "1px solid rgba(0,0,0,.122)",
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Card.Body
          className="checkout-card"
          style={hasRendered ? { padding: "24px" } : { padding: "20px" }}
        >
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={6}
              lg={6}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name
                <span style={{ color: "#d9534f" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                readOnly
                maxLength={35}
                name="firstName"
                value={data.firstName?.value}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={6} lg={6}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name
                <span style={{ color: "#d9534f" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                readOnly
                maxLength={60}
                name="lastName"
                value={data.lastName?.value}
              />
            </Form.Group>
            <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
              Today's Date
              <span style={{ color: "#d9534f" }}>*</span>
            </Form.Label>
            <Form.Group as={Col} controlId="month" xs={2} className="mb-3">
              <Form.Control
                type="text"
                name="month"
                value={data.month?.value}
                readOnly
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={3} sm={2} md={2}>
              <Form.Control
                type="text"
                name="day"
                value={data.day?.value}
                readOnly
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={4} md={3}>
              <Form.Control
                type="text"
                name="year"
                value={data.year?.value}
                readOnly
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <h5>Agree to Our Terms</h5>
      <Card
        style={{
          marginBottom: 40,
          boxShadow: "0 2px 8px #ddd",
          border: "1px solid rgba(0,0,0,.122)",
          borderRadius: 5,
          width: "100%",
        }}
        className="terms-container"
      >
        <Card.Body>
          <Card.Text>
            <div className="form-check">
              <div className="d-flex align-items-center">
                <Form.Check
                  inline
                  name="termsAccepted"
                  checked={data.termsAccepted?.value}
                  onChange={handleChangeData}
                  isInvalid={!data.termsAccepted?.isValid}
                />

                {/* <span style={{color: "#d9534f"}}>*</span> */}
                <Form.Control.Feedback type="invalid">
                  {/* {getIn(errors, "checkout.fitnessPolicy")} */}
                </Form.Control.Feedback>
                <label
                  className="form-check-label mb-0"
                  htmlFor="flexCheckDefault"
                  style={{ maxWidth: `90%` }}
                >
                  By checking this box, I acknowledge that I have read and agree
                  to comply with the terms and conditions of the{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "primary",
                      cursor: "pointer",
                    }}
                    onClick={() => setAttestationOpen(!attestationOpen)}
                    className="a-link"
                  >
                    Payroll Deduction{" "}
                  </span>
                  {attestationOpen ? (
                    <FontAwesomeIcon
                      style={{ color: "var(--primary)" }}
                      icon={faChevronDown}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{ color: "var(--primary)" }}
                      icon={faChevronUp}
                    />
                  )}
                </label>
              </div>
              {attestationOpen ? (
                <ol type="1" style={{ marginTop: "10px" }}>
                  {attestations.map((attest) => (
                    <li
                      style={{
                        marginBottom: `10px`,
                        fontWeight: "bold",
                        listStyleType: "number",
                      }}
                    >
                      <span style={{ fontWeight: "normal" }}>
                        {attest.text}
                      </span>
                    </li>
                  ))}
                </ol>
              ) : null}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      {/* <div className="auth-payroll-modal__button-container">
        <Button
          variant="outline-primary"
          className="nav-btn-enroll font-weight-bold"
          style={{ whiteSpace: "nowrap" }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="nav-btn-enroll font-weight-bold"
          style={{ whiteSpace: "nowrap" }}
          disabled={
            data.fitnessProgramAgreementAccepted.value === false ||
            data.termsAccepted.value === false
          }
          onClick={onConfirm}
        >
          Complete Checkout
        </Button>
      </div> */}
    </div>
    //   </Modal.Body>
    // </Modal>
  );
};

export default PayrollDeductionModal;
