import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReEnrollImg from "./Tiles/assets/reenroll_banner.png";
import { Tile } from "./Tiles/Tile";
import { AccountContext } from "../../context/AccountProvider";
import { useContext } from "react";




const UpgradeBanner = () => {

  const { member } = useContext(AccountContext);


  const getUpgradeLink = ():string => {

    const filteredEligibilities = member?.eligibilities.filter(
      (eligibility) => {
        return eligibility.isMembershipActive;
      }
    );
    if (
      filteredEligibilities &&
      filteredEligibilities.length == 1 &&
      (filteredEligibilities[0].productCode === "260" ||
        filteredEligibilities[0].productCode === "261") &&
      filteredEligibilities[0].isMembershipActive
    ) {
      return "/enroll/package"
    }
    
    return "/account/manage"
  }

  return (
    <Tile className="upgrade-banner">
      <Tile.THeader className="upgrade-banner__header">
        <div className="h-100">
          <img width="100%" height="100%" className="upgrade-banner__img" src={ReEnrollImg} alt="enroll" />
        </div>
      </Tile.THeader>
      <Tile.TBody className="upgrade-banner__body">
        <div>
          <h5 className="text-left">
            <b>Upgrade Your Gym Access Today!</b>
          </h5>
          <hr />
          <p style={{ fontSize: `16px` }} className="text-left p-0 mb-0">
          Upgrade and gain access to our vast network of participating gyms and premium digital content. Packages start <b>as low as $10 a month.</b>
          </p>
        </div>
      </Tile.TBody>
      <Tile.TFooter
        className="upgrade-banner__footer"
        // className="w-25 d-flex mb-2 justify-content-end flex-column"
      >
        <div className="d-flex justify-content-between">
          
            <Col xs={6} style={{ paddingRight: "8px" }}>
              <Button
                as={Link}
                variant="outline-primary"
                style={{ whiteSpace: "nowrap"}}
                className="btn btn-outline-primary align-self-left btn-sm w-90"
                to={"/locations"}
              >
                Explore Gyms
              </Button>
            </Col>
          
          <Col xs>
            <Button
              as={Link}
              style={{ color: "white"}}
              className="btn btn-primary align-self-left btn-sm w-90 upgrade-banner__button"
              to={getUpgradeLink()}
            >
              Upgrade Now
            </Button>
          </Col>
        </div>
      </Tile.TFooter>
    </Tile>
  );
};
export default UpgradeBanner;
