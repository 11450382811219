import {
  IAddressForm,
  ICheckoutForm,
  IMembershipForm,
  IPackageSelectForm,
  IEligibilityFormProfileBCBSA,
  IEligibilityFormProfileBSC,
  IEligibilityFormProfileWalmart,
  IEligibilityFormProfileHCSC,
  IEligibilityFormProfileFYW,
  IEligibilityFormProfileMW,
} from "./../types";
import { CorpCodeOption, CoverageTypeOption } from "../../../types";

export enum POLICY_TYPES {
  PRIVACY = "PRIVACY_POLICY",
  FITNESS = "FITNESS_AGREEMENT",
  CONTACT = "CONTACT_AGREEMENT",
}

export const initialBscModel: IEligibilityFormProfileBSC = {
  firstName: "",
  lastName: "",
  middleName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  privacyPolicy: false,
  fitnessPolicy: false,
  locked: false,
  completed: false,
  zip: "",
  associateIdentificationNumber: "",
  isDependent: false,
  createLogin: false,
  card: "",
};

export const initialBcbsaModel: IEligibilityFormProfileBCBSA = {
  primarySubscriber: "",
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  coverageType: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  privacyPolicy: false,
  fitnessPolicy: false,
  contactInfo: false,
  completed: false,
};

export const initialWalmartModel: IEligibilityFormProfileWalmart = {
  primarySubscriber: "",
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  coverageType: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  associateIdentificationNumber: "",
  isDependent: false,
  createLogin: false,
  privacyPolicy: false,
  fitnessPolicy: false,
  contactInfo: false,
  completed: false,
  card: "",
  isPayroll: false,
  consumerId: "",
  clientMemberId: "",
};

export const initialHCSCModel: IEligibilityFormProfileHCSC = {
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  isDependent: false,
  createLogin: false,
  corpCode: "",
  contactInfo: false,
  privacyPolicy: false,
  refresh: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  // eslint-disable-next-line
  setDependentStatus: function (): void {
    throw new Error("Function not implemented.");
  },
  error: false,
  loading: false,
  refreshing: false,
  // eslint-disable-next-line
  setAccountDetails: function (): void {
    throw new Error("Function not implemented.");
  },
  homeGymFrame: {
    current: {
      locationId: "",
      effective: "",
      term: "",
      set: "",
    },
    pending: {
      locationId: "",
      effective: "",
      term: "",
      set: "",
    },
    status: "",
    allowedActions: [],
  },
  locationsTier: "",
  nextPayment: null,
  suspension: {
    status: "",
    suspend: "",
    resume: "",
  },
  currentPrice: 0,
  acceptedTerms: false,
  memberCanCancel: false,
  completed: false,
  clientMemberId: "",
  groupId: "",
  planName: "",
  subProduct: "",
  divisionId: "",
};

export const initialFYWModel: IEligibilityFormProfileFYW = {
  hasBlue365: "",
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  gender: "",
  zip: "",
  subscriberId: "",
  groupNumber: "",
  email: "",
  isBSCA: false,
  relationship: "",
  isDependent: undefined,
  createLogin: false,
  corpCode: "",
  contactInfo: false,
  privacyPolicy: false,
  error: false,
  loading: false,
  refreshing: false,
  acceptedTerms: false,
  completed: false,
  clientMemberId: "",
  groupId: "",
  planName: "",
  subProduct: "",
  divisionId: "",
};

export const initialMWModel: IEligibilityFormProfileMW = {
  firstName: "",
  lastName: "",
  year: "",
  month: "",
  day: "",
  zip: "",
  email: "",
  isDependent: false,
  createLogin: false,
  privacyPolicy: false,
  acceptedTerms: false,
  completed: false,
  clientMemberId: "",
};


export const initialPackageModel: IPackageSelectForm = {
  sku: "",
  ratePlanChargeId: "",
  completed: false,
  tierNumber: "",
  tier: "3",
};

export const initialMembershipModel: IMembershipForm = {
  email: "",
  password: "",
  fitnessPolicy: false,
  completed: false,
  dependents: [],
};

export const initialAddressModel: IAddressForm = {
  home: { street1: "", street2: "", city: "", state: "", zip: "" },
  billing: { street1: "", street2: "", city: "", state: "", zip: "" },
  verifiedBilling: false,
  verifiedHome: false,
  billingRequired: false,
  homeId: "",
  billingId: "",
  completed: false,
};

export const initialCheckoutModel: ICheckoutForm = {
  paymentMethod: "",
  name: "",
  number: "",
  expiration: "",
  cvv: "",
  completed: false,
  fitnessPolicy: false,
};

export const initialCorpCode: CorpCodeOption[] = [
  {
    value: "MT1",
    label: "MT1",
  },
  {
    value: "TX1",
    label: "TX1",
  },
  {
    value: "NM1",
    label: "NM1",
  },
  {
    value: "IL1",
    label: "IL1",
  },
  {
    value: "OK1",
    label: "OK1",
  },
];

export const initialCoverageType: CoverageTypeOption[] = [
  {
    value: "medical",
    label: "Medical",
  },
  {
    value: "dental",
    label: "Dental",
  },
  {
    value: "vision",
    label: "Vision",
  },
];
