import React from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Payment as PaymentType } from "../../../types";
import Payment from "../../wizard/forms/Payment";

const EditPaymentModal = ({
  open,
  payment,
  loading,
  onEdit,
  onClose,
}: {
  open: boolean;
  payment: PaymentType | null;
  loading: boolean;
  onEdit: (paymentMethod: PaymentType) => void;
  onClose: Dispatch<SetStateAction<boolean>>;
}) => {
  return open ? (
    <Modal show={open} onHide={() => onClose(true)} centered size="lg">
      <div className="setHomeGymModal">
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Edit Payment Method</h2>
          <span style={{ cursor: "pointer" }} onClick={() => onClose(true)}>
            X
          </span>
        </Modal.Title>
        {payment !== null ? (
          !loading ? (
            <Payment
              paymentForm={payment}
              onClose={() => onClose(true)}
              onEdit={onEdit}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="border"></Spinner>
            </div>
          )
        ) : null}
      </div>
    </Modal>
  ) : null;
};

export default EditPaymentModal;
