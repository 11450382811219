import { UserContext } from "../../context/UserProvider";

import React, { useContext, useEffect, useState } from "react";

import styles from "./MockPanel.module.scss";
import useAxiosMock from "../../hooks/useAxiosMock";

export const MockPanel = () => {
  const [opacity, setOpacity] = useState(0.3);
  const {
    setUser,
    user,
    isContentfulPreviewOn,
    setIsContentfulPreviewOn,
    mockEnvironment,
    setMockEnvironment,
  } = useContext(UserContext);
  const { setIsMocked } = useAxiosMock();
  const [showWalmart, setShowWalmart] = useState(false);
  const [showHCSC, setShowHCSC] = useState(false);
  const [showBCBSA, setShowBCBSA] = useState(false);
  const [showBSCA, setShowBSCA] = useState(false);
  const [showMW, setShowMW] = useState(false);

  const [resetEnrollment, setResetEnrollment] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updated = user;
    const key = e.target.attributes["name"].nodeValue;
    localStorage.setItem("mockEnvironment", mockEnvironment);

    if (key == "resetEnrollment") {
      setResetEnrollment(e.target.checked);
      //clear local cache and navigate to homepage.
      sessionStorage.removeItem("enrollment-flow");
      sessionStorage.removeItem("pipeline-session-id");
      // history.push("/");
    }
    if (key) {
      updated[key] = e.target.checked;
      setUser(updated);
    }
  };

  useEffect(() => {
    setIsMocked(localStorage.isMocked === "true" ? true : false);
  }, []);
  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpacity(opacity == 0.3 ? 1 : 0.3);
  };

  return (
    <div style={{ opacity }} className={styles.mockpanel}>
      <div
        className={styles["mock-transparency"]}
        onClick={(e) => handleOnClick(e)}
      ></div>
      <div className={styles.mocks}>
        <div>
          <p>Set to True:</p>
          <input
            name="isContentfulPreviewOn"
            checked={isContentfulPreviewOn}
            type="checkbox"
            onChange={(e) => setIsContentfulPreviewOn(e.target.checked)}
          />
          <label htmlFor="isContentfulPreviewOn">Contentful Preview</label>
        </div>
        <div>
          <input
            id="isSSO"
            name="isSSO"
            checked={user.isSSO}
            onChange={handleChange}
            type="checkbox"
          />
          <label htmlFor="isSSO">isSSO</label>
        </div>
        <div>
          <input
            id="resetEnrollment"
            name="resetEnrollment"
            checked={resetEnrollment}
            onChange={handleChange}
            type="checkbox"
          />
          <label htmlFor="resetEnrollment">Reset Enrollment</label>
        </div>
        <p>DEV USE ONLY:</p>
        <p>CLIENT:</p>
        <div>
          <input
            name="showClient"
            checked={showWalmart}
            type="radio"
            onChange={(e) => {
              setShowWalmart(e.target.checked);
              setShowHCSC(!e.target.checked);
              setShowMW(!e.target.checked);
              setShowBCBSA(!e.target.checked);
              setShowBSCA(!e.target.checked);
            }}
          />
          <label htmlFor="showWalmart">Walmart</label>
          <input
            name="showClient"
            checked={showHCSC}
            type="radio"
            onChange={(e) => {
              setShowWalmart(!e.target.checked);
              setShowHCSC(e.target.checked);
              setShowMW(!e.target.checked);
              setShowBCBSA(!e.target.checked);
              setShowBSCA(!e.target.checked);
            }}
          />
          <label htmlFor="showHCSC">HCSC</label>
          <input
            name="showClient"
            checked={showBCBSA}
            type="radio"
            onChange={(e) => {
              setShowWalmart(!e.target.checked);
              setShowHCSC(!e.target.checked);
              setShowMW(!e.target.checked);
              setShowBCBSA(e.target.checked);
              setShowBSCA(!e.target.checked);
            }}
          />
          <label htmlFor="showBCBSA">BCBSA</label>
          <input
            name="showClient"
            checked={showBSCA}
            type="radio"
            onChange={(e) => {
              setShowWalmart(!e.target.checked);
              setShowHCSC(!e.target.checked);
              setShowMW(!e.target.checked);
              setShowBCBSA(!e.target.checked);
              setShowBSCA(e.target.checked);
            }}
          />
          <label htmlFor="showBCBSA">BSCA</label>
          <br />
          <input
            name="showClient"
            checked={showMW}
            type="radio"
            onChange={(e) => {
              setShowWalmart(!e.target.checked);
              setShowHCSC(!e.target.checked);
              setShowMW(e.target.checked);
              setShowBCBSA(!e.target.checked);
              setShowBSCA(!e.target.checked);
            }}
          />
          <label htmlFor="showMW">MW</label>
        </div>
        <p>ENVIRONMENT:</p>
        {showWalmart && (
          <div>
            <div>
              <input
                name="showWalmartOptions"
                value="WALMARTDEV"
                checked={mockEnvironment === "WALMARTDEV"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("WALMARTDEV");
                  handleChange;
                }}
              />
              <label htmlFor="WALMARTDEV">DEV</label>
            </div>
            <div>
              <input
                name="showWalmartOptions"
                value="WALMARTSANDBOX"
                checked={mockEnvironment === "WALMARTSANDBOX"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("WALMARTSANDBOX");
                  handleChange;
                }}
              />
              <label htmlFor="WALMARTSANDBOX">SANDBOX</label>
            </div>
            <div>
              <input
                name="showWalmartOptions"
                value="WALMARTQA"
                checked={mockEnvironment === "WALMARTQA"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("WALMARTQA");
                  handleChange;
                }}
              />
              <label htmlFor="WALMARTQA">QA</label>
            </div>
            <div>
              <input
                name="showWalmartOptions"
                value="WALMARTUAT"
                checked={mockEnvironment === "WALMARTUAT"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("WALMARTUAT");
                  handleChange;
                }}
              />
              <label htmlFor="WALMARTUAT">UAT</label>
            </div>
          </div>
        )}

        {showHCSC && (
          <div>
            <div>
              <input
                name="showHCSCOptions"
                value="HCSCDEV"
                checked={mockEnvironment === "HCSCDEV"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("HCSCDEV");
                  handleChange;
                }}
              />
              <label htmlFor="HCSCDEV">DEV</label>
            </div>
            <div>
              <input
                name="showHCSCOptions"
                value="HCSCSANDBOX"
                checked={mockEnvironment === "HCSCSANDBOX"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("HCSCSANDBOX");
                  handleChange;
                }}
              />
              <label htmlFor="HCSCSANDBOX">SANDBOX</label>
            </div>
            <div>
              <input
                name="showHCSCOptions"
                value="HCSCQA"
                checked={mockEnvironment === "HCSCQA"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("HCSCQA");
                  handleChange;
                }}
              />
              <label htmlFor="HCSCQA">QA</label>
            </div>
            <div>
              <input
                name="showHCSCOptions"
                value="HCSCUAT"
                checked={mockEnvironment === "HCSCUAT"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("HCSCUAT");
                  handleChange;
                }}
              />
              <label htmlFor="HCSCUAT">UAT</label>
            </div>
          </div>
        )}

        {showBCBSA && (
          <div>
            <div>
              <input
                name="showBCBSAOptions"
                value="BCBSADEV"
                checked={mockEnvironment === "BCBSADEV"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BCBSADEV");
                  handleChange;
                }}
              />
              <label htmlFor="BCBSADEV">DEV</label>
            </div>
            <div>
              <input
                name="showBCBSAOptions"
                value="BCBSASANDBOX"
                checked={mockEnvironment === "BCBSASANDBOX"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BCBSASANDBOX");
                  handleChange;
                }}
              />
              <label htmlFor="BCBSASANDBOX">SANDBOX</label>
            </div>
            <div>
              <input
                name="showBCBSAOptions"
                value="BCBSAQA"
                checked={mockEnvironment === "BCBSAQA"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BCBSAQA");
                  handleChange;
                }}
              />
              <label htmlFor="BCBSAQA">QA</label>
            </div>
            <div>
              <input
                name="showBCBSAOptions"
                value="BCBSAUAT"
                checked={mockEnvironment === "BCBSAUAT"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BCBSAUAT");
                  handleChange;
                }}
              />
              <label htmlFor="BCBSAUAT">UAT</label>
            </div>
          </div>
        )}

        {showBSCA && (
          <div>
            <div>
              <input
                name="showBSCAOptions"
                value="BSCADEV"
                checked={mockEnvironment === "BSCADEV"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BSCADEV");
                  handleChange;
                }}
              />
              <label htmlFor="BSCADEV">DEV</label>
            </div>
            <div>
              <input
                name="showBSCAOptions"
                value="BSCASANDBOX"
                checked={mockEnvironment === "BSCASANDBOX"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BSCASANDBOX");
                  handleChange;
                }}
              />
              <label htmlFor="BSCASANDBOX">SANDBOX</label>
            </div>
            <div>
              <input
                name="showBSCAOptions"
                value="BSCAQA"
                checked={mockEnvironment === "BSCAQA"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BSCAQA");
                  handleChange;
                }}
              />
              <label htmlFor="BSCAQA">QA</label>
            </div>
            <div>
              <input
                name="showBSCAOptions"
                value="BSCAUAT"
                checked={mockEnvironment === "BSCAUAT"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("BSCAUAT");
                  handleChange;
                }}
              />
              <label htmlFor="BSCAUAT">UAT</label>
            </div>
          </div>
        )}

        {showMW && (
          <div>
            <div>
              <input
                name="showMWOptions"
                value="MWDEV"
                checked={mockEnvironment === "MWDEV"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("MWDEV");
                  handleChange;
                }}
              />
              <label htmlFor="MWDEV">DEV</label>
            </div>
            <div>
              <input
                name="showMWOptions"
                value="MWSANDBOX"
                checked={mockEnvironment === "MWSANDBOX"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("MWSANDBOX");
                  handleChange;
                }}
              />
              <label htmlFor="MWSANDBOX">SANDBOX</label>
            </div>
            <div>
              <input
                name="showMWOptions"
                value="MWQA"
                checked={mockEnvironment === "WWQA"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("MWQA");
                  handleChange;
                }}
              />
              <label htmlFor="WWQA">QA</label>
            </div>
            <div>
              <input
                name="showMWOptions"
                value="MWUAT"
                checked={mockEnvironment === "MWUAT"}
                type="radio"
                onChange={() => {
                  setMockEnvironment("MWUAT");
                  handleChange;
                }}
              />
              <label htmlFor="MWUAT">UAT</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
