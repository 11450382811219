import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  container: {
    overflow: 'hidden',
    display: 'inline-flex',
    backgroundColor: 'white',
    borderRadius: '100px',
    verticalAlign: 'middle',
    position: 'relative',
    marginTop: '10px',
    marginBottom: '10px',
    zIndex: 0,
    border: 'solid 5px #ddd',

    // 1. Clear all styles as we're using the active bg instead
    // 2. Redeclaring so the outline follows the border radius
    '& button, & a': {
      // Button Reset.
      // From https://gist.github.com/MoOx/9137295
      border: 'none',
      margin: 0,
      width: 'auto',
      overflow: 'visible',

      background: 'transparent',

      /* inherit font from ancestor */
      color: 'inherit',
      font: 'inherit',

      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      lineHeight: 'normal',

      /* Corrects font smoothing for webkit */
      '-webkit-font-smoothing': 'inherit',
      '-moz-osx-font-smoothing': 'inherit',

      /* Corrects inability to style clickable `input` types in iOS */
      '-webkit-appearance': 'none',

      /* Remove excess padding and border in Firefox 4+ */
      '&::-moz-focus-inner': {
        border: 0,
        padding: 0
      },
      cursor: 'pointer',
      borderRadius: 0, // 1
      boxShadow: 'none', // 1
      zIndex: 1,
      padding: '5px 10px',

      '&:focus': {
        outline: 'none', // 2
        boxShadow: `0 1px 3px rgba(0,0,0,0.25), 0 0 0 0.2rem ${theme.primary.hoverColor
          .alpha(0.25)
          .css()}`, // 2
        borderRadius: '100px'
      },

      '& + button, & + a': {
        borderLeft: 'solid 1px #ddd',
        marginLeft: '0 !important'
      }
    }
  },

  isActiveOldBrowser: {
    background: `${theme.accessible.primary.color.css()}!important`
  },

  isFullWidth: {
    display: 'flex',

    '& a, & button': {
      width: '100%',
      textAlign: 'center'
    }
  },

  isActive: {
    borderLeftColor: 'transparent!important',
    '& + button, & + a': {
      borderLeftColor: 'transparent!important'
    }
  },

  activeBg: {
    backgroundColor: theme.accessible.primary.color.css(),
    borderRadius: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
    zIndex: 0,
    boxShadow: '0 2px 3px rgba(0,0,0, 0.25)'
  },
  canTransition: {
    transition: 'width 0.2s ease-in-out, transform 0.2s ease-in-out'
  }
}))

export default useStyles
