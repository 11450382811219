import React from "react";
import { useConfig } from "../../configuration/useConfig";

const PoweredBy = () => {
  const { config, clientConfig } = useConfig();
  return (
    <div className={`footer-main${config.client === "hcsc" ? "__hcsc" : ""}`}>
      <br></br>
      <p style={{ fontSize: "14px" }}>Powered by Tivity Health</p>
      <br />
      <p>
        <a href="/terms" rel="noreferrer" target="_blank" className="a-link">
          <b>Terms of Use</b>
        </a>{" "}
        |{" "}
        <a
          href="/privacy-policy"
          rel="noreferrer"
          target="_blank"
          className="a-link"
        >
          <b>Privacy Policy</b>
        </a>{" "}
        |{" "}
        <a
          href={
            config.client === "walmart"
              ? `https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66/?source=fitnesspass&referred_url=https://fitnesspass.tivityhealth.com`
              : config.client === "hcsc"
              ? `https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66/?source=HCSCFitnessProgram&referred_url=https://bcbsilforyourhealth.com`
              : config.client === "mutual-of-omaha"
              ? `https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66/?source=MutuallyWell.com&referred_url=https://www.mutuallywell.com`
              : config.client === "bcbsa" || config.client === "bsca"
              ? `https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66/?source=fitnessyourway&referred_url=https://fitnessyourway.tivityhealth.com`
              : ""
          }
          rel="noreferrer"
          target="_blank"
          className="a-link"
        >
          <b>Your Privacy Choices</b>
        </a>{" "}
        |{" "}
        <a
          href="/privacy-policy#information_we_collect_and_how_we_use_personal_information"
          target="_blank"
          className="a-link"
        >
          <b>Notice of Collection (CA)</b>
        </a>
      </p>
      <br></br>
      <p> Copyright 2024 Tivity Health, Inc. All rights reserved.</p>
      {clientConfig.isWholeHealthAllowed && (
        <p>
          WholeHealth Living Choices is a registered trademark of Tivity Health,
          Inc.
          <br></br>
          The WholeHealth Living Choices logotype is a trademark of Tivity
          Health, Inc.
        </p>
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default PoweredBy;
