import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "react-jss";
import { LocationsContext } from "../../../../context/LocationsProvider";
import useStyles from "./styles";

const MarkerCluster = ({ locations, count, $hover, onClick, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const { locationHover, studioHover } = useContext(LocationsContext);
  const [isHovering, setIsHovering] = useState(false);
  useEffect(() => {
    setIsHovering(false);

    // Apply hover state if user hovers over corresponding card
    if (locationHover && locationHover.geometry) {
      if (
        locations.find((singleLocation) => {
          // Since supercluster coords manipulates the coords we feed it,
          // the location of the hovered card won't match directly, so we
          // need to see if it's close enough to be the same
          // This is only accurate at certain zoom levels…
          const withinRange = (a, b) => {
            const absA = Math.abs(a);
            const absB = Math.abs(b);
            return (
              absA >= parseFloat((absB - 0.01).toFixed(3)) &&
              absA <= parseFloat((absB + 0.01).toFixed(3))
            );
          };
          const singleLng = singleLocation.geometry.coordinates[0].toFixed(3);
          const singleLat = singleLocation.geometry.coordinates[1].toFixed(3);
          const hoverLng = locationHover.geometry.coordinates[0].toFixed(3);
          const hoverLat = locationHover.geometry.coordinates[1].toFixed(3);
          return (
            withinRange(singleLng, hoverLng) && withinRange(singleLat, hoverLat)
          );
        })
      ) {
        setIsHovering(true);
      }
    }
    // Apply hover state if user hovers over corresponding card
    if (studioHover && studioHover.geometry) {
      if (
        locations.find((singleLocation) => {
          // Since supercluster coords manipulates the coords we feed it,
          // the location of the hovered card won't match directly, so we
          // need to see if it's close enough to be the same
          // This is only accurate at certain zoom levels…
          const withinRange = (a, b) => {
            const absA = Math.abs(a);
            const absB = Math.abs(b);
            return (
              absA >= parseFloat((absB - 0.01).toFixed(3)) &&
              absA <= parseFloat((absB + 0.01).toFixed(3))
            );
          };
          const singleLng = singleLocation.geometry.coordinates[0].toFixed(3);
          const singleLat = singleLocation.geometry.coordinates[1].toFixed(3);
          const hoverLng = studioHover.geometry.coordinates[0].toFixed(3);
          const hoverLat = studioHover.geometry.coordinates[1].toFixed(3);
          return (
            withinRange(singleLng, hoverLng) && withinRange(singleLat, hoverLat)
          );
        })
      ) {
        setIsHovering(true);
      }
    }
    // Apply studio hover state if user hovers over corresponding card
    if (studioHover && studioHover.geometry) {
      if (
        locations.find((singleLocation) => {
          // Since supercluster coords manipulates the coords we feed it,
          // the location of the hovered card won't match directly, so we
          // need to see if it's close enough to be the same
          // This is only accurate at certain zoom levels…
          const withinRange = (a, b) => {
            const absA = Math.abs(a);
            const absB = Math.abs(b);
            return (
              absA >= parseFloat((absB - 0.01).toFixed(3)) &&
              absA <= parseFloat((absB + 0.01).toFixed(3))
            );
          };
          const singleLng = singleLocation.geometry.coordinates[0].toFixed(3);
          const singleLat = singleLocation.geometry.coordinates[1].toFixed(3);
          const hoverLng = locationHover.geometry.coordinates[0].toFixed(3);
          const hoverLat = locationHover.geometry.coordinates[1].toFixed(3);
          return (
            withinRange(singleLng, hoverLng) && withinRange(singleLat, hoverLat)
          );
        })
      ) {
        setIsHovering(true);
      }
    }
  }, [locationHover, studioHover, locations]);

  return (
    <button
      type="button"
      className={`${classes.cluster} ${
        (isHovering || $hover) && classes.clusterHover
      }`}
      onClick={onClick}
    >
      {count}
    </button>
  );
};
export default MarkerCluster;
