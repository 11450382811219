import { useContext, useState } from "react";
import { ImpersonationContext } from "../context/ImpersonationProvider";
import axiosInstance from "../axios.instance";
//Queries required

const useIncontact = () => {
  const [canContinue, setCanContinue] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, isImpersonated, setValues } =
    useContext(ImpersonationContext);

  const setMasking = async (accessToken: string) => {
    const endpoint = `/csr/stop-recording`;

    await axiosInstance
      .post(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setCanContinue(true);
          setValues({ ...values, maskingEnabled: false });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setCanContinue(false);
          setError(false);
          setValues({ ...values, maskingEnabled: false });
          return;
        } else {
          setCanContinue(false);
          setValues({ ...values, maskingEnabled: false });
          setError(true);
          return;
        }
      });
  };

  const setUnmasking = async (accessToken: string) => {
    // const endpoint = `${url}services/v13.0/agent-sessions/${sessionId}/interactions/${contactId}/unmask`;
    const endpoint = `/csr/resume-recording`;
    try {
      const resp = await axiosInstance.post(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (resp.status === 200) {
        return;
      }
      throw new Error("Invalid response");
    } catch (e) {
      throw new Error("Invalid query");
    }
  };
  const setMaskEnabled = async () => {
    try {
      await setMasking(values.accessToken);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
      // console.log("finally");
      // setValues({ ...values, maskingEnabled: true });
      // setLoading(false);
      // setCanContinue(true);
      // setError(false);
    }
  };

  const setMaskDisabled = async () => {
    try {
      await setUnmasking(values.accessToken);
    } catch (e) {
      setError(true);
      // setValues({...values, maskingEnabled:false})
    } finally {
      setLoading(false);
      setCanContinue(false);
      setError(false);
      setValues({ ...values, maskingEnabled: false });
    }
  };

  return {
    loading,
    error,
    canContinue,
    setMaskDisabled,
    setMaskEnabled,
    isImpersonated,
  };
};

export default useIncontact;
