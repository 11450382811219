import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import { Row, Col, Card, Form, Spinner } from "react-bootstrap";
import styles from "../../components/locations/SidePane/styles";
import ProcessingOrderModal from "../../components/wizard/components/ProcessingOrderModal";
import useZuoraPayments from "../../hooks/useZuoraPayments";

const ZuoraIframe = () => {
  type PaymentType = {
    id: string;
    tenantId: string;
    token: string;
    signature: string;
    key: string;
    url: string;
    paymentGateway: string;
  };
  type IframeQueryString = {
    paymentType: PaymentType | string;
    paymentTypeId: string;
    accountId: string;
    uri: string;
    postalCode: string;
    firstName: string;
    lastName: string;
  };
  const location = useLocation();
  const [paymentToken, setPaymentToken] = useState(null);
  const [paymentMethod] = useState("Credit Card");
  const [, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [, setShowError] = useState(false);
  const [, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const query = QueryString.parse(
    location.search
  ) as unknown as IframeQueryString;

  const zuora = useZuoraPayments;
  const onSuccess = (res) => {
    if (res.refId) {
      setProgress(0);
      setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
      setShow(true);
      setPaymentToken(res.refId);
    }
  };

  const onSubmissionFailed = useCallback((res) => {
    forwardCallbackURL(res);
    setShow(false);
    setShowError(false);
    return;
  }, []);

  const onRequestFailed = useCallback((res) => {
    forwardCallbackURL(res);
    setShow(false);
    setShowError(true);
    return;
  }, []);

  const prePopulatedFields = {
    creditCardPostalCode: (query.postalCode && query.postalCode) || "",
    achPostalCode: (query.postalCode && query.postalCode) || "",
    creditCardHolderName:
      (query.firstName &&
        query.lastName &&
        `${query.firstName} ${query.lastName}`) ||
      "",
  };

  if (typeof query.paymentType === "string" && query.paymentType !== "") {
    query.paymentType = JSON.parse(query.paymentType);
  }
  const zuoraArgs = {
    paymentType: paymentMethod,
    paymentTypePageId: query.paymentType["id"] || "",
    prePopulate: {
      creditCardPostalCode:
        (prePopulatedFields.creditCardPostalCode as string) || "",
      achPostalCode: (prePopulatedFields.achPostalCode as string) || "",
      creditCardHolderName: prePopulatedFields.creditCardHolderName,
    },
    accountId: (query.accountId as string) || "",
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
  };

  const { render, handleSubmit, hasRendered } = zuora(zuoraArgs);
  useEffect(() => {
    render();
  }, [render, paymentMethod]);

  const forwardCallbackURL = (response) => {
    let callbackUrl = window.location.origin;
    for (const id in response) {
      callbackUrl =
        callbackUrl + id + "=" + encodeURIComponent(response[id]) + "&";
    }
    window.location.replace(callbackUrl);
  };

  useEffect(() => {
    if (paymentToken) {
      window.location.href = `${query.uri}/?token=${paymentToken}`;
    }
  }, [paymentToken]);

  return (
    <div>
      <Row>
        <Col xs={12} md={7}>
          <>
            <Card>
              <Card.Body
                className="checkout-card"
                style={hasRendered ? { padding: "24px" } : { padding: "20px" }}
              >
                <Row className="eligibility-form__row">
                  <Form.Group className="mb-1">
                    {!hasRendered && (
                      <div className="zuora-loading">
                        <Spinner animation="border" />
                      </div>
                    )}
                    <div id="zuora_payment"></div>
                    <button
                      type="button"
                      className="btn btn-primary col mb-2"
                      onClick={handleSubmit}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   window.Z.submit();
                      // }}
                    >
                      Add Payment
                    </button>
                    {hasRendered && (
                      <div className={styles["checkout__zuoraText"]}>
                        Powered by{" "}
                        <a
                          className={styles["checkout__zuoraButton"]}
                          onClick={() => setOpenModal(true)}
                        >
                          Zuora
                        </a>
                      </div>
                    )}
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
          </>
        </Col>
      </Row>
      <ProcessingOrderModal
        show={show}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
    </div>
  );
};

export { ZuoraIframe };
