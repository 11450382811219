import { Component } from "react";
import PropTypes from "prop-types";
import { createMemoryHistory } from "history";
import renderCallback from "../utils/renderCallback";

class Wizard extends Component {
  state = {
    step: {
      id: null,
    },
    steps: [],
  };

  getChildContext() {
    return {
      wizard: {
        go: this.history.go,
        set: this.set,
        history: this.history,
        init: this.init,
        next: this.next,
        previous: this.previous,
        push: this.push,
        replace: this.replace,
        ...this.state,
      },
    };
  }

  componentDidMount() {
    this.unlisten = this.history.listen(({ pathname }) =>
      this.setState({ step: this.pathToStep(pathname) })
    );

    if (this.props.onNext) {
      const { ...wizard } = this.getChildContext().wizard;
      this.props.onNext(wizard);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  get basename() {
    return `${this.props.basename}/`;
  }

  get ids() {
    return this.state.steps.map((s) => s.id);
  }

  get nextStep() {
    return this.ids[this.ids.indexOf(this.state.step.id) + 1];
  }

  get previousStep() {
    return this.ids[this.ids.indexOf(this.state.step.id) - 1];
  }

  history = this.props.history || createMemoryHistory();
  steps = [];

  pathToStep = (pathname) => {
    const id = pathname.replace(this.basename, "");
    const [step] = this.state.steps.filter((s) =>
      this.props.exactMatch ? s.id === id : id.startsWith(s.id)
    );

    return step || this.state.step;
  };

  init = (steps) => {
    this.setState({ steps }, () => {
      const step = this.pathToStep(this.history.location.pathname);
      if (step.id) {
        this.setState({ step });
      } else {
        this.history.replace(`${this.basename}${this.ids[0]}`);
      }
    });
  };

  set = (step) => this.history.push(`${this.basename}${step}`);
  push = (step = this.nextStep) => this.set(step);
  replace = (step = this.nextStep) =>
    this.history.replace(`${this.basename}${step}`);
  pushPrevious = (step = this.previousStep) => this.set(step);

  next = () => {
    if (this.props.onNext) {
      this.props.onNext(this.getChildContext().wizard);
    } else {
      this.push();
    }
  };

  previous = () => {
    this.pushPrevious();
  };

  render() {
    const { ...wizard } = this.getChildContext().wizard;
    return renderCallback(this.props, wizard);
  }
}

Wizard.propTypes = {
  basename: PropTypes.string,
  history: PropTypes.shape({
    entries: PropTypes.array,
    go: PropTypes.func,
    goBack: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.object,
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  onNext: PropTypes.func,
  exactMatch: PropTypes.bool,
};

Wizard.defaultProps = {
  basename: "",
  history: null,
  onNext: null,
  render: null,
  exactMatch: true,
};

Wizard.childContextTypes = {
  wizard: PropTypes.object,
};

export default Wizard;
