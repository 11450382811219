import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  '@keyframes pulseRing': {
    '0%': {
      transform: 'scale(.33)',
    },
    '80%, 100%': {
      opacity: 0,
    },
  },

  '@keyframes pulseDot': {
    '0%': {
      transform: 'scale(.8)',
    },
    '50%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(.8)',
    },
  },

  locationWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  locationPulse: {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: '45px',
    backgroundColor: theme.primary.color.css(),
    animation: '$pulseRing 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
    position: 'absolute',
    top: '0',
    left: '0',
  },

  locationDot: {
    content: '""',
    boxSizing: 'border-box',
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: theme.primary.color.css(),
    border: 'solid 0.25em white',
    borderRadius: '15px',
    boxShadow: '0 0 8px rgba(0,0,0,.3)',
    transform: 'translate3d(0,0,0)',
    animation: '$pulseDot 3s linear -0.4s infinite',
  },
}))

export default useStyles
