import React, { useState } from "react";
import { useCallback } from "react";

interface IButton {
  text: string;
  onClick: (e: unknown) => void;
}

interface IButtonContext {
  button: IButton;
  alternate: IButton;
  setButton: (b: IButton) => void;
  setAlternate: (b: IButton) => void;
}

export const ButtonContext = React.createContext<IButtonContext>({
  button: {
    text: "",
    onClick: () => {
      return;
    },
  },
  alternate: {
    text: "",
    onClick: () => {
      return;
    },
  },
  setButton: () => {
    return;
  },
  setAlternate: () => {
    return;
  },
});

const ButtonProvider = ({ children }: { children: React.ReactNode }) => {
  const [button, setButton] = useState<IButton>({
    text: "",
    onClick: () => {
      return;
    },
  });
  const [alternate, setAlternate] = useState<IButton>({
    text: "",
    onClick: () => {
      return;
    },
  });

  const buttonValue = {
    button: button,
    alternate: alternate,
    setButton: useCallback(
      (b: IButton) => {
        setButton(b);
      },
      [setButton]
    ),
    setAlternate: useCallback(
      (b: IButton) => {
        setAlternate(b);
      },
      [setAlternate]
    ),
  };

  return (
    <ButtonContext.Provider value={buttonValue}>
      {children}
    </ButtonContext.Provider>
  );
};

export default ButtonProvider;
