import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserProvider";
import axiosInstance from "../../axios.instance";

import { AccountContext } from "../../context/AccountProvider";
import { Spinner } from "react-bootstrap";
import { momentStartOfDay } from "../../utility/util";

const UpcomingPaymentDisplay = () => {
  const { nextPayment, suspension } = useContext(AccountContext);
  let amount: string | undefined;
  const due = momentStartOfDay(nextPayment?.due).format("MM/DD/YYYY");

  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (nextPayment) {
        await axiosInstance
          .get(`/account/next-payment-info`, {})
          .then((response) => {
            nextPayment.amount = response.data.amount;
            nextPayment.due = response.data.nextPaymentDate;
            nextPayment.overdue = response.data.isBalanceOverdue;
            setIsLoading(false);
          })
          .catch((e) => console.log(e));
      }
    };
    fetchData();

    return () => setIsLoading(false);
  }, []);

  //TODO: need to check if this business logic is true.
  if (!nextPayment) {
    return null;
  }
  // Authenticated member without an active subscription
  if (suspension.status === "TERMED" || suspension.status === "CANCELLED") {
    return null;
  }

  // Authenticated member with an active subscription
  if (nextPayment.amount > 0 && nextPayment.due.length > 0) {
    amount = nextPayment?.amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const getPaymentDisplaySpinner = () => {
    return (
      <Spinner
        style={{ borderWidth: ".1em", width: 15, height: 15 }}
        animation="border"
        as="span"
        size="sm"
      />
    );
  };

  return (
    <>
      <div className="upcoming-payment-display">
        {!nextPayment.due && !isLoading ? (
          "No payment due"
        ) : nextPayment.amount && user.isPayroll ? (
          <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
            Your next payment of{" "}
            {isLoading ? getPaymentDisplaySpinner() : amount} will be deducted
            from your next paycheck
          </p>
        ) : nextPayment.amount && !user.isPayroll ? (
          <p style={{ display: "flex", alignItems: "center", gap: 10 }}>
            Your next payment of{" "}
            {isLoading ? getPaymentDisplaySpinner() : amount} will be due on{" "}
            {isLoading ? getPaymentDisplaySpinner() : due}{" "}
          </p>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UpcomingPaymentDisplay;
