import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { LocationsContext } from "../../../../context/LocationsProvider";
import useStyles from "./styles";

const Marker = ({ location, name, $hover, isStudio, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });

  const {
    locationDetail,
    setLocationDetail,
    studioDetail,
    setStudioDetail,
    locationHover,
    studioHover,
  } = useContext(LocationsContext);
  const [isHovering, setIsHovering] = useState(false);

  let active = false;

  // Apply active state if location is selected
  if (!isStudio && Object.keys(locationDetail).length) {
    if (locationDetail.id === location.id) {
      active = true;
    }
  }
  // Apply studio active state if location is selected
  if (isStudio && Object.keys(studioDetail).length) {
    if (studioDetail.id === location.id) {
      active = true;
    }
  }

  useEffect(() => {
    setIsHovering(false);

    // Apply gym hover state if user hovers over corresponding card
    if (locationHover) {
      if (locationHover.id === location.id) {
        setIsHovering(true);
      }
    }
    // Apply studio hover state if user hovers over corresponding card
    if (studioHover) {
      if (studioHover.id === location.id) {
        setIsHovering(true);
      }
    }
  }, [locationHover, studioHover, location.id]);

  const handleButtonClick = () => {
    if (isStudio) {
      setStudioDetail(active ? {} : location);
    } else {
      setLocationDetail(active ? {} : location);
    }
  };

  const handleMarkerClose = () => {
    if (isStudio) {
      setStudioDetail({});
    } else {
      setLocationDetail({});
    }
  };

  return (
    <button
      type="button"
      className={`${classes.marker} ${active && classes.markerActive} ${
        (isHovering || $hover) && classes.markerHover
      }`}
      onClick={handleButtonClick}
    >
      <div
        className={`${classes.markerName} ${
          active && classes.markerNameActive
        }`}
        onClick={handleMarkerClose}
      >
        {name}
        {active ? (
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-19bqh2r"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        ) : null}
      </div>

      <svg viewBox="0 0 16 24" xmlns="http://www.w3.org/2000/svg">
        {isStudio ? (
          <g
            xmlns="http://www.w3.org/2000/svg"
            transform="translate(-756.943 -503)"
          >
            <g
              fill="#fff"
              transform="translate(756.943 503)"
              className={
                !active ? classes.markerGroup : classes.markerGroupActive
              }
              fillRule="evenodd"
            >
              <path
                stroke="none"
                d="M 7.978008270263672 22.3930492401123 C 6.045652866363525 20.0545482635498 0.9999968409538269 13.42588996887207 0.9999968409538269 7.977348327636719 C 0.9999968409538269 4.130028247833252 4.130026817321777 0.999998152256012 7.977346897125244 0.999998152256012 C 11.82461643218994 0.999998152256012 14.95459651947021 4.130028247833252 14.95459651947021 7.977348327636719 C 14.95459651947021 13.42593002319336 9.909987449645996 20.05454254150391 7.978008270263672 22.3930492401123 Z"
              />
              <path
                fill="currentColor"
                d="M 7.977346897125244 1.999998092651367 C 4.681427001953125 1.999998092651367 1.999997138977051 4.681417465209961 1.999997138977051 7.977337837219238 C 1.999997138977051 10.51470851898193 3.25749683380127 13.81146812438965 5.636556625366211 17.51119804382324 C 6.46507740020752 18.79965209960938 7.300732612609863 19.93289756774902 7.978442668914795 20.79984092712402 C 8.645107269287109 19.94671249389648 9.465758323669434 18.83368301391602 10.28568649291992 17.56263732910156 C 12.6859073638916 13.84186840057373 13.95459651947021 10.52731800079346 13.95459651947021 7.977337837219238 C 13.95459651947021 4.681417465209961 11.27321624755859 1.999998092651367 7.977346897125244 1.999998092651367 M 7.977346897125244 -1.9073486328125e-06 C 12.38344669342041 -1.9073486328125e-06 15.95459651947021 3.571619033813477 15.95459651947021 7.977337837219238 C 15.95459651947021 15.15644836425781 7.978086948394775 23.93193817138672 7.978086948394775 23.93193817138672 C 7.978086948394775 23.93193817138672 -2.86102294921875e-06 15.15644836425781 -2.86102294921875e-06 7.977337837219238 C -2.86102294921875e-06 3.571619033813477 3.571056365966797 -1.9073486328125e-06 7.977346897125244 -1.9073486328125e-06 Z"
              />
            </g>
            <g transform="translate(-43.829 87.072)">
              <path
                fill="currentColor"
                d="M810.107,425.773a.774.774,0,0,0-.292-.644,3.638,3.638,0,0,0-1.028-.478,9.834,9.834,0,0,1-1.2-.478,2.277,2.277,0,0,1-1.519-2.05,1.839,1.839,0,0,1,.378-1.146,2.449,2.449,0,0,1,1.071-.773,4.168,4.168,0,0,1,1.556-.276,3.645,3.645,0,0,1,1.511.3,2.392,2.392,0,0,1,1.039.856,2.235,2.235,0,0,1,.37,1.269h-1.878a.945.945,0,0,0-.293-.741,1.141,1.141,0,0,0-.792-.263,1.286,1.286,0,0,0-.8.223.656.656,0,0,0,.03,1.111,4.059,4.059,0,0,0,1.133.5,6.951,6.951,0,0,1,1.331.561,2.237,2.237,0,0,1,1.267,2.013,1.908,1.908,0,0,1-.773,1.61,3.438,3.438,0,0,1-2.12.585,4.206,4.206,0,0,1-1.72-.341,2.593,2.593,0,0,1-1.159-.934,2.433,2.433,0,0,1-.389-1.366h1.889a1.2,1.2,0,0,0,.325.926,1.535,1.535,0,0,0,1.055.3,1.213,1.213,0,0,0,.738-.2A.664.664,0,0,0,810.107,425.773Z"
                transform="translate(0 0)"
              />
            </g>
          </g>
        ) : (
          <g fill="none" fillRule="evenodd">
            <g
              className={
                !active ? classes.markerGroup : classes.markerGroupActive
              }
              fill="none"
              fillRule="evenodd"
            >
              <path
                d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
                fill="currentColor"
              />
              <circle fill="#FFF" cx="8" cy="7.5" r="3" />
            </g>
          </g>
        )}
      </svg>
    </button>
  );
};
export default Marker;
