import { createUseStyles } from 'react-jss'

const scaleUpMarker = {
  transform: 'scale(1.2)',
  // Make sure active/hover is above other markers
  zIndex: 2,
}

const useStyles = createUseStyles(theme => ({
  marker: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.primary.color.css(),
    position: 'absolute',
    top: '-45px', // half of height/width
    left: '-45px', // half of height/width
    height: 90,
    width: 90,
    transition: 'transform 0.2s ease-in-out',

    '& svg': {
      width: 16,
      height: 24,
    },
  },

  markerHover: {
    color: theme.primary.hoverColor.css(),
    ...scaleUpMarker,

    '& div': {
      maxWidth: 300,
      // Need zindex for safari
      zIndex: 1,
    },
  },

  markerActive: {
    ...scaleUpMarker,
  },

  markerName: {
    color: '#4a4a4a',
    maxWidth: 74,
    // height: 24,
    marginBottom: 5,
    padding: '0 7px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    boxShadow: '0 1px 4px #999',
    background: '#fff',
    borderRadius: 50,
    lineHeight: '24px',
    transition: '0.2s ease-in-out max-width',
  },

  markerNameActive: {
    border: `1px solid ${theme.primary.color}`,
    maxWidth: 300,
    // Need z-index for safari
    zIndex: 1,
  },
}))

export default useStyles
