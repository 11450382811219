import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { AccountContext } from "../../context/AccountProvider";
import { Alert } from "react-bootstrap";
import ArrowRotateLeftSolidGold from "../../resources/ArrowRotateLeftSolidGold.svg";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMembership from "../../hooks/useMembership";
import DiscardDowngradeModal from "./DiscardDowngradeModal";

import useGetDependents from "../../hooks/useGetDependents";
import { getUnderageDependents } from "../../utility/checkIfUnderAge";
import { useConfig } from "../../configuration/useConfig";

const PendingDowngradeBanner = () => {
  const { member } = useContext(AccountContext);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [hasUnderageDependents, setHasUnderageDependents] =
    useState<boolean>(false);
  const { refresh } = useContext(AccountContext);

  const { loading: dependentsLoading, dependents } = useGetDependents();
  const { clientConfig } = useConfig();

  useEffect(() => {
    if (dependents && clientConfig.minBuyerMemberAge) {
      const underAgeDependents = getUnderageDependents(
        dependents,
        clientConfig.minBuyerMemberAge
      );
      setHasUnderageDependents(underAgeDependents ? true : false);
    }
  }, [dependents, clientConfig]);

  return (
    <Alert className="discard-cancel-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <div className="discard-cancel-banner__body">
        {member?.eligibilities[0].isPrimary ? (
          <div>
            You will no longer have access to your current fitness package
            effective{" "}
            <b>
              {moment(
                member?.eligibilities[0].currentSubscription?.effectiveEndDate
              ).format("MM/DD/YYYY")}
            </b>
            , as it appears you downgraded your package. You can select a
            different package after the effective date or discard your downgrade
            to remain in your current package.
            <br />
            <button
              className="discard-cancel-banner__discard-button"
              onClick={() => setIsDiscarding(true)}
            >
              <img
                className="discard-cancel-banner__back-arrow"
                alt="arrow"
                src={ArrowRotateLeftSolidGold}
              />
              Discard Downgrade
            </button>
          </div>
        ) : (
          <div>
            You will no longer have access to your current fitness package
            effective{" "}
            <b>
              {moment(
                member?.eligibilities[0].currentSubscription?.effectiveEndDate
              ).format("MM/DD/YYYY")}
            </b>
            , as it appears the primary member on the account downgraded your
            package. If you believe this is an error, please contact the primary
            member.
          </div>
        )}
      </div>
      {isDiscarding && (
        <DiscardDowngradeModal
          member={useMembership()}
          onClose={(success) => {
            if (success) {
              refresh();
            }
            setIsDiscarding(false);
          }}
          hasUnderageDependents={hasUnderageDependents}
          underAgeDependentsLoading={dependentsLoading}
        />
      )}
    </Alert>
  );
};

export default PendingDowngradeBanner;
