import React from "react";

const FeatureList = ({
  backgroundColor,
  header,
  subtext,
  children,
}: {
  backgroundColor: string;
  header: string;
  subtext: string;
  children: (background: string) => JSX.Element[];
}) => {
  return (
    <div className="feature-digital-content">
      <h1 className="text-center">
        <b>{header}</b>
      </h1>
      <h2 className="text-secondary pb-4 text-center">
        <i>{subtext}</i>
      </h2>
      {children(backgroundColor)}
    </div>
  );
};

export default FeatureList;
