import React from 'react'
import { useTheme } from 'react-jss'
import useStyles from './styles'

const Spinner = ({ className, children, text, isVertical, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() })
  return (
    <div
      className={classes.wrapper}
      style={isVertical && { flexDirection: 'column' }}
    >
      <div className={`${classes.spinner} ${className || ''}`}>
        <svg width="1em" height="1em">
          <circle cx="0.5em" cy="0.5em" r="0.45em" />
        </svg>
      </div>
      {(children || text) && (
        <div className={classes.text}>{children || text}</div>
      )}
    </div>
  )
}

export default Spinner
