import { Form, useFormikContext } from "formik";
import React, { useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import AddressForm from "../../wizard/components/AddressForm";
import { IAddressFields } from "../../wizard/types";
import "react-toastify/dist/ReactToastify.css";
import AddressVerificationModal from "../../wizard/components/AddressVerificationModal";

export interface CurrentAddress {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  defaultFor?: string[];
}
export const ManagePlanShippingAddress = ({
  verifyOpen,
  setVerifyOpen,
  handle,
  setIsVerifiedAddress,
  back,
}) => {
  const { values, setFieldValue, setFieldTouched, handleChange } =
    useFormikContext<IAddressFields>();
  const [, setDisable] = useState(true);

  const setAddressValues = (smartAddressArr) => {
    if (smartAddressArr.length > 0) {
      const smartAddress = smartAddressArr[0];
      setFieldValue("street1", smartAddress.address.streetLine);
      setTimeout(() => setFieldTouched("street1", true));
      setFieldValue("street2", smartAddress.address.secondary);
      setTimeout(() => setFieldTouched("street2", true));
      setFieldValue("city", smartAddress.address.city);
      setTimeout(() => setFieldTouched("city", true));
      setFieldValue("state", smartAddress.address.state);
      setTimeout(() => setFieldTouched("state", true));
      setFieldValue("zip", smartAddress.address.zipcode);
      setTimeout(() => setFieldTouched("zip", true));
      setIsVerifiedAddress(false);
    }
  };

  const handleChangeWrapper = (e) => {
    setDisable(false);
    setIsVerifiedAddress(true);
    handleChange(e);
  };

  const handleInputChange = (input) => {
    setFieldValue("street1", input);
    setTimeout(() => setFieldTouched("street1", true));
    setDisable(false);
  };

  const handleCancel = () => {
    setVerifyOpen(false);
  };
  const handleConfirm = () => {
    handle(values);
  };

  const formatHomeAddress = (): string => {
    return `${values.street1}${
      values.street2 !== "" ? " " + values.street2 : ""
    } ${values.city}, ${values.state} ${values.zip}`;
  };

  return (
    <>
      <AddressVerificationModal
        homeAddress={formatHomeAddress()}
        billingAddress={undefined}
        show={verifyOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className="edit-profile">
        <Card.Body className="p-0">
          <Form>
            <div style={{ maxWidth: "98%" }}>
              <AddressForm
                //select an item from suggestions true
                onChangeSmarty={setAddressValues}
                //every other field apart from street1 false
                onChange={handleChangeWrapper}
                //regular typing in street1 input field  false
                onInputChange={handleInputChange}
                prefix=""
                values={values}
              />
            </div>
            <br />
            <Row>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  maxWidth: "100%",
                }}
              >
                <Button
                  className="nav-btn-enroll font-weight-bold"
                  variant="outline-primary"
                  onClick={back}
                >
                  Cancel
                </Button>
                <Button
                  className="nav-btn-enroll font-weight-bold"
                  variant="primary"
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <div className="vp__personal-info my-4" />
      </div>
    </>
  );
};
export default ManagePlanShippingAddress;
