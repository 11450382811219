import { useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";

const useGetTierThreeCount = () => {
  const [tierThreeCount, setTierThreeCount] = useState<unknown>(0);

  const getTierThreeCount = {
    run: async (programCode: string) => {
      await axiosNetworkInstance
        .get(`/location-summary?programCode=${programCode}&tierNumber=${3}`, {})
        .then((response) => {
          if (response.data) {
            setTierThreeCount(response.data.data.activeLocationCount);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
    },
  };

  return {
    getTierThreeCount,
    tierThreeCount,
  };
};

export default useGetTierThreeCount;
