import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { Spinner } from "react-bootstrap";

interface DecodedToken {
  exp: number;
}

interface Auth0TokenProviderProps {
  children: ReactNode;
}

export const TokenContext = createContext<string | null>(null);

export const Auth0TokenProvider: React.FC<Auth0TokenProviderProps> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const renewToken = async () => {
    try {
      if (isAuthenticated) {
        const fetchedToken = await getAccessTokenSilently();
        setToken(fetchedToken);
        const decodedToken: DecodedToken = jwtDecode(fetchedToken);
        const expireTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        const timeout = expireTime - currentTime - 60000;
        setTimeout(renewToken, timeout);
      } else {
        //Setting token to null for unauthenticated users
        setToken(null);
      }
    } catch (error) {
      console.error("Error renewing token", error);
    }
  };
  useEffect(() => {
    renewToken();
  }, [isAuthenticated]);
  if (isAuthenticated === undefined) {
    return <Spinner animation="border" />;
  }
  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
};

export const useAuthToken = () => {
  return useContext(TokenContext);
};
