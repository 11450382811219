import { useEffect, useContext } from "react";
import useIncontact from "./useIncontact";
import { ImpersonationContext } from "../context/ImpersonationProvider";

const useDisableMasking = (location) => {
  const { isImpersonated, setMaskDisabled } = useIncontact();
  const { values: impersonationValues } = useContext(ImpersonationContext);
  useEffect(() => {
    (async () => {
      if (
        location.pathname !== "/enroll/checkout" &&
        location.pathname !== "/account/billing" &&
        location.pathname !== "/apps/PublicHostedPageLite.do" &&
        isImpersonated
      ) {
        if (impersonationValues.maskingEnabled) {
          await setMaskDisabled();
        }
      }
    })();
  }, [location.pathname, impersonationValues.maskingEnabled]);
};

export default useDisableMasking;
