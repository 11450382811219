import React from "react";
// import { useGlobalState } from "../../../../context/GlobalContext";

function ModalDisclaimer() {
  //   const [
  //     {
  //       configState: {
  //         com: {
  //           ageThresholds: { buyerMember },
  //         },
  //       },
  //     },
  //   ] = useGlobalState();

  return (
    <div className="mx-4 mb-2">
      <p className="font-small text-secondary" style={{ fontSize: "13px" }}>
        {`*Due to Tivity Health policy, you cannot enroll a family member under age 16 in the Well onTarget Fitness Program. In addition, family member(s) under age 18 enrolled in the Fitness Program should be accompanied by an adult (age 18 or older) when visiting participating fitness locations. Some locations may not allow members under age 18 to use the location regardless of membership.`}
      </p>
    </div>
  );
}

export { ModalDisclaimer };
