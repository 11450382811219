import { FeatureInput } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureFaq = ({ input }: { input: FeatureInput }) => {
  return (
    <section className="section_howdoesitworks gray-bg mt-5 pb-4 pt-5">
      <div className="container">
        <div className="row-fluid text-center p-5">
          <h1>How Does It Work?</h1>
          <h3 className="font-italic">It’s quick and easy to get started.</h3>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-2">
            <div className="row">
              <div className="col-6 col-md-12 mb-4">
                <div className="position-relative pb-3">
                  <div className="parallelograms">1</div>
                </div>
              </div>
              <div className="col-6 col-md-12 text-left text-md-center">
                <h3 className="font-weight-bold">
                  <b>Enroll Online</b>
                </h3>
                <p>It only takes a couple minutes.</p>
                <br></br>
                Log into your Blue Access for Members<sup>SM</sup> portal and
                click the Fitness Program quicklink for more information or to
                enroll.
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-2">
            <div className="row">
              <div className="col-6 col-md-12 mb-4">
                <div className="position-relative pb-3">
                  <div className="parallelograms">2</div>
                </div>
              </div>
              <div className="col-6 col-md-12 text-left text-md-center">
                <h3 className="font-weight-bold">
                  <b>Get Ready</b>
                </h3>
                <p>
                  <b>Heading to the gym?</b>
                  <br />
                  Download/print your card.
                </p>
                <br></br>
                <p>
                  <b>Heading to a fitness studio?</b>
                  <br />
                  No card needed.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="row">
              <div className="col-6 col-md-12 mb-4">
                <div className="position-relative pb-3">
                  <div className="parallelograms">3</div>
                </div>
              </div>
              <div className="col-6 col-md-12 text-left text-md-center">
                <h3 className="font-weight-bold">
                  <b>Start Working Out</b>
                </h3>
                <p>
                  Choose your gym or studio<br></br> and start working out!
                </p>
                <br></br>
                Gym-goers will need to bring their ID card<br></br> during the
                first visit.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureFaq;
