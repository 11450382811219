import React from "react";
import { ProgramAgreementSection, TermsOfUseSection } from "../../types";

const Section = ({
  header,
  content,
  agreement,
}: { agreement?: boolean } & (TermsOfUseSection | ProgramAgreementSection)) => {
  return (
    <div className={agreement ? "fitness-program-agreement" : "terms"}>
      <h4
        className={`${
          agreement ? "fintness-program-agreement" : "terms"
        }__sectionHeader`}
      >
        <b>{header}</b>
      </h4>
      {content}
    </div>
  );
};

export default Section;
