import React from "react";
import { IProductBundleData } from "../api/platform/Queries";
import usePackagePricing from "../hooks/usePackagePricing";

interface IProductBundleContext {
  bundles: IProductBundleData | null;
  loading: boolean;
}

export const ProductBundleContext = React.createContext<IProductBundleContext>({
  bundles: null,
  loading: true,
});

const ProductBundleProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const { bundles, loading } = usePackagePricing();

  return (
    <ProductBundleContext.Provider value={{ bundles, loading }}>
      {children}
    </ProductBundleContext.Provider>
  );
};

export default ProductBundleProvider;
