import * as yup from "yup";
import { WizardStepperStep } from "../components/Stepper";
import { ICompleted } from "../types";

export type BeforeHook<T> = (current: Partial<T>, update: Partial<T>) => T;

/*
TODO: Implement typeguards for the before hooks
*/

interface IWizardStep<
  T extends { [key: string]: unknown; completed: boolean }
> {
  getModel(): T;
  getValidation(): yup.SchemaOf<T>;
  getStep(): WizardStepperStep;
}
/*
  Handles intial setup of all of the forms.
  ie. refresh
*/
class WizardStep<T extends Record<string, unknown> & ICompleted, S>
  implements IWizardStep<T>
{
  private model: T;
  private formValidation: yup.SchemaOf<T>;
  private modelValidation: yup.SchemaOf<S>;
  private step: WizardStepperStep;

  constructor(
    state: T,
    formValidation: yup.SchemaOf<T>,
    modelValidation: yup.SchemaOf<S>,
    step: WizardStepperStep
  ) {
    this.model = state;
    this.formValidation = formValidation;
    this.modelValidation = modelValidation;
    this.step = step;
  }

  getValidation() {
    return this.formValidation;
  }

  getModel() {
    return this.model;
  }

  getStep() {
    return this.step;
  }
}

export default WizardStep;
