import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getVideosList } from "../api/platform/Queries";
import VideoList from "../components/primeOnDemand/VideoList";
import VideoToPlay from "../components/primeOnDemand/VideoToPlay";

export type Program = {
  name: string;
  description: string;
  totalProgramVideos: string;
};

type Category = {
  name: string;
  description: string;
};

export type VideoData = {
  categories: Category[];
  descriptionLong: string;
  descriptionShort: string;
  expirationDate: string;
  instructors: string[];
  intensityType: string;
  program: {
    name: string;
    description: string;
  };
  releaseNumber: number;
  resolutions: { width: number; height: number }[];
  videoType: string;
  workoutType: string;
  fileId: string;
  thumbnail: string;
  title: string;
  duration: string;
};

const PrimeOnDemand = () => {
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  const [videoList, setVideoList] = useState<VideoData[]>([]);
  const [programList, setPrograms] = useState<Program[]>([]);
  const [error, setError] = useState(false);
  const { path } = useRouteMatch();

  if (error) {
    throw error;
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingVideos(true);
        const resp = await getVideosList();
        setVideoList(resp.videos);
        setPrograms(resp.programs);
      } catch (e) {
        setError(true);
      } finally {
        setIsLoadingVideos(false);
      }
    })();
  }, []);

  return (
    <Switch>
      <Route path={`${path}/:videoId`}>
        <VideoToPlay />
      </Route>
      <Route exact path={`${path}`}>
        {isLoadingVideos ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <VideoList videos={videoList} programs={programList} />
        )}
      </Route>
    </Switch>
  );
};

export default PrimeOnDemand;
