import React from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import PaymentVerifyImage from "../../../resources/paymentverifyimage.svg";

const HangTightPaymentModal = ({
  open,
  onClose,
  progress,
}: {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  progress: number;
}) => {
  return open ? (
    <Modal
      show={open}
      onHide={() => onClose(true)}
      style={{
        width: "50%",
        height: "80%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Modal.Title
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: "30px",
        }}
      ></Modal.Title>
      <Modal.Body>
        <p style={{ textAlign: "center" }}>
          <h3>
            <b>Hang tight!</b>
          </h3>
        </p>
        <center>Verifying your payment method </center>
        <p
          style={{
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "30px",
          }}
        >
          <div className="mt-4">
            <br />
            <img src={PaymentVerifyImage} alt="Family Icon" />
            <br />
            <br />
            <p>Wrapping up...</p>
            <br />
            <ProgressBar striped now={progress} />
            <br />
          </div>
          <br />
          <span>
            <small>
              Please <b>do not refresh the page.</b>
            </small>
          </span>
        </p>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default HangTightPaymentModal;
