import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  cluster: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    marginLeft: '-10px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: '50%',
    backgroundColor: theme.primary.color.css(),
    transition: 'all 0.2s ease-in-out',
    zIndex: 1,
    position: 'relative',
    fontVariant: 'proportional-nums',
  },

  clusterHover: {
    backgroundColor: theme.primary.hoverColor.css(),
    transform: 'scale(1.2)',
    zIndex: 2,
  },
}))

export default useStyles
