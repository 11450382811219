import React from 'react'
import { useTheme } from 'react-jss'
import useStyles from './styles'

// This might seem like overkill to make a single div a component
// And you'd be right about that
// google-map-react expects all markers to be a component
// otherwise you'll get a lot of errors in the console
const CenterPin = props => {
  const classes = useStyles({ ...props, theme: useTheme() })
  return (
    <div className={classes.locationWrapper}>
      <div className={classes.locationPulse}></div>
      <div className={classes.locationDot}></div>
    </div>
  )
}

export default CenterPin
