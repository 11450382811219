import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export interface IImpersonation {
  accessToken: string;
  client: string;
  contactId: string;
  csrUsername: string;
  inContactBaseUri: string;
  inContactToken: string;
  maskingEnabled: boolean;
  sessionId: string;
  personId?: string;
  siteUrl: string;
}

export interface IImpersonationContext {
  values: IImpersonation;
  isImpersonated: boolean;
  setValues: (values: IImpersonation) => void;
}

const initialState = {
  accessToken: "",
  client: "",
  contactId: "",
  csrUsername: "",
  inContactToken: "",
  sessionId: "",
  inContactBaseUri: "",
  siteUrl: "",
  maskingEnabled: false,
};

export const ImpersonationContext = React.createContext<IImpersonationContext>({
  values: initialState,
  isImpersonated: false,
  setValues: () => {
    return;
  },
});

const keyname = "csr-identity";

const ImpersonationProvider = ({ children }: { children: React.ReactNode }) => {
  const [isImpersonated, setIsImpersonated] = useState(false);

  const [values, setValues] = useState<IImpersonation>(initialState);
  const setValuesImpl = (values: IImpersonation) => {
    sessionStorage.setItem(keyname, JSON.stringify(values));
    setValues(values);
  };

  useEffect(() => {
    const items = sessionStorage.getItem(keyname);
    if (items) {
      setValues(JSON.parse(items));
    }
  }, []);

  useEffect(() => {
    if (values.csrUsername !== "") {
      setIsImpersonated(true);
    }
  }, [values]);
  return (
    <ImpersonationContext.Provider
      value={{ values: values, isImpersonated, setValues: setValuesImpl }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};

export default ImpersonationProvider;
