import React from "react";

const FeatureListBurnalong = ({
  headerImage,
  backgroundColor,
  header,
  subtext,
  children,
}: {
  headerImage: string;
  backgroundColor: string;
  header: string;
  subtext: string;
  children: (background: string) => JSX.Element[];
}) => {
  return (
    <div className="feature-digital-content">
      <div
        style={{
          display: `flex`,
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <img
          src={headerImage}
          width={"8%"}
          className="img-fluid"
          alt="testimonial"
        />
      </div>

      <h1 className="text-center">
        <b>{header}</b>
      </h1>
      <div style={{ display: `flex`, justifyContent: "center" }}>
        <h4
          className="pb-4"
          style={{
            color: `#333333`,
            maxWidth: `66%`,
            textAlign: "center",
          }}
        >
          {subtext}
        </h4>
      </div>
      <div className="container">
        <div className="row">{children(backgroundColor)}</div>
      </div>
    </div>
  );
};

export default FeatureListBurnalong;
