import moment from "moment";
import { POLICY_TYPES } from "../components/wizard/model/InitialState";
import { UserInfo } from "../context/UserProvider";
import {
  ImmediatePaymentPackages,
  PackageDetails,
} from "../context/SummaryProvider";
import { CurrentPlans, PaymentDetail } from "./member-portal-api-types";

export const getApiUrl = async () => {
  try {
    const res = await fetch("/boot.json");
    const json = await res.json();
    return json.apiUrl;
  } catch (e: unknown) {
    return Promise.reject();
  }
};
export const getApiUrlNew = async () => {
  try {
    const res = await fetch("/boot.json");
    const json = await res.json();
    return json.apiUrlNew;
  } catch (e: unknown) {
    return Promise.reject();
  }
};

export const fetchWrapper = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  apiUrlNew?: boolean
): Promise<Response> => {
  const { headers, ...rest } = init ?? { headers: {} };
  let nh = {
    ...headers,
  };
  const tokenObj = sessionStorage.getItem("authkit.storage.tokens");
  let token = "";
  if (tokenObj) {
    const obj = JSON.parse(tokenObj);
    token = obj.accessToken;
  }
  if (token !== "") {
    nh = {
      ...nh,
      Authorization: `Bearer ${token}`,
    };
  }
  let api = sessionStorage.getItem("api-url");
  let apiNew = sessionStorage.getItem("api-url-new");
  if (api === null) {
    try {
      const res = await getApiUrl();
      if (res) {
        sessionStorage.setItem("api-url", res);
        api = res as string;
      } else {
        api = "";
      }
    } catch (e) {
      api = "";
    }
  }
  if (apiNew === null) {
    try {
      const res = await getApiUrlNew();
      if (res) {
        sessionStorage.setItem("api-url-new", res);
        apiNew = res as string;
      } else {
        apiNew = "";
      }
    } catch (e) {
      apiNew = "";
    }
  }
  const nInit: RequestInit = {
    ...rest,
    headers: nh,
  };
  if (apiUrlNew) {
    return fetch(apiNew + input, nInit);
  } else {
    return fetch(api + input, nInit);
  }
};

/*
Credit: https://stackoverflow.com/questions/34436133/pdf-is-blank-when-downloading-using-javascript/45669785#45669785
*/
export function pdfDownloadFromBase64(base64Data: string, date: string) {
  const arrBuffer = base64ToArrayBuffer(base64Data);

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([arrBuffer], { type: "application/pdf" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  const link = document.createElement("a");
  document.body.appendChild(link); //required in FF, optional for Chrome
  link.href = data;
  link.download = `invoice-${date}.pdf`;
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
}

function base64ToArrayBuffer(data: string): Uint8Array {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const convertToCurrency = (amount: number | undefined) => {
  if (!amount) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
export const convertToOneTimeDiscountDetails = (
  packages: Array<ImmediatePaymentPackages | PaymentDetail> | []
) => {
  if (packages.length === 0) return null;
  const pDetails = packages.filter((p) => {
    return p.chargeType === "DISCOUNT";
  });

  const pDetailsMapped = pDetails.map((p) => {
    return {
      subtotal: p.amountWithoutTax,
      name: p.chargeName,
      amountWithoutTax: p.amountWithoutTax,
    };
  });
  const removedDuplicatesAndAppliedQuantity = pDetailsMapped
    .map((p) => {
      const quantity = pDetailsMapped.filter((d) => d.name === p.name).length;
      return {
        subtotal: p.subtotal ? p.subtotal * quantity : p.amountWithoutTax,
        name: p.name,
        quantity,
      };
    })
    .filter((value, index, self) => {
      return index === self.findIndex((v) => v.name === value.name);
    });

  return removedDuplicatesAndAppliedQuantity as PackageDetails[];
};
export const convertToRecurringDiscountDetails = (
  packages: Array<PaymentDetail> | []
) => {
  if (packages.length === 0) return null;
  const pDetails = packages.filter((p) => {
    return p.chargeType === "DISCOUNT";
  });

  const pDetailsMapped = pDetails.map((p) => {
    return {
      subtotal: p.amountWithoutTax,
      name: p.chargeName,
      amountWithoutTax: p.amountWithoutTax,
    };
  });
  const removedDuplicatesAndAppliedQuantity = pDetailsMapped
    .map((p) => {
      const quantity = pDetailsMapped.filter((d) => d.name === p.name).length;
      let total = 0;
      pDetailsMapped.map((d) =>
        d.name === p.name ? (total = total + (d.subtotal ?? 0)) : null
      );
      return {
        subtotal: total,
        name: p.name,
        quantity,
      };
    })
    .filter((value, index, self) => {
      return index === self.findIndex((v) => v.name === value.name);
    });

  return removedDuplicatesAndAppliedQuantity as PackageDetails[];
};

export const convertToDuplicateStackRatePlanNames = (
  currentPlans: CurrentPlans[] | []
) => {
  if (currentPlans.length === 0) return null;
  const removedDuplicatesAndAppliedQuantity = currentPlans
    .map((p) => {
      const quantity = currentPlans.filter(
        (d) => d.ratePlanName === p.ratePlanName
      ).length;
      return {
        ...p,
        price:
          typeof p.price === "number"
            ? p.price * quantity
            : parseFloat(p.price) * quantity,
        name: p.ratePlanName,
        quantity,
      };
    })
    .filter((value, index, self) => {
      return index === self.findIndex((v) => v.name === value.name);
    });

  return removedDuplicatesAndAppliedQuantity as CurrentPlans[];
};
export const convertToOneTimePackageDetails = (
  packages: Array<ImmediatePaymentPackages> | []
) => {
  if (packages.length === 0) return null;
  const pDetails = packages.filter((p) => {
    return p.chargeType === "RECURRING";
  });

  const pDetailsMapped = pDetails.map((p) => {
    return { subtotal: p.amountWithoutTax, name: p.chargeName };
  });

  const removedDuplicatesAndAppliedQuantity = pDetails
    .map((p) => {
      const quantity = pDetailsMapped.filter(
        (pdm) => pdm.name === p.chargeName
      ).length;
      return {
        subtotal: p.amountWithoutTax * quantity,
        name: p.chargeName,
        quantity,
      };
    })
    .filter((value, index, self) => {
      return index === self.findIndex((v) => v.name === value.name);
    });

  return removedDuplicatesAndAppliedQuantity as PackageDetails[];
};

export const convertToEnrollmentFeeDetails = (
  packages: Array<ImmediatePaymentPackages>
) => {
  if (packages.length === 0) return null;
  const pDetails = packages.filter((p) => {
    return p.chargeType === "ONETIME";
  });

  const pDetailsMapped = pDetails.map((p) => {
    return { subtotal: p.amountWithoutTax, name: p.chargeName, quantity: 1 };
  });

  const removedDuplicatesAndAppliedQuantity = pDetails
    .map((p) => {
      const quantity = pDetailsMapped.filter((p) => p.name === p.name).length;
      return {
        subtotal: p.amountWithoutTax * quantity,
        name: p.chargeName,
        quantity,
      };
    })
    .filter((value, index, self) => {
      return index === self.findIndex((v) => v.name === value.name);
    });

  return removedDuplicatesAndAppliedQuantity as PackageDetails[];
};

/** maps state containing terms booleans to array of strings */
export const termsConditionsMap = (
  values: Record<string, unknown>
): Array<POLICY_TYPES> => {
  const ret = new Array<POLICY_TYPES>();

  Object.keys(values).forEach((k) => {
    switch (k) {
      case "privacyPolicy":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.PRIVACY);
        }
        break;
      case "fitnessPolicy":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.FITNESS);
        }
        break;
      case "contactInfo":
        if (values[k] === true) {
          ret.push(POLICY_TYPES.CONTACT);
        }
        break;
      default:
        break;
    }
  });

  return ret;
};
/**
 *
 * @param existing
 * @returns merged userinfo object with session storage object when present, or null
 */
export const userinfoWrapper = (existing: UserInfo): UserInfo | undefined => {
  if (existing) {
    const userinfoModified = sessionStorage.getItem("userinfoModified");
    if (userinfoModified) {
      const userinfoModifiedJSON = JSON.parse(userinfoModified);
      return { ...existing, ...userinfoModifiedJSON };
    }
    return { ...existing };
  }
  return undefined;
};

export const momentStartOfDay = (date?: string | moment.MomentInput) => {
  return moment(date).startOf("day");
};

export const momentEndOfDate = (date?: string | moment.MomentInput) => {
  return moment(date).endOf("day");
};

/**
 *
 * @param predicates - array of predicates.
 * @returns boolean - and's together all predicates
 */
export const showBanner = ({
  predicates,
}: {
  predicates: (() => boolean)[];
}) => {
  return predicates.reduce<boolean>((a, b) => {
    return a && b();
  }, true);
};

export const deepCopy = (data: object | undefined) => {
  if (!data) {
    return data;
  }
  return JSON.parse(JSON.stringify(data));
};

export function poll(fn, retries = Infinity, timeoutBetweenAttempts = 2000) {
  return Promise.resolve()
    .then(fn)
    .catch(function retry(err) {
      if (retries-- > 0)
        return delay(timeoutBetweenAttempts).then(fn).catch(retry);
      throw err;
    });
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
