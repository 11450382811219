import { PROGRAM_CODE, USER_PAYROLL_STATUS, UserContext } from "../context/UserProvider";
import axiosInstance, { axiosMemberInstance, axiosMemberCardInstance, axiosNetworkInstance, axiosBaseInstance } from "../axios.instance";
import { useContext, useEffect, useState } from "react";
import MockAdapter from "axios-mock-adapter";
import { momentStartOfDay } from "../utility/util";
import { useConfig } from "../configuration/useConfig";

let mock = new MockAdapter(axiosInstance, { delayResponse: 2000 });
let mockMember = new MockAdapter(axiosMemberInstance, {
  delayResponse: 2000,
});

const useAxiosMock = () => {
  const { user, isMocked, setIsMocked, mockEnvironment } = useContext(UserContext);
  const { setConfig } = useConfig();

  const [baseUrl, setBaseUrl] = useState("");
  const start = momentStartOfDay();
  const end = momentStartOfDay();
  const defaultStartDate = start.subtract(1, "y").format("l");
  const defaultEndDate = end.format("l");

  useEffect(() => {
    (async () => {
      const res = await fetch(`/configs/${mockEnvironment}.json`);
      const json = await res.json();
      setBaseUrl(json.apiUrl);
      setConfig(json);
      axiosInstance.defaults.baseURL = json.apiUrl;
      axiosMemberInstance.defaults.baseURL = json.memberApiUrl;
      axiosMemberCardInstance.defaults.baseURL = json.memberCardApiUrl;
      axiosNetworkInstance.defaults.baseURL = json.networkApiUrl;
      axiosBaseInstance.defaults.baseURL = json.baseApiUrl;
    })();
    mock.restore();
    mockMember.restore();
  }, [mockEnvironment]);

  useEffect(() => {
    mock.restore();
    mockMember.restore();
    if (isMocked) {
      mock = new MockAdapter(axiosInstance, { delayResponse: 2000 });
      mockMember = new MockAdapter(axiosMemberInstance, {
        delayResponse: 2000,
      });

      mock.onGet(`${baseUrl}/config`).reply(200, {
        memberCancellations: [
          {
            attributeName: "Attribute Name",
            valueList: ["CA", "SF"],
          },
          {
            attributeName: "Attribute Name",
            valueList: ["TX", "FL"],
          },
          {
            attributeName: "Attribute Name",
            valueList: ["NY", "DE", "AZ"],
          },
        ],
      });

      mock.onGet("/account/next-payment-info").reply(200, {
        amount: 99.23,
        nextPaymentDate: "2023-12-07",
        isBalanceOverdue: true,
      });

      mock.onPost("/account/cancel").reply(200, {
        wasSuccessful: true,
      });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "NotEligible",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: false,
          message: "NotEligible", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "Eligible",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "Eligible", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "EmailUnavailable",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "EmailUnavailable", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "LoginCreated",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "LoginCreated", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "IsSSO",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "IsSSO", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "ExistingLoginFound",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "ExistingLoginFound", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: true,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "LoginCreated",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "LoginCreated", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: false,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "ExistingLoginFound",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "ExistingLoginFound", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: true,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock
        .onPost(`${baseUrl}/eligibility`, {
          createLogin: true,
          dateOfBirth: "1965-05-05",
          email: "testNewClientId@tivityhealth.com",
          firstName: "John",
          groupId: "1111111111",
          isDependent: false,
          lastName: "PersonIdFound",
          subscriberId: "1111111111",
          zip: "70001",
        })
        .reply(200, {
          isEligible: true,
          message: "PersonIdFound", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
          hasLogin: true,
          payrollStatus: USER_PAYROLL_STATUS.Payroll,
          isSSO: user.isSSO,
          hasEnrollments: false,
          consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
        });

      mock.onPost(`${baseUrl}/eligibility`).reply(200, {
        isEligible: true,
        message: "Eligible", //"'Eligible', 'NotEligible', 'EmailUnavailable', 'LoginCreated','ExistingLoginFound', 'PersonIdFound'",
        hasLogin: true,
        payrollStatus: USER_PAYROLL_STATUS.Payroll,
        isSSO: user.isSSO,
        hasEnrollments: false,
        consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
      });
      mock.onPost(`${baseUrl}/dependent`).reply(200, {
        success: true,
        id: "12345",
        errors: [
          {
            code: "some_error_code",
            message: "some_message",
          },
        ],
      });

      mock.onPost(`${baseUrl}/payment-process`).reply(200, {
        id: "1201212",
        consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
      });

      mock.onPut(`${baseUrl}/account/address`).reply(200, {
        wasSuccessful: true,
      });
      mock.onGet(`${baseUrl}/addresses`).reply(200, [
        {
          id: "1201212",
          address1: "2165 Camel Back Road",
          address2: "1705 Clifford Street",
          city: "Oakland",
          state: "CA",
          zip: "94621",
          defaultFor: ["shipping"],
        },
      ]);

      mock.onPost(`${baseUrl}/account/subscriptions/preview`).reply(200, {
        immediatePaymentSummary: {
          creditAmountWithoutTax: 22.99,
          creditAmountWithTax: 25.47,
          creditTaxAmount: 2.48,
          immediatePaymentDetails: [
            {
              amountWithoutTax: 35.99,
              amountWithTax: 40.1,
              chargeName: "Elite",
              consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
              chargeType: "RECURRING  ",
              serviceStartDate: "2023-06-05",
              serviceEndDate: "2024-06-05",
              tax: 4.11,
              tierId: "3",
            },
          ],
          immediateTotalPaymentAmountWithoutTax: 0,
          immediateTotalPaymentAmountWithTax: 0,
          immediateTotalPaymentTaxAmount: 0,
          immediatePaymentDiscountAmountWithoutTax: 0,
          immediatePaymentDiscountTaxAmount: 0,
          immediateTotalPaymentAfterDiscountAmountWithoutTax: 0,
          immediateTotalPaymentAfterDiscountAmountWithTax: 0,
          immediateTotalPaymenAfterDiscountTaxAmount: 0,
          immediateTotalPaymentSetUpFee: 0,
          immediateTotalPaymentRecurringFee: 0,
        },
        monthlyPaymentSummary: {
          monthlyPaymentDetails: [
            {
              amountWithoutTax: 0,
              amountWithTax: 0,
              chargeName: "POWER",
              consumerId: "09973fa3-995b-4525-92e7-29de6bb0b0bd",
              chargeType: "chargeType",
              serviceStartDate: "2023-06-05",
              serviceEndDate: "2023-06-05",
              tax: 0,
              tierId: "1",
            },
          ],
          monthlyTotalPaymentAmountWithoutTax: 0,
          monthlyTotalPaymentAmountWithTax: 0,
          monthlyTotalPaymentTaxAmount: 0,
          monthlyPaymentDiscountAmountWithoutTax: 0,
          monthlyPaymentDiscountTaxAmount: 0,
          monthlyTotalPaymentAfterDiscountAmountWithoutTax: 0,
          monthlyTotalPaymentAfterDiscountAmountWithTax: 0,
          monthlyTotalPaymentAfterDiscountTaxAmount: 0,
          monthlyTotalPaymentSetUpFee: 0,
          monthlyTotalPaymentRecurringFee: 0,
          promoCodeDescription: [],
          detailedPromoCodeDescription: [],
        },
        orders: [],
        promoCodeError: [],
        success: true,
      });

      mock
        .onGet(
          `${baseUrl}/account/activity?startDate=${defaultStartDate}&endDate=${defaultEndDate}`
        )
        .reply(200, [
          {
            activeLivingMemberId: 0,
            class: "Zumba",
            location: "LA Fitness",
            activityTime: "16:00:00",
            activityDate: new Date("05/03/2023"),
            type: "Location check-in",
            visitType: "Location checkn-in",
          },
          {
            activeLivingMemberId: 0,
            class: "Plena",
            location: "LA Fitness",
            activityTime: "16:00:00",
            activityDate: new Date("03/19/2023"),
            type: "Location check-in",
            visitType: "Location checkn-in",
          },
          {
            activeLivingMemberId: 0,
            class: "Tango",
            location: "LA Fitness",
            activityTime: "16:00:00",
            activityDate: new Date("03/20/2023"),
            type: "Location check-in",
            visitType: "Location checkn-in",
          },
          {
            activeLivingMemberId: 0,
            class: "Dance",
            location: "LA Fitness",
            activityTime: "16:00:00",
            activityDate: new Date("03/21/2023"),
            type: "Location check-in",
            visitType: "Location checkn-in",
          },
          {
            activeLivingMemberId: 0,
            class: "Extreme Dance",
            location: "LA Fitness",
            activityTime: "16:00:00",
            activityDate: new Date("03/22/2023"),
            type: "Location check-in",
            visitType: "Location checkn-in",
          },
        ]);

      mock.onGet(`${baseUrl}/programs/${PROGRAM_CODE.PrimeSTD}`).reply(200, {
        id: "2c92c0f872a83f6d0172aa522f69253b",
        name: "Walton Life Fitness Pass by Tivity Health",
        description: "",
        productRatePlans: [
          {
            id: "8ad081c68727e474018734c3537a39a1",
            name: "WLFP Digital",
            description: "",
            billingPeriod: "Paycheck",
            isBestValue: false,
            recurringPrice: 10,
            recurringRatePlanChargeId: "8ad094088727f65b018734c5007f7568",
            setupFee: 0,
            tier: 0,
          },
          {
            id: "2c92c0f972a852300172aa53451d4700",
            name: "MOCK'd Core",
            description:
              '<p class="num-of-gyms">2000 +</p>\n<p class="num-des">locations</p>\n',
            billingPeriod: "Paycheck",
            isBestValue: false,
            recurringPrice: 15,
            recurringRatePlanChargeId: "2c92c0f872a83f7a0172aa54c9ff4c87",
            setupFee: 0,
            tier: 1,
          },
          {
            id: "2c92c0f972a852360172aa54ddf47efb",
            name: "MOCK'd Power",
            description:
              '<p class="num-of-gyms">5000 +</p>\n<p class="num-des">locations</p>\n',
            billingPeriod: "Paycheck",
            isBestValue: false,
            recurringPrice: 20,
            recurringRatePlanChargeId: "2c92c0f972a852360172aa54de087efd",
            setupFee: 0,
            tier: 2,
          },
          {
            id: "2c92c0f872a83f6d0172aa562356389c",
            name: "MOCK'd Ultra",
            description:
              '<p class="num-of-gyms">9000 +</p>\n<p class="num-des">locations</p>\n',
            billingPeriod: "Paycheck",
            isBestValue: true,
            recurringPrice: 25,
            recurringRatePlanChargeId: "2c92c0f872a83f6d0172aa562373389e",
            setupFee: 0,
            tier: 3,
          },
        ],
        programCode: PROGRAM_CODE.PrimeSTD,
      });
      mock.onPost(`${baseUrl}/enrollment/preview`).reply(200, {
        immediatePaymentSummary: {
          creditAmountWithoutTax: 0,
          creditAmountWithTax: 0,
          creditTaxAmount: 0,
          immediatePaymentDetails: [
            {
              amountWithoutTax: 28.17,
              amountWithTax: 30.76,
              chargeName: "WLFP Power Plan",
              chargeType: "RECURRING",
              serviceStartDate: "2023-04-18",
              serviceEndDate: "2023-05-17",
              tax: 2.59,
              tierId: 2,
            },
          ],
          immediateTotalPaymentAmountWithoutTax: 28.17,
          immediateTotalPaymentAmountWithTax: 30.76,
          immediateTotalPaymentTaxAmount: 2.59,
          immediatePaymentDiscountAmountWithoutTax: 0,
          immediatePaymentDiscountTaxAmount: 0,
          immediateTotalPaymentAfterDiscountAmountWithoutTax: 28.17,
          immediateTotalPaymentAfterDiscountAmountWithTax: 30.76,
          immediateTotalPaymenAfterDiscountTaxAmount: 2.59,
          immediateTotalPaymentSetUpFee: 0,
          immediateTotalPaymentRecurringFee: 28.17,
          promoCodeDescription: [],
          detailedPromoCodeDescription: [],
        },
        monthlyPaymentSummary: {
          monthlyPaymentDetails: [
            {
              amountWithoutTax: 28.17,
              amountWithTax: 30.76,
              chargeName: "WLFP Power Plan",
              chargeType: "RECURRING",
              serviceStartDate: "2023-05-18",
              serviceEndDate: "2023-06-17",
              tax: 2.59,
              tierId: 2,
            },
          ],
          monthlyTotalPaymentAmountWithoutTax: 28.17,
          monthlyTotalPaymentAmountWithTax: 30.76,
          monthlyTotalPaymentTaxAmount: 2.59,
          monthlyPaymentDiscountAmountWithoutTax: 0,
          monthlyPaymentDiscountTaxAmount: 0,
          monthlyTotalPaymentAfterDiscountAmountWithoutTax: 28.17,
          monthlyTotalPaymentAfterDiscountAmountWithTax: 30.76,
          monthlyTotalPaymentAfterDiscountTaxAmount: 2.59,
          monthlyTotalPaymentSetUpFee: 0,
          monthlyTotalPaymentRecurringFee: 28.17,
          promoCodeDescription: [],
          detailedPromoCodeDescription: [],
        },
        orders: [],
        promoCodeError: [],
        success: true,
      });
      // MADE MOCK
      const fileUrl = new RegExp(`${baseUrl}/account/files/.*`);
      mock
        .onGet(fileUrl)
        .reply(
          200,
          "JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwogIC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAvTWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0KPj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAgL1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9udAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2JqCgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJUCjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVuZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4gCjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAwMDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9vdCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G"
        );

      mock.onGet(`${baseUrl}/account/billing-history`).reply(200, {
        details: {
          personId: 0,
          zuoraAccountId: "1201212",
          hubspotAccountId: "string",
          createdAtUtc: "2023-04-26T16:53:08.074Z",
          createdBy: "string",
          updatedAtUtc: "2023-04-26T16:53:08.074Z",
          updatedBy: "string",
        },
        invoices: [
          {
            date: "2020-02-05",
            description: "Membership",
            status: " ",
            amount: 300,
            balance: 0,
            dueDate: "2023-03-29",
            fileId: "1",
            id: "1201212",
            invoiceDate: "2023-03-29",
            invoiceNumber: "string",
            invoiceStatus: "string",
            paymentStatus: "string",
            paymentMethod: "string",
            productType: "string",
            paymentId: "string",
            paymentNumber: "string",
            paymentDate: "2023-03-29",
            refundOrCreditAmount: 0,
            maxRefundAmountAllowed: 0,
            isRefundable: true,
            isLegacyOrder: true,
            legacyOrderId: 0,
            legacyOrderDate: "string",
          },
          //Second Invoice
          {
            date: "2020-01-05",
            description: "Membership",
            status: " ",
            amount: 900,
            balance: 0,
            dueDate: "2023-03-29",
            fileId: "1",
            id: "120144",
            invoiceDate: "2023-03-29",
            invoiceNumber: "string",
            invoiceStatus: "string",
            paymentStatus: "string",
            paymentMethod: "string",
            productType: "string",
            paymentId: "string",
            paymentNumber: "string",
            paymentDate: "2023-03-29",
            refundOrCreditAmount: 0,
            maxRefundAmountAllowed: 0,
            isRefundable: true,
            isLegacyOrder: true,
            legacyOrderId: 0,
            legacyOrderDate: "string",
          },
          // Third Invoice
          {
            date: "2020-01-05",
            description: "Membership",
            status: " ",
            amount: 200,
            balance: 0,
            dueDate: "2023-03-29",
            fileId: "1",
            id: "120166",
            invoiceDate: "2023-03-29",
            invoiceNumber: "string",
            invoiceStatus: "string",
            paymentStatus: "string",
            paymentMethod: "string",
            productType: "string",
            paymentId: "string",
            paymentNumber: "string",
            paymentDate: "2023-03-29",
            refundOrCreditAmount: 0,
            maxRefundAmountAllowed: 0,
            isRefundable: true,
            isLegacyOrder: true,
            legacyOrderId: 0,
            legacyOrderDate: "string",
          },
        ],
        payrollAuthorizations: [
          {
            memberPayrollAuthorizationId: "string",
            personId: 0,
            subscriberId: "string",
            authorizationAtUtc: "2023-04-26",
            currentTierEndDate: "2023-04-26",
            currentPackageName: "string",
            currentPackageAmount: 0,
            newTierStartDate: "2023-04-26",
            newPackageName: "string",
            newPackageAmount: 0,
            createdAtUtc: "2023-04-26T16:53:08.075Z",
            createdBy: "string",
            updatedAtUtc: "2023-04-26T16:53:08.075Z",
            updatedBy: "string",
          },
        ],
      });
      // PAYMENT METHODS
      mock.onGet(`${baseUrl}/account/payment-methods`).reply(200, [
        {
          id: "120556",
          type: "CreditCard",
          creditCardHolderName: "Jane Smith",
          createdDate: "2023-03-29T04:34:32.316Z",
          creditCardType: "VISA", //Change to Either Credit Card or ACH

          isDefaultPaymentMethod: true,
          // isDefaultPaymentMethod: true, // Parameter isn't being used.
          // EDITED CODE FOR CARD AND ARCH

          creditCardMaskNumber: "**** 4562",
          creditCardExpirationMonth: "08",
          creditCardExpirationYear: "2020",
          creditCardPostalCode: "46898",
        },
        //SECONDARY Card
        {
          id: "1301223", //ID NEEDS TO BE DIFFEREN FOR IT TO MANIFEST
          type: "ACH",
          creditCardHolderName: "John Smith",
          createdDate: "2023-03-29T04:34:32.316Z",
          achAccountType: "Credit Union", //NEEDS TO BE EXPLICIT "CreditCard" or "ACH"
          isDefaultPaymentMethod: false,
          achAccountNumberMask: "**** 5114",
          achPostalCode: "91234",
          achBankName: "Wells Fargo",
        },
      ]);
      // EDITED CODE ENDS HERE

      mock.onPost(`${baseUrl}/account/payment-methods/default`).reply(200, [
        {
          id: "1201212",
          createdDate: "2023-03-29T04:34:32.316Z",
          paymentMethodType: "CreditCard",
          isDefault: true,
          // isDefaultPaymentMethod: true, // Parameter isn't being used.
          // EDITED CODE FOR CARD AND ARCH
          card: {
            type: "Master Card",
            number: "422322329992288473",
            expMonth: "March",
            expYear: "2030",
            zip: "13345",
          },
          arch: {
            bankName: "string",
            type: "string",
            mask: "string",
          },
        },
      ]);
    }
    // else {
    //   mock.restore();
    //   mockMember.restore();
    // }
  }, [isMocked]);

  return {
    isMocked,
    setIsMocked,
  };
};

export default useAxiosMock;
