import moment from "moment";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Button, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { useConfig } from "../../../configuration/useConfig";
import { InvoiceResponse } from "../../../types";
import ProfileToast from "../../../utility/ProfileToast";
import { pdfDownloadFromBase64 } from "../../../utility/util";
import filedownload from "../../../resources/filedownload.svg";
import { UserContext } from "../../../context/UserProvider";
import axiosInstance from "../../../axios.instance";

const BillingHistoryTable = () => {
  const [invoices, setInvoices] = useState<InvoiceResponse | null>(null);
  const [invoiceDownloadError, setInvoiceDownloadError] = useState(false);
  const [errorInvoices, setErrorInvoices] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const { client } = useConfig();
  useEffect(() => {
      axiosInstance
        .get(`/account/billing-history`, {})
        .then(({ data }) => {
          setInvoices(data);
          setLoadingInvoices(false);
          setErrorInvoices(false);
          if (data.payrollAuthorizations.length) {
            setUser({
              payrollAuthorizations: data.payrollAuthorizations,
            });
          }
        })
        .catch((error) => console.log(error));
  }, []);

  const handleInvoiceDownload = (fileId: string, date: string) => {
    axiosInstance
      .get(`/account/files/${fileId}`, {})
      .then(({ data }) => {
        pdfDownloadFromBase64(data, date);
        ProfileToast(`Successfully downloaded your invoice.`, true, client);
      })
      .catch((error) => {
        setInvoiceDownloadError(true);
        console.log(error.toJSON());
      });
  };

  useEffect(() => {
    if (invoiceDownloadError) {
      ProfileToast(
        `There was an error downloading your invoice.`,
        false,
        client
      );
    }
  }, [invoiceDownloadError]);

  const addZeroes = (num: string) => {
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  };
  // "title" has been left empty to cause it's not used in the mock up
  return (
    <Tabs defaultActiveKey="billing">
      <Tab eventKey="billing" title=" " className="p-0 p-md-3">
        <div>
          {typeof invoices !== null && (
            <Table
              responsive
              striped={!loadingInvoices && !errorInvoices}
              hover={!loadingInvoices && !errorInvoices}
              className="billing-table"
            >
              <thead>
                <tr className="table-row">
                  <th>DATE</th>
                  <th>DESCRIPTION</th>
                  <th>STATUS</th>
                  <th>AMOUNT</th>
                  <th>INVOICE DOC (PDF)</th>
                </tr>
              </thead>
              <tbody>
                {loadingInvoices && (
                  <tr>
                    <td colSpan={5}>
                      <div
                        className="center-loading"
                        style={{ marginTop: 0, background: "none" }}
                      >
                        <Spinner
                          animation="border"
                          style={{ marginTop: 20, borderWidth: ".1em" }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {!loadingInvoices && errorInvoices && (
                  <tr>
                    <td colSpan={5}>
                      <div
                        className="center-loading"
                        style={{ marginTop: "24px", background: "none" }}
                      >
                        <h2>
                          <strong>
                            Sorry there was an error fetching your invoices.
                          </strong>
                        </h2>
                      </div>
                    </td>
                  </tr>
                )}
                {!loadingInvoices &&
                  !errorInvoices &&
                  invoices?.invoices &&
                  invoices?.invoices.map((inv) => {
                    return (
                      <tr key={inv.id}>
                        <td>
                          {inv.invoiceDate
                            ? moment(inv.invoiceDate).format("MM/DD/YY")
                            : ""}
                        </td>
                        <td>{inv.productType}</td>
                        <td>{inv.paymentStatus}</td>
                        <td>
                          $
                          {inv.amount
                            ? addZeroes(inv.amount.toString())
                            : "0.00"}
                        </td>
                        <td>
                          {inv.fileId ? (
                            <Button
                              variant="link"
                              className="unstyled-button"
                              onClick={() =>
                                handleInvoiceDownload(
                                  inv.fileId,
                                  inv.invoiceDate
                                )
                              }
                            >
                              <img
                                id="file-download"
                                height={17}
                                src={filedownload}
                              ></img>
                              Download
                            </Button>
                          ) : (
                            <span>Unavailable</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {user.isPayroll && (
            <p
              style={{
                color: "#777",
                background: "#eee",
                borderRadius: 5,
                padding: 10,
              }}
            >
              Please contact Walmart People Services for payroll billing history
            </p>
          )}
        </div>
      </Tab>
    </Tabs>
  );
};

export default BillingHistoryTable;
