import React, { useState } from "react";

import { useTheme } from "react-jss";
import { ToastContainer, toast } from "react-toastify";

import Map from "../Map";
import SidePane from "../SidePane";
import Filters from "../Filters";
import useStyles from "./styles";
import LocationsProvider from "../../../context/LocationsProvider";
import ModalSearch from "../ModalSearch";

const AppContainer = (props) => {
  const { tier, ...rest } = props;
  const classes = useStyles({ ...rest, theme: useTheme() });

  const [isStudio, setIsStudio] = useState(false);

  const handleIsStudio = (isStudio) => {
    if (isStudio) {
      setIsStudio(true);
    } else {
      setIsStudio(false);
    }
  };

  return (
    <LocationsProvider
      packageSelected={tier}
      isStudio={isStudio}
      isLuxuryModal={props.isLuxuryModal}
      setOpenChooseLuxuryGymModal={props.setOpenChooseLuxuryGymModal}
      setShowSuccessToast={props.setShowSuccessToast}
    >
      <div className={classes.locationFinder}>
        <SidePane
          isStudio={handleIsStudio}
          isLuxuryModal={props.isLuxuryModal}
        />
        <div className={classes.locationFinderMapSearch}>
          <ModalSearch />
          {props.isLuxuryModal ? null : <Filters isStudio={isStudio} />}
          <Map isStudio={isStudio} isLuxuryModal={props.isLuxuryModal} />
        </div>
      </div>
      <div className={classes.toastWrapper}>
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          toastClassName={classes.toast}
          bodyClassName={classes.toastBody}
          progressClassName={classes.toastProgress}
          closeOnClick={true}
          autoClose={5000}
          pauseOnHover={false}
        />
      </div>
    </LocationsProvider>
  );
};

export default AppContainer;
