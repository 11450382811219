import React from "react";
import { FeatureBottomBody, FeatureInput } from "../../types";

const FeatureBottomNoButton = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureBottomBody;
  const newSubTest = body.subText.split("\n");
  return (
    <div className="bottom-feature-no-button">
      <h2>
        <b>{body.header}</b>
      </h2>
      {newSubTest.map((text) => (
        <div key={text}>{text !== "" ? <p>{text}</p> : null}</div>
      ))}
    </div>
  );
};

export default FeatureBottomNoButton;
