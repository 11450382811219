import { useEffect, useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";

const useGetAmenities = () => {
  const [amenities, setAmenities] = useState<string[]>([]);

  useEffect(() => {
      axiosNetworkInstance
        .get(`/amenities`, {
        })
        .then((response) => {
          if (response.data) {
            setAmenities(response.data.data.activeLocationCount);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
        });
  }, []);
  return amenities;
};

export default useGetAmenities;
