import React, { useContext } from "react";
import BillingHistoryTable from "../../components/account/Billing/BillingHistoryTable";
import { Card, Col, Row } from "react-bootstrap";
import PaymentMethods from "../../components/account/Billing/PaymentMethods";
import CreditBalance from "../../components/account/Billing/CreditBalance";
import { AccountContext } from "../../context/AccountProvider";
const Billing = () => {
  const { creditBalance } = useContext(AccountContext);
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="billing-container">
            <Col xs={12} md={7}>
              <Card.Title>Billing History</Card.Title>
              <BillingHistoryTable />
            </Col>
            <Col xs={12} md={5}>
              <Card.Title>Payment Options</Card.Title>
              {creditBalance && <CreditBalance balance={creditBalance} />}
              <PaymentMethods />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Billing;
