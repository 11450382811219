import { Form, Col, Button, Modal, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { emailRegex } from "../../../utility/Constants";

interface IEmailChange {
  email: string;
}

interface ChangeEmailProps {
  currentEmail: string | undefined;
  handleChangeEmail?: (newEmail: string) => void | undefined;
  close?: (e: unknown) => void | undefined;
  errorMessage?: string;
  isDependentEmailLoading?: boolean;
}

const ChangeFamilyMemberEmail: React.FC<ChangeEmailProps> = ({
  currentEmail,
  handleChangeEmail,
  close,
  errorMessage,
  isDependentEmailLoading,
}) => {
  const [initialState] = useState<IEmailChange>({
    email: currentEmail ? currentEmail : "",
  });
  const [, setLoadingSpinner] = useState(false);

  const emailValidation = yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .required("Required")
        .test(
          "emails-match",
          "An account already exists with that email. Please enter a different email address.",
          function (value) {
            return currentEmail !== value;
          }
        ),
    })
    .defined();

  const handleSubmit = (email: string) => {
    try {
      if (handleChangeEmail) {
        setLoadingSpinner(true);
        handleChangeEmail(email);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isDependentEmailLoading) {
    return (
      <Modal.Body style={{ minHeight: "175px" }}>
        <div className="d-flex flex-column justify-content-center">
          <div className="center-loading" style={{ marginTop: "10px" }}>
            <Spinner animation="border" />
          </div>
          <span className="text-center mt-3">
            Processing... This may take a minute.
          </span>
          <p className="text-center">Please do not refresh the page.</p>
        </div>
      </Modal.Body>
    );
  }

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p
          style={{ marginLeft: "0.6rem", fontSize: "30px", fontWeight: "bold" }}
        >
          Send Account Setup Invite
        </p>
        <div className="modal-edit-family-close" onClick={close}></div>
      </div>
      <p className="mb-0" style={{ marginLeft: "0.7rem" }}>
        Confirm your family member's email address below
      </p>
      <div style={{ marginLeft: "1rem" }}>
        <Formik
          validationSchema={emailValidation}
          initialValues={initialState}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values.email);
            resetForm();
          }}
        >
          {(props) => (
            <FormikForm>
              <Form.Group as={Col} xs={7} md={7} lg={7}>
                <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                  CONFIRM EMAIL
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  id="email"
                  onChange={props.handleChange}
                  required
                  className="edit-profile__input"
                  onBlur={props.handleBlur}
                  value={props.values.email}
                />
              </Form.Group>
              {props.touched.email && props.errors.email ? (
                <div className="form-requirements dependent-email invalid extra-margin">
                  {props.errors.email}
                </div>
              ) : null}
              {errorMessage && (
                <div className="form-requirements dependent-email invalid extra-margin">
                  {
                    //errorMessage == '200' ? '200' : null
                    errorMessage == "200-NoChanges"
                      ? "Account setup invite already sent to this dependent's email address. Enter a different email or use forgot password to gain access."
                      : errorMessage == "409-Error"
                      ? "This email is unavailable. Please enter a different email address."
                      : null
                  }
                </div>
              )}

              <hr style={{ marginTop: "30px", marginBottom: "30px" }}></hr>

              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outline-primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={close}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    handleSubmit(props.values.email);
                  }}
                  disabled={props.values.email === "" || !!props.errors.email}
                >
                  Send Invite
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeFamilyMemberEmail;
