import { AccountSummaryMember } from "./member-portal-api-types";

const isUnderage = (birthDate) => {
  const today = new Date();
  const birthday = new Date(birthDate);
  let age = today.getFullYear() - birthday.getFullYear();
  const month = today.getMonth() - birthday.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  if (age < 18) {
    return true;
  }
  return false;
};
const calculateAge = (dateString) => {
  // birthday is a date
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getUnderageDependents = (
  dependents: AccountSummaryMember[],
  minimumAge,
) => {
  const underAgeDependents = dependents.find(dep =>
    calculateAge(dep.dateOfBirth) < minimumAge
  );
  return underAgeDependents;
};

export { isUnderage, calculateAge, getUnderageDependents };
