import React from "react";
import { FAQStillHaveQuestions } from "../../types";

const StillHaveQuestions = ({
  hours,
  phoneNumber,
  spanishPhoneNumber,
}: FAQStillHaveQuestions) => {
  return (
    <div>
      <h3>
        <b>Still have questions? Call us.</b>
      </h3>
      <p>
        <small>
          <b>Phone: </b>
          <a href={`tel:${phoneNumber}`} className="a-link">{phoneNumber}</a> {"(English) "}
          <a
            style={{
              borderLeft: "1px solid grey",
              height: "10px",
              marginRight: "5px",
            }}
          >
            {" "}
          </a>
          <a href={`tel:${spanishPhoneNumber}`} className="a-link">{spanishPhoneNumber}</a>{" "}
          {"(Spanish)"}
        </small>
      </p>
      <p>
        <small>
          <b>Hours: </b>
          {hours}
        </small>
      </p>
    </div>
  );
};

export default StillHaveQuestions;
