import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { UserContext, USER_STATUS } from "../../../context/UserProvider";
import { EligibilityFlow, Flow, FormikWizardState } from "../types";

export type WizardStepperStep = {
  label: string;
  url: string;
  stateFieldName: string;
  component?: React.FunctionComponent<{
    next: () => void;
    previous: () => void;
  }>;
};

const Step = ({
  label,
  selected,
  disabled,
}: WizardStepperStep & { selected: boolean; disabled: boolean }) => {
  if (selected) {
    return (
      <h6 className="wizard-step__selected">
        <b>{label}</b>
      </h6>
    );
  }
  if (disabled) {
    return <span className="wizard-step__disabled">{label}</span>;
  }
  return <h6 className="wizard-step__unselected">{label}</h6>;
};

const Stepper = ({
  steps,
  step,
  flow,
}: {
  steps: WizardStepperStep[];
  step: WizardStepperStep;
  flow: Flow | null;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { user } = useContext(UserContext);

  const { values } = useFormikContext<FormikWizardState<EligibilityFlow>>();

  useEffect(() => {
    const i = steps.findIndex((s) => step.label === s.label);

    if (i > -1) {
      setSelectedIndex(i);
    }
  }, [steps, step]);

  const isDisabled = useCallback(
    (s: WizardStepperStep, index: number) => {
      if (!flow) {
        return true;
      }

      const currentFlow = flow[steps[index].stateFieldName];
      //redemption/fep flow
      if (
        steps[index].label.toLowerCase() === "eligibility" &&
        user.status === USER_STATUS.ELIGIBLE
      ) {
        return false;
      }
      //Check if current state is not completed
      if (!getIn(values, `${currentFlow.getStep().stateFieldName}`).completed) {
        return true;
      }
      return false;
    },
    [steps, user, values, flow]
  );
  return (
    <div className="wizard-step__container">
      {steps.map((s, i) => (
        <Step
          key={`step-${s.label}`}
          label={`${i + 1}.${s.label}`}
          url={s.url}
          selected={selectedIndex === i}
          disabled={isDisabled(s, i)}
          stateFieldName={s.stateFieldName}
        />
      ))}
    </div>
  );
};

export default Stepper;
