import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  paneContainer: {
    padding: "3px 10px 0",
    width: "100%",
    height: "100%",
    minHeight: "100%", // Set the minimum height to 100% of the viewport height
    overflow: "auto",
    overflowX: "hidden",
    "& > div > .ReactVirtualized__Grid": {
      // Add extra space below virtualized list
      // to indicate you've reached the bottom
      paddingBottom: 40,
    },
  },
}));

export default useStyles;
