import React from "react";

const Header = ({
  title,
  subtitle,
  enrollmentFee,
  ...rest
}: {
  title: string;
  subtitle?: string;
  enrollmentFee?: string;
  [x: string]: unknown;
}) => {
  return (
    <div className="header-component" {...rest}>
      <h3>
        <b style={{ color: "#000000" }}>{title}</b>
      </h3>
      {subtitle && (
        <p style={{ fontSize: "16px", color: "#333333" }}>{subtitle}</p>
      )}
      {enrollmentFee && <p style={{ fontSize: "14px" }}>{enrollmentFee}</p>}
    </div>
  );
};

export default Header;
