import React, { useContext, useState } from "react";
import { Formik, Form, FormikState } from "formik";
import ProfileToast from "../../../utility/ProfileToast";
import { changePassword } from "../../../api/identity/Queries";
import * as Yup from "yup";
import { useConfig } from "../../../configuration/useConfig";
import { UserContext } from "../../../context/UserProvider";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../../axios.instance";
interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
type formCallback = (
  n: Partial<FormikState<ChangePasswordValues>> | undefined
) => void;

const ChangePassword = () => {
  const { config } = useConfig();
  async function changePasswordEmail() {
    axiosInstance
      .post(
        "https://" + config["idp.issuer"] + "/dbconnections/change_password",
        {
          client: config["idp.clientId"],
          email: userInfo?.email,
          connection: config["client"],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {})
      .catch((e) => console.log(e.toJSON()));
  }

  const initialValues: ChangePasswordValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const { client } = useConfig();
  const { user } = useContext(UserContext);
  const userInfo = user.info;
  const [isResetPassword, setIsResetPassword] = useState(false);
  const handlePasswordChange = async ({
    currentPassword,
    newPassword,
    resetForm,
  }: {
    currentPassword: string;
    newPassword: string;
    resetForm: formCallback;
  }) => {
    try {
      const res = await changePassword({ currentPassword, newPassword });
      if (res.code === 200) {
        ProfileToast("Your account was successfully updated", true, client);
        resetForm(undefined);
      } else {
        let errorMessage = "There was an error with the password change.";
        if (res.code === 13) {
          errorMessage = "Current Password does not match, please try again.";
        }
        ProfileToast(errorMessage, false, client);
      }
    } catch (Error) {
      const errorMessage = "There was an error with the password change.";
      ProfileToast(errorMessage, false, client);
    }
  };

  const resetButton = (props: {
    handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
    isValid: unknown;
  }) => (
    <>
      <Form
        onSubmit={props.handleSubmit}
        style={{
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <button
          // disabled={props.isSubmitting}
          disabled={!props.isValid}
          type="submit"
          className="nav-btn-enroll mt-2 font-weight-bold btn btn-primary"
          onClick={() => {
            setIsResetPassword(true);
            changePasswordEmail();
          }}
        >
          Reset My Password
        </button>
      </Form>
    </>
  );

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().min(1).required("Required"),
    newPassword: Yup.string()
      .required()
      .min(8)
      .matches(RegExp("(.*[a-z].*)"), "Lowercase")
      .matches(RegExp("(.*[A-Z].*)"), "Uppercase")
      .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Special")
      .defined("Password must be defined."),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.newPassword === value;
      }
    ),
  });
  return (
    <div className="change-password">
      <span className="change-password__title">Change Password</span>
      <p className="mb-2">
      Lost or forgot your password? No worries! We can email you a link to reset your password.
      </p>
      <Formik
        key="password"
        initialValues={initialValues}
        validationSchema={changePasswordSchema}
        onSubmit={(values, { resetForm }) => {
          const currentPassword = values.currentPassword;
          const newPassword = values.newPassword;
          handlePasswordChange({
            currentPassword,
            newPassword,
            resetForm,
          });
        }}
      >
        {!isResetPassword ? (
          resetButton
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              columnGap: "1rem",
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
            <p style={{ color: "green", fontSize: "1rem" }}>
              Password reset link sent to: <b>{userInfo?.email}</b>
            </p>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
