import React from "react";
import { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { Spinner } from "react-bootstrap";

const MembershipCard = () => {
  const { memberCardBaseImage } = useContext(AccountContext);
  return (
    <div className="membership-card">
      {memberCardBaseImage !== "" ? (
        <img src={`data:image/png;base64,${memberCardBaseImage}`} alt="card" />
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default MembershipCard;
