import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

const Question = ({
  question,
  answer,
  index,
}: {
  question: string;
  answer: React.ReactNode;
  index: number;
}) => {
  const [open, setOpen] = useState(false);
  const [chevronUp, setChevronUp] = useState(false);
  return (
    <Accordion className="faq-section__accordion">
      <Card className="faq-section__card">
        <Accordion.Toggle
          as={Card.Header}
          eventKey={`${index}`}
          className="faq-section__question"
          onClick={() => setOpen(!open)}
        >
          {question}
          {chevronUp ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey={`${index}`}
          onExited={() => setChevronUp(false)}
          onEntered={() => setChevronUp(true)}
        >
          <div>
            <Card.Body className="faq-section__body">
              <div className="faq-section">
                <div className="">{answer}</div>
              </div>
            </Card.Body>
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Question;
