import React, { useState, useEffect, useContext } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { useTheme } from "react-jss";
import SuperCluster from "supercluster";
import { CSSTransition } from "react-transition-group";
import StudioCluster from "./StudioCluster";
import MarkerCluster from "./MarkerCluster";
import Marker from "./Marker";
import CenterPin from "./CenterPin";
import Loader from "./Loader";
import useStyles from "./styles";
import { LocationsContext } from "../../../context/LocationsProvider";
import isEmpty from "../../../utility/isEmpty";
import { useConfig } from "../../../configuration/useConfig";

// TODO: Do not hardcode the api key
// TODO: Default search radius handled by the api

const Map = ({ ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const {
    radius,
    loading,
    locations,
    studios,
    locationDetail,
    studioDetail,
    isMapLoaded,
    setIsMapLoaded,
    visibleLocations,
    visibleStudios,
    bounds,
    setBounds,
    searchCenter,
    setSearchCenter,
    hasValidSearch,
    noResults,
    setNoLocations,
    // setNoStudios,
    tierCount,
    currentTier,
    tierNameList,
  } = useContext(LocationsContext);
  const defaultProps = {
    clusterRadius: 60,
    center: {
      lat: 39.5,
      lng: -98.35,
    },
    mapZoom: 4,
  };

  const [mapsObj, setMapsObj] = useState({});
  const [size, setSize] = useState({});
  const [mapCenter, setMapCenter] = useState(defaultProps.center);
  const [mapZoom, setMapZoom] = useState(defaultProps.mapZoom);
  const [clusters, setClusters] = useState();
  const [studioClusters, setStudioClusters] = useState();
  const [superClusters, setSuperClusters] = useState();
  const [superStudioClusters, setSuperStudioClusters] = useState();
  const [showNewSearchBtn, setShowNewSearchBtn] = useState();
  const { config } = useConfig();

  // Recreate bounds format used by google-map-react
  const createBounds = (boundsObj) => {
    return {
      ne: {
        lat: boundsObj.getNorthEast().lat(),
        lng: boundsObj.getNorthEast().lng(),
      },
      sw: {
        lat: boundsObj.getSouthWest().lat(),
        lng: boundsObj.getSouthWest().lng(),
      },
      // FIXME: This is kinda hacky. Need this to make available
      // to Provider's setVisibleLocations.
      methods: boundsObj,
    };
  };

  useEffect(() => {
    if (props.noGyms) {
      setNoLocations();
    }
  }, [props.noGyms]);

  // temp solution - fetches studioClusters/markers after reload
  // disables reload after one instance
  // useEffect(() => {
  //   if (props.isPackagesPage && !localStorage.getItem("reload")) {
  //     localStorage.setItem("reload", "true");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 3000);
  //   } else {
  //     localStorage.removeItem("reload");
  //   }
  // }, []);

  useEffect(() => {
    if (isMapLoaded && !loading && hasValidSearch) {
      const viewableLocations = locations;
      const isNotLocationDetail = isEmpty(locationDetail);
      const viewableStudios = studios;
      const isNotStudioDetail = isEmpty(studioDetail);
      const currentBounds = new mapsObj.maps.LatLngBounds();
      const isMapBoundsLoaded = !isEmpty(mapsObj.maps);

      if (isMapBoundsLoaded) {
        // No Results
        if (noResults) {
          setMapCenter(searchCenter);
          setMapZoom(7);
          return;
        }
        // For Studio view
        if (props.isStudio) {
          // Single Result / Location Detail
          if (viewableStudios.length === 1 || !isNotStudioDetail) {
            const singleStudio = isNotStudioDetail
              ? viewableStudios[0]
              : studioDetail;

            // Set center & zoom when a specific location is selected
            // OR if there's only 1 search result as we need to explicitly set the
            // zoom & center to that location or it will move map to the other side
            // of the world since fitBounds expects multiple markers
            setMapCenter({
              lat: singleStudio.contactInfoViewModel.latitude,
              lng: singleStudio.contactInfoViewModel.longitude,
            });
            setMapZoom(isNotStudioDetail ? 14 : 16);
          }
          if (viewableStudios.length > 1 && isNotStudioDetail) {
            viewableStudios.forEach((marker) => {
              currentBounds.extend(
                new mapsObj.maps.LatLng(
                  marker.contactInfoViewModel.latitude,
                  marker.contactInfoViewModel.longitude
                )
              );
            });
            const newBounds = createBounds(currentBounds);
            const { center: newCenter, zoom: newZoom } = fitBounds(newBounds, {
              width: size.width,
              height: size.height,
            });

            setBounds(newBounds);
            setMapCenter(newCenter);
            setMapZoom(newZoom);
          }
        } else {
          // Single Result / Location Detail
          if (viewableLocations.length === 1 || !isNotLocationDetail) {
            const singleLocation = isNotLocationDetail
              ? viewableLocations[0]
              : locationDetail;

            // Set center & zoom when a specific location is selected
            // OR if there's only 1 search result as we need to explicitly set the
            // zoom & center to that location or it will move map to the other side
            // of the world since fitBounds expects multiple markers
            setMapCenter({
              lat: singleLocation.latitude,
              lng: singleLocation.longitude,
            });
            setMapZoom(isNotLocationDetail ? 14 : 16);
          }
          if (viewableLocations.length > 1 && isNotLocationDetail) {
            viewableLocations.forEach((marker) => {
              currentBounds.extend(
                new mapsObj.maps.LatLng(marker.latitude, marker.longitude)
              );
            });
            const newBounds = createBounds(currentBounds);
            const { center: newCenter, zoom: newZoom } = fitBounds(newBounds, {
              width: size.width,
              height: size.height,
            });

            setBounds(newBounds);
            setMapCenter(newCenter);
            setMapZoom(newZoom);
          }
          if (
            (props.isPackagesPage && viewableStudios.length === 1) ||
            !isNotStudioDetail
          ) {
            const singleStudio = isNotStudioDetail
              ? viewableStudios[0]
              : studioDetail;

            // Set center & zoom when a specific location is selected
            // OR if there's only 1 search result as we need to explicitly set the
            // zoom & center to that location or it will move map to the other side
            // of the world since fitBounds expects multiple markers
            setMapCenter({
              lat: singleStudio.contactInfoViewModel.latitude,
              lng: singleStudio.contactInfoViewModel.longitude,
            });
            setMapZoom(isNotStudioDetail ? 14 : 16);
          }
          if (
            props.isPackagesPage &&
            viewableStudios.length > 1 &&
            isNotStudioDetail
          ) {
            viewableStudios.forEach((marker) => {
              currentBounds.extend(
                new mapsObj.maps.LatLng(
                  marker.contactInfoViewModel.latitude,
                  marker.contactInfoViewModel.longitude
                )
              );
            });
            const newBounds = createBounds(currentBounds);
            const { center: newCenter, zoom: newZoom } = fitBounds(newBounds, {
              width: size.width,
              height: size.height,
            });

            setBounds(newBounds);
            setMapCenter(newCenter);
            setMapZoom(newZoom);
          }
        }
      }
    }
    // FIXME: Can't add setBounds as it will trigger infinite loop. Figure out why
    // eslint-disable-next-line
  }, [
    noResults,
    hasValidSearch,
    isMapLoaded,
    locations,
    locationDetail,
    studios,
    studioDetail,
    size.height,
    size.width,
    mapsObj.map,
    mapsObj.maps,
    loading,
    radius,
    searchCenter,
  ]);

  // Update the clustered Locations
  useEffect(() => {
    const createStudioClusters = async () => {
      // Add GeoJSON format https://geojson.org/ for supercluster
      // **NOTE** They use the order [longitude, latitude] due to some
      // academic mathematical reasons instead of the natural order of
      // [latitude, longitude] used literally everywhere else. 🤦‍
      const convertedVisibleStudios = visibleStudios.map((location) => {
        const newLocation = {
          ...location,
          distance: 1,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              location.contactInfoViewModel.longitude,
              location.contactInfoViewModel.latitude,
            ],
          },
          properties: {
            name: location.name,
          },
        };
        return newLocation;
      });

      let newClusters = [];
      const dynamicRadius = () => {
        if (mapZoom === 11) return defaultProps.clusterRadius - 5;
        if (mapZoom === 12) return defaultProps.clusterRadius - 20;
        return defaultProps.clusterRadius;
      };

      if (mapZoom < 13) {
        const superCluster = new SuperCluster({
          radius: dynamicRadius(),
        }).load(convertedVisibleStudios);
        setSuperStudioClusters(superCluster);
        newClusters = superCluster.getClusters(
          [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
          mapZoom
        );
        // Add children locations to cluster so we can
        // query against them
        newClusters.forEach((cluster) => {
          if (cluster.properties.cluster) {
            cluster.children = superCluster.getChildren(cluster.id);
          }
        });
      } else {
        newClusters = convertedVisibleStudios;
      }
      setStudioClusters(newClusters);
      return newClusters;
    };
    if (isMapLoaded && visibleStudios.length > 0 && props.isStudio) {
      createStudioClusters();
    } else if (visibleStudios.length === 0 && props.isStudio) {
      setStudioClusters([]);
    }

    if (isMapLoaded && visibleStudios.length > 0 && props.isPackagesPage) {
      createStudioClusters();
    } else if (visibleStudios.length === 0 && props.isPackagesPage) {
      setStudioClusters([]);
    }
    const createClusters = () => {
      // Add GeoJSON format https://geojson.org/ for supercluster
      // **NOTE** They use the order [longitude, latitude] due to some
      // academic mathematical reasons instead of the natural order of
      // [latitude, longitude] used literally everywhere else. 🤦‍
      const convertedVisibleLocations = visibleLocations.map((location) => {
        const newLocation = {
          ...location,
          distance: 1,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.longitude, location.latitude],
          },
          properties: {
            name: location.name,
          },
        };
        return newLocation;
      });

      let newClusters = [];
      const dynamicRadius = () => {
        if (mapZoom === 11) return defaultProps.clusterRadius - 5;
        if (mapZoom === 12) return defaultProps.clusterRadius - 20;
        return defaultProps.clusterRadius;
      };

      if (mapZoom < 13) {
        const superCluster = new SuperCluster({
          radius: dynamicRadius(),
        }).load(convertedVisibleLocations);
        setSuperClusters(superCluster);
        newClusters = superCluster.getClusters(
          [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat],
          mapZoom
        );
        // Add children locations to cluster so we can
        // query against them
        newClusters.forEach((cluster) => {
          if (cluster.properties.cluster) {
            cluster.children = superCluster.getChildren(cluster.id);
          }
        });
      } else {
        newClusters = convertedVisibleLocations;
      }
      setClusters(newClusters);
      return newClusters;
    };
    if (isMapLoaded && visibleLocations.length > 0 && !props.isStudio) {
      createClusters();
    } else if (visibleLocations.length === 0 && !props.isStudio) {
      setClusters([]);
    }
  }, [
    isMapLoaded,
    visibleLocations,
    visibleStudios,
    mapZoom,
    defaultProps.clusterRadius,
    bounds,
  ]);

  const apiIsLoaded = (map, maps) => {
    setMapsObj({ map, maps });
    setIsMapLoaded(!isEmpty({ map, maps }));
    setBounds(createBounds(map.getBounds()));
  };

  const handleChange = (newMapCenter, zoom, newBounds, newSize) => {
    if (newMapCenter) setMapCenter(newMapCenter);
    const { zoom: newZoom } = fitBounds(newBounds, size);
    if (newZoom) setMapZoom(newZoom);
    if (newBounds && !isEmpty(mapsObj)) {
      setBounds({ ...newBounds, methods: mapsObj.map.getBounds() });
    }
    if (newSize) setSize(newSize);

    // Show search this area button
    if (!isEmpty(bounds) && newBounds) {
      const latDifference = Math.abs(bounds.ne.lat - bounds.sw.lat);
      const lngDifference = Math.abs(bounds.ne.lng - bounds.sw.lng);
      const latPercentChanged = Math.abs(
        (mapCenter.lat - newMapCenter.lat) / latDifference
      );
      const lngPercentChanged = Math.abs(
        (mapCenter.lng - newMapCenter.lng) / lngDifference
      );
      setShowNewSearchBtn(
        !!(latPercentChanged > 0.25 || lngPercentChanged > 0.25)
      );
    }
  };

  const handleClusterClick = (cluster) => {
    setMapCenter({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
    setMapZoom(superClusters.getClusterExpansionZoom(cluster.id));
  };

  const handleStudioClusterClick = (cluster) => {
    setMapCenter({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
    setMapZoom(superStudioClusters.getClusterExpansionZoom(cluster.id));
  };

  const handleRedoSearch = () => {
    setShowNewSearchBtn(false);
    setSearchCenter(mapCenter);
  };

  // const studioClustersList = () => {
  //   studioClusters.map((visibleItem) => {
  //     if (visibleItem.properties.cluster) {
  //       return (
  //         <MarkerCluster
  //           key={`cluster-${visibleItem.id}`}
  //           lat={visibleItem.geometry.coordinates[1]}
  //           lng={visibleItem.geometry.coordinates[0]}
  //           count={visibleItem.properties.point_count_abbreviated}
  //           locations={visibleItem.children}
  //           onClick={() => handleStudioClusterClick(visibleItem)}
  //         />
  //       );
  //     }

  //     return (
  //       <Marker
  //         key={`marker-${visibleItem.id}`}
  //         lat={visibleItem.geometry.coordinates[1]}
  //         lng={visibleItem.geometry.coordinates[0]}
  //         name={visibleItem.name}
  //         location={visibleItem}
  //         isStudio={props.isStudio}
  //       />
  //     );
  //   });
  // };

  // const gymClustersList = () => {
  //   clusters.map((visibleItem) => {
  //     if (visibleItem.properties.cluster) {
  //       return (
  //         <MarkerCluster
  //           key={`cluster-${visibleItem.id}`}
  //           lat={visibleItem.geometry.coordinates[1]}
  //           lng={visibleItem.geometry.coordinates[0]}
  //           count={visibleItem.properties.point_count_abbreviated}
  //           locations={visibleItem.children}
  //           onClick={() => handleClusterClick(visibleItem)}
  //         />
  //       );
  //     }

  //     return (
  //       <Marker
  //         key={`marker-${visibleItem.id}`}
  //         lat={visibleItem.latitude}
  //         lng={visibleItem.longitude}
  //         name={visibleItem.name}
  //         location={visibleItem}
  //         isStudio={props.isStudio}
  //       />
  //     );
  //   });
  // };

  return (
    // Important! Always set the container height explicitly
    <div className={classes.map}>
      {props.isPackagesPage || props.isStudio || props.isLuxuryModal ? null : (
        <div className={classes.locationFinderTotalGyms}>
          <div className={classes.tierBanner}>
            <span className={classes.tierBannerSpan}>
              {tierNameList && tierNameList[currentTier - 1]}
            </span>
          </div>
          <div className={classes.tierNameList}>
            <span className={classes.tierNameListSpan}>{tierCount}</span>
          </div>
          <div className={classes.tierCount}>
            <span className={classes.gymsText}>GYMS</span>
            <span className={classes.gymsText}>NATIONWIDE</span>
          </div>
        </div>
      )}
      <div className={classes.redoSearchWrapper}>
        <CSSTransition
          in={showNewSearchBtn && hasValidSearch && isEmpty(locationDetail)}
          timeout={200}
          classNames={{
            appear: classes.appear,
            enter: classes.enter,
            enterDone: classes.enterDone,
            exit: classes.exit,
            exitDone: classes.exitDone,
          }}
        >
          <button
            type="button"
            onClick={handleRedoSearch}
            className={classes.redoSearch}
          >
            Search this area
          </button>
        </CSSTransition>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          libraries: "places",
          key: config["google.maps.key"],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.mapZoom}
        resetBoundsOnResize
        center={mapCenter}
        zoom={mapZoom}
        hoverDistance={45}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          apiIsLoaded(map, maps);
        }}
        onChange={({
          center: newMapCenter,
          zoom: newZoom,
          bounds: newBounds,
          size: newSize,
        }) => handleChange(newMapCenter, newZoom, newBounds, newSize)}
        options={{
          disableDefaultUI: props.isLuxuryModal,
          zoomControl: true,
        }}
      >
        {/* Need to have separate lat/lng props for GoogleMapReact */}
        <CenterPin
          key="search-location-pin"
          lat={(searchCenter && searchCenter.lat) || defaultProps.center.lat}
          lng={(searchCenter && searchCenter.lng) || defaultProps.center.lng}
          className={classes.locationPin}
        />

        {props.isStudio &&
          !props.isPackagesPage &&
          studios.length &&
          studioClusters &&
          studioClusters.map((visibleItem) => {
            if (visibleItem.properties.cluster) {
              return (
                <StudioCluster
                  key={`cluster-${visibleItem.id}`}
                  lat={visibleItem.geometry.coordinates[1]}
                  lng={visibleItem.geometry.coordinates[0]}
                  count={visibleItem.properties.point_count_abbreviated}
                  locations={visibleItem.children}
                  onClick={() => handleStudioClusterClick(visibleItem)}
                />
              );
            }

            return (
              <Marker
                key={`marker-${visibleItem.id}`}
                lat={visibleItem.geometry.coordinates[1]}
                lng={visibleItem.geometry.coordinates[0]}
                name={visibleItem.name}
                location={visibleItem}
                isStudio={props.isStudio}
              />
            );
          })}
        {!props.isStudio &&
          !props.isPackagesPage &&
          locations.length &&
          clusters &&
          clusters.map((visibleItem) => {
            if (visibleItem.properties.cluster) {
              return (
                <MarkerCluster
                  key={`cluster-${visibleItem.id}`}
                  lat={visibleItem.geometry.coordinates[1]}
                  lng={visibleItem.geometry.coordinates[0]}
                  count={visibleItem.properties.point_count_abbreviated}
                  locations={visibleItem.children}
                  onClick={() => handleClusterClick(visibleItem)}
                />
              );
            }

            return (
              <Marker
                key={`marker-${visibleItem.id}`}
                lat={visibleItem.latitude}
                lng={visibleItem.longitude}
                name={visibleItem.name}
                location={visibleItem}
                isStudio={props.isStudio}
              />
            );
          })}
        {props.isPackagesPage &&
          locations.length &&
          clusters &&
          clusters.map((visibleItem) => {
            if (visibleItem.properties.cluster) {
              return (
                <MarkerCluster
                  key={`cluster-${visibleItem.id}`}
                  lat={visibleItem.geometry.coordinates[1]}
                  lng={visibleItem.geometry.coordinates[0]}
                  count={visibleItem.properties.point_count_abbreviated}
                  locations={visibleItem.children}
                  onClick={() => handleClusterClick(visibleItem)}
                />
              );
            }

            return (
              <Marker
                key={`marker-${visibleItem.id}`}
                lat={visibleItem.latitude}
                lng={visibleItem.longitude}
                name={visibleItem.name}
                location={visibleItem}
                isStudio={false}
              />
            );
          })}
        {props.isPackagesPage &&
          studios.length &&
          studioClusters &&
          studioClusters.map((visibleItem) => {
            if (visibleItem.properties.cluster) {
              return (
                <StudioCluster
                  key={`cluster-${visibleItem.id}`}
                  lat={visibleItem.geometry.coordinates[1]}
                  lng={visibleItem.geometry.coordinates[0]}
                  count={visibleItem.properties.point_count_abbreviated}
                  locations={visibleItem.children}
                  onClick={() => handleStudioClusterClick(visibleItem)}
                />
              );
            }

            return (
              <Marker
                key={`marker-${visibleItem.id}`}
                lat={visibleItem.geometry.coordinates[1]}
                lng={visibleItem.geometry.coordinates[0]}
                name={visibleItem.name}
                location={visibleItem}
                isStudio={true}
              />
            );
          })}
      </GoogleMapReact>
      {loading && <Loader />}
    </div>
  );
};

export default Map;
