/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from "react";
import { defaultConfig, DynamicConfig, defaultClientConfig } from "./config";
import axiosInstance from "../axios.instance";
import { ConfigResponse } from "../utility/member-portal-api-types";

interface DynamicConfigContext {
  client: string;
  config: DynamicConfig;
  clientConfig: ConfigResponse;
  setConfig: (newConfig: DynamicConfig) => void;
  getSiteConfig: () => void;
  setClient: (newClient: string) => void;
}

export const ConfigContextObject = React.createContext<DynamicConfigContext>({
  client: "",
  config: defaultConfig,
  clientConfig: defaultClientConfig,
  setConfig: () => {},
  getSiteConfig: () => {},
  setClient: () => {},
});

export const useConfig = () => useContext(ConfigContextObject);

const ConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [configState, setConfigState] = useState(defaultConfig);
  const [clientConfigState, setClientConfigState] =
    useState(defaultClientConfig);

  const getClientConfig = async () => {
    try {
      const { data } = await axiosInstance.get("/config");
      setClientConfigState(data);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        console.error("Client config not found");
        // Handle the 404 error here, such as showing an error message to the user or taking appropriate action.
      } else {
        console.error("Error fetching client config:", error);
        // Handle other errors that may occur during the request.
      }
    }
  };
  return (
    <ConfigContextObject.Provider
      value={{
        client: "",
        config: configState,
        clientConfig: clientConfigState,
        setConfig: setConfigState,
        getSiteConfig: getClientConfig,
        setClient: (newClient: string) => {
          setConfigState((prevState) => {
            const newConfig = {
              ...prevState,
              client: newClient,
            };
            axiosInstance.defaults.baseURL = `${newConfig.baseApiUrl}/clients/${newClient}`;
            return newConfig;
          });
        },
      }}
    >
      {children}
    </ConfigContextObject.Provider>
  );
};

export default ConfigContextProvider;
