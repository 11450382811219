import React, { useContext } from "react";
import { FeatureBottomBody, FeatureInput } from "../../types";
import FeatureButton from "./FeatureButton";
import { ButtonContext } from "../../context/ButtonProvider";

import { useHistory } from "react-router-dom";

const FeatureBottom = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureBottomBody;
  const { button } = useContext(ButtonContext);
  const history = useHistory();
  return (
    <div className="bottom-feature">
      <h1>
        <b>{body.header}</b>
      </h1>
      <p className="h3">{body.subText}</p>
      {input.button && (
        <FeatureButton
          contrast
          text={input.buttonText ? input.buttonText : button.text}
          onClick={
            input.buttonUrl
              ? () => history.push(input.buttonUrl)
              : button.onClick
          }
        />
      )}
    </div>
  );
};

export default FeatureBottom;
