import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useConfig } from "../../configuration/useConfig";
import { momentStartOfDay } from "../../utility/util";
import { useHistory } from "react-router-dom";

const PayrolltoNonPayrollBanner = ({ termDate }: { termDate?: string }) => {
  const history = useHistory();
  const date = momentStartOfDay(termDate).format("MM/DD/YYYY");
  const { config } = useConfig();

  return (
    <>
      <Card className="eligibility-toast__card">
        <Card.Body>
          <Row>
            <Col xs={12}>
              <p className="eligibility-toast__title">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>
                  <strong>Attention</strong>
                </span>
              </p>
            </Col>
            <Col xs={12} className="eligibility-toast__body">
              <p>
                Our records indicate that your Walmart Identification Number is
                associated with a new location; based on your new location, you
                must update your payment method. If you do not add a new payment
                method by <b>{date}</b>, you will no longer have access to the
                program. If you have any questions, please call Customer
                Service:{" "}
                <a
                  href={`tel:${config["contact.phoneNumber"]}`}
                  className="a-link"
                >
                  {config["contact.phoneNumber"]}
                </a>
              </p>
            </Col>
            <Col xs={12}>
              <button
                onClick={() => {
                  history.push("/enroll/package");
                }}
                className="col mx-2 btn btn-secondary"
                style={{ width: "250px", float: "right" }}
              >
                Add New Payment Method
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default PayrolltoNonPayrollBanner;
