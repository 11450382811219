import React from "react";

const FeatureBase = ({
  background,
  backgroundColor,
  leftAlign,
  slanted,
  children,
  size,
  colorOverlay,
  featureIndex,
  featureClassName,
  centerBackground,
  paddingRight,
}: {
  background?: string;
  backgroundColor?: string;
  slanted?: boolean;
  leftAlign?: boolean;
  children: React.ReactNodeArray | React.ReactChild;
  size: string;
  colorOverlay?: boolean;
  featureIndex?: unknown;
  featureClassName?: string;
  centerBackground?: boolean;
  paddingRight?: boolean;
}) => {
  return (
    <>
      <div className="d-none d-sm-none d-md-inline">
        <div
          className={`feature-base ${
            size === "large"
              ? "feature-base--large"
              : size === "xs"
              ? "feature-base--xs"
              : ""
          } ${featureClassName}`}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "",
          }}
          data-feature-index={featureIndex}
        >
          <div
            className={`feature-base__background ${
              slanted && "feature-base__background--slanted"
            } ${colorOverlay && "feature-base__background--color-overlay"}`}
            style={{
              backgroundImage: background ? `url('${background}')` : "",
              backgroundPosition: centerBackground
                ? "center"
                : leftAlign
                ? `right ${paddingRight ? `5% bottom 80%` : ""}`
                : "left",
              backgroundSize: centerBackground ? "cover" : "contain",
            }}
          />
          <div className="feature-base__child-container">
            {Array.isArray(children)
              ? children.map((c, i) => {
                  return (
                    <div
                      key={`feature-base-${i}`}
                      className={`feature-base__child ${
                        i % 2 === 0 ? "even" : "odd"
                      }`}
                    >
                      {c}
                    </div>
                  );
                })
              : children}
          </div>
        </div>
      </div>
      <div className="d-sm-block d-md-none">
        {background && (
          <img
            alt="background"
            src={background}
            className="d-sm-flex d-md-none position-relative img-fluid"
          />
        )}

        <div className="feature-base__child-container">
          {Array.isArray(children)
            ? children.map((c, i) => {
                return (
                  <div
                    key={`feature-base-${i}`}
                    className={`feature-base__child ${
                      i % 2 === 0 ? "even" : "odd"
                    }`}
                  >
                    {c}
                  </div>
                );
              })
            : children}
        </div>
      </div>
    </>
  );
};

export default FeatureBase;
