const styles = theme => ({
  button: {
    display: 'inline-block',
    // fontFamily: $sans-serif,
    fontWeight: '700',
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundColor: 'white',
    border: '1px solid transparent',
    padding: '7.5px 12px',
    fontSize: '16px',
    lineHeight: 1.5,
    borderRadius: '8px',
    transition: 'all 0.15s ease-in-out',
    textDecoration: 'none',
    boxShadow: '0px 3px 5px 0px black',

    '&:hover, &:focus, &:active': {
      textDecoration: 'none',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },

    '& + .button': {
      marginLeft: '10px',
    },
  },

  //
  // Color Variants
  //

  primary: {
    color: 'white',
    backgroundColor: theme.accessible.primary.color,

    '&:hover, &:focus, &:active': {
      backgroundColor: theme.accessible.primary.hoverColor,
    },
  },

  // secondary: {
  //   backgroundColor: $primary-blue,

  //   '&:hover, &:focus, &:active': {
  //     backgroundColor: $primary-blue-hover,
  //   },
  // }

  // danger: {
  //   backgroundColor: $primary - red,
  //   color: $white,

  //   '&:hover, &:focus, &:active': {
  //     backgroundColor: $secondary - red,
  //   },
  // },

  // Ghost Buttons
  // dangerOutline: {
  //   backgroundColor: $white,
  //   color: 'darken($primary-red, 10%)',
  //   border: 'solid 1px darken($primary-red, 10%)',

  //   '&:hover, &:focus, &:active': {
  //     backgroundColor: 'lighten($primary-red, 42%)',
  //   },
  // },

  // //
  // // Link Buttons
  // //
  // 'link, linkLight, linkDark': {
  //   backgroundColor: 'transparent',
  //   boxShadow: 'none',

  //   '&:hover, &:focus, &:active': {
  //     textDecoration: 'underline',
  //   },
  // },

  // link: {
  //   color: 'inherit',
  // },

  // linkPrimary: {
  //   color: $blue500,
  // },

  // linkSecondary: {
  //   color: $primary - blue,
  // },

  // linkLight: {
  //   color: $white,
  // },

  // linkDark: {
  //   color: $black,
  // },

  // TODO: Add additional variants
  // .light {
  // }
  // .dark {
  // }
  // .success {
  // }
  // .warning {
  // }
  // .danger {
  // }
  // .info {
  // }
  //
  // Sizes
  //
  large: {
    paddingTop: '2.5px',
    paddingBottom: '2.5px',
    fontSize: '21px',
  },

  small: {
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '16px',
  },

  //
  // Misc
  //
  isActive: {
    color: `${theme.accessible.primary.textColor}!important`,
  },

  isLoading: {
    opacity: 0.5,
  },

  isFullWidth: {
    width: '100%',
  },

  isInline: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    padding: 0,
    verticalAlign: 'inherit',
  },
})

export default styles
