import React from "react";

export function EmptyList({
  accountPageActivityLog,
  manageDependents,
  emptyActivityLog,
  billingHistoryList,
  addMemberModal,
  emptySelectedActivities,
}) {

  return (
    <>
      {accountPageActivityLog && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center height-500 border">
          <p className="text-dark-gray">You do not have any check-ins yet.</p>
        </div>
      )}
      {manageDependents && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center height-500 border">
          <p className="text-dark-gray">
            You do not have any secondary members to manage.
          </p>
        </div>
      )}
      {emptySelectedActivities && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center height-500 border">
          <p className="text-dark-gray">
            Seems that there are no activities to show for the filter
            <br /> settings you specified. Try adjusting your filter settings.
          </p>
        </div>
      )}
      {billingHistoryList && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center height-500 border border">
          <p className="text-dark-gray">
            You do not have a billing payment history yet.
          </p>
        </div>
      )}
      {addMemberModal && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center height-250 border border">
          <p className="text-dark-gray">
            You do not have any eligible dependents.
          </p>
        </div>
      )}
      {emptyActivityLog && (
        <div className="d-flex justify-content-center align-items-center w-100 text-center h-100 border border flex-column">
          <p className="my-5">No activities reported at this time.</p>
                <p>
                  Download the app now to check in at gyms or book studio <br />{" "}
                  classes.
                </p>
                <div className="apps d-flex flex-sm-row flex-column justify-content-center align-items-center mb-5">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tivityhealth.prime.wellontarget"
                    className="mr-3"
                  >
                    <img
                      src="https://cfc879fb0219e1ba.azureedge.net//wp-content/uploads/2020/04/google-play-badge.svg"
                      alt="google play store badge"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/id1453242180"
                    className="mt-3 mt-sm-0"
                  >
                    <img
                      src="https://cfc879fb0219e1ba.azureedge.net//wp-content/uploads/2020/04/apple-badge.svg"
                      alt="apple app store badge"
                    />
                  </a>
                </div>
        </div>
      )}
    </>
  );
}
