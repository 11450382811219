import { useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";

const useGetTierCount = () => {
  const [tierCount, setTierCount] = useState<unknown>(0);

  const getTierCount = {
    run: async (tier: string, programCode : string ) => {
      if (tier && tier !== "0") {
        await axiosNetworkInstance
          .get(`/location-summary?programCode=${programCode}&tierNumber=${tier}`, {
          })
          .then((response) => {
            if (response.data) {
              setTierCount(response.data.data.activeLocationCount);
            }
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
      }
    },
  };

  return {
    getTierCount,
    tierCount,
  };
};

export default useGetTierCount;
