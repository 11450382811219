import { Alert } from "react-bootstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfig } from "../../configuration/useConfig";

const AccountUpdateFailedBanner = () => {
  const { config } = useConfig();

  return (
    <Alert className="account-update-failed-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </span>
        Error Updating Plan
      </Alert.Heading>
      <div className="account-suspension-banner__body">
        There was an issue updating your plan. Please try again or call customer
        service to resolve this issue:{" "}
        <Alert.Link
          href={`tel:${config["contact.phoneNumber"]}`}
          className="alert-info__number"
        >
          {config["contact.phoneNumber"]}
        </Alert.Link>
        .
      </div>
    </Alert>
  );
};

export default AccountUpdateFailedBanner;
