import { useEffect, useState } from "react";
import { ModalDisclaimer } from "./ModalDisclaimer";
import classNames from "classnames";
import ModalContainer from "./ModalContainer";
import { PlanDependent } from "../components/ManageMembers";
import { Checkbox } from "../../../utility/Checkbox";
import { EmptyList } from "../../account/Activity/EmptyList";
import { format, parseISO } from "date-fns";

type AddDependentsModalProps = {
  dependents: PlanDependent[];
  showFamilyModal: boolean;
  setShowFamilyModal: () => void;
  getOrderPreview: (newDependents: PlanDependent[]) => void;
  setEmailValidations: (val: boolean) => void;
  isPrimaryStudioOnly: boolean;
};

const AddDependentsModal = ({
  dependents: dependentsOriginal,
  showFamilyModal,
  setShowFamilyModal,
  getOrderPreview,
  isPrimaryStudioOnly,
}: AddDependentsModalProps) => {
  const [dependents, setDependents] = useState<PlanDependent[]>([]);

  useEffect(() => {
    setDependents([...dependentsOriginal]);
  }, [dependentsOriginal]);

  const submitAddFamilyMember = () => {
    getOrderPreview(dependents);
  };

  const handleCheckboxChange = (checkedDependentId: string) => {
    const updatedDependents = dependents.map((dep) => {
      if (dep.memberId === checkedDependentId) {
        return {
          ...dep,
          isSelected: !dep.isSelected,
        };
      } else {
        return dep;
      }
    });

    setDependents(updatedDependents);
  };

  const renderCheckboxes = () => {
    const depList = dependents.filter((member) => {
      return (
        !member.isChosen &&
        ["Eligible", "UnderAgeDependent"].includes(member.status)
      );
    });
    // If primary is studio-only, adding underage dependents is not allowed
    const studioOnlyDepList = dependents.filter((member) => {
      return !member.isChosen && ["Eligible"].includes(member.status);
    });

    if (!depList.length) {
      return (
        <EmptyList
          addMemberModal
          accountPageActivityLog={undefined}
          manageDependents={undefined}
          emptyActivityLog={undefined}
          billingHistoryList={undefined}
          emptySelectedActivities={undefined}
        />
      );
    }

    return (isPrimaryStudioOnly ? studioOnlyDepList : depList).map(
      (member, i) => {
        const dobDate = parseISO(member.dateOfBirth);
        return (
          <div
            key={i}
            className={classNames(
              "addMemberModal__row",
              "justify-content-between",
              "py-3",
              "px-2",
              "d-flex",
              "border-bottom"
            )}
          >
            <div className="addMemberModal__row__name">
              <Checkbox
                text={`${member.firstName} ${member.lastName}`}
                optional
                htmlFor={`${i}`}
                checked={member.isSelected}
                onChange={() => handleCheckboxChange(member.memberId)}
                hyperLink={undefined}
                hyperLinkValue={undefined}
                name={undefined}
                classes={undefined}
              />
              <div className="addMemberModal__row__dob">
                DOB: {format(dobDate, "M/dd/yyyy")}
              </div>
            </div>
          </div>
        );
      }
    );
  };

  return (
    <ModalContainer
      isOpen={showFamilyModal}
      setIsOpen={setShowFamilyModal}
      title="Add Family"
      titleSubText="Select the family member(s) (16 years or older*) you would like to add."
      cancelText="Cancel"
      submitText="Add Member(s)"
      submit={submitAddFamilyMember}
      disclaimer={<ModalDisclaimer />}
      isDismissable={true}
    >
      <div className="addMemberModal">
        {dependents.length > 0 ? renderCheckboxes() : null}
      </div>
    </ModalContainer>
  );
};
export default AddDependentsModal;
