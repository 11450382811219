import React from "react";

export const NoPlanTileSVG = () => {
  return (
    <>
      <svg
        className="mb-2"
        xmlns="http://www.w3.org/2000/svg"
        width="60%"
        // height="700.031"
        viewBox="0 0 1068.627 700.031"
      >
        <g id="undraw_healthy_habit_bh5w" transform="translate(-0.002 -0.004)">
          <path
            id="Path_1300"
            data-name="Path 1300"
            d="M110.691,339.107c-8.046,24.358,15.61,46.265,34.217,63.908a250.668,250.668,0,0,1,58.984,85.962c8.088,19.623,13.714,40.734,12.205,61.907-4.274,60.053-60.828,100.064-106.014,139.844-6.4,5.689-12.8,11.64-16.532,19.309-11.975,24.61,8.381,52.97,30.8,68.665,79.843,55.862,195.088,39.246,272.532-19.9,40.1-30.634,74.573-72.29,123.175-85.909,31.074-8.664,64.055-4.557,96.208-1.886A1024.352,1024.352,0,0,0,761.2,672.729c16.951-1.048,34.678-2.682,48.874-12,35.946-23.552,29.88-79.393,55.82-113.652,18.648-24.62,50.508-34.112,78.24-47.721a244.78,244.78,0,0,0,102.494-95.108c22.263-37.894,33.8-80.975,45.05-123.448,8.486-31.912,17-65.333,9.817-97.57s-35.558-62.337-68.455-59.508c-25.26,2.169-45.354,22.127-69.681,29.272-58.272,17.161-114.846-42.441-175.3-36.69C750,119.922,719.308,148.786,696.1,179.168s-42.85,64.589-73.495,87.471c-47.669,35.621-112.981,36.878-171.337,25.343s-114.343-34.238-173.349-41.8c-34.762-4.453-61.4,16.26-92.709,27.648C156.485,288.284,121.336,306.891,110.691,339.107Z"
            transform="translate(-90 -115.91)"
            fill="#0071ce"
            opacity="0.1"
          />
          <ellipse
            id="Ellipse_96"
            data-name="Ellipse 96"
            cx="76.208"
            cy="23.154"
            rx="76.208"
            ry="23.154"
            transform="translate(68.319 653.729)"
            fill="#101c2c"
            opacity="0.1"
          />
          <ellipse
            id="Ellipse_97"
            data-name="Ellipse 97"
            cx="203.532"
            cy="13.505"
            rx="203.532"
            ry="13.505"
            transform="translate(661.566 367.242)"
            fill="#101c2c"
            opacity="0.1"
          />
          <g
            id="Group_2881"
            data-name="Group 2881"
            transform="translate(669.287 71.661)"
            opacity="0.5"
          >
            <path
              id="Path_1301"
              data-name="Path 1301"
              d="M708.764,68.4h0a10.644,10.644,0,0,1,10.645,10.644V375.013H698.12V79.034A10.644,10.644,0,0,1,708.764,68.4Z"
              transform="translate(-636.003 -68.4)"
              fill="#0071ce"
            />
            <path
              id="Path_1302"
              data-name="Path 1302"
              d="M931.7,68.4h0a10.644,10.644,0,0,1,10.645,10.644V375.013H921V79.034A10.645,10.645,0,0,1,931.7,68.4Z"
              transform="translate(-625.377 -68.4)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2079"
              data-name="Rectangle 2079"
              width="21.289"
              height="379.091"
              rx="10.16"
              transform="translate(379.091 44.872) rotate(90)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2080"
              data-name="Rectangle 2080"
              width="16.679"
              height="79.959"
              rx="7.96"
              transform="translate(39.11 15.527)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2081"
              data-name="Rectangle 2081"
              width="16.679"
              height="65.008"
              rx="7.96"
              transform="translate(22.431 23.007)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2082"
              data-name="Rectangle 2082"
              width="16.679"
              height="79.959"
              rx="7.96"
              transform="translate(339.971 95.485) rotate(180)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2083"
              data-name="Rectangle 2083"
              width="16.679"
              height="65.008"
              rx="7.96"
              transform="translate(356.65 88.005) rotate(180)"
              fill="#0071ce"
            />
          </g>
          <g
            id="Group_2882"
            data-name="Group 2882"
            transform="translate(819.765 195.538)"
            opacity="0.5"
          >
            <path
              id="Path_1303"
              data-name="Path 1303"
              d="M800.269,186.64H843.79a17.81,17.81,0,0,1,17.81,17.81v74.762H782.5V204.45A17.811,17.811,0,0,1,800.269,186.64Z"
              transform="translate(-782.458 -186.64)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2084"
              data-name="Rectangle 2084"
              width="11.577"
              height="38.586"
              transform="translate(33.756 85.857)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2085"
              data-name="Rectangle 2085"
              width="11.577"
              height="38.586"
              transform="translate(10.602 129.262)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2086"
              data-name="Rectangle 2086"
              width="11.577"
              height="38.586"
              transform="translate(58.837 129.262)"
              fill="#0071ce"
            />
            <rect
              id="Rectangle_2087"
              data-name="Rectangle 2087"
              width="79.1"
              height="10.613"
              transform="translate(0 121.541)"
              fill="#0071ce"
            />
          </g>
          <ellipse
            id="Ellipse_98"
            data-name="Ellipse 98"
            cx="434.073"
            cy="31.828"
            rx="434.073"
            ry="31.828"
            transform="translate(61.572 573.676)"
            fill="#101c2c"
            opacity="0.1"
          />
          <path
            id="Path_1304"
            data-name="Path 1304"
            d="M670.36,586.722s29.9,13.5,44.369,13.5c0,0,15.433,3.856,24.1,7.721s42.442,5.783,42.442,5.783,39.549,14.468,44.369,21.226,19.288-24.1,19.288-24.1L837.2,591.573,691.575,563.6Z"
            transform="translate(-62.331 -94.566)"
            fill="#b06c49"
          />
          <path
            id="Path_1305"
            data-name="Path 1305"
            d="M670.36,586.722s29.9,13.5,44.369,13.5c0,0,15.433,3.856,24.1,7.721s42.442,5.783,42.442,5.783,39.549,14.468,44.369,21.226,19.288-24.1,19.288-24.1L837.2,591.573,691.575,563.6Z"
            transform="translate(-62.331 -94.566)"
            opacity="0.05"
          />
          <path
            id="Path_1306"
            data-name="Path 1306"
            d="M811.23,627.683s18.323,24.1,10.613,38.585-4.82,14.469,0,15.433,31.828,6.757,34.73-14.469,8.685-68.486,8.685-68.486l-39.6-3.866S836.312,630.574,811.23,627.683Z"
            transform="translate(-55.615 -93.074)"
            fill="#737a9d"
          />
          <path
            id="Path_1307"
            data-name="Path 1307"
            d="M593.94,555.992,676.9,594.578s26.045-20.262,24.1-30.865L604.532,540.56Z"
            transform="translate(-65.974 -95.664)"
            fill="#4c4c78"
          />
          <path
            id="Path_1308"
            data-name="Path 1308"
            d="M593.94,555.992,676.9,594.578s26.045-20.262,24.1-30.865L604.532,540.56Z"
            transform="translate(-65.974 -95.664)"
            opacity="0.1"
          />
          <path
            id="Path_1309"
            data-name="Path 1309"
            d="M936.283,606.289l-8.716,21.216-20.221,4.8a129.168,129.168,0,0,0-18.114-12.656c-10.886-6.286-25.207-12.436-38.764-11.461,0,0-54.018-8.686-56.91-12.573,0,0-34.731-7.721-42.441-6.757-3.719.461-11.713-2.21-18.984-5.113-7.8-3.143-14.782-6.464-14.782-6.464l25.081-39.55a67.437,67.437,0,0,0,9.01,3.143c9.586,2.808,26.842,6.454,51.766,6.454,40.514,0,96.46,41.477,96.46,41.477s.555.189,1.508.472c4.191,1.268,16.135,4.253,21.645-.472C929.525,583.136,936.283,606.289,936.283,606.289Z"
            transform="translate(-60.09 -95.799)"
            fill="#b06c49"
          />
          <path
            id="Path_1310"
            data-name="Path 1310"
            d="M928.461,604l-8.717,21.216-20.22,4.8a129.21,129.21,0,0,0-18.114-12.656c29.418,2.21,11.849-30.268,11.849-30.268h.063c4.191,1.268,16.134,4.254,21.645-.471C921.7,580.849,928.461,604,928.461,604Z"
            transform="translate(-52.268 -93.512)"
            opacity="0.1"
          />
          <path
            id="Path_1311"
            data-name="Path 1311"
            d="M881.52,618.212s23.332,32.719,13.515,51.147-6.139,18.418,0,19.654,40.535,8.6,44.212-18.418c2.829-20.776.345-60.65-.954-77.958a8.264,8.264,0,0,0-8.988-7.606l-34.123,3.143S913.453,621.9,881.52,618.212Z"
            transform="translate(-52.263 -93.546)"
            fill="#737a9d"
          />
          <path
            id="Path_1312"
            data-name="Path 1312"
            d="M262.71,531.889l.964,47.271,10.613,7.711h36.658l6.747-9.638V531.889l-10.613-9.649h-34.72Z"
            transform="translate(12.525 24.898)"
            fill="#0071ce"
          />
          <path
            id="Path_1313"
            data-name="Path 1313"
            d="M358.493,648.28s6.758,43.406,0,47.271l-4.819-.964-.964-46.3Z"
            transform="translate(-77.475 -90.529)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_1314"
            data-name="Path 1314"
            d="M433.731,644.952l7.721-7.722h36.669l7.721,7.722s9.649,27.973,1.928,46.3l-8.707,8.685H442.4l-8.686-7.722Z"
            transform="translate(-73.613 -91.056)"
            fill="#0071ce"
          />
          <path
            id="Path_1315"
            data-name="Path 1315"
            d="M262.71,531.889l.964,47.271,10.613,7.711h36.658l6.747-9.638V531.889l-10.613-9.649h-34.72Z"
            transform="translate(12.525 24.898)"
            fill="#101c2c"
            opacity="0.1"
          />
          <path
            id="Path_1316"
            data-name="Path 1316"
            d="M433.731,644.952l7.721-7.722h36.669l7.721,7.722s9.649,27.973,1.928,46.3l-8.707,8.685H442.4l-8.686-7.722Z"
            transform="translate(-73.613 -91.056)"
            fill="#101c2c"
            opacity="0.1"
          />
          <path
            id="Path_1317"
            data-name="Path 1317"
            d="M442.4,646.528s7.721,33.766,0,45.332H433.71V644.6Z"
            transform="translate(-73.613 -90.704)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_1318"
            data-name="Path 1318"
            d="M405.19,491.76s3.856,37.622,3.856,42.441-1.928,36.669,3.855,67.523-3.855,69.44-3.855,69.44h31.828l13.5-104.181L444.729,512l-3.855-17.36Z"
            transform="translate(-74.973 -97.991)"
            fill="#b06c49"
          />
          <path
            id="Path_1319"
            data-name="Path 1319"
            d="M405.19,491.76s3.856,37.622,3.856,42.441-1.928,36.669,3.855,67.523-3.855,69.44-3.855,69.44h31.828l13.5-104.181L444.729,512l-3.855-17.36Z"
            transform="translate(-74.973 -97.991)"
            opacity="0.05"
          />
          <path
            id="Path_1320"
            data-name="Path 1320"
            d="M400.659,400.643s-42.442.964-8.686,71.378c0,0,8.686,28.937,8.686,33.767s43.405,4.819,48.193,0-7.722-44.369-7.722-62.7S428.632,394.86,400.659,400.643Z"
            transform="translate(-76.225 -102.37)"
            fill="#cbcdda"
          />
          <path
            id="Path_1321"
            data-name="Path 1321"
            d="M400.659,400.643s-42.442.964-8.686,71.378c0,0,8.686,28.937,8.686,33.767s43.405,4.819,48.193,0-7.722-44.369-7.722-62.7S428.632,394.86,400.659,400.643Z"
            transform="translate(-76.225 -102.37)"
            opacity="0.05"
          />
          <path
            id="Path_1322"
            data-name="Path 1322"
            d="M351.834,361s36.669,39.549,67.523,10.613L415.5,473.866S365.339,413.1,330.64,417.92Z"
            transform="translate(-78.527 -104.225)"
            fill="#b06c49"
          />
          <path
            id="Path_1323"
            data-name="Path 1323"
            d="M751.4,540.985c-1.466,11.3-6.076,35.107-19.309,42.819-7.805-3.143-14.782-6.465-14.782-6.465l25.081-39.55A67.462,67.462,0,0,0,751.4,540.985Z"
            transform="translate(-60.092 -95.796)"
            opacity="0.1"
          />
          <path
            id="Path_1324"
            data-name="Path 1324"
            d="M757.007,539.264s-2.891,47.271-26.046,51.126L591.118,565.163a42.211,42.211,0,0,1-18.586-8.381c-5.647-4.442-12.572-10.477-16.668-16.009-2.221-3.017-3.562-5.9-3.342-8.277.964-10.613,43.405-35.694,28.937-80.063a26.444,26.444,0,0,1,6.789-3.9c12.08-5.019,39.885-8.706,80.021,32.834C668.269,481.422,742.538,541.234,757.007,539.264Z"
            transform="translate(-67.95 -100.194)"
            fill="#4c4c78"
          />
          <path
            id="Path_1325"
            data-name="Path 1325"
            d="M296.78,535.569l.964,47.271,10.6,7.721H345l6.757-9.649V535.569L341.15,525.93H306.419Z"
            transform="translate(14.149 25.074)"
            fill="#0071ce"
          />
          <path
            id="Path_1326"
            data-name="Path 1326"
            d="M392.563,652s6.757,43.406,0,47.271l-4.819-.964L386.78,652Z"
            transform="translate(-75.851 -90.351)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_1327"
            data-name="Path 1327"
            d="M488.06,647.711l7.721-7.721H532.45l7.722,7.721s9.649,27.973,1.927,46.3l-8.685,8.686H496.745l-8.685-7.722Z"
            transform="translate(-71.022 -90.924)"
            fill="#0071ce"
          />
          <path
            id="Path_1328"
            data-name="Path 1328"
            d="M496.735,649.288s7.721,33.767,0,45.333H488.05V647.36Z"
            transform="translate(-71.022 -90.573)"
            fill="#fff"
            opacity="0.2"
          />
          <path
            id="Path_1329"
            data-name="Path 1329"
            d="M439.336,664.86h52.185c4.086,0,7.4,3.667,7.4,8.2h0c0,4.526-3.311,8.2-7.4,8.2H439.336c-4.431,0-7.868-4.285-7.334-9.157h0C432.432,667.971,435.6,664.86,439.336,664.86Z"
            transform="translate(-73.697 -89.738)"
            fill="#0071ce"
          />
          <path
            id="Path_1330"
            data-name="Path 1330"
            d="M584.339,524.731c-10.32,7.229-21.195,11.965-28.486,16-2.221-3.018-3.562-5.9-3.342-8.277.964-10.613,43.4-35.695,28.937-80.064a26.451,26.451,0,0,1,6.789-3.9,29.464,29.464,0,0,0,6.715-.922S603.626,511.227,584.339,524.731Z"
            transform="translate(-67.95 -100.098)"
            opacity="0.1"
          />
          <path
            id="Path_1331"
            data-name="Path 1331"
            d="M376.221,435.918s14.468,14.469,28.455,23.635c12.908,8.455-10.131-76.69-10.131-76.69s14.291-11.524,22.682-18.366a28.746,28.746,0,0,1,19.99-6.5c19.309,1.247,54.678,9.366,83.217,48.507,41.478,56.91,79.1,45.333,79.1,45.333s8.685,63.667-10.613,77.172-40.514,18.323-36.668,26.045-17.36-37.622-65.6-45.333S385.367,468.25,386.341,451.853C386.352,451.843,372.365,440.769,376.221,435.918Z"
            transform="translate(-76.386 -104.371)"
            fill="#cbcdda"
          />
          <path
            id="Path_1332"
            data-name="Path 1332"
            d="M368.013,386.054a48.49,48.49,0,0,0-.733-8.319c-12.635-6.286-10.843-14.835-10.843-14.835L335.21,419.81c7.763-1.048,16.3,1.131,24.872,5.238C371.365,416.132,368.013,401.444,368.013,386.054Z"
            transform="translate(-78.309 -104.135)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_99"
            data-name="Ellipse 99"
            cx="48.235"
            cy="48.235"
            r="48.235"
            transform="translate(199.027 232.678)"
            fill="#b06c49"
          />
          <path
            id="Path_1333"
            data-name="Path 1333"
            d="M285.133,398.073c-.89,2.86-2.263,5.8-4.85,7.334-3.143,1.865-7.48.943-10.278-1.467s-4.306-6.014-5.092-9.618a32.289,32.289,0,0,1,.524-15.767c1.257-4.348,3.426-8.381,4.337-12.834.974-4.735.482-9.691,1.572-14.405,1.477-6.391,5.689-11.744,9.8-16.868a17.411,17.411,0,0,1,11.346-7.543l20.188-6.087a68.223,68.223,0,0,1,11.755-2.808,40.731,40.731,0,0,1,15.285,1.341A35.219,35.219,0,0,1,350.8,324.3a41,41,0,0,1,8.748,8.853c10.676,13.955,16.763,36.784,27.774,50.519-4.348,1.341-8.7-3.143-13.043-1.781a3.058,3.058,0,0,1-1.959.157,3.215,3.215,0,0,1-1.435-1.593,33.387,33.387,0,0,0-17.067-14.6c-5.343-2.022-12.572-2.012-15.5,2.892-1.98,3.31-1.184,7.49-.66,11.315s.43,8.381-2.6,10.739a11.234,11.234,0,0,1-5.636,1.907,62.107,62.107,0,0,1-27.931-2.734c-3.143-1.048-6.338-4.327-9.66-3.279C287.354,388.1,286.317,394.27,285.133,398.073Z"
            transform="translate(-81.696 -106.285)"
            opacity="0.1"
          />
          <path
            id="Path_1334"
            data-name="Path 1334"
            d="M284.124,397.063c-.891,2.86-2.263,5.8-4.851,7.334-3.143,1.865-7.481.943-10.278-1.467s-4.306-6.013-5.092-9.618a32.294,32.294,0,0,1,.524-15.767c1.257-4.348,3.426-8.381,4.337-12.834.974-4.736.482-9.691,1.572-14.406,1.477-6.391,5.689-11.744,9.8-16.868a17.411,17.411,0,0,1,11.346-7.543l20.189-6.087a68.226,68.226,0,0,1,11.755-2.8,40.725,40.725,0,0,1,15.286,1.341,35.217,35.217,0,0,1,11.084,4.945,40.986,40.986,0,0,1,8.748,8.853c10.676,13.955,16.763,36.784,27.774,50.519-4.348,1.341-8.7-3.143-13.044-1.781a3.056,3.056,0,0,1-1.959.157,3.217,3.217,0,0,1-1.457-1.593,33.388,33.388,0,0,0-17.066-14.6c-5.343-2.022-12.572-2.012-15.5,2.891-1.98,3.311-1.184,7.491-.66,11.315s.43,8.381-2.6,10.739A11.231,11.231,0,0,1,328.4,391.7a62.112,62.112,0,0,1-27.931-2.734c-3.143-1.048-6.338-4.327-9.659-3.279C286.344,387.089,285.307,393.26,284.124,397.063Z"
            transform="translate(-81.744 -106.333)"
          />
          <g
            id="Group_2883"
            data-name="Group 2883"
            transform="translate(299.089 275.863)"
            opacity="0.1"
          >
            <path
              id="Path_1335"
              data-name="Path 1335"
              d="M396.949,379.22l-3.269,2.64s13.5,49.9,14.039,69.5C414.613,449.5,401.349,396.109,396.949,379.22Z"
              transform="translate(-374.611 -379.22)"
            />
            <path
              id="Path_1336"
              data-name="Path 1336"
              d="M590.8,517.745c-19.288,13.5-40.514,18.324-36.668,26.045s-17.36-37.622-65.6-45.333c-45.249-7.24-94.741-37.4-99.9-54.646-7.229-6.181-12.488-11.43-12.488-11.43-3.856,4.819,10.131,15.914,10.131,15.914-.964,16.4,52.09,50.163,100.315,57.874s69.45,53.054,65.595,45.333,17.36-12.572,36.669-26.045c5.521-3.866,8.748-11.839,10.55-21.257C597.545,510.16,594.78,514.989,590.8,517.745Z"
              transform="translate(-375.478 -376.685)"
            />
          </g>
          <path
            id="Path_1337"
            data-name="Path 1337"
            d="M490.693,608.834c-2.891,3.855-4.819,43.4-4.819,43.4s11.525,56.91-24.1,51.127-4.819-46.3-4.819-46.3l-4.82-55.946c-18.324-48.193-14.468-82.955-14.468-82.955l-3.426-31.5-1.4-12.866,41.477-9.649c-1.131,1.508-.649,10.917.367,21.844,1.582,17.119,4.452,37.958,4.452,37.958C487.79,533.59,493.584,604.968,490.693,608.834Z"
            transform="translate(-73.655 -99.308)"
            fill="#b06c49"
          />
          <path
            id="Path_1338"
            data-name="Path 1338"
            d="M432.879,381.313s-42.442.964-8.685,71.378c0,0,8.685,28.937,8.685,33.767s43.405,4.819,48.193,0-7.721-44.369-7.721-62.7S460.852,375.52,432.879,381.313Z"
            transform="translate(-74.689 -103.292)"
            opacity="0.1"
          />
          <path
            id="Path_1339"
            data-name="Path 1339"
            d="M474.655,485.994c-9.01,2.462-31.095,2.682-40.45.671L432.8,473.8l41.478-9.649C473.167,465.648,473.649,475.056,474.655,485.994Z"
            transform="translate(-73.657 -99.308)"
            opacity="0.1"
          />
          <path
            id="Path_1340"
            data-name="Path 1340"
            d="M431.039,381.313s-42.441.964-8.685,71.378c0,0,8.685,28.937,8.685,33.767s43.405,4.819,48.193,0-7.722-44.369-7.722-62.7S459.012,375.52,431.039,381.313Z"
            transform="translate(-74.777 -103.292)"
            fill="#cbcdda"
          />
          <g
            id="Group_2884"
            data-name="Group 2884"
            transform="translate(42.892 429.013)"
            opacity="0.5"
          >
            <path
              id="Path_1341"
              data-name="Path 1341"
              d="M76.666,409.5l-30.718.629-5.008,6.893v23.824l6.265,4.39H76.666l6.276-6.894V415.776Z"
              transform="translate(-40.94 -409.49)"
              fill="#0071ce"
            />
            <path
              id="Path_1342"
              data-name="Path 1342"
              d="M165.848,529.172s-28.214,4.389-30.718,0l.629-3.143,30.089-.628Z"
              transform="translate(-130.74 -525.4)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1343"
              data-name="Path 1343"
              d="M169.066,591.23l5.018,5.018v23.825l-5.018,5.018s-18.177,6.287-30.089,1.258L133.33,620.7V596.878l5.018-5.648Z"
              transform="translate(-130.826 -522.262)"
              fill="#0071ce"
            />
            <path
              id="Path_1344"
              data-name="Path 1344"
              d="M167.581,596.878s-21.938,5.018-29.461,0V591.23h30.718Z"
              transform="translate(-130.598 -522.262)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1345"
              data-name="Path 1345"
              d="M156.556,559.579v33.913a5.092,5.092,0,0,1-5.333,4.81h0a5.092,5.092,0,0,1-5.333-4.81V559.579c0-2.881,2.787-5.113,5.951-4.777h0A5.018,5.018,0,0,1,156.556,559.579Z"
              transform="translate(-130.227 -524)"
              fill="#0071ce"
            />
            <path
              id="Path_1346"
              data-name="Path 1346"
              d="M123.626,410.42l-30.718.629-5.018,6.894v23.824l6.276,4.39h29.461l6.265-6.894V416.7Z"
              transform="translate(-38.702 -409.446)"
              fill="#0071ce"
            />
            <path
              id="Path_1347"
              data-name="Path 1347"
              d="M212.8,530.1s-28.214,4.389-30.718,0l.629-3.143,30.089-.629Z"
              transform="translate(-128.502 -525.355)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1348"
              data-name="Path 1348"
              d="M216.016,592.161l5.018,5.018V621l-5.018,5.019s-18.177,6.286-30.089,1.257l-5.647-5.647V597.8l5.018-5.647Z"
              transform="translate(-128.588 -522.217)"
              fill="#0071ce"
            />
            <path
              id="Path_1349"
              data-name="Path 1349"
              d="M214.541,597.8s-21.938,5.018-29.461,0V592.15H215.8Z"
              transform="translate(-128.359 -522.217)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1350"
              data-name="Path 1350"
              d="M203.485,560.5v33.913a5.091,5.091,0,0,1-5.333,4.809h0a5.091,5.091,0,0,1-5.333-4.809V560.5c0-2.881,2.787-5.113,5.951-4.778h0A5.019,5.019,0,0,1,203.485,560.5Z"
              transform="translate(-127.99 -523.956)"
              fill="#0071ce"
            />
            <path
              id="Path_1351"
              data-name="Path 1351"
              d="M183.119,427.74l-25.291.513-4.128,5.678v19.613l5.165,3.6h24.254l5.154-5.679V432.895Z"
              transform="translate(-35.564 -408.62)"
              fill="#0071ce"
            />
            <path
              id="Path_1352"
              data-name="Path 1352"
              d="M272.431,546.741s-23.227,3.615-25.291,0l.513-2.578,24.767-.513Z"
              transform="translate(-125.4 -524.53)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1353"
              data-name="Path 1353"
              d="M275.089,597.83l4.128,4.128V621.57l-4.128,4.128s-14.961,5.165-24.767,1.048L245.67,622.1V602.471l4.128-4.641Z"
              transform="translate(-125.47 -521.947)"
              fill="#0071ce"
            />
            <path
              id="Path_1354"
              data-name="Path 1354"
              d="M273.864,602.471s-18.062,4.128-24.254,0V597.83H274.9Z"
              transform="translate(-125.282 -521.947)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1355"
              data-name="Path 1355"
              d="M264.79,571.771v27.921a4.191,4.191,0,0,1-4.39,3.939h0a4.191,4.191,0,0,1-4.39-3.96v-27.9a4.244,4.244,0,0,1,4.9-3.929h0A4.128,4.128,0,0,1,264.79,571.771Z"
              transform="translate(-124.977 -523.379)"
              fill="#0071ce"
            />
            <path
              id="Path_1356"
              data-name="Path 1356"
              d="M221.769,428.49l-25.291.524-4.128,5.668v19.613l5.165,3.615h24.254l5.165-5.679V433.655Z"
              transform="translate(-33.721 -408.584)"
              fill="#0071ce"
            />
            <path
              id="Path_1357"
              data-name="Path 1357"
              d="M311.091,547.5s-23.227,3.615-25.291,0l.513-2.577,24.767-.514Z"
              transform="translate(-123.557 -524.494)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1358"
              data-name="Path 1358"
              d="M313.738,598.591l4.128,4.128v19.612l-4.128,4.128s-14.961,5.165-24.767,1.048l-4.641-4.641V603.211l4.128-4.642Z"
              transform="translate(-123.627 -521.912)"
              fill="#0071ce"
            />
            <path
              id="Path_1359"
              data-name="Path 1359"
              d="M312.524,603.211s-18.062,4.128-24.254,0V598.57h25.28Z"
              transform="translate(-123.439 -521.912)"
              fill="#fff"
              opacity="0.2"
            />
            <path
              id="Path_1360"
              data-name="Path 1360"
              d="M303.4,572.531v27.921a4.191,4.191,0,0,1-4.39,3.96h0a4.191,4.191,0,0,1-4.39-3.96V572.531a4.244,4.244,0,0,1,4.9-3.928h0A4.127,4.127,0,0,1,303.4,572.531Z"
              transform="translate(-123.136 -523.342)"
              fill="#0071ce"
            />
          </g>
          <g
            id="Group_2885"
            data-name="Group 2885"
            transform="translate(97.973 590.323)"
            opacity="0.996"
          >
            <path
              id="Path_1361"
              data-name="Path 1361"
              d="M208.308,705.077s-14.846-5.029-22,8.057a23.866,23.866,0,0,0,.314,22.976c4.693,8.486,14.364,19.5,32.687,18.523,15.548-.828,20.713-12.7,22.116-23.73a24.379,24.379,0,0,0-32.373-26.1Z"
              transform="translate(-183.515 -678.224)"
              fill="#0071ce"
            />
            <path
              id="Path_1362"
              data-name="Path 1362"
              d="M189.775,737.292a23.866,23.866,0,0,1-.314-22.976c7.145-13.085,22-8.056,22-8.056l.765-.273a24.161,24.161,0,0,1,17.444.388,24.161,24.161,0,0,0-20.6-1.97l-.765.272s-14.846-5.029-22,8.057a23.867,23.867,0,0,0,.314,22.976c3.143,5.583,8.381,12.257,16.763,15.893A36.8,36.8,0,0,1,189.775,737.292Z"
              transform="translate(-183.515 -678.244)"
              fill="#fff"
              opacity="0.1"
            />
            <path
              id="Path_1363"
              data-name="Path 1363"
              d="M199.86,711s5.5,4.327,18.858,0"
              transform="translate(-182.736 -677.861)"
              fill="#101c2c"
              opacity="0.2"
            />
            <path
              id="Path_1364"
              data-name="Path 1364"
              d="M198.74,681s9.429,25.563,8.654,32.247l7.47-.785s-12.572-22.41-9.429-31.43Z"
              transform="translate(-182.789 -679.292)"
              fill="#0071ce"
            />
            <path
              id="Path_1365"
              data-name="Path 1365"
              d="M198.74,681s9.429,25.563,8.654,32.247l7.47-.785s-12.572-22.41-9.429-31.43Z"
              transform="translate(-182.789 -679.292)"
              fill="#101c2c"
              opacity="0.2"
            />
            <path
              id="Path_1366"
              data-name="Path 1366"
              d="M236.827,683.173s-14.856-7.6-21.5-1.32-7.669,22.316-7.669,22.316S231.882,707.51,236.827,683.173Z"
              transform="translate(-182.364 -679.369)"
              fill="#0071ce"
            />
            <path
              id="Path_1367"
              data-name="Path 1367"
              d="M236.827,683.173s-14.856-7.6-21.5-1.32-7.669,22.316-7.669,22.316S231.882,707.51,236.827,683.173Z"
              transform="translate(-182.364 -679.369)"
              fill="#fff"
              opacity="0.1"
            />
            <path
              id="Path_1368"
              data-name="Path 1368"
              d="M207.66,703.953s3.9-18.617,29.167-20.953"
              transform="translate(-182.364 -679.196)"
              fill="none"
              stroke="#0071ce"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
          <path
            id="Path_1369"
            data-name="Path 1369"
            d="M268.128,727.167s-14.846-5.029-22,8.057a23.866,23.866,0,0,0,.314,22.976c4.694,8.486,14.364,19.5,32.688,18.523,15.548-.828,20.713-12.7,22.116-23.73a24.379,24.379,0,0,0-32.373-26.1Z"
            transform="translate(-82.69 -86.848)"
            fill="#0071ce"
          />
          <path
            id="Path_1370"
            data-name="Path 1370"
            d="M249.625,759.392a23.865,23.865,0,0,1-.314-22.975c7.145-13.086,22-8.057,22-8.057l.765-.273a24.165,24.165,0,0,1,17.444.388,24.157,24.157,0,0,0-20.6-1.969l-.765.272s-14.845-5.029-22,8.056a23.866,23.866,0,0,0,.314,22.976c3.143,5.584,8.381,12.257,16.763,15.893A36.8,36.8,0,0,1,249.625,759.392Z"
            transform="translate(-82.688 -86.868)"
            fill="#fff"
            opacity="0.1"
          />
          <path
            id="Path_1371"
            data-name="Path 1371"
            d="M259.71,733.09s5.5,4.326,18.858,0"
            transform="translate(-81.909 -86.485)"
            opacity="0.2"
          />
          <path
            id="Path_1372"
            data-name="Path 1372"
            d="M258.58,703.07s9.429,25.564,8.654,32.247l7.47-.785s-12.572-22.41-9.429-31.43Z"
            transform="translate(-81.963 -87.917)"
            fill="#0071ce"
          />
          <path
            id="Path_1373"
            data-name="Path 1373"
            d="M258.58,703.07s9.429,25.564,8.654,32.247l7.47-.785s-12.572-22.41-9.429-31.43Z"
            transform="translate(-81.963 -87.917)"
            fill="#101c2c"
            opacity="0.2"
          />
          <path
            id="Path_1374"
            data-name="Path 1374"
            d="M296.677,705.273s-14.856-7.6-21.5-1.321-7.669,22.316-7.669,22.316S291.732,729.6,296.677,705.273Z"
            transform="translate(-81.537 -87.993)"
            fill="#0071ce"
          />
          <path
            id="Path_1375"
            data-name="Path 1375"
            d="M296.677,705.273s-14.856-7.6-21.5-1.321-7.669,22.316-7.669,22.316S291.732,729.6,296.677,705.273Z"
            transform="translate(-81.537 -87.993)"
            fill="#fff"
            opacity="0.1"
          />
          <path
            id="Path_1376"
            data-name="Path 1376"
            d="M267.51,726.094s3.9-18.617,29.167-20.954"
            transform="translate(-81.537 -87.818)"
            fill="none"
            stroke="#0071ce"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_1377"
            data-name="Path 1377"
            d="M154.443,670.36s1.456-1.624-1.278-2.274v-2.671h1.645a14.1,14.1,0,0,0-11.168-13.787V648.39h.43V644.2H133.489v4.19h.3v3.279a14.1,14.1,0,0,0-10.98,13.746h1.467v2.567s-3.478,1.645,0,4.578,2.933,5.123,2.933,5.123-1.467,5.49-4.39,6.223v51.074a11.347,11.347,0,0,0,11.346,11.346h8.968a11.347,11.347,0,0,0,11.3-11.336V683.906s-5.123-3.845-4.191-6.223a57.7,57.7,0,0,1,4.191-7.324Z"
            transform="translate(-88.439 -90.723)"
            fill="#0071ce"
          />
        </g>
      </svg>
    </>
  );
};
