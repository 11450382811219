import { useState, useEffect, useCallback, useContext } from "react";
import { useConfig } from "../configuration/useConfig";
import axiosInstance from "../axios.instance";
import { UserContext } from "../context/UserProvider";
import useIncontact from "./useIncontact";

export type ZuoraProps = Record<string, string | number | boolean | null>;

export interface IZuoraResponse {
  success: boolean;
  refId: string;
  responseFrom: string;
  errorCode?: string;
}
interface PrePopulate {
  creditCardPostalCode: string;
  achPostalCode: string;
  creditCardHolderName: string;
}

interface IZuoraHook {
  render: () => void;
  handleSubmit: (e: unknown) => void;
  hasRendered: boolean;
}

const useZuora = ({
  paymentType,
  paymentTypePageId,
  prePopulate,
  accountId,
  onSuccess,
  onSubmissionFailed,
  onRequestFailed,
  setPaymentToken,
}: {
  paymentType: string; //'CREDIT' | 'E-Check'
  paymentTypePageId?: string;
  prePopulate?: PrePopulate;
  accountId?: string;
  onSuccess: (res: unknown) => unknown;
  onSubmissionFailed: (res: unknown) => unknown;
  onRequestFailed: (res: unknown) => unknown;
  setPaymentToken?;
}): IZuoraHook => {
  const { user } = useContext(UserContext);
  const { config } = useConfig();
  const [props, setProps] = useState<ZuoraProps>({
    tenantId: "",
    id: "",
    token: null,
    signature: null,
    style: "inline",
    key: null,
    submitEnabled: true,
    locale: "English (en)",
    param_supportedTypes: "AmericanExpress,Visa,MasterCard,Discover",
    url: config["zuora.hostedPagePath"],
    paymentGateway: config["zuora.paymentGatewayCode.cc"],
  });
  const [toRender, setToRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prepop] = useState(prePopulate ? prePopulate : {});
  const [rendered, setRendered] = useState(false);

  const [paymentTypesLoading, setPaymentTypesLoading] = useState(true);
  const [readyToSend, setReadyToSend] = useState(false);

  const [creditCardPageId, setCreditCardPageId] = useState("");
  const [echeckPageId, setEcheckPageId] = useState("");

  const [zuoraAccountId, setZuoraAccountId] = useState("");
  const { isImpersonated, setMaskDisabled } = useIncontact();

  useEffect(() => {
    setToRender(false);
    setRendered(false);
    setLoading(true);
    setReadyToSend(false);
  }, [paymentType]);

  useEffect(() => {
    if (user.zuoraAccountId) {
      setZuoraAccountId(user.zuoraAccountId);
    } else if (accountId) {
      setZuoraAccountId(accountId);
    }
    setTimeout(() => {
      getPaymentTypes();
    }, 2000);
  }, []);

  useEffect(() => {
    if (!paymentTypesLoading) {
      if (window.location.href.includes(":3005"))  {
        if (config["client"] === "walmart") {
          setCCPayment("2c92c0f87506696b01750aae5fb94669");
        } if (config["client"] === "hcsc") {
          setCCPayment("2c92c0f9699ec9f80169b6fca486655b");
        } 
        if (config["client"] === "bcbsa") {
          setCCPayment("8ad08c0f7c642e21017c76e994182500");
        }
        if (config["client"] === "bsca") {
          setCCPayment("8ad084a67c642e25017c76f5fce13d15");
        }
        if (config["client"] === "mutual-of-omaha") {
          setCCPayment("8ad081dd91f0ebba0192013fe38f580d");
        }
      } else {
        if (paymentTypePageId && paymentTypePageId !== "") { // for mobile
          setCCPayment(paymentTypePageId);
        } else {
          if (paymentType === 'Credit Card') {
            setCCPayment(creditCardPageId);
          }
          if (paymentType === 'E-Check') {
            setCCPayment(echeckPageId);
          }
        }
      }
    }
  }, [paymentTypesLoading, paymentType]);

  const getPaymentTypes = async () => {
    setPaymentTypesLoading(true);
    await axiosInstance
      .get(`${config["apiUrl"]}/payment-method-types`)
      .then((response) => {
        if (response.data) {
          response.data.map(function (entry) {
            if (entry.pageType === "Credit Card") {
              setCreditCardPageId(entry.pageId);
            }
            if (entry.pageType === "E-Check") {
              setEcheckPageId(entry.pageId);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
      });
    setPaymentTypesLoading(false);
  };

  const setCCPayment = async (pageId: string) => {
    setLoading(true);
    await axiosInstance
      .get(`${config["apiUrl"]}/payment-method-types/${pageId}`)
      .then((response) => {
        if (response.data && zuoraAccountId) {
          setProps((p) => ({
            ...p,
            ...{
              field_accountId: zuoraAccountId,
              tenantId: response.data.tenantId,
              paymentGateway: response.data.paymentGateway,
              token: response.data.token,
              id: response.data.id,
              signature: response.data.signature,
              key: response.data.key,
              url: response.data.url,
            },
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setReadyToSend(true);
    setLoading(false);
  };

  const callback = useCallback(
    async (response: IZuoraResponse) => {
      if (response.success) {
        if (setPaymentToken) {
          setPaymentToken(response.refId);
        }
        onSuccess(response);
      } else {
        if (response.responseFrom === "Response_From_Submit_Page") {
          onSubmissionFailed(response);
        } else {
          onRequestFailed(response);
        }
      }
    },
    [onSuccess, onSubmissionFailed, onRequestFailed]
  );

  //render when we can.
  useEffect(() => {
    if (toRender && !loading && !rendered && readyToSend) {
      window.Z.render(props, prepop, callback);
      setRendered(true);
    }
  }, [toRender, loading, props, prepop, callback, rendered]);
  //
  const render = useCallback(() => {
    setToRender(true);
  }, []);

  const handleSubmit = useCallback((e) => {
    if (isImpersonated) setMaskDisabled();
    e.preventDefault();
    window.Z.submit();
  }, []);

  return { render, handleSubmit, hasRendered: rendered };
};

export default useZuora;
