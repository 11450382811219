import { useEffect, useState } from "react";
import { useConfig } from "../configuration/useConfig";

type ITheme = {
  primary: string;
  contrastTextPrimary: string;
  secondary: string;
  accent: string;
};

const useTheme = () => {
  const [theme, setTheme] = useState<ITheme>({
    primary: "#ffffff",
    contrastTextPrimary: "#ffffff",
    secondary: "#ffffff",
    accent: "#ffffff",
  });
  const { config } = useConfig();

  //set the theme in the dom
  useEffect(() => {
    Object.keys(theme).forEach((k) => {
      const val = theme[k as keyof ITheme];
      if (val) {
        document.documentElement.style.setProperty(`--${k}`, val);
      }
    });
  }, [theme]);
  useEffect(() => {
    (async () => {
      const t = await fetch(`/${config["client"]}-theme.json`);
      const converted = await t.json();
      setTheme(converted);
    })();
  }, []);

  return theme;
};

export default useTheme;
