import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import { useEffect, useState, useMemo } from "react";

import { EligibilityFlow, FormikWizardState } from "../types";
import { ErrorMessage, getIn, useFormikContext } from "formik";

import BundlesDropDown from "../../shared/BundlesDropDown";

import showToastMessage from "../../../utility/ProfileToast";
import { Dependent } from "../../../utility/member-portal-api-types";
import { PROGRAM_CODE } from "../../../context/UserProvider";
// import API from "../../axios.instance";

import { useConfig } from "../../../configuration/useConfig";
import useSummary from "../../../hooks/useSummary";
import useRouteQuery from "../../../hooks/useRouteQuery";

import AddDependentsModal from "../forms/AddDependentsModal";
import useGetPrograms from "../../../hooks/useGetPrograms";
import axiosInstance from "../../../axios.instance";
import { UnderageFamilyMemberBanner } from "./UnderageFamilyMemberBanner";
import { Product } from "../forms/Package";
import BundlesDropDownUnderageDependent from "../../shared/BundlesDropDownUnderageDependent";
import BundlesDropDownDependent from "../../shared/BundlesDropDownDependent";

export enum AddressType {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

export type PlanDependent = {
  isSelected: boolean;
  ratePlanChargeId?: string;
  email?: string;
  isChosen?: boolean;
  sku?: string;
  isEmailTaken?: boolean;
} & Dependent;

export type DependentError = {
  email: string;
  index: number;
  isEmailTaken: boolean;
  errorMessage: string;
};

export function ManageMembers(props) {
  const { values, errors, touched, setFieldValue, handleBlur } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();
  const { client, clientConfig } = useConfig();
  const routeQuery = useRouteQuery();
  const { getSummaryRatePlan } = useSummary();
  const { productRatePlans, programCode } = useGetPrograms();

  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const [isLoadingAccount] = useState(false);
  const [dependents, setDependents] = useState<PlanDependent[]>([]);
  const [dependentChange, setDependentChange] = useState(false);
  const [dependentTierErrors, setDependendentTierErrors] = useState<boolean[]>(
    []
  );
  const [primarySelectedPlan, setPrimarySelectedPlan] = useState(
    values.package?.ratePlanChargeId
  );
  const [isStudioOnly, setIsStudioOnly] = useState(false);

  useEffect(() => {
    if (values.package?.programCode === PROGRAM_CODE.Studio) {
      setIsStudioOnly(true);
    } else {
      setIsStudioOnly(false);
    }
  }, [values.package]);

  const [dependentErrors, setDependentErrors] = useState<Array<DependentError>>(
    []
  );

  const [dependentsLoaded, setDependentsLoaded] = useState(false);
  const [validateEmailsTrigger, setValidateEmailsTrigger] = useState(false);

  useEffect(() => {
    const allDependentsValid = dependents.every((dependent) =>
      dependent.isChosen
        ? dependent.email && isValidEmail(dependent.email)
        : true
    );

    if (!allDependentsValid) {
      props.setEmailValidations(false);
    }
  }, [values?.eligibility?.dependents]);

  useEffect(() => {
    if (routeQuery.get("edit_success") === "true") {
      setTimeout(() => {
        showToastMessage(`Your account was successfully updated`, true, client);
      }, 250);
    }
  }, []);
  useEffect(() => {
    if (validateEmailsTrigger) {
      validExistingEmail();
    }
  }, [validateEmailsTrigger]);

  useEffect(() => {
    if (values.eligibility?.dependents) {
      setDependents(values.eligibility?.dependents as PlanDependent[]);
      setDependentsLoaded(true);
    } else {
      getDependents();
    }
  }, []);

  useEffect(() => {
    getSummaryRatePlan(values);
  }, [
    dependentChange,
    values.package?.ratePlanChargeId,
    props.EmailValidations,
  ]);

  const getIsUnderAge = (dateOfBirth: string) => {
    const currentDate = new Date();
    const ageLimit = new Date(
      currentDate.getFullYear() - clientConfig.minBuyerMemberAge!,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return new Date(dateOfBirth) > ageLimit;
  };

  const isDependentPackageAllowed = (
    dependent: PlanDependent,
    selectedPlan: Product,
    primaryPlan: Product
  ): boolean => {
    const isUnderAge = getIsUnderAge(dependent.dateOfBirth);
    if (isUnderAge) {
      if (selectedPlan.tier <= primaryPlan.tier && selectedPlan.tier <= 3) {
        return true;
      }

      if (
        primaryPlan.tier > 3 &&
        (selectedPlan.tier === primaryPlan.tier || selectedPlan.tier <= 3)
      ) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  };

  const getDependents = () => {
    axiosInstance
      .post("/family-members-search", {
        firstName: values.eligibility?.firstName,
        lastName: values.eligibility?.lastName,
        dateOfBirth: `${values.eligibility?.year}-${values.eligibility?.month}-${values.eligibility?.day}`,
        subscriberId: values.eligibility?.subscriberId,
        groupNumber: values.eligibility?.groupNumber,
        corporationCode: values.eligibility?.corpCode,
      })
      .then((response) => {
        setDependents(response.data as PlanDependent[]);
        setDependentsLoaded(true);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const formDependentsObject = (selectedMembers) => {
    return selectedMembers.map((dependent) => {
      const currentSubscription = productRatePlans.find((item) => {
        return dependent.ratePlanChargeId === item.recurringRatePlanChargeId;
      });

      return {
        ...dependent,
        eligibilities: [
          {
            isPrimary: false,
            isDependentToMember: true,
            isChkChosenDependent: dependent.isSelected,
            currentSubscription,
          },
        ],
      };
    });
  };

  const addDependentsToPlan = (selectedMembers: PlanDependent[]) => {
    const newDependents = selectedMembers.map((item) => {
      if (item.isSelected) {
        return { ...item, isSelected: false, isChosen: true };
      } else {
        return item;
      }
    });
    setDependents(newDependents);
    const formDependents = formDependentsObject(newDependents);
    setFieldValue("eligibility.dependents", formDependents);
    setShowFamilyModal(false);
    setDependentChange(!dependentChange);
  };

  const onPrimaryPlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPlan = productRatePlans.find(
      (prp) => prp.recurringRatePlanChargeId === e.target.value
    );

    if (newPlan) {
      setFieldValue(
        "package.ratePlanChargeId",
        newPlan.recurringRatePlanChargeId
      );
      setFieldValue(
        "package.programCode",
        newPlan.name === PROGRAM_CODE.Studio ? PROGRAM_CODE.Studio : programCode
      );
      setFieldValue("package.sku", newPlan.name);
      setPrimarySelectedPlan(newPlan.recurringRatePlanChargeId);
    }
  };

  const onDependentPlanChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const dependentPlan = productRatePlans.find(
      (prp) => prp.recurringRatePlanChargeId === e.target.value
    );

    const primaryPlan = productRatePlans.find(
      (prp) =>
        prp.recurringRatePlanChargeId === values?.package?.ratePlanChargeId
    );

    // Check whether the dependent has elite when the primary doesn't
    const newDependentTierErrors = [...dependentTierErrors];

    let planAllowed = true;
    if (dependentPlan && primaryPlan) {
      planAllowed = isDependentPackageAllowed(
        dependents[index],
        dependentPlan,
        primaryPlan
      );
    }

    newDependentTierErrors[index] = !planAllowed;

    props.setCanSubmit(!newDependentTierErrors.includes(true));
    setDependendentTierErrors(newDependentTierErrors);

    const newDependents = [...dependents];
    newDependents[index] = {
      ...dependents[index],
      ratePlanChargeId: e.target.value,
      sku: dependentPlan?.name,
    };

    const formDependents = formDependentsObject(newDependents);

    setDependents(newDependents);
    setFieldValue(`eligibility.dependents`, formDependents);
    setDependentChange(!dependentChange);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const hasError = (index) => {
    return dependentErrors.some((item) => item.index === index);
  };

  const checkEmailExistence = async (email: string) => {
    const response = await axiosInstance.get(
      `/check-email/${encodeURIComponent(email)}`,
      {}
    );
    return response.data.doesEmailExist;
  };

  const debouncedCheckEmailExistanceMemo = useMemo(
    () =>
      debounce((value, index) => {
        checkEmailExistence(value).then((doesEmailExist) => {
          if (doesEmailExist) {
            const indexOfError = dependentErrors.findIndex(
              (error) => error.index == index
            );
            if (indexOfError < 0) {
              const newDependentErrors = [...dependentErrors];
              newDependentErrors.push({
                email: value,
                index: index,
                isEmailTaken: doesEmailExist,
                errorMessage:
                  "An account already exists with that email. Please enter a different email address.",
              });
              setDependentErrors(newDependentErrors);
            }
            props.setEmailValidations(true);
          } else {
            const indexOfError = dependentErrors.findIndex(
              (error) => error.index == index
            );
            if (indexOfError >= 0) {
              const newDependentError = [...dependentErrors];
              newDependentError.splice(indexOfError, 1);
              setDependentErrors(newDependentError);
            }
            setValidateEmailsTrigger(true);
          }
        });
      }, 2000),
    [dependentErrors]
  );

  const onChangeDependentEmail = (value: string, index) => {
    // e.preventDefault();
    const newDependents = [...dependents];
    newDependents[index] = {
      ...dependents[index],
      email: value,
    };
    setDependents(newDependents);
    setFieldValue(`eligibility.dependents[${index}].email`, value);
    if (isValidEmail(value)) {
      debouncedCheckEmailExistanceMemo.cancel();
      debouncedCheckEmailExistanceMemo(value, index);
    } else {
      props.setEmailValidations(false);
    }
  };

  const removeDependent = (index: number) => {
    const newDependents = [...dependents];
    newDependents[index] = {
      ...dependents[index],
      isChosen: false,
    };

    const formDependents = formDependentsObject(newDependents);

    setDependents(newDependents);
    setFieldValue(`eligibility.dependents`, formDependents);
    setDependentChange(!dependentChange);
  };

  // client side validation beetween all email before submit
  const validExistingEmail = () => {
    setValidateEmailsTrigger(false);
    props.setEmailValidations(true);

    // Get all the emails of dependents and primary
    let dependentEmails = dependents.map((item) => item.email);
    dependentEmails.push(values.eligibility?.email as string);
    dependentEmails = dependentEmails.filter((item) => item?.trim().length);

    let checkDependentsAndPrimary = [...dependents];
    checkDependentsAndPrimary.push(
      values.eligibility as unknown as PlanDependent
    );

    checkDependentsAndPrimary = checkDependentsAndPrimary.filter(
      (item) => (item?.email !== undefined ? item.email : "").trim().length
    );
    props.setValidDependentEmails(dependentEmails);

    const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const newDependentErrors = [...dependentErrors];
    const duplicateEmailObject = checkDependentsAndPrimary.find(
      (item, index) => {
        if (
          checkDependentsAndPrimary.findIndex(
            (dep) => dep.email === item.email
          ) !== index
        ) {
          const indexOfError = dependentErrors.findIndex(
            (error) => error.email == item.email
          );
          if (indexOfError < 0) {
            newDependentErrors.push({
              email: item.email ?? "",
              index: index,
              isEmailTaken: true,
              errorMessage:
                "That email is already in use above. Please use a different email address.",
            });
          }
          return true;
        }
      }
    );
    if (duplicateEmailObject === undefined) {
      setDependentErrors([]);
    } else {
      setDependentErrors(newDependentErrors);
    }
    const validEmails = dependentEmails.every((email) => {
      return email != null && expression.test(email);
    });

    if (duplicateEmailObject !== undefined || !validEmails) {
      props.setEmailValidations(false);
      return;
    }
    props.setValidDependentEmails(dependentEmails);
    props.setEmailValidations(true);
  };

  const handleEmailBlur = (e, index?) => {
    if (
      e.target.value.length > 1 &&
      isValidEmail(e.target.value) &&
      dependents[index].email !== e.target.value
    ) {
      // checkEmailExistance(e.target.value, index);
      // verifyEmail(e.target.value, index);
      debouncedCheckEmailExistanceMemo.cancel();

      debouncedCheckEmailExistanceMemo(e.target.value, index);
      // debouncedCheckEmailExistance.cancel();

      // debouncedCheckEmailExistance(e.target.value, index);

      // debouncedCheckEmailExistance.flush();
      // debouncedCheckEmailExistance(value, index);
      // verifyEmail.flush();
    }
    handleBlur(e);
  };

  const renderUnderageBanner = (dependent: Dependent) => {
    if (dependent.status !== "UnderAgeDependent") {
      return;
    }
    return <UnderageFamilyMemberBanner />;
  };

  const getProductTierFromRatePlanChargeId = (
    ratePlanChargeId: string | undefined
  ) => {
    if (ratePlanChargeId == undefined) {
      return "3";
    }

    return productRatePlans
      .find((product) => product.recurringRatePlanChargeId === ratePlanChargeId)
      ?.tier.toString();
  };
  const getDefaultPlanId = () => {
    if (isStudioOnly) {
      return productRatePlans[0].recurringRatePlanChargeId;
    }
    return primarySelectedPlan;
  };

  const renderDependents = () => {
    return dependents.map((dependent, index) => {
      if (!dependent.isChosen) {
        return null;
      }
      const isUnderage = dependent.status === "UnderAgeDependent";
      return (
        <>
          {renderUnderageBanner(dependent)}
          <Card
            key={index}
            className={`manage-plans__card${
              dependent.status === "UnderAgeDependent" ? "__border" : ""
            }`}
          >
            <Card.Body>
              <div className="mt-2">
                <Row>
                  <Col xs={12} lg={5}>
                    <h6 className="text-uppercase">Family Member</h6>
                    <div>
                      {dependent.firstName} {dependent.lastName}
                    </div>
                  </Col>
                  <Col xs={12} lg={7}>
                    <div
                      className="mt-minus-half-rem"
                      style={{ display: "block" }}
                    >
                      <Form.Group>
                        <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                          EMAIL ADDRESS
                          <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // onBlur={(e) =>
                          //   onChangeDependentEmail(e.target.value, index)
                          // }
                          name="dependent.email"
                          key={index}
                          value={dependent.email}
                          isInvalid={
                            getIn(touched, "dependent.email") &&
                            getIn(errors, "dependent.email")
                          }
                          onChange={(e) =>
                            onChangeDependentEmail(e.target.value, index)
                          }
                          onBlur={(e) => handleEmailBlur(e, index)}
                          disabled={dependent.personId !== 0}
                        />
                        <ErrorMessage component="div" name="dependent.email" />
                      </Form.Group>
                      {dependentErrors && hasError(index) && (
                        <text className="invalid-email-text">
                          {dependentErrors.map((error) =>
                            error.index === index ? error.errorMessage : null
                          )}
                        </text>
                      )}
                      {dependent.email === values.eligibility?.email && (
                        <text className="invalid-email-text">
                          The Primary account is using that email. Please enter
                          a different email address.
                        </text>
                      )}
                    </div>
                  </Col>
                  {/* {((isPrimaryLuxury() &&
                    dependent.status === "UnderAgeDependent") ||
                    dependentTierErrors[index]) && (
                    <Col xs={12} lg={12} className="manage-plans__card__note">
                      <b>** Please Note:</b> Family members under the age of 18
                      may only register for ELITE tier access if the primary
                      plan member has ELITE tier access
                    </Col>
                  )} */}
                </Row>
              </div>
            </Card.Body>

            <div>
              <Row className="manage-plans__footer">
                <Col xs={12} lg={5}>
                  <h6 className="text-uppercase">Fitness Package</h6>

                  <Form.Group>
                    <Form.Control
                      as="select"
                      value={
                        dependent.ratePlanChargeId
                          ? dependent.ratePlanChargeId
                          : getDefaultPlanId()
                      }
                      onChange={(e) => {
                        onDependentPlanChange(
                          e as React.ChangeEvent<HTMLSelectElement>,
                          index
                        );
                      }}
                      // isInvalid={dependentTierErrors[index]}
                      isInvalid={
                        dependentTierErrors[index] &&
                        dependent.status === "UnderAgeDependent"
                      }
                      style={{ appearance: "auto" }}
                    >
                      {isUnderage ? (
                        <BundlesDropDownUnderageDependent
                          planId={dependent.ratePlanChargeId}
                          highestSelectableTier={
                            getProductTierFromRatePlanChargeId(
                              primarySelectedPlan
                            ) || ""
                          }
                          primaryTier={
                            getProductTierFromRatePlanChargeId(
                              primarySelectedPlan
                            ) || "9"
                          }
                          loaded={() => console.log("")}
                        />
                      ) : (
                        <BundlesDropDownDependent
                          planId={dependent.ratePlanChargeId}
                          highestSelectableTier="9"
                          underAge={isUnderage}
                          primaryTier="9"
                          loaded={() => console.log("")}
                        />
                      )}
                      {/* <NoOpBundlesDropDown
                        planId={dependent.ratePlanChargeId}
                        membersPage={true}
                        productRatePlans={filterProductRateplans(
                          productRatePlans,
                          dependent
                        )}
                        addendum={
                          values?.eligibility?.isPayroll ? "paycheck" : "month"
                        }
                      /> */}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <div
                    className="manage-plans__footer"
                    style={{ background: "" }}
                  >
                    <span
                      className="manage-plans__footer__action"
                      onClick={() => removeDependent(index)}
                      style={{
                        marginLeft: "auto",
                        fontSize: "16px",
                      }}
                    >
                      X Remove Member
                    </span>
                  </div>
                </Col>
              </Row>
            </div>

            {/* <div className="manage-plans__footer" style={{ background: "" }}>
            <span
              className="manage-plans__footer__action"
              onClick={() => removeDependent(index)}
              style={{
                marginLeft: "auto",
                fontSize: "16px",
              }}
            >
              X Remove Member
            </span>
          </div> */}
          </Card>
        </>
      );
    });
  };

  const renderPrimaryMember = () => {
    return (
      <div className="edit-plan">
        <div className="d-flex flex-column">
          <div className="mb-4">
            <Card className="edit-plan__primary">
              <Card.Body>
                <Row>
                  <Col xs={12} lg={5}>
                    <h6 className="text-uppercase">Primary Member</h6>
                    <div>
                      {values.eligibility?.firstName as string}{" "}
                      {values.eligibility?.lastName as string}
                    </div>
                  </Col>
                  <Col xs={12} lg={7}>
                    <Form.Group>
                      <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                        EMAIL ADDRESS
                        <span className="required-field-marker">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="eligibility.member.email"
                        // value={values.eligibility?.member?.email}
                        value={
                          values.eligibility?.email
                            ? values.eligibility?.email.toString()
                            : ""
                        }
                        isInvalid={
                          getIn(touched, "eligibility.member.email") &&
                          getIn(errors, "eligibility.member.email")
                        }
                        // disabled={
                        //   user.AccountSummaryMember?.eligibilities[0]
                        //     .isDependentToMember
                        // }
                        disabled={true}
                        onBlur={handleEmailBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, "eligibility.member.email")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Body className="edit-plan__primary__body">
                <Row>
                  <Col xs={12} lg={5}>
                    <h6 className="text-uppercase">Fitness Package</h6>{" "}
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={primarySelectedPlan}
                        onChange={onPrimaryPlanChange}
                        disabled={false}
                        style={{ appearance: "auto" }}
                      >
                        <BundlesDropDown
                          planId={values.package?.ratePlanChargeId}
                        />
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  };

  if (isLoadingAccount) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <Spinner animation="border" />
        <p className="mt-4">Loading your plan details</p>
      </div>
    );
  }

  return (
    <>
      <Row className="manage-plans">
        <Col>
          {renderPrimaryMember()}
          {renderDependents()}
          <Row className="eligibility-form__row ">
            <Col lg={4} xl={2}>
              <Button
                onClick={() => {
                  setShowFamilyModal(true);
                }}
                disabled={!dependentsLoaded}
                className="btn btn-outline-primary add-family-members-button"
              >
                Add Family Members
              </Button>
            </Col>
          </Row>
        </Col>
        {/* <Col xl={2}>
          <Summary />
        </Col> */}
      </Row>

      <Row>
        <Col lg={12} xl={12}>
          &nbsp;
        </Col>
      </Row>

      {showFamilyModal && (
        <AddDependentsModal
          showFamilyModal={showFamilyModal}
          setShowFamilyModal={() => {
            setShowFamilyModal(false);
            getSummaryRatePlan(values);
          }}
          getOrderPreview={addDependentsToPlan}
          dependents={dependents}
          setEmailValidations={props.setEmailValidations}
          isPrimaryStudioOnly={isStudioOnly}
        />
      )}
    </>
  );
}

export default ManageMembers;
