import React from "react";
import { ActivityListItem } from "./ActivityListItem";
import { IActivity } from "./ActivityLog";

interface IProps {
  activities: IActivity[];
  currentPageNum: number;
}

const ActivityList = ({ activities, currentPageNum }: IProps) => {
  return (
    <div className="d-flex flex-column">
      <span className="border-bottom w-100 my-2" />

      <div className="d-lg-flex d-none justify-content-between p-1">
        <p className="text-uppercase from-text text-secondary font-weight-bold h6 ps-1 p-0 ms-5 col">
          Location
        </p>
        <p className="text-uppercase from-text text-secondary font-weight-bold h6 p-0  col">
          Class
        </p>
        <p className="text-uppercase from-text text-secondary font-weight-bold h6 p-0  col">
          Date
        </p>
        <p className="text-uppercase from-text text-secondary font-weight-bold h6 p-0  col">
          Time
        </p>
        <p className="text-uppercase from-text text-secondary font-weight-bold h6 me-5 p-0  col">
          Type
        </p>
      </div>
      {activities
        .slice(10 * (currentPageNum - 1), 10 * currentPageNum)
        .sort(
          (
            x: { activityDate: string | number | Date },
            y: { activityDate: string | number | Date }
          ) => +new Date(y.activityDate) - +new Date(x.activityDate)
        )
        .map((activity, index) => (
          <ActivityListItem activity={activity} key={index} />
        ))}
    </div>
  );
};
export default ActivityList;
