import { useContext } from "react";
import {
  USER_PAYROLL_FREQUENCY,
  USER_PAYROLL_STATUS,
} from "../../context/UserProvider";
import { AccountContext } from "../../context/AccountProvider";
import BannerWrapper from "../shared/BannerWrapper";
import ReEnrollBanner from "./ReEnrollBanner";

const InformationDisplay = ({
  header,
  canShowReEnrollBanner = false,
}: {
  header: string;
  canShowReEnrollBanner?: boolean;
}) => {
  const { member, payrollStatus, clientPayrollFrequency } =
    useContext(AccountContext);

  return (
    <div className="account-information">
      <h1>{header}</h1>
      <BannerWrapper
        show={
          canShowReEnrollBanner && member?.eligibilities[0].isPrimary === true
        }
      >
        <div className="widget d-flex flex-wrap flex-row mb-3">
          <ReEnrollBanner
            title="Welcome back! Re-Enroll to enjoy all the perks of the membership"
            content={
              <>
                Enroll and gain access to our vast network of participating gyms
                and digital content from Burnalong. Packages start{" "}
                <b>
                  as low as{" "}
                  {payrollStatus === USER_PAYROLL_STATUS.Payroll
                    ? clientPayrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
                      ? "$2.50/paycheck."
                      : "$5/paycheck."
                    : payrollStatus === USER_PAYROLL_STATUS.NonPayroll
                    ? "$10.83/month."
                    : payrollStatus === USER_PAYROLL_STATUS.NotApplicable
                    ? "10.00/month."
                    : ""}
                </b>
              </>
            }
            enrollHref={"/enroll/package"}
            exploreHref="/locations"
          />
        </div>
      </BannerWrapper>
    </div>
  );
};

export default InformationDisplay;
