import React, { useCallback, useEffect } from "react";

import { Optional } from "@authkitcom/react/dist/Lang";
import { useState } from "react";
import { AccountSummaryMember } from "../utility/member-portal-api-types";
import { useConfig } from "../configuration/useConfig";
import axiosInstance from "../axios.instance";
import { USER_PAYROLL_STATUS } from "./UserProvider";

export enum ENTITLEMENTS {
  GYM = "gym",
  HOME_GYM = "homeGym",
  BURNALONG = "burnalong",
  STUDIO = "studio",
}

type LocationStatus = {
  locationId: string;
  effective: string;
  term: string;
  set: string;
};

export interface IAccount {
  clientEligibilityStatus?: boolean;
  clientStateCode?: string;
  clientPayrollStatus?: string;
  clientPayrollFrequency?: string;
  payrollStatus?: "Payroll" | "NonPayroll" | "NotApplicable";
  isImpersonated?: boolean;
  csrUsername?: string | null;
  member?: AccountSummaryMember;

  locationsTier: string;
  entitlements?: string[];
  memberCardBase64?: {
    value: string;
  };
  homeGymFrame: {
    current?: LocationStatus;
    pending?: LocationStatus;
    status: string;
    allowedActions: string[];
  };

  memberCardBaseImage?: string;
  memberCardNumber?: string;
  nextPayment: {
    due: string;
    amount: number;
    overdue: boolean;
  } | null;
  creditBalance?: string;
  suspension: {
    status: string;
    suspend: string;
    resume: string;
  };
  currentPrice: number | undefined;
  hasAccount?: boolean;
  isDependent?: boolean;
  hasPaymentMethods?: boolean;
  acceptedProgram?: boolean;
  acceptedTerms: boolean;
  isMembershipActive?: boolean;
  memberCanCancel: boolean;
  payrollFrequency?: string;
}

const initialState: IAccount = {
  clientEligibilityStatus: true,
  clientStateCode: "",
  clientPayrollStatus: "",
  clientPayrollFrequency: "",
  payrollStatus: USER_PAYROLL_STATUS.NotApplicable,
  isImpersonated: false,
  csrUsername: null,
  member: {
    consumerId: "",
    personId: null,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    address1: "",
    address2: null,
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    cardNumber: "",
    eligibilities: [],
    subscriptionAccount: null,
  },
  locationsTier: "",
  entitlements: ["gym", "burnalong", "studio", "memberCard", "primeOnDemand"],
  nextPayment: {
    due: "",
    amount: 0,
    overdue: false,
  },
  memberCardBase64: {
    value: "",
  },
  memberCardBaseImage: "",
  currentPrice: undefined,
  suspension: {
    status: "",
    suspend: "",
    resume: "",
  },
  hasAccount: true,
  isDependent: false,
  memberCardNumber: "",
  hasPaymentMethods: true,
  acceptedProgram: true,
  acceptedTerms: true,
  isMembershipActive: true,
  memberCanCancel: false,
  payrollFrequency: "",
  homeGymFrame: {
    current: undefined,
    pending: undefined,
    status: "",
    allowedActions: [],
  },
};

export const AccountContext = React.createContext<
  IAccount & {
    refresh: () => Promise<void>;
    setDependentStatus: (status: boolean) => void;
    error: boolean;
    loading: boolean;
    refreshing: boolean;
    setAccountDetails(accountDetails: Partial<IAccount>): void;
  }
>({
  ...initialState,
  refresh: () => Promise.resolve(),
  setDependentStatus: () => Promise.resolve(),
  error: false,
  loading: true,
  refreshing: false,
  setAccountDetails: () => {
    return;
  },
});

const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  //before validation account
  const [tempAccount] = React.useState<IAccount | null>(null);
  //validated account
  const [account, setAccount] = React.useState<IAccount>(initialState);
  //statuses
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);

  const [refreshing, setRefreshing] = useState(false);
  const { clientConfig } = useConfig();
  const validate = (values: Optional<IAccount>): boolean => {
    if (!values?.member?.eligibilities[0].currentSubscriptionStatus) {
      return false;
    }
    return true;
  };

  const setDependentStatus = (status: boolean) => {
    setAccount({ ...account, isDependent: status });
  };
  useEffect(() => {
    if (tempAccount) {
      if (validate(tempAccount)) {
        setAccount(tempAccount);
      } else {
        setError(false);
      }
      setRefreshing(false);
      setLoading(false);
    }
  }, [tempAccount]);

  //refresh the aggregated enrollment
  const handleRefresh = async () => {
    try {
      const accountSummary = await axiosInstance("/account/summary", {});
      setAccount({ ...account, ...accountSummary.data });
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (
      (clientConfig &&
        account.member?.state &&
        !clientConfig.memberCancellations) ||
      (clientConfig &&
        account.member?.state &&
        clientConfig.memberCancellations &&
        clientConfig.memberCancellations.length === 0)
    ) {
      setAccount({ ...account, memberCanCancel: true });
    }
    if (
      clientConfig.memberCancellations &&
      clientConfig.memberCancellations.length > 0
    ) {
      clientConfig.memberCancellations?.map((item) => {
        item.valueList?.map((value) => {
          if (value === account.member?.state) {
            setAccount({ ...account, memberCanCancel: true });
          }
        });
      });
    }
  }, [account.member?.state, clientConfig]);

  return (
    <AccountContext.Provider
      value={{
        ...account,
        refresh: handleRefresh,
        setDependentStatus: setDependentStatus,
        setAccountDetails: useCallback(
          (accountDetails: Partial<IAccount>) => {
            setAccount({ ...account, ...accountDetails });
          },
          [account]
        ),
        error,
        loading,
        refreshing,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
