import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

/**
 *
 * @description requires a token
 */
const AuthWrapper = ({ children }: { children: React.ReactElement }) => {
  const [error] = useState(false);

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  }

  if (error) throw new Error();

  return <div>{isAuthenticated && children}</div>;
};

export default AuthWrapper;
