import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import Locations from "../../../pages/Locations";

const ChooseLuxuryGymModal = ({
  show,
  onClose,
  setOpenChooseLuxuryGymModal,
  setShowSuccessToast,
}: {
  show?: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  setOpenChooseLuxuryGymModal: Dispatch<SetStateAction<boolean>>;
  setShowSuccessToast: Dispatch<SetStateAction<boolean>>;
}) => {
  const [showModal, setShowModal] = useState(show);
  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header style={{ fontSize: "2rem", color: "gray" }}>
        <Modal.Title>Choose a luxury gym</Modal.Title>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => onClose(false)}
        >
          X
        </span>
      </Modal.Header>
      <div
        style={{
          marginLeft: "17px",
          marginBottom: "15px",
          marginTop: "-15px",
        }}
      >
        Search for nearby luxury gyms and choose your favorite
      </div>
      <Modal.Body style={{ marginTop: "-25px" }}>
        <Locations
          isLuxuryModal
          setOpenChooseLuxuryGymModal={setOpenChooseLuxuryGymModal}
          setShowSuccessToast={setShowSuccessToast}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ChooseLuxuryGymModal;
