import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useRect } from "@reach/rect";
import classNames from "classnames";
import { useTheme } from "react-jss";

import useStyles from "./styles";

const ButtonGroup = ({
  className,
  children,
  activeIndex,
  defaultActiveIndex,
  onButtonClick,
  groupLabel,
  role,
  isFullWidth,
  ...props
}) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const [uncontrolledActiveIndex, setUncontrolledActiveIndex] =
    useState(defaultActiveIndex);
  const [canTransition, setCanTransition] = useState(false);
  const [localActiveIndex, setLocalActiveIndex] = useState(
    activeIndex || activeIndex === 0 || uncontrolledActiveIndex
  );
  const activeDivRef = useRef();
  const isResizeObserverSupported = typeof ResizeObserver !== "undefined";

  useEffect(() => {
    setLocalActiveIndex(
      activeIndex || activeIndex === 0 ? activeIndex : uncontrolledActiveIndex
    );
  }, [activeIndex, uncontrolledActiveIndex]);

  const refs = useRef(children.map(() => React.createRef()));
  // This isn't kosher, but not sure how to track unknown number of buttons
  // eslint-disable-next-line
  const refSizes = refs.current.map((ref) => useRect(ref));

  useEffect(() => {
    // Don't run for IE11
    if (isResizeObserverSupported && refSizes[localActiveIndex]) {
      const baseXPosition = refSizes[0].x;
      activeDivRef.current.style.width = `${refSizes[localActiveIndex].width}px`;
      activeDivRef.current.style.transform = `translateX(${Math.abs(
        refSizes[localActiveIndex].x - baseXPosition
      )}px)`;
    }

    return () => {
      if (isResizeObserverSupported && refSizes[localActiveIndex]) {
        setCanTransition(true);
      }
    };
  }, [isResizeObserverSupported, localActiveIndex, refSizes]);

  const renderChildren = () => {
    // Extend Button components to automatically add styles and handle
    // onClick events
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        const isActive = index === localActiveIndex;
        return React.cloneElement(child, {
          isActive,
          className: classNames(child.props.className, {
            [classes.isActive]: isActive,
            [classes.isActiveOldBrowser]:
              isActive && !isResizeObserverSupported,
          }),
          ref: refs.current[index],
          role: role === "tablist" ? "tab" : undefined,
          onClick: (event) => {
            if (!activeIndex && activeIndex !== 0) {
              setUncontrolledActiveIndex(index);
            }
            if (child.props.onClick) {
              child.props.onClick(event, index);
            } else {
              onButtonClick(event, index);
            }
          },
        });
      }
    });
  };

  return (
    <div
      {...props}
      className={classNames(className, classes.container, {
        [classes.isFullWidth]: isFullWidth,
      })}
      role={role}
      aria-label={groupLabel}
    >
      {renderChildren()}
      {isResizeObserverSupported && (
        <div
          ref={activeDivRef}
          className={classNames(classes.activeBg, {
            [classes.canTransition]: canTransition,
          })}
        />
      )}
    </div>
  );
};

ButtonGroup.defaultProps = {
  role: "group",
  size: "medium",
  defaultActiveIndex: null,
  activeIndex: null,
  isFullWidth: false,
  onButtonClick: () => {
    return;
  },
};

ButtonGroup.propTypes = {
  /**
   * An ARIA role describing the button group.
   *   * @type {'group' | 'toolbar' | 'tablist'}
   */
  role: PropTypes.string,
  /**
   * Makes the button group take up the full width of it's parent
   */
  isFullWidth: PropTypes.bool,
  /**
   * Group Label used for assistive technologies
   */
  groupLabel: PropTypes.string.isRequired,
  /**
   * Sets the size for all Buttons in the group.
   *
   * @type ('small'|'medium'|'large')
   */
  size: PropTypes.string,
  /**
   * Sets which button is active on load
   * If you want to have none selected at start, pass null
   */
  defaultActiveIndex: PropTypes.number,
  /**
   * Controlled way to change which button is active
   * If you want to have none selected at start, pass null
   */
  activeIndex: PropTypes.number,
  /**
   * onClick function to apply to all butons
   */
  onButtonClick: PropTypes.func,
};

ButtonGroup.displayName = "ButtonGroup";

export default ButtonGroup;
