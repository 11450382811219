/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Modal, Spinner } from "react-bootstrap";

const DeletePaymentModal = ({
  open,
  onDelete,
  onClose,
}: {
  open: boolean;
  onDelete: Dispatch<string>;
  onClose: Dispatch<SetStateAction<boolean>>;
}) => {
  const [loading, setloading] = useState<boolean>(false);
  const handleDelete = () => {
    setloading(true);
    onDelete("id");
  };
  return open ? (
    <Modal show={open} onHide={() => onClose(true)} centered>
      <div className="setHomeGymModal">
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Delete Payment Method</h2>
          <span style={{ cursor: "pointer" }} onClick={() => onClose(true)}>
            X
          </span>
        </Modal.Title>
        <Modal.Body>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner animation="border"></Spinner>
            </div>
          ) : (
            <p className="mt-3">
              Are you sure you want to delete the payment method? By doing so we
              will no longer be able to issue refunds to this payment method.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <button
              onClick={() => onClose(false)}
              className="col btn btn-outline-primary ml-2"
            >
              Do Not Delete
            </button>
            <button
              onClick={() => handleDelete()}
              className="col mx-2 btn btn-secondary "
            >
              Delete Payment Method
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  ) : null;
};

export default DeletePaymentModal;
