import { Styles } from "jss";
import React from "react";
import { Card } from "react-bootstrap";
const Tile = ({
  children,
  style,
  className = "tile-container__card",
}: {
  children: React.ReactNode;
  style?: Styles;
  className?: string;
}) => {
  return (
    <Card style={style} className={className}>
      {children}
    </Card>
  );
};

const THeader = ({
  children,
  style,
  className = "tile-container__header m-2",
}: {
  children: React.ReactNode;
  style?: Styles;
  className?: string;
}) => {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

const TBody = ({
  children,
  style,
  className = "tile-container__body m-2",
}: {
  children: React.ReactNode;
  style?: Styles;
  className?: string;
}) => {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

const TFooter = ({
  children,
  style,
  className = "tile-container__footer m-2",
}: {
  children: React.ReactNode;
  style?: Styles;
  className?: string;
}) => {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

Tile.THeader = THeader;
Tile.TBody = TBody;
Tile.TFooter = TFooter;

export { Tile };
