import React, { useState, useEffect, useContext } from "react";
// import ActivityFilters from "./ActivityFilters";
import ActivityListTile from "../Activity/ActivityListTile";
// import { ActivityPagination } from "./ActivityPagination";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner } from "react-bootstrap";
import ActivitiesTileSVG from "../../../resources/ActivitiesTileSVG";
import { Link } from "react-router-dom";
import { momentStartOfDay } from "../../../utility/util";
import axiosInstance from "../../../axios.instance";
import { UserContext } from "../../../context/UserProvider";
// import { EmptyList } from "../Activity/EmptyList";

export interface IActivity {
  activeLivingMemberId: string;
  class?: string;
  location: string;
  activityTime: string;
  activityDate: string;
  type: string;
  visitType: string;
}
type IState = IActivity[];

const ActivityLogTile = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const today = momentStartOfDay().format("l");
  const now = momentStartOfDay();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultStartDate = now.subtract(1, "y").format("l");
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedActivities, setSelectedActivities] = useState<IState>([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useContext(UserContext);

  const getActivities = async (startDate: string, endDate: string) => {
    setLoadingActivityLog(true);
    axiosInstance
      .get(`/account/activity?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response) {
          const activityList: IActivity[] = response.data;
          const filteredActivities = activityList.filter((activity) => {
            const activityDate = new Date(activity.activityDate);
            if (
              activityDate >= new Date(startDate) &&
              activityDate <= new Date(endDate)
            ) {
              return true;
            }

            return false;
          });
          setLoadingActivityLog(false);
          setSelectedActivities(filteredActivities);
        }
      })
      .catch((error) => {
        setSelectedActivities([]);
        setLoadingActivityLog(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getActivities(defaultStartDate, today);
    // eslint-disable-next-line
  }, []);
  



  useEffect(() => {
    if (localStorage.getItem("currentActivityPage")) {
      const currentActivityPage = localStorage.getItem("currentActivityPage");
      setCurrentPageNum(Number(currentActivityPage));
    }
    return () => {
      localStorage.removeItem("currentActivityPage");
    };
  }, []);

  return (
    <Tile className="widget__card">
      <Tile.THeader>
        <div>
          <FontAwesomeIcon icon={faRunning} /> <b>ACTIVITY LOG</b>
        </div>
        {selectedActivities && selectedActivities.length !== 0 && (
          <Link to="/account/activity" className="a-link">
            <span>View Full Activity Log</span>
          </Link>
        )}
      </Tile.THeader>

      <Tile.TBody>
        {loadingActivityLog ? (
          <div className="d-flex justify-content-center align-items-center m-2">
            <Spinner animation="border" />
          </div>
        ) : !selectedActivities || selectedActivities.length === 0 ? (
          <div>
            <ActivitiesTileSVG />
            <h5 className="text-center mb-0 mt-3">No logged activity yet</h5>
            <p className="text-center">
              Visits will show up here when you start visiting participating
              locations.
            </p>
          </div>
        ) : (
          <>
            <ActivityListTile
              activities={selectedActivities}
              currentPageNum={currentPageNum}
            />
          </>
        )}
      </Tile.TBody>
      {loadingActivityLog ? null : !selectedActivities ||
        selectedActivities.length === 0 ? (
        <Tile.TFooter>
          <Button
            as={Link}
            to="/account/locations"
            variant="outline-primary"
            className="btn align-self-center mb-3 m-lg-0 text-center activity_log_button"
          >
            View Locations Near You
          </Button>
        </Tile.TFooter>
      ) : null}

    </Tile>
  );
};

export default ActivityLogTile;
