import { useState, useEffect } from "react";

const useAgeValidation = ({
  dateOfBirth,
  ageLimit = 18,
}: {
  dateOfBirth: string | Date;
  ageLimit?: number;
}) => {
  const [isUnderAge, setIsUnderAge] = useState(false);
  if (typeof dateOfBirth === "string") {
    dateOfBirth = new Date(dateOfBirth);
  }
  useEffect(() => {
    const currentDate = new Date();
    const ageLimitYearsAgo = new Date(
      currentDate.getFullYear() - ageLimit,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (dateOfBirth > ageLimitYearsAgo) {
      setIsUnderAge(true);
    } else {
      setIsUnderAge(false);
    }
  }, [dateOfBirth]);

  return isUnderAge;
};

export default useAgeValidation;
