import { Button, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useConfig } from "../../configuration/useConfig";
import { UserContext } from "../../context/UserProvider";
import { useContext } from "react";

const NavMenu = ({
  onEligibility,
  onLogin,
  onEnroll,
}: {
  onEligibility?: (e: unknown) => void;
  onLogin?: (e: unknown) => void;
  onEnroll?: (e: unknown) => void;
}) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const userLoggedIn = isAuthenticated;
  const { config } = useConfig();
  const { user } = useContext(UserContext);

  return (
    <div className="slanted-background d-flex px-2">
      <Nav className="landing-page-nav-bar px-2">
        {onLogin && (
          <Nav.Item>
            <Button
              variant="outline-primary"
              className={`${
                !userLoggedIn && `nav-btn-enroll`
              } small font-weight-bold mx-lg-4 mx-sm-0`}
              onClick={() => {
                loginWithRedirect();
              }}
            >
              {userLoggedIn ? "My Account" : "Log in"}
            </Button>
          </Nav.Item>
        )}
        {onEligibility &&
          (userLoggedIn && !user.isSSO ? (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll small font-weight-bold"
                onClick={() => logout()}
              >
                Logout
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </Button>
            </Nav.Item>
          ) : (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll landing-page"
                onClick={onEligibility}
              >
                {config["client"] == "walmart"
                  ? "Get Started"
                  : config["client"] == "hcsc"
                  ? `Enroll Now`
                  : `Check My Eligibility`}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </Button>
            </Nav.Item>
          ))}
        {onEnroll &&
          (userLoggedIn ? (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll small font-weight-bold"
                onClick={() => logout()}
              >
                Logout
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </Button>
            </Nav.Item>
          ) : (
            <Nav.Item>
              <Button
                variant="primary"
                className="nav-btn-enroll font-weight-bold"
                onClick={onEnroll}
              >
                Get Started
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-right"
                />
              </Button>
            </Nav.Item>
          ))}
      </Nav>
    </div>
  );
};

export default NavMenu;
