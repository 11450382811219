import { Entry } from "contentful";
import React from "react";
import { IFeature, IFeatureFields } from "../../@types/generated/contentful";
import { FeatureComponentBody, FeatureInput } from "../../types";
import {
  convertToFeature,
  convertToFeatureLinked,
} from "../../utility/contentful";
import Feature from "./Feature";
import FeatureBottom from "./FeatureBottom";
import FeatureBottomNoButton from "./FeatureBottomNoButton";
import FeatureDigital from "./FeatureDigital";
// import FeatureMembership from "./FeatureMembership";
import FeatureFamilyMembership from "./FeatureFamilyMembership";
import FeatureMobile from "./FeatureMobile";
import FeatureTestimonial from "./FeatureTestimonial";
import FeatureFaq from "./FeatureFaq";
import FeatureDigitalBurnAlong from "./FeatureDigitalBurnalong";
const FeatureFactory = ({
  type,
  data,
  params,
  index,
  linked,
}: {
  type: string;
  data: Entry<IFeatureFields> | IFeature;
  params?: { [key: string]: unknown };
  index?: number;
  linked?: boolean;
}) => {
  let converted: FeatureInput | null = null;
  if (linked) {
    converted = convertToFeatureLinked(
      data as IFeature,
      params ?? {},
      index ?? 0
    );
  } else {
    converted = convertToFeature(
      data as Entry<IFeatureFields>,
      params ?? {},
      index ?? 0
    );
  }
  switch (type) {
    case "feature-default":
      return <Feature input={converted} />;
    case "feature-default-weekly":
      return <Feature input={converted} type={type} />;
    case "feature-family-membership":
      return <FeatureFamilyMembership input={converted} />;
    case "feature-wellness":
      return <Feature input={converted} />;
    case "feature-digital":
      return <FeatureDigital input={converted} />;
    case "feature-membership-cards":
      return <FeatureDigitalBurnAlong input={converted} />;
    case "feature-digital-burnalong":
      return <FeatureDigitalBurnAlong input={converted} />;
    case "feature-mobile-app":
      return <FeatureMobile input={converted} />;
    case "feature-testimonial":
      return <FeatureTestimonial input={converted} />;
    case "feature-faq":
      return <FeatureFaq input={converted} />;
    case "feature-bottom":
      return <FeatureBottom input={converted} />;
    case "feature-bottom-no-button":
      return <FeatureBottomNoButton input={converted} />;
    case "feature-component":
      return (converted.body as FeatureComponentBody).node;
    default:
      return null;
  }
};

export default FeatureFactory;
