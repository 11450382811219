import React from "react";

const BannerWrapper = ({
  children,
  show,
}: {
  children: React.ReactChild;
  show: boolean;
}) => {
  return <>{show && children}</>;
};

export default BannerWrapper;
