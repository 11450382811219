import * as React from "react";
import { FormikProps, connect } from "formik";
import debounce from "lodash.debounce";
import isEqual from "react-fast-compare";

/**
 * FROM GITHUB
 * https://github.com/jaredpalmer/formik-persist/blob/master/src/formik-persist.tsx
 */

export interface PersistProps {
  name: string;
  debounce?: number;
  isSessionStorage?: boolean;
}

class PersistImpl extends React.Component<
  PersistProps & { formik: FormikProps<unknown> },
  unknown
> {
  static defaultProps = {
    debounce: 300,
  };

  saveForm = debounce((data: FormikProps<unknown>) => {
    if (this.props.isSessionStorage) {
      window.sessionStorage.setItem(this.props.name, JSON.stringify(data));
    } else {
      window.localStorage.setItem(this.props.name, JSON.stringify(data));
    }
  }, this.props.debounce);

  componentDidUpdate(
    prevProps: PersistProps & { formik: FormikProps<unknown> }
  ) {
    if (!isEqual(prevProps.formik, this.props.formik)) {
      this.saveForm(this.props.formik);
    }
  }

  componentDidMount() {
    const maybeState = this.props.isSessionStorage
      ? window.sessionStorage.getItem(this.props.name)
      : window.localStorage.getItem(this.props.name);
    if (maybeState && maybeState !== null) {
      this.props.formik.setFormikState(JSON.parse(maybeState));
    }
  }

  render() {
    return null;
  }
}

export const Persist = connect<PersistProps, unknown>(PersistImpl);
