import React, { useContext } from "react";
import { useTheme } from "react-jss";
import { LocationsContext } from "../../../../../context/LocationsProvider";

import useStyles from "./styles";
// import { ENROLLMENT_ACTIONS } from "../../../../../graphql/Mutations";

// TODO: Get distance from the api

// CODE MIGHT BE NEEDED IN THE FUTURE FOR HOMEGYMS

const Marker = () => {
  return (
    <svg
      viewBox="0 0 5 24"
      width="24"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillRule="evenodd">
          <path
            d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
            fill="#82B266"
          />
          <circle fill="#FFF" cx="8" cy="7.5" r="3" />
        </g>
      </g>
    </svg>
  );
};

// CODE MIGHT BE NEEDED IN THE FUTURE FOR HOMEGYMS

const HomeGymIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      margin="-6px 5px 0 0"
      viewBox="0 0 782.208 752"
    >
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
        fill="#0d2674"
      />
    </svg>
  );
};

const Item = ({ location, studio, distance, style, ...props }) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const {
    setLocationDetail,
    setLocationHover,
    setStudioDetail,
    setStudioHover,
    account,
  } = useContext(LocationsContext);

  // const currentTier =
  //   account.member?.eligibilities[0].currentSubscription?.ratePlanName.substr(
  //     account.member?.eligibilities[0].currentSubscription?.ratePlanName.indexOf(
  //       "-"
  //     ) + 1
  //   );

  const handleClick = () => {
    if (props.isStudio) {
      setStudioDetail(studio);
    } else {
      setLocationDetail(location);
    }
  };

  const handleMouseEnter = () => {
    if (props.isStudio) {
      setStudioHover(studio);
    } else {
      setLocationHover(location);
    }
  };

  const handleMouseLeave = () => {
    if (props.isStudio) {
      setStudioHover();
    } else {
      setLocationHover();
    }
  };

  // Width style hack to show hover border with react-virtualized
  return (
    <>
      <div
        style={{ ...style, width: "calc(100% - 6px)" }}
        className={classes.itemWrapper}
      >
        <button
          type="button"
          className={classes.item}
          onClick={(e) => handleClick(e)}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <h4 className={classes.itemName}>
            {(location && location.name) || (studio && studio.name)}
          </h4>
          {/* Home Gym not needed for walmart */}
          {location?.tierNumber >= 4 && !studio ? (
            <div className={classes.itemMarkerContainer}>
              <Marker />
              <span className={classes.eliteText}>LUXURY</span>
              <div className="position-realtive">
                <div className="locations-setHomeGym">
                  {account?.homeGymFrame?.status !== "NONE" &&
                    location.id ===
                      account?.homeGymFrame?.current?.locationId && (
                      <span>
                        <HomeGymIcon className="locations-setHomeGym__icon" />
                        <div className="locations-setHomeGym__text">
                          LUXURY GYM
                        </div>
                      </span>
                    )}
                </div>
              </div>
            </div>
          ) : null}
          <div className={classes.itemAddress}>
            <p>
              {(location && location.street) ||
                (studio && studio.contactInfoViewModel.street)}
              . <br></br>
              {(location && location.city) ||
                (studio && studio.contactInfoViewModel.city)}{" "}
              {(location && location.state) ||
                (studio && studio.contactInfoViewModel.stateCode)}
              ,{" "}
              {(location && location.zip) ||
                (studio && studio.contactInfoViewModel.postalCode)}
            </p>
            <span>{distance?.toPrecision(2)} miles</span>
          </div>

          {/*CODE MIGHT BE NEEDED IN THE FUTURE FOR HOMEGYMS*/}
          {/* {parseInt(location?.tierNumber) >=4 &&
            currentTier >= 4 && (
              <div className="mt-2">
                <HomeGymIcon />
                (
                  <>
                    {location.id !==
                    account?.homeGymFrame?.pending?.locationId ? (
                      <button
                        // onClick={() => setChangeShowModal(true)}
                        className="locations-homeGym"
                      >
                        Set As Home Gym
                      </button>
                    ) : (
                      <button className="locations-homeGym">
                        Future Home Gym
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    // onClick={() => setShowModal(true)}
                    className="locations-homeGym"
                  >
                    Set As Home Gym
                  </button>
                )
              </div>
            )} */}
          {/* {parseInt(location.tierNumber) >= 4 &&
            currentTier >= 4 && (
              <div className="mt-2">
                <HomeGymIcon />
                <button
                  // onClick={() => setShowModal(true)}
                  className="locations-homeGym"
                >
                  Set As Home Gym
                </button>
              </div>
            )} */}
        </button>
      </div>
    </>
  );
};

export default Item;
