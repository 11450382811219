import React from "react";
import useTheme from "../hooks/useTheme";

type ITheme = {
  primary: string;
  contrastTextPrimary: string;
  secondary: string;
  accent: string;
};

export const ThemeContext = React.createContext<ITheme>({
  primary: "",
  accent: "",
  contrastTextPrimary: "",
  secondary: "",
});

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
