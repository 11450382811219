import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "react-jss";
import PlacesAutocomplete from "react-places-autocomplete";
import Spinner from "../../UI/Spinner";
import useStyles from "./styles";
import { LocationsContext } from "../../../../context/LocationsProvider";

// TODO: Fix the address
// TODO: Fix the commented useEffect
const Search = (props) => {
  const classes = useStyles({ ...props, theme: useTheme() });
  const [address, setAddress] = useState("");
  const [modifiedSuggestions, setModifiedSuggestions] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [showSearchLoader, setShowSearchLoader] = useState(false);
  const [searchError, setSearchError] = useState();
  const {
    setLoading,
    clearLocations,
    setLocationDetail,
    isMapLoaded,
    setHasValidSearch,
    setLookupAddress,
  } = useContext(LocationsContext);

  const trimUSA = (passedAddress) => {
    return typeof passedAddress === "string" && passedAddress.endsWith(", USA")
      ? passedAddress.slice(0, passedAddress.length - 5)
      : passedAddress;
  };

  // Load supplied location on the map
  const loadLocation = useCallback(
    (location) => {
      setLocationDetail({});
      setAddress(trimUSA(location));
      setLoading(true);
      setLookupAddress(location);
      setHasValidSearch(true);
    },
    [setLocationDetail, setLoading, setHasValidSearch, setLookupAddress]
  );

  useEffect(() => {
    if (isSearchLoading) {
      const loaderTimer = setTimeout(() => {
        setShowSearchLoader(true);
      }, 1000);

      // Cleanup
      return () => {
        setShowSearchLoader(false);
        clearInterval(loaderTimer);
      };
    }
  }, [isSearchLoading]);

  const addCurrentLocationButton = (suggestions, autoSelect) => {
    // Add the current location button if we have suggestions,the
    // browser supports geolocation, and we haven't already done it
    if (
      navigator.geolocation &&
      (suggestions.length === 0 ||
        (suggestions.length > 0 && suggestions[0].id !== "currentLocationBtn"))
    ) {
      const currentLocationBtn = {
        active: autoSelect,
        id: "currentLocationBtn",
        placeId: "currentLocationBtn",
        index: 0,
        description: (
          <div>
            <FontAwesomeIcon
              className={classes.currentLocationIcon}
              icon={faLocationArrow}
            />
            Use Current Location
          </div>
        ),
      };
      suggestions.forEach((suggestion) => (suggestion.index += 1));
      suggestions.unshift(currentLocationBtn);
    }
    setModifiedSuggestions(suggestions);
  };

  // Update text for controlled search input
  const handleInputChange = (passedAddress) => {
    // Lop off USA at end of suggestion since they're all USA
    setAddress(
      passedAddress.props &&
        passedAddress.props.children[1].includes("Current Location")
        ? passedAddress.props.children[1]
        : trimUSA(passedAddress)
    );
    addCurrentLocationButton(modifiedSuggestions);
    setSearchError(null);
  };

  // Load Selected Location
  const handleInputSelect = (passedAddress) => {
    // Set address using geolocation
    if (
      passedAddress.props &&
      passedAddress.props.children[1].includes("Current Location")
    ) {
      loadLocation("Current Location");
    } else if (passedAddress === "") {
      // Clear location/locations if empty search is performed
      setLocationDetail({});
      setAddress("");
      clearLocations();
      setHasValidSearch("initial");
      setSearchError(null);
      // Set address
    } else {
      loadLocation(passedAddress);
      if (typeof props.onSearched === "function") {
        props.onSearched();
      }
    }
  };

  const searchInput = useRef(null);

  const handleClearSearch = () => {
    setAddress("");
    searchInput.current.focus();
    setSearchError(null);
    clearLocations();
  };

  const handleInputFocus = (suggestions) => {
    setSearchError(false);
    addCurrentLocationButton(suggestions, true);
  };

  const handleInputBlur = () => {
    setModifiedSuggestions([]);
  };

  return (
    <form
      className={classes.searchForm}
      id="locationForm"
      onSubmit={(e) => e.preventDefault()}
    >
      {isMapLoaded && (
        <PlacesAutocomplete
          value={address}
          onChange={handleInputChange}
          onSelect={handleInputSelect}
          highlightFirstSuggestion
          searchOptions={{
            componentRestrictions: { country: ["US", "PR"] },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              className={`${classes.searchInputWrapper} ${
                suggestions.length > 0 || searchError
                  ? classes.searchInputWrapperActive
                  : ""
              }`}
            >
              <div className={classes.searchInputContainer}>
                <input
                  ref={searchInput}
                  {...getInputProps({
                    placeholder: "Search by address, city, zip, or location",
                    className: classes.searchInput,
                    id: "location",
                    onFocus: () => handleInputFocus(suggestions),
                    onBlur: handleInputBlur,
                  })}
                />
                {address && (
                  <button
                    type="button"
                    onClick={handleClearSearch}
                    className={classes.clearButton}
                  >
                    <span className={classes.visuallyHidden}>Clear Search</span>
                    <FontAwesomeIcon className={classes.icon} icon={faTimes} />
                  </button>
                )}
              </div>

              <div className={classes.autoCompleteDropDown}>
                {searchError && (
                  <div className={classes.searchError}>{searchError}</div>
                )}
                {/* Show Loader only if nothing comes back after 1 sec */}
                {setIsSearchLoading(loading)}
                {showSearchLoader && (
                  <div className={classes.autoCompleteDropDownItem}>
                    <Spinner>Loading…</Spinner>
                  </div>
                )}

                {/* Add Current Location Button */}
                {suggestions.length > 0 &&
                  addCurrentLocationButton(suggestions, false)}

                {/* Suggestions */}
                {suggestions.map((suggestion, index) => {
                  return (
                    <button
                      key={`suggestion-${index}`}
                      type="button"
                      {...getSuggestionItemProps(suggestion, {
                        className: `${classes.autoCompleteDropDownItem} ${
                          suggestion.active
                            ? classes.autoCompleteDropDownItemActive
                            : ""
                        }`,
                      })}
                    >
                      <span>{trimUSA(suggestion.description)}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </form>
  );
};

export default Search;
