import React from "react";
import { FeatureDefaultBody, FeatureInput } from "../../types";

const FeatureBurnalong = ({ input }: { input: FeatureInput }) => {
  const TextArea = () => {
    const body: FeatureDefaultBody = input.body as FeatureDefaultBody;
    return <div className="text-center p-2">{body.text}</div>;
  };
  return (
    <div className="col-sm">
      <img alt="background" src={`https://${input.media?.slice(2)}`} />
      <TextArea />
    </div>
  );
};

export default FeatureBurnalong;
