import {  useEffect, useState } from "react";
import axiosInstance from "../axios.instance";
import { AccountSummaryMember } from "../utility/member-portal-api-types";

const useGetDependents = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dependents, setDependents] = useState<AccountSummaryMember[]>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      axiosInstance
        .get(`/account/dependents`)
        .then((response) => {
          if (response.data) {
            setLoading(false);
            setDependents(response.data)
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setError(true);
        });
    };
    fetchData();
    return () => setLoading(false);
  }, []);

  return { error, loading, dependents };
};

export default useGetDependents;
