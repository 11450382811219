import React from "react";
import { FeatureDefaultBody, FeatureInput } from "../../types";
import { IFeatureDefaultBodyFields } from "../../@types/generated/contentful";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureFamilyMembership = ({ input }: { input: FeatureInput }) => {
  const body: FeatureDefaultBody = input.body as FeatureDefaultBody;
  const featureTime: IFeatureDefaultBodyFields =
    input.body as IFeatureDefaultBodyFields;
  return (
    <section
      className="family-memberships"
      style={{
        backgroundImage: `url(https://${input.media.slice(2)})`,
      }}
    >
      <div className="container">
        <div className="row hero-left">
          <div className="white col-lg-7 extra-padding-bottom pt-5 mt-5 py-5">
            {body.text}
            <div className="extra-padding-bottom" />
            {featureTime.iconList?.map((icon) => {
              return (
                <div className="row">
                  <div className="col-md-1">
                    <img
                      src={`https://${icon.fields.icon.fields.file.url}`}
                      alt={icon.fields.icon.fields.title}
                    />
                  </div>
                  <div className="col-md-7">
                    <p>{icon.fields.text}</p>
                    <br></br>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureFamilyMembership;
