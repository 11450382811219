import React from "react";
import { Icon } from "../../../resources/Icons";

const UnderageFamilyMemberBanner = () => {
  return (
    <div
      className={
        "d-flex shadow card mb-2 p-3 border-0 my-lg-3 justify-content-between suspended-warning py-4"
      }
    >
      <p className="underage-banner-heading px-4 mb-1">
        <span className="suspended-warning__icon">
          <Icon
            name="exclamation-triangle"
            width="19px"
            height="17px"
            className="suspended-warning__icon"
          />
        </span>
        <b>Underage Family Member(s) Added</b>
      </p>
      <p className="underage-banner-subheading m-0 px-4 mb-2">
        <b>
          The following restrictions apply for family members under the age of
          18:
        </b>
      </p>
      <ul className="underage-bullet-list">
        <li className="mb-1">
          A parent or guardian must be present during the first visit to sign
          the legal waiver(s).
        </li>
        <li className="mb-1">
          Access to participating locations will be at the location's
          discretion.
        </li>
        <li className="mb-1">
          Access to fitness packages will be based on the primary's package
          selection.
        </li>
      </ul>
    </div>
  );
};

export { UnderageFamilyMemberBanner };
