/* eslint-disable no-constant-condition */
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useConfig } from "../../../configuration/useConfig";
import useIncontact from "../../../hooks/useIncontact";
import useSummary from "../../../hooks/useSummary";
import useZuora from "../../../hooks/useZuoraPayments";
import ProfileToast from "../../../utility/ProfileToast";
import StatusModal from "../../wizard/components/StatusModal";
import styles from "./AddPaymentModal.module.scss";
import axiosInstance from "../../../axios.instance";
import { UserContext } from "../../../context/UserProvider";
import { AccountContext } from "../../../context/AccountProvider";

const AddPaymentModal = ({
  open,
  onAdd,
  onClose,
}: {
  open: boolean;
  onAdd: () => void;
  onClose: () => void;
}) => {
  const { user } = useContext(UserContext);
  const { refresh } = useContext(AccountContext);
  const [openModal, setOpenModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isSetAsDefault, setIsSetAsDefault] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const [, setExecuting] = useState(false);
  const [reloadToggle, setReloadToggle] = useState<boolean>(false);
  const [, setLoading] = useState<boolean>(false);

  const { client, config } = useConfig();

  const { error: summaryError } = useSummary();
  const {
    isImpersonated,
    loading: csrLoading,
    error: csrError,
    canContinue,
    setMaskDisabled,
    setMaskEnabled,
  } = useIncontact();

  useEffect(() => {
    (async () => {
      if (isImpersonated && !user.isPayroll) {
        await setMaskEnabled();
      }
    })();
  }, []);
  const onSuccess = useCallback(async () => {
    onAdd();
    onClose();
    refresh();
    return;
  }, []);
  const onSubmissionFailed = useCallback(async () => {
    if (isImpersonated) {
      try {
        await setMaskDisabled();
      } catch (e) {
        ProfileToast("Could not disable Incontact mask.", false, client);
      }
    }
    ProfileToast("Payment Method could not be created.", false, client);
    return;
  }, []);
  const onRequestFailed = useCallback(async () => {
    if (isImpersonated) {
      try {
        await setMaskDisabled();
      } catch (e) {
        ProfileToast("Could not disable Incontact mask.", false, client);
      }
    }
    ProfileToast("Payment Method could not be created.", false, client);
  }, []);
  const { render, handleSubmit, hasRendered } = useZuora({
    paymentType: paymentMethod,
    onSuccess,
    onSubmissionFailed,
    onRequestFailed,
    setPaymentToken,
  });

  useEffect(() => {
    render();
  }, [render, paymentMethod]);

  const handlePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };

  useEffect(() => {
    if (paymentToken) {
      updatePaymentMethods(paymentToken);
    }
  }, [paymentToken, isSetAsDefault]);

  const updatePaymentMethods = (id: string) => {
    setExecuting(true);
    if (isSetAsDefault || (paymentMethod && paymentMethod.length === 0)) {
      axiosInstance
        .put(`/account/payment-methods/${id}/default`, id, {
          headers: {
            accept: "application/json",
          },
        })
        .then(() => {
          setReloadToggle(!reloadToggle);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    }
  };

  const handleClose = async () => {
    if (isImpersonated) await setMaskDisabled();
    onClose();
  };

  const CSRState = () => {
    if (csrLoading) {
      <div className="center-loading">
        <Spinner animation="border" />
      </div>;
    }
    if (csrError) {
      return (
        <div>
          <h5 style={{ color: "red" }}>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    if (!canContinue) {
      return (
        <div>
          <h5>
            Issue with call recording session. Please contact your
            administrator.
          </h5>
        </div>
      );
    }
    return null;
  };

  const handleZuora = () => {
    window.open("https://www.zuora.com/", "_blank");
    setOpenModal(false);
  };

  const handleZuoraCancel = () => {
    setOpenModal(false);
  };

  const handleTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  return open ? (
    <Modal show={open} onHide={handleClose} centered>
      <div className="setHomeGymModal">
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Add Payment Method</h2>
          <span style={{ cursor: "pointer" }} onClick={handleClose}>
            X
          </span>
        </Modal.Title>
        <Modal.Body>
          {csrLoading || csrError || !canContinue ? (
            <Fragment>{CSRState()}</Fragment>
          ) : (
            <>
              <Form.Group className="mb-1">
                <Form.Label>PAYMENT METHOD</Form.Label>
                <Form.Control as="select" onChange={handlePaymentMethod}>
                  <option value="Credit Card">Credit Card</option>
                  {config["client"] !== "walmart" && (
                    <option value="E-Check">E-Check</option>
                  )}
                </Form.Control>
              </Form.Group>
              {!hasRendered && !summaryError && (
                <div className="zuora-loading">
                  <Spinner animation="border" />
                </div>
              )}
              {summaryError && (
                <div className="zuora-center-error">
                  <h2>Sorry, there was an error loading this payment page.</h2>
                </div>
              )}
              {!summaryError && <div id="zuora_payment"></div>}
              {hasRendered && (
                <div className={styles["add-payment-modal__zuoraText"]}>
                  Powered by{" "}
                  <a
                    className={styles["add-payment-modal__zuoraButton"]}
                    onClick={() => setOpenModal(true)}
                  >
                    Zuora
                  </a>
                </div>
              )}
              {hasRendered && (
                <div className={styles["add-payment-modal__item"]}>
                  <span className={styles["add-payment-modal__subheader"]}>
                    Agreements
                  </span>
                  <div>
                    <label
                      className={styles["add-payment-modal__form-check-label"]}
                    >
                      <input
                        type="checkbox"
                        onChange={handleTerms}
                        style={{
                          marginRight: "8px",
                          marginTop: "1px",
                        }}
                      />
                      <div>
                        <span
                          className={
                            styles["add-payment-modal__required-field-marker"]
                          }
                        >
                          *
                        </span>
                        By checking this box, I acknowledge that I have read and
                        agree to comply with the terms and conditions of the{" "}
                        <a
                          href={
                            config["client"] === "bsca" ||
                            config["client"] === "bcbsa"
                              ? `/fitness-program-agreement?clientCode=${config["client"]}`
                              : "/fitness-program-agreement"
                          }
                          target="_blank"
                          className="a-link"
                        >
                          Fitness Program Agreement
                        </a>
                      </div>
                    </label>
                  </div>
                  <div>
                    <label
                      className={styles["add-payment-modal__form-check-label"]}
                    >
                      <input
                        type="checkbox"
                        onChange={() => setIsSetAsDefault(!isSetAsDefault)}
                        style={{
                          marginRight: "8px",
                          marginTop: "1px",
                        }}
                      />
                      <div>Set As Default Payment Method</div>
                    </label>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <button
              onClick={handleClose}
              className="col btn btn-outline-primary ml-2"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              disabled={!termsAccepted}
              className="col mx-2 btn btn-secondary "
            >
              Add Payment Method
            </button>
          </div>
        </Modal.Footer>
      </div>
      {openModal && (
        <StatusModal
          show={openModal}
          status={"ZUORA"}
          message=""
          onCancel={handleZuoraCancel}
          onNext={handleZuora}
        />
      )}
    </Modal>
  ) : null;
};

export default AddPaymentModal;
