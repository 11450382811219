import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../context/AccountProvider";
/*
 * Determines whether to start eligibility, handoff to enrollment, or resume enrollment

* TODO: possibly return states instead of pushing history
 *
 */
const useEligibilityStart = (): {
  loading: boolean;
  currentEligibility: {
    [key: string]: unknown;
  } | null;
} => {
  const [loading, setLoading] = useState(false);
  const [currentEligibility] = useState<{
    [key: string]: unknown;
  } | null>(null);
  const { isAuthenticated } = useAuth0();

  const eligibilityQuery = () => {
    return;
  };
  const enrollmentResume = () => {
    return;
  };
  const { member } = useContext(AccountContext);
  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated) {
          if (member?.eligibilities[0]) {
            //already valid member
            if (
              member?.eligibilities[0].currentSubscriptionStatus === undefined
            ) {
              enrollmentResume();
            } else if (
              member?.eligibilities[0].currentSubscriptionStatus ===
                "Cancelled" &&
              member?.eligibilities[0].currentSubscription?.ratePlanName ===
                "Fitness Pass Legacy"
            ) {
              enrollmentResume();
            } else {
              // history.push("/account");
            }
          } else {
            //resume enrollment flow
            enrollmentResume();
          }
        } else {
          //standard eligibility flow
          eligibilityQuery();
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [isAuthenticated]);

  return { loading, currentEligibility };
};

export default useEligibilityStart;
