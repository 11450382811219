import React from "react";

const TableComponent = ({ tableItems }: { tableItems: string[] }) => {
  return (
    <ul className="wellness-table">
      {tableItems.map((ti: string, index: number) => (
        <li key={`table-component-${index}`}>{ti}</li>
      ))}
    </ul>
  );
};

export default TableComponent;
