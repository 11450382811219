import { useEffect, useState } from "react";

interface ICheckOrder {
  success: boolean;
  loading: boolean;
  error: boolean;
  start: () => void;
  reset: () => void;
}

const useCheckOrder = (): ICheckOrder => {
  const [skip, setSkip] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const start = () => {
    setSkip(false);
    return;
  };
  const reset = () => {
    setSkip(true);
    setSuccess(false);
    setLoading(false);
    setError(false);
  };

  useEffect(() => {
    if (error || success) {
      setLoading(false);
      setSkip(true);
    }
  }, [error, success]);

  useEffect(() => {
    if (!skip) {
      setLoading(true);
      setError(false);
      setSuccess(false);
    }
  }, [skip]);

  return { success, loading, error, start, reset };
};

export default useCheckOrder;
