import React from "react";
import { FeatureDigitalBody, FeatureInput } from "../../types";
import Feature from "./Feature";
import FeatureList from "./FeatureList";

const FeatureDigital = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureDigitalBody;
  return (
    <FeatureList
      backgroundColor={input.backgroundColor}
      header={body.header}
      subtext={body.subtext}
    >
      {(background) => {
        return body.features.map((f, index: number) => (
          <Feature
            input={{ ...f, background: background }}
            key={`list-${index}`}
          />
        ));
      }}
    </FeatureList>
  );
};

export default FeatureDigital;
