import { Spinner } from "react-bootstrap";

const Impersonate = () => {

  return (
    <div className="center-loading">
      <Spinner animation="border" />
    </div>
  );
};

export default Impersonate;
