import { PROGRAM_CODE } from "../context/UserProvider";

const getProgramCode = (client) => {
  switch (client) {
    case "walmart":
      return PROGRAM_CODE.PrimeLTE;
    case "hcsc":
      return PROGRAM_CODE.PrimeSTD;
    case "bsca":
      return PROGRAM_CODE.PrimeFYW;
    case "bcbsa":
      return PROGRAM_CODE.PrimeFYW;
    case "mutual-of-omaha":
      return PROGRAM_CODE.PrimeLGY;
    default:
      return "";
  }
};

export default getProgramCode;
