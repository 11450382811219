import React from "react";

export const ActivitiesTileSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1598.879 552.689"
      >
        <g
          id="Group_3175"
          data-name="Group 3175"
          transform="translate(-660.321 -979.126)"
        >
          <path
            id="Path_1944"
            data-name="Path 1944"
            d="M844.785,796.065l-58.15-86.476,58.032,95.779-.122,9.731q6.368.048,12.612-.212L854.273,689.8l.079-.965-.107-.187-.276-11.819,63.682-92.458-63.607,83.612-.226,2.5-2.179-94.5,55-95.858L851.483,559.38l.576-195.788v-.652l-.019.641-3.652,154.308-50.129-62.68,49.965,75.973-1.094,84.532L801,533.214l45.96,94.928-.609,47L779.267,560.224l66.881,131.319Z"
            transform="translate(317.315 697.474)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1945"
            data-name="Path 1945"
            d="M1001.135,798.241l-58.151-86.476,58.032,95.779-.121,9.731q6.367.049,12.612-.212l-2.884-125.082.079-.965-.107-.186-.276-11.819L1074,586.552l-63.607,83.612-.226,2.5-2.178-94.5,55-95.858-55.16,79.256.575-195.788v-.652l-.019.641-3.651,154.307-50.13-62.68,49.966,75.973-1.094,84.532-46.129-82.5,45.96,94.927-.609,47L935.617,562.4,1002.5,693.719Z"
            transform="translate(-34.736 698.018)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2981"
            data-name="Rectangle 2981"
            width="347.979"
            height="470.684"
            transform="translate(1583.467 979.615)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_2982"
            data-name="Rectangle 2982"
            width="323.455"
            height="437.512"
            transform="translate(1595.73 996.204)"
            fill="#fff"
          />
          <path
            id="Path_1946"
            data-name="Path 1946"
            d="M291.876,237.922H529.263a7.256,7.256,0,0,0,0-14.513H291.876a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1346.37 835.019)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1947"
            data-name="Path 1947"
            d="M291.876,297.551H529.263a7.256,7.256,0,0,0,0-14.513H291.876a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1346.37 850.024)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1948"
            data-name="Path 1948"
            d="M291.876,357.18H529.263a7.256,7.256,0,0,0,0-14.513H291.876a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1346.37 865.036)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1949"
            data-name="Path 1949"
            d="M291.876,416.809H529.263a7.256,7.256,0,0,0,0-14.513H291.876a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1346.37 880.042)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1950"
            data-name="Path 1950"
            d="M291.876,476.438H529.263a7.256,7.256,0,1,0,0-14.513H291.876a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1346.37 895.05)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1951"
            data-name="Path 1951"
            d="M291.047,237.922H488.006a7.256,7.256,0,0,0,0-14.513H291.047a7.256,7.256,0,0,0,0,14.513Z"
            transform="translate(1388.663 835.019)"
            fill="#0d2674"
          />
          <path
            id="Path_1952"
            data-name="Path 1952"
            d="M291.047,297.551h135.8a7.256,7.256,0,0,0,0-14.513h-135.8a7.256,7.256,0,0,0,0,14.513Z"
            transform="translate(1449.823 850.024)"
            fill="#0d2674"
          />
          <path
            id="Path_1953"
            data-name="Path 1953"
            d="M291.047,357.18h61.161a7.256,7.256,0,0,0,0-14.513H291.047a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1524.461 865.036)"
            fill="#0d2674"
          />
          <path
            id="Path_1954"
            data-name="Path 1954"
            d="M291.047,416.809H506.665a7.256,7.256,0,0,0,0-14.513H291.047a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1370.004 880.042)"
            fill="#0d2674"
          />
          <path
            id="Path_1955"
            data-name="Path 1955"
            d="M291.047,476.438H458.981a7.256,7.256,0,1,0,0-14.513H291.047a7.256,7.256,0,1,0,0,14.513Z"
            transform="translate(1417.688 895.05)"
            fill="#0d2674"
          />
          <rect
            id="Rectangle_2983"
            data-name="Rectangle 2983"
            width="1598.879"
            height="2.944"
            transform="translate(660.321 1512.359)"
            fill="#dedede"
          />
          <path
            id="Path_1956"
            data-name="Path 1956"
            d="M638.4,440.336s7.867-57.695,20.98-41.96,0,39.337,0,39.337l-10.49,13.112-10.49-7.867Z"
            transform="translate(746.089 705.709)"
            fill="#9f616a"
          />
          <path
            id="Path_1957"
            data-name="Path 1957"
            d="M652.781,450.893s26.225-28.847,31.47-23.6,20.979,7.868,18.357,18.358-57.695,44.582-57.695,44.582Z"
            transform="translate(700.234 713.51)"
            fill="#0078bf"
          />
          <path
            id="Path_1958"
            data-name="Path 1958"
            d="M652.781,450.893s26.225-28.847,31.47-23.6,20.979,7.868,18.357,18.358-57.695,44.582-57.695,44.582Z"
            transform="translate(700.234 713.51)"
            opacity="0.1"
          />
          <path
            id="Path_1959"
            data-name="Path 1959"
            d="M786.21,443.923V464.9s-2.622,47.2,13.113,44.582,7.867-41.96,7.867-41.96l-2.622-23.6Z"
            transform="translate(417.323 717.854)"
            fill="#9f616a"
          />
          <path
            id="Path_1960"
            data-name="Path 1960"
            d="M881.542,565.443s-23.6,36.715-31.47,39.337S800.245,631,795,654.607l-36.715,47.2,18.357,23.6s83.919-89.164,107.522-97.032,47.2-47.2,47.2-47.2Z"
            transform="translate(329.859 748.439)"
            fill="#9f616a"
          />
          <path
            id="Path_1961"
            data-name="Path 1961"
            d="M701.684,517.254s49.827,7.868,41.96,39.337-83.92,97.032-83.92,97.032L657.1,669.358,628.254,674.6l-13.112-13.112,2.623-7.867s31.47-28.848,34.092-39.337,31.47-52.45,44.582-55.072l-18.357-10.49Z"
            transform="translate(695.914 736.309)"
            fill="#9f616a"
          />
          <path
            id="Path_1962"
            data-name="Path 1962"
            d="M908.131,677.087s10.49-15.735-5.245-13.113-26.225,5.245-26.225,15.735,13.112,83.919,28.847,78.675,2.622-28.847,2.622-28.847,7.868-20.98,13.113-23.6,5.245-15.735,0-15.735S905.509,682.332,908.131,677.087Z"
            transform="translate(187.88 773.164)"
            fill="#0078bf"
          />
          <path
            id="Path_1963"
            data-name="Path 1963"
            d="M680.881,629.469s0-13.112-10.49,0-15.735,23.6-5.244,31.47,47.2,26.225,47.2,26.225,39.337,7.868,34.092-10.49c0,0-26.225-13.112-26.225-23.6s0-28.847-7.867-23.6S680.881,637.337,680.881,629.469Z"
            transform="translate(638.042 763.088)"
            fill="#0078bf"
          />
          <path
            id="Path_1964"
            data-name="Path 1964"
            d="M711.442,471.161s-36.714,5.245-34.092,57.695c0,0-47.2,47.2-34.092,62.94s41.96,44.582,52.45,36.714,41.959-68.184,41.959-68.184,73.43,26.225,86.542,13.112,26.225-44.582,26.225-44.582l-65.562-34.092Z"
            transform="translate(557.653 724.709)"
            fill="#a0c484"
          />
          <ellipse
            id="Ellipse_383"
            data-name="Ellipse 383"
            cx="31.47"
            cy="31.47"
            rx="31.47"
            ry="31.47"
            transform="translate(1339.903 983.449)"
            fill="#9f616a"
          />
          <path
            id="Path_1965"
            data-name="Path 1965"
            d="M692.25,328.69s-18.357,28.847-23.6,31.47,36.715,28.848,36.715,28.848,0-34.092,13.112-41.96Z"
            transform="translate(655.521 688.854)"
            fill="#9f616a"
          />
          <path
            id="Path_1966"
            data-name="Path 1966"
            d="M752.8,352.55s-41.96-13.113-49.827,7.868-26.225,60.317-23.6,86.542,7.867,20.98,0,28.848-13.113,2.622-10.49,13.112,5.245,10.49,2.623,15.735,31.469,23.6,60.317,26.225l28.847,2.622s0-47.2,5.245-57.695,15.735-31.47,15.735-39.338V415.653a79.615,79.615,0,0,0-10.49-39.5h0S755.418,352.55,752.8,352.55Z"
            transform="translate(592.353 693.836)"
            fill="#0c7faf"
          />
          <path
            id="Path_1967"
            data-name="Path 1967"
            d="M816.528,360.117s-97.032,7.867-107.522,34.092-15.735,78.674-5.245,78.674,23.6,5.245,26.225,0,13.112-57.695,15.735-57.695,81.3-7.868,81.3-7.868S861.111,365.362,816.528,360.117Z"
            transform="translate(497.149 696.761)"
            fill="#0078bf"
          />
          <path
            id="Path_1968"
            data-name="Path 1968"
            d="M670.72,325.99s8.351-14.513,18.79-1.907,27.421-10.559,24.814-15.5-11.813.222-27.59-4.935-46.168-15.664-51.258,18.609,7.032,30.68,7.032,30.68,5.556-30.37,10.578-21.646l5.022,8.724,4.356,1.072Z"
            transform="translate(701.917 681.127)"
            fill="#2f2e41"
          />
        </g>
      </svg>
    </>
  );
};
export default ActivitiesTileSVG;
