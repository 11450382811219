import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  // 1. Magic number to account for top filter bar
  map: {
    height: "100%",
    width: "100%",
    position: "relative",
    display: "flex",

    "@media (min-width: 780px)": {
      height: "calc(100% - 58px)", // 1
    },

    "& > div:last-child": {
      minHeight: 250,
    },
  },

  redoSearchWrapper: {
    position: "absolute",
    top: 10,
    left: 0,
    width: "100%",
    zIndex: 2,
    textAlign: "center",
  },

  locationFinderTotalGyms: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 5,
    position: "absolute",
    width: "25%",
    minHeight: "!80px",
    maxHeight: "!120px",
    zIndex: 2,
    top: "9%",
    right: "5%",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "white",
    "@media (max-width: 422px)": {
      display: "none",
      position: "absolute",
      zIndex: 3,
    },
  },
  tierBanner: {
    position: `absolute`,
    top: `-15%`,
    backgroundColor: theme.primary.color.css(),
    height: `20px`,
    width: `75%`,
    webkitClipPath: `polygon(0 0, 100% 0, 100% 0%, 0 100%)`,
    clipPath: `polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%)`,
    display: `flex`,
    justifyContent: `center`,
  },

  tierBannerSpan: {
    color: `white`,
    fontWeight: `bold`,
    fontStyle: `italic`,
  },

  tierNameList: {
    display: "flex",
    flexDirection: "row",
    width: `50%`,
    justifyContent: "center",
  },

  tierNameListSpan: {
    color: "black", 
    fontSize: "1.0rem", 
    paddingTop: `10px`
  },

  tierCount: {
    display: "flex",
    flexDirection: "column",
    width: `50%`,
    justifyContent: "center",
    alignItems: "center",
  },

  gymsText: {
    color: "grey", 
    fontSize: "0.7rem" 
  },

  redoSearch: {
    backgroundColor: "white",
    color: theme.accessible.primary.color.css(),
    padding: "10px 15px",
    borderRadius: 5,
    fontWeight: 500,
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    opacity: 0,
    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",

    "&:hover, &:focus, &:active": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
      border: `solid 1px ${theme.primary.color.css()}`,
    },
  },

  appear: {
    opacity: 0,
  },

  enter: {
    opacity: 0,
    transform: "translate(0, 10px)",
  },

  enterDone: {
    opacity: 1,
    transform: "translate(0, 0)",
  },

  exit: {
    opacity: 1,
    transform: "translate(0, 0)",
  },

  exitDone: {
    opacity: 0,
    transform: "translate(0, 10px)",
  },
}));

export default useStyles;
