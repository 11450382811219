import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

const CollapseHeader = ({
  initial,
  header,
  price,
  children,
}: {
  initial?: boolean;
  header: string;
  price: string;
  children?: React.ReactElement;
}) => {
  const [open, setOpen] = useState(initial ?? false);
  return (
    <div className="mb-2">
      <div
        onClick={() => setOpen((o) => !o)}
        className="d-flex justify-content-between collapse-header"
      >
        <span>
          {header}{" "}
          {children && (
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          )}
        </span>
        <span>{price}</span>
      </div>
      {children && (
        <Collapse in={open}>
          <div>
            <div className="collapse-header__content">{children}</div>
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default CollapseHeader;
