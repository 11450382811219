import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { LocationsContext } from "../../../context/LocationsProvider";
import Search from "../SidePane/Search";

const CenteredModal = (props) => {
  const { searchModalOpen, setSearchModalOpen } =
  useContext(LocationsContext);
  
  return (
    <Modal
      {...props}
      show={searchModalOpen}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div style={{display:`flex`, width:`100%`,flexDirection:`column`, justifyContent:`center`, alignItems:`center`, textAlign: `center`}}>
          <span style={{fontSize:`1.4rem`, textDecoration:`bold`, marginBottom:`10px`}}>Members have access to thousands of locations nationwide</span>
          <span style={{fontSize:`1.0rem`, color:`grey`}}>Explore location near your home, work, or favorite travel destinations.</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Search onSearched={()=>setSearchModalOpen(false)} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>setSearchModalOpen(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default CenteredModal;