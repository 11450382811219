import React, { Fragment } from "react";
import { FAQQuestion } from "../../types";
import { convertToFAQSection } from "../../utility/contentful";
import Question from "./Question";
import { Entry } from "contentful";
import { IFaqSectionFields } from "../../@types/generated/contentful";
const Section = ({ data }: { data: Entry<IFaqSectionFields> }) => {
  const converted = convertToFAQSection(data, {});
  return (
    <Fragment>
      <h3 className="faq-page faq-page__section">
        <b>{converted.header}</b>
      </h3>
      {converted.questions.map((q: FAQQuestion, i: number) => (
        <Question question={q.question} answer={q.answer} index={i} key={i} />
      ))}
    </Fragment>
  );
};

export default Section;
