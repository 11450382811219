import { createUseStyles } from 'react-jss'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = createUseStyles(theme => ({
  cluster: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    marginLeft: '-10px',
    textAlign: 'center',
    fontSize: '14px',
    color: 'var(--primary)',
    border: '2px solid var(--primary)',
    borderRadius: '50%',
    backgroundColor: '#fff',
    transition: 'all 0.2s ease-in-out',
    zIndex: 1,
    position: 'relative',
    fontVariant: 'proportional-nums',
  },

  clusterHover: {
    backgroundColor: "#fff",
    color: '#ff',
    border: '2px solid var(--primary)',
    transform: 'scale(1.2)',
    zIndex: 2,
  },
}));

export default useStyles;
