import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    display: 'inline-block',
    fontSize: '1rem' /* Change this to resize the loader */,
    animation: '2s $rotate infinite linear',
    width: '1em',
    height: '1em',

    '& circle': {
      fill: 'transparent',
      stroke: theme.primary.color.css(),
      strokeWidth: '0.07em',
      strokeDasharray: '2.3525em 0.4705em',
      animation: '1.3s $strokeDashArray infinite linear, 24s infinite linear',
    },
  },

  text: {
    marginLeft: 5,
  },

  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },

    to: {
      transform: 'rotate(360deg)',
    },
  },

  '@keyframes strokeDashArray': {
    from: {
      strokeDasharray: '2.3525em 0.4705em',
      strokeDashoffset: 0,
    },

    '10%': {
      strokeDasharray: '2.3525em 0.4705em',
      strokeDashoffset: 0,
    },

    '50%': {
      strokeDasharray: '0.001em 2.825em',
      strokeDashoffset: '-2.826em',
    },

    '60%': {
      strokeDasharray: '0.001em 2.825em',
    },

    to: {
      strokeDasharray: '2.3525em 0.4705em',
      strokeDashoffset: '-2.826em',
    },
  },
}))

export default useStyles
