import React from "react";
import { FeatureInput, FeatureMobileBody } from "../../types";
import FeatureBase from "./FeatureBase";
import iosIcon from "../../resources/iosIcon.svg";
import androidIcon from "../../resources/androidIcon.svg";

const FeatureMobile = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureMobileBody;
  return (
    <section className="feature-mobile-container">
      <FeatureBase
        backgroundColor={input.backgroundColor}
        leftAlign={true}
        size="large"
        featureClassName={input.featureClassName}
      >
        <div className="feature-content feature-mobile">
          {body.text}
          <br></br>
          <div className="app-store-button-container">
            <a
              href="https://apps.apple.com/us/app/id1453242180"
              target="_blank"
            >
              <img src={iosIcon} alt="IOS" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.tivityhealth.prime.wellontarget"
              target="_blank"
            >
              <img src={androidIcon} alt="Android" />
            </a>
          </div>
        </div>
        <div>
          <img src={`https://${input.media.slice(2)}`}></img>
        </div>
      </FeatureBase>
    </section>
  );
};

export default FeatureMobile;
