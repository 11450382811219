import React from "react";
import { Container, Spinner } from "react-bootstrap";


const RedirectPage = ({ message }: { message: string }) => {
  return (
    <Container>
      <div className="error-page">
        <h1>Redirecting...</h1>
        <p>{message}</p>
        <Spinner animation="border" variant="primary" />
      </div>
    </Container>
  );
};

export default RedirectPage;
