import { useEffect, useState } from "react";

const useGetGeoFromBrowser = () => {
  const [geo, setGeo] = useState<{ lat: number; lng: number }>({
    lat: 44.96,
    lng: -103.77,
  });
  const [geoDisabled, setGeoDisabled] = useState<boolean>(false);
  const [geoError, setGeoError] = useState<unknown>({});

  const getCurrentLocation = async () => {
    const data: { lat: number; lng: number } = await new Promise(
      (resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            setGeoDisabled(true);
            setGeoError(error);
            reject(error);
          },
          { timeout: 30000, enableHighAccuracy: true, maximumAge: 75000 }
        )
    );
    if (data) {
      setGeo(data);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        await getCurrentLocation();
      } catch (err: unknown) {
        return;
      }
    })();
  }, []);

  return { geo, getCurrentLocation, geoDisabled, geoError };
};

export default useGetGeoFromBrowser;
