import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
const Link = ({
  text,
  onClick,
  href,
}: {
  text: string;
  onClick?: (e: unknown) => void;
  href: string;
}) => {
  const getLinkType = () => {
    if (onClick) {
      return (
        <Button variant="link" onClick={onClick} style={{ color: "white" }}>
          {text}
        </Button>
      );
    } else if (href && href.charAt(0) !== "#") {
      return (
        <a href={href} tabIndex={0} className="a-link">
          {text}
        </a>
      );
    }
    return <HashLink to={href}>{text}</HashLink>;
  };
  return <Fragment>{getLinkType()}</Fragment>;
};

export default Link;
