import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  isBrowser,
  osName,
  browserName,
  mobileVendor,
} from "react-device-detect";
import * as Yup from "yup";
import { Issue } from "../../../types";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountProvider";
import { useConfig } from "../../../configuration/useConfig";

const helpSchema = Yup.object().shape({
  body: Yup.string().min(1).required("Required"),
});

interface HelpFormValues {
  issue: string;
  body: string;
}
const HelpForm = ({ issuesList }: { issuesList: Issue[] }) => {
  const initialValues: HelpFormValues = {
    issue: "Choose a category",
    body: "",
  };
  const { member } = useContext(AccountContext);
  const { config } = useConfig();

  let subjectLine;

  switch (config["client"]) {
    case "walmart":
      subjectLine = "Help Request - Fitness Pass";
      break;
    case "hcsc":
      subjectLine = "Help Request - Well onTarget";
      break;
    default:
      subjectLine = "Help Request";
      break;
  }

  //   const getSubjectLine = () => {
  //     switch(tag){
  //         case "hcscGeneral":
  //             return "Well OnTarget Fitness Program"
  //         case "hcscBcbsa":
  //             return "Well OnTarget Fitness Program Blue Cross Blue Shield "
  //         case "hcscBsc":
  //             return "Well OnTarget Fitness Program Blue Shield of California"
  //         case "fepAuthenticated":
  //             return "fepAuthenticated"
  //         case "hcsc":
  //             return 'Well OnTarget Fitness Program';
  //         default:
  //             return ""
  //     }
  //   };

  return (
    <>
      <h5 style={{ fontWeight: "normal" }}>
        Or to report an issue via email, complete the form below.
      </h5>
      <p
        className="mb-1"
        style={{ fontSize: "12px", fontWeight: "bold", color: "#666666" }}
      >
        ISSUE CATEGORY
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={helpSchema}
        onSubmit={(values, actions) => {
          if (!member) throw new Error("No UserInfo");
          const emailSendTo = config["help.email"];
          const configState = {
            client: config["client"],
          };
          // e.preventDefault();
          if (values.issue && values.body) {
            window.location.href = `mailto:${emailSendTo}?body=We're sorry to hear you are experiencing an issue. %0D%0AThe information contained in this email will help our customer service easily locate your profile and assist you. %0D%0APlease make sure to review the information below for accuracy. If any information is incorrect, please do not delete it; instead include the correct information in the additional details section. %0D%0APlease do not delete any of the information contained below.%0D%0A%0D%0AMessage%3A ${
              values.body
            } %0D%0AAdditional Details%3A (If additional details are needed, please add them here)
                     %0D%0AIssue Category%3A ${
                       issuesList.filter((v) => v.value === values.issue)[0]
                         .label
                     }%0D%0AFirst Name%3A${
              " " + member.firstName
            }%0D%0ALast Name%3A${" " + member.lastName}%0D%0ABrowser%3A${
              " " + browserName
            }%0D%0ADevice%3A${
              " " + isBrowser ? osName : mobileVendor
            }%0D%0AClient%3A${" " + configState.client}${
              member.cardNumber
                ? `%0D%0ATivity Card Number%3A${member.cardNumber}&subject=${subjectLine}`
                : ""
            }`;
          } else {
            return;
          }
          actions.setSubmitting(false);
        }}
      >
        {(props) => (
          <>
            <Form
              style={{
                display: `flex`,
                flexDirection: `column`,
                marginLeft: `10px`,
              }}
            >
              <label htmlFor="issue">I am having an issue with...</label>
              <Field
                className="w-50 rounded border-light mb-2"
                component="select"
                id="issue"
                name="issue"
                placeholder="Choose a category"
              >
                <option selected disabled hidden value={"Choose a category"}>
                  Choose a category
                </option>
                {issuesList.map((p) => (
                  <option key={p.label} value={p.value}>
                    {p.label}
                  </option>
                ))}
                {/* {Object.keys(helpMap).map((p, index) => (
                        <option key={index} value={helpMap[p].value}>{helpMap[p].label}</option>
                    ))} */}
              </Field>
            </Form>
            {props.values.issue === "Choose a category" ||
            props.values.issue === "technical_error" ? null : (
              <Card className="p-2 mb-2 mt-2 border-primary text-primary account-help__information">
                <h5 style={{ color: "#072c4c" }} className="m-0 mb-2 ">
                  <FontAwesomeIcon
                    color={"#072c4c"}
                    width="20px"
                    icon={faInfoCircle}
                  />{" "}
                  Looking for help with{" "}
                  {
                    issuesList.filter((v) => v.value === props.values.issue)[0]
                      .informationLabel
                  }
                  ?
                </h5>
                <p style={{ color: "#072c4c" }} className="m-0 pb-0">
                  {
                    issuesList.filter((v) => v.value === props.values.issue)[0]
                      .information
                  }
                </p>
                <p
                  style={{ fontWeight: "bold", color: "#072c4c" }}
                  className="m-0 pb-0"
                >
                  Still need help? Please explain your issue below and send us
                  an email.
                </p>
              </Card>
            )}
            {props.values.issue !== "Choose a category" ? (
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#666666",
                  }}
                  className="mb-1"
                >
                  DETAILS
                </p>
                <span className="m-0 p-2 pb-0 text-black">
                  Describe below the issues you are having:
                </span>
                <ol type="1">
                  <li>
                    1. What were you <b>doing</b>?
                  </li>
                  <li>
                    2. What <b>happened</b>? (error, nothing, etc.)
                  </li>
                  <li>
                    3. What did you <b>expect</b> to happen?
                  </li>
                </ol>
                <Form
                  onSubmit={props.handleSubmit}
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    marginLeft: `10px`,
                  }}
                >
                  <ErrorMessage name="body" />
                  <Field
                    className="mw-100"
                    name="body"
                    id="body"
                    component="textarea"
                    rows="4"
                    placeholder="The more details the better..."
                  />
                  <Button
                    type="submit"
                    disabled={!props.isValid}
                    style={{ width: "33%" }}
                    className="btn-primary btn mt-2"
                  >
                    Review Email{" "}
                    <FontAwesomeIcon width="20px" icon={faExternalLinkAlt} />
                  </Button>
                </Form>
              </div>
            ) : null}
          </>
        )}
      </Formik>
    </>
  );
};

export default HelpForm;
