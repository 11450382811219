import React, { Dispatch, Fragment, SetStateAction } from "react";
import { Button, ButtonProps, Card } from "react-bootstrap";
const PaymentWidget = ({
  children,
  selected,
}: {
  children: React.ReactElement[] | React.ReactElement;
  selected: boolean;
}) => {
  return (
    <Card border={selected ? "primary" : ""} className={"payment-widget__card"}>
      {Array.isArray(children)
        ? children.map((c, index: number) => (
            <Fragment key={index}>
              {React.cloneElement(c, { selected })}
            </Fragment>
          ))
        : children && React.cloneElement(children, { selected })}
    </Card>
  );
};
const PaymentBody = ({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) => {
  return (
    <Card.Body className={"payment-widget__body"}>
      {Array.isArray(children)
        ? children.map((c, index: number) => (
            <Fragment key={index}>
              {React.isValidElement(c) && React.cloneElement(c)}
            </Fragment>
          ))
        : React.isValidElement(children) &&
          React.cloneElement(children)}
    </Card.Body>
  );
};
const PaymentFooter = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <Card.Footer className="payment-widget__footer">
      {Array.isArray(children)
        ? children.map((c, index: number) => (
            <Fragment key={index}>{c}</Fragment>
          ))
        : children}
    </Card.Footer>
  );
};
const PaymentLabel = ({
  children,
  selected,
}: {
  children: React.ReactNode;
  selected?: boolean;
}) => {
  return <span>{selected ? <b>{children}</b> : children}</span>;
};

const PaymentButton = ({
  index = 1,
  children,
  variant,
  disabled = false,
  setDefault,
  ...rest
}: {
  index?: number | 1;
  children: React.ReactNode;
  variant: "error" | "success" | "secondary";
  disabled?: boolean;
  setDefault?: Dispatch<SetStateAction<number>>;
} & ButtonProps) => {
  return (
    <Button
      variant="outline-primary"
      className={`payment-widget__button payment-widget__${variant}`}
      disabled={disabled}
      onClick={
        typeof setDefault === "function"
          ? () => setDefault(index)
          : rest.onClick
      }
    >
      {children}
    </Button>
  );
};
export default Object.assign(PaymentWidget, {
  Label: PaymentLabel,
  Button: PaymentButton,
  Footer: PaymentFooter,
  Body: PaymentBody,
});
