import {
  IAddress,
  IAddressForm,
  ICheckout,
  ICheckoutForm,
  IEligibilityFormProfileWalmart,
  IEligibilityProfileWalmart,
  IEligibilityFormProfileHCSC,
  IEligibilityProfileHCSC,
  IEligibilityFormProfileFYW,
  IEligibilityProfileFYW,
  IMembership,
  IMembershipForm,
  IPackageSelect,
  IPackageSelectForm,
  IEligibilityFormProfileMW,
  IEligibilityProfileMW,
} from "./../types";
import Address from "../forms/Address";
import Checkout from "../forms/Checkout";
import EligibilityWalmart from "../forms/EligibilityWalmart";
import EligibilityHCSC from "../forms/EligibilityHCSC";
import EligibilityFYW from "../forms/EligibilityFYW";
import EligibilityMOO from "../forms/EligibilityMOO";

import Members from "../forms/Members";
import Package from "../forms/Package";
import { FormValidation } from "./FormValidations";
import WizardStep from "./WizardStep";
import {
  initialAddressModel,
  initialCheckoutModel,
  initialMembershipModel,
  initialPackageModel,
  initialWalmartModel,
  initialHCSCModel,
  initialFYWModel,
  initialMWModel,
} from "./InitialState";
import { ModelValidations } from "./ModelValidations";

export const WalmartStep: WizardStep<
  IEligibilityFormProfileWalmart,
  IEligibilityProfileWalmart
> = new WizardStep<IEligibilityFormProfileWalmart, IEligibilityProfileWalmart>(
  initialWalmartModel,
  FormValidation.WalmartValidation,
  ModelValidations.WalmartValidation,
  {
    label: "Eligibility",
    stateFieldName: "eligibility",
    url: "/enroll/eligibility",
    component: EligibilityWalmart,
  }
);

export const HCSCStep: WizardStep<
  IEligibilityFormProfileHCSC,
  IEligibilityProfileHCSC
> = new WizardStep<IEligibilityFormProfileHCSC, IEligibilityProfileHCSC>(
  initialHCSCModel,
  FormValidation.HCSCValidation,
  ModelValidations.HCSCValidation,
  {
    label: "Eligibility",
    stateFieldName: "eligibility",
    url: "/enroll/eligibility",
    component: EligibilityHCSC,
  }
);

export const FYWStep: WizardStep<
IEligibilityFormProfileFYW,
  IEligibilityProfileFYW
> = new WizardStep<IEligibilityFormProfileFYW, IEligibilityProfileFYW>(
  initialFYWModel,
  FormValidation.FYWValidation,
  ModelValidations.FYWValidation,
  {
    label: "Eligibility",
    stateFieldName: "eligibility",
    url: "/enroll/eligibility",
    component: EligibilityFYW,
  }
);

export const MWStep: WizardStep<
  IEligibilityFormProfileMW,
  IEligibilityProfileMW
> = new WizardStep<IEligibilityFormProfileMW, IEligibilityProfileMW>(
  initialMWModel,
  FormValidation.MWValidation,
  ModelValidations.MWValidation,
  {
    label: "Eligibility",
    stateFieldName: "eligibility",
    url: "/enroll/eligibility",
    component: EligibilityMOO,
  }
);
export const PackageStep: WizardStep<IPackageSelectForm, IPackageSelect> =
  new WizardStep<IPackageSelectForm, IPackageSelect>(
    initialPackageModel,
    FormValidation.PackageValidation,
    ModelValidations.PackageValidation,
    {
      label: "Package",
      stateFieldName: "package",
      url: "/enroll/package",
      component: Package,
    }
  );

export const MembershipStep: WizardStep<IMembershipForm, IMembership> =
  new WizardStep<IMembershipForm, IMembership>(
    initialMembershipModel,
    FormValidation.MembershipValidation,
    ModelValidations.MembershipValidation,
    {
      label: "Members",
      stateFieldName: "membership",
      url: "/enroll/members",
      component: Members,
    }
  );

export const AddressStep: WizardStep<IAddressForm, IAddress> = new WizardStep<
  IAddressForm,
  IAddress
>(
  initialAddressModel,
  FormValidation.AddressValidation,
  ModelValidations.AddressValidation,
  {
    label: "Address",
    stateFieldName: "address",
    url: "/enroll/address",
    component: Address,
  }
);

export const CheckoutStep: WizardStep<ICheckoutForm, ICheckout> =
  new WizardStep<ICheckoutForm, ICheckout>(
    initialCheckoutModel,
    FormValidation.CheckoutValidation,
    ModelValidations.CheckoutValidation,
    {
      label: "Checkout",
      stateFieldName: "checkout",
      url: "/enroll/checkout",
      component: Checkout,
    }
  );
