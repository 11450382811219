import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useEffect, useState } from "react";
import styles from "./BinaryToggle.module.scss";

interface BinaryToggleProps {
  options: {
    name: string;
    value: string;
  }[];
  selectedToggle: string;
  setSelectedToggle: React.Dispatch<React.SetStateAction<string>>;
}

export const BinaryToggle: React.FC<BinaryToggleProps> = ({
  options,
  selectedToggle,
  setSelectedToggle,
}) => {
  const [hoveredToggle, setHoveredToggle] = useState<string>("");
  const [isHoveringUnselectedToggle, setIsHoveringUnselectedToggle] =
    useState<boolean>(false);

  useEffect(() => {
    setIsHoveringUnselectedToggle(
      !!hoveredToggle && hoveredToggle !== selectedToggle
    );
  }, [hoveredToggle, selectedToggle]);

  const handleToggleButtonMouseEnter = (toggleValue: string) => {
    setHoveredToggle(toggleValue);
  };

  const handleToggleButtonMouseLeave = (toggleValue: string) => {
    if (toggleValue === hoveredToggle) setHoveredToggle("");
  };

  return (
    <div className={`mb-4`}>
      <ButtonGroup
        className={`${styles.customToggleBtnGroup} ${
          isHoveringUnselectedToggle ? styles.greyBg : ""
        }`}
      >
        {options.map((option, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="outline-primary"
            name="options"
            value={option.value}
            checked={selectedToggle === option.value}
            onChange={() => setSelectedToggle(option.value)}
            onMouseEnter={() => handleToggleButtonMouseEnter(option.value)}
            onMouseLeave={() => handleToggleButtonMouseLeave(option.value)}
            className={`${
              selectedToggle === option.value ? styles.selected : ""
            } ${styles.btn}`}
          >
            {option.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default BinaryToggle;
