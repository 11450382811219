import { PropsWithChildren, useContext, useState } from "react";
import { Modal, Button, Spinner, ModalProps } from "react-bootstrap";
import moment from "moment";
import { useConfig } from "../../configuration/useConfig";

import { UserContext } from "../../context/UserProvider";
import { AccountSummaryMember } from "../../utility/member-portal-api-types";
import showToastMessage from "../../utility/ProfileToast";
import { discardSubscription } from "./DiscardCancellationModal";
import axiosInstance from "../../axios.instance";
import { AccountContext } from "../../context/AccountProvider";
import { poll } from "../../utility/util";

export type OrderAction =
  | {
      ratePlanChargeId: string;
      subscriptionNumber?: string;
      type: "DiscardSubscription";
    }
  | {
      currentRatePlanChargeId: string;
      newRatePlanChargeId: string;
      currentSubscriptionNumber: string;
      type: "SubscriptionUpdate";
    };

function Element({ children, ...props }: PropsWithChildren<ModalProps>) {
  return (
    <Modal show {...props} centered>
      <Modal.Header>
        <Modal.Title>
          <h2>Discard Downgrade?</h2>
          {props.onHide && (
            <span style={{ cursor: "pointer" }} onClick={props.onHide}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}

interface DiscardCancellationProperties {
  member: AccountSummaryMember;
  onClose: (success?: boolean) => void;
  hasUnderageDependents: boolean;
  underAgeDependentsLoading: boolean;
}

const DiscardDowngradeModal = ({
  member,
  onClose,
  hasUnderageDependents,
  underAgeDependentsLoading,
}: DiscardCancellationProperties) => {
  const [isDiscarding, setIsDiscarding] = useState(false);
  const { client } = useConfig();
  const { user } = useContext(UserContext);
  const { refresh } = useContext(AccountContext);
  const currentSubscription = member?.eligibilities[0].currentSubscription;
  const futureSubscription = member?.eligibilities[0].futureSubscription;

  const personId = member?.personId;

  const isDependent = !member.eligibilities[0].isPrimary;

  const isPayroll = user.isPayroll;

  if (
    !(
      currentSubscription &&
      futureSubscription &&
      personId &&
      isPayroll !== undefined
    )
  ) {
    return <></>;
  }

  function validate(res) {
    if ((res.data.status as unknown as string) == "SUCCESS") {
      refresh().then(() =>
        showToastMessage("Your account was successfully updated", true, client)
      );
      return true;
    } else if ((res.data.status as unknown as string) == "FAILED") {
      refresh().then(() =>
        showToastMessage(
          "There was an error with your plan update.",
          false,
          client
        )
      );
      return false;
    } else if ((res.data.status as unknown as string) == "PROCESSING") {
      throw res;
    }
  }

  const onDiscard = async () => {
    try {
      setIsDiscarding(true);
      await discardSubscription(
        personId,
        futureSubscription,
        isPayroll,
        isDependent,
        currentSubscription
      )
        .then((url) => {
          poll(() => axiosInstance.get(`${url}`).then(validate), 45, 3000);
        })
        .catch((err) => console.log("There was an error:" + err));
    } catch (e) {
      console.log(e);
      onClose(false);
    }
  };

  if (isDiscarding) {
    return (
      <Element style={{ pointerEvents: "none" }}>
        <Modal.Body style={{ minHeight: "175px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      </Element>
    );
  }

  return (
    <Element onHide={() => onClose()}>
      <Modal.Body>
        {underAgeDependentsLoading ? (
          <>
            <p className="text-center mb-3">Please wait...</p>
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </>
        ) : (
          <>
            <p>
              You recently changes your fitness package, from{" "}
              {currentSubscription?.ratePlanName} to{" "}
              {futureSubscription?.ratePlanName}. which is scheduled to take
              effect{" "}
              <b>
                on{" "}
                {moment(futureSubscription?.effectiveStartDate).format(
                  "MM/DD/YYYY"
                )}
              </b>
              . Would you like to discard the changes?
            </p>
            {hasUnderageDependents && (
              <p style={{ paddingTop: "1rem" }}>
                <b>Note</b>: Discarding this downgrade will also discard the
                pending downgrade for family members under 18.
              </p>
            )}
          </>
        )}
      </Modal.Body>
      {!underAgeDependentsLoading && (
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "white", width: "48%" }}
            onClick={() => onClose()}
          >
            <span style={{ color: "var(--primary)" }}>Close</span>
          </Button>
          <Button
            variant="primary"
            style={{ width: "48%" }}
            onClick={onDiscard}
          >
            Discard Change
          </Button>
        </Modal.Footer>
      )}
    </Element>
  );
};

export default DiscardDowngradeModal;
