import { useCallback, useEffect, useState } from "react";
import { useConfig } from "../../configuration/useConfig";
import { axiosBaseInstance } from "../../axios.instance";
import { useAuthToken } from "./Auth0TokenProvider";

const VersionBanner = () => {
  const [apiVersion, setApiVersion] = useState<string>("loading");
  const { config, clientConfig } = useConfig();
  const token = useAuthToken();

  const fetchData = useCallback(() => {
    axiosBaseInstance
      .get("/version", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data) {
          setApiVersion(data.version);
        }
      })
      .catch(() => {
        setApiVersion("unknown");
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="version-banner">
      <p>{`API Version: ${apiVersion}, API Env: ${
        config.environment
      }, UI Version: ${config.version}, Contentful Env: ${
        config.contentfulEnvironment
      }, Mock Client Calls: ${
        clientConfig.mockClientCalls
      }, Timestamp: ${new Date().toLocaleString("en-US", {
        timeZoneName: "short",
      })}`}</p>
    </div>
  );
};

export default VersionBanner;
