import React, { useContext } from "react";
import { AccountContext } from "../../context/AccountProvider";
import { Alert } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfig } from "../../configuration/useConfig";

const AccountSuspensionBanner = () => {
  const { member } = useContext(AccountContext);
  const { config } = useConfig();

  const phoneNumber = config["contact.phoneNumber"];

  const convertDate = (date: string): string => {
    if (!date) {
      return "";
    }
    const s = date.split("-");
    return [s[1], s[2], s[0]].join("/");
  };

  return (
    <Alert className="account-suspension-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <div className="account-suspension-banner__body">
        Our records indicate that you have temporarily paused this account
        effective{" "}
        <b>
          {convertDate(
            member?.eligibilities[0]?.currentSubscription
              ?.suspensionStartDate ?? ""
          )}
        </b>
        ; this account will resume on{" "}
        <b>
          {convertDate(
            member?.eligibilities[0]?.currentSubscription?.suspensionEndDate ??
              ""
          )}
        </b>
        . You will not have access to the program for this period of time. If
        you have any questions, please contact us at{" "}
        <u>
          <a href={`tel:${phoneNumber}`} className="a-link">
            {phoneNumber}
          </a>
        </u>{" "}
        Monday - Friday 7 a.m. - 7 p.m. CT.
      </div>
    </Alert>
  );
};

export default AccountSuspensionBanner;
