import React from "react";
import { Product } from "../wizard/forms/Package";

const NoOpBundlesDropDown = ({
  planId,
  error,
  loading,
  productRatePlans,
  addendum,
}: {
  planId?: string;
  membersPage?: boolean;
  error?: boolean;
  loading?: boolean;
  productRatePlans: Product[];
  addendum?: string;
}) => {
  if (loading) {
    return <option>Loading...</option>;
  }
  if (error) {
    return <option>Error getting pricing</option>;
  }

  return (
    <>
      {productRatePlans.map((plan) => {
        if (plan.name !== "studio") {
          return (
            <option
              defaultValue={planId}
              value={plan.recurringRatePlanChargeId}
              key={plan.id}
            >
              {plan.name.toUpperCase()}{" "}
              {`$${plan.recurringPrice.toFixed(2) ?? 0}/${addendum} `}
            </option>
          );
        }
      })}
    </>
  );
};

export default NoOpBundlesDropDown;
