import React from "react";
import { Navbar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useConfig } from "../../configuration/useConfig";
import { Link } from "react-router-dom";

const NavBar = ({
  logo,
  pbLogo,
  second,
  children,
  id,
  enrollment,
}: {
  logo?: boolean;
  pbLogo?: boolean;
  second?: boolean;
  children?: React.ReactElement[] | React.ReactChild | undefined;
  id?: boolean;
  enrollment?: boolean;
}) => {
  const { config } = useConfig();

  return (
    <Navbar
      collapseOnSelect
      className={`app-navbar ${second ? "second" : ""} ${
        id
          ? config["client"] === "walmart"
            ? "app-navbar-account"
            : "app-navbar-hcsc-account"
          : ""
      }`}
      bg="white"
      expand="lg"
      sticky="top"
    >
      {!enrollment && id ? (
        <Navbar.Toggle aria-controls="basic-navbar-nav-2" as="div">
          <div className="toggle-open" style={{ color: "white" }}>
            <div className="toggle-open d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faBars} />
              <div className="app-navbar__toggle-text">menu</div>
            </div>
          </div>
          <div className="toggle-close" style={{ color: "white" }}>
            <div className="toggle-close d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faTimes} />
              <div className="app-navbar__toggle-text">close</div>
            </div>
          </div>
        </Navbar.Toggle>
      ) : !enrollment ? (
        <Navbar.Toggle aria-controls="basic-navbar-nav-2" as="div">
          <div className="toggle-open">
            <div className="toggle-open d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faBars} />
              <div className="app-navbar__toggle-text">menu</div>
            </div>
          </div>
          <div className="toggle-close">
            <div className="toggle-close d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faTimes} />
              <div className="app-navbar__toggle-text">close</div>
            </div>
          </div>
        </Navbar.Toggle>
      ) : (
        ""
      )}
      {logo && (
        <div style={{ display: `flex`, flexDirection: `row` }}>
          <Navbar.Brand as={Link} to="/" className="order-lg-first ">
            {config["client"] === "mutual-of-omaha" ? (
              <div className="logo-containter">
                <img
                  alt="Mutually Well Logo - Together with Tivity Health"
                  src={`/${config["logo"]}`}
                />
                <div className="logo-text">
                  <h1 className="logo-header">
                    MUTUALLY<span>WELL</span>
                  </h1>
                  <p className="logo-sub-header">
                    <i>together with</i> Tivity Health<span>©</span>
                  </p>
                </div>
              </div>
            ) : (
              <img
                alt="logo"
                src={
                  config["client"] === "walmart"
                    ? id
                      ? "/Tivity_fitness_pass_white_512.png"
                      : "/Tivity_fitness_pass_full_512.png"
                    : `/${config["logo"]}`
                }
                width="240"
                className={`d-inline-block align-center img-fluid`}
              />
            )}
          </Navbar.Brand>
        </div>
      )}

      {pbLogo && (
        <>
          <Navbar.Brand as={Link} to="/" className="order-lg-first ">
            <img
              alt="logo"
              src="/Well-On-Target-logo.svg"
              width="180"
              className="d-inline-block align-top d-md-none"
            />
          </Navbar.Brand>
        </>
      )}
      <Navbar.Collapse id="basic-navbar-nav-2">{children}</Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
