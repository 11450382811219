import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  FormikWizardState,
  IEligibilityFormProfileHCSC,
  SSOInfo,
} from "../types";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ELIGIBILITY_MESSAGE,
  USER_PAYROLL_STATUS,
  USER_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import { getIn, useFormikContext } from "formik";

import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import StatusModal from "../components/StatusModal";
import FwEligibilityModal from "../../account/FwEligibilityModal";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useRouteQuery from "../../../hooks/useRouteQuery";
import axiosInstance from "../../../axios.instance";
import Select, { OptionsType, ValueType } from "react-select";
import { CorpCodeOption } from "../../../types";
import { initialCorpCode } from "../model/InitialState";
import { AccountContext } from "../../../context/AccountProvider";

const Eligibility = ({ next }: { next?: () => void }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = useFormikContext<FormikWizardState<IEligibilityFormProfileHCSC>>();

  const { user, setUser } = useContext(UserContext);
  const { member } = useContext(AccountContext);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [cardOneRef] = useState<HTMLDivElement | null>(null);
  const [, setRefs] = useState<(HTMLDivElement | null)[]>([]);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [impersonateLockForm, setImpersonateLockForm] =
    useState<boolean>(false);
  const { isImpersonated } = useContext(ImpersonationContext);
  const query = useRouteQuery();
  const [isDependentToPrimary, setIsDependentToPrimary] = useState(false);
  const [showRegistrationModel, setShowRegistrationModel] = useState(false);

  const [isCorpCodeValid, setIsCorpCodeValid] = useState(true);
  const selectRef = useRef<Select<CorpCodeOption>>(null);
  const [corpCodeSelected, setCorpCodeSelected] =
    useState<ValueType<CorpCodeOption, false>>(null);
  const [corpCodeOptions] =
    useState<OptionsType<CorpCodeOption>>(initialCorpCode);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 150, // Limit the visible options
      overflowY: "scroll", // Enable scrolling
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: !isCorpCodeValid
        ? "red"
        : state.isFocused
        ? "#86b7fe"
        : provided.borderColor,
      outline: state.isFocused ? 0 : provided.outline,
      boxShadow: !isCorpCodeValid
        ? "0 0 0 0.25rem rgba(255, 0, 0, 0.25)"
        : state.isFocused
        ? "0 0 0 0.25rem rgba(13, 110, 253, 0.25)"
        : provided.boxShadow,
    }),
  };

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  useEffect(() => {
    if (query.get("isSSO") === "true") {
      setUser({ isSSO: true });
    }
  }, []);
  //set impersonate value from browser parameter
  useEffect(() => {
    setImpersonateLockForm(isImpersonated && window.location.search.length > 0);
    const ssoString = localStorage.getItem("ssoInfo");
    if (isImpersonated && query.get("firstName")) {
      const dob = new Date(query.get("dob") || 0);
      const year = dob.getFullYear();
      const month = ("0" + (dob.getMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getDate()).slice(-2).toString();
      setFieldValue("eligibility.firstName", query.get("firstName"));
      setFieldValue("eligibility.lastName", query.get("lastName"));
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        query.get("subscriberId")
      );
      setFieldValue("eligibility.email", query.get("email"));
      setFieldValue("eligibility.subscriberId", query.get("subscriberId"));
      setFieldValue("eligibility.zip", query.get("zip"));

      setFieldTouched("eligibility.name", true);
    } else if (user.isSSO && ssoString) {
      const ssoInfo: SSOInfo = JSON.parse(ssoString);
      setFieldValue("eligibility.firstName", ssoInfo.firstName);
      setFieldValue("eligibility.lastName", ssoInfo.lastName);
      setFieldValue("eligibility.month", ssoInfo.month);
      setFieldValue("eligibility.day", ssoInfo.day);
      setFieldValue("eligibility.year", ssoInfo.year);
      setFieldValue("eligibility.corpCode", ssoInfo.corporationCode);
      setFieldValue("eligibility.subscriberId", ssoInfo.subscriberId);
      setFieldValue("eligibility.groupNumber", ssoInfo.groupNumber);
      setCorpCodeSelected({
        label: ssoInfo.corporationCode,
        value: ssoInfo.corporationCode,
      });
    } else if (
      member?.eligibilities[0]?.currentSubscriptionStatus === "Cancelled" &&
      !member?.eligibilities[0]?.isPrimary
    ) {
      const { firstName, lastName, dateOfBirth, email, postalCode } = member;

      setFieldValue("eligibility.firstName", firstName);
      setFieldValue("eligibility.lastName", lastName);
      setFieldValue("eligibility.month", dateOfBirth.substring(5, 7));
      setFieldValue("eligibility.day", dateOfBirth.substring(8, 10));
      setFieldValue("eligibility.year", dateOfBirth.substring(0, 4));
      setFieldValue("eligibility.email", email);
      setFieldValue("eligibility.zip", postalCode);
      setIsDependentToPrimary(true);
    }
  }, [user.isSSO, isImpersonated]);

  useEffect(() => {
    if (values.eligibility?.completed && next) {
      next();
    }
  }, []);

  const lockSSOField = (field) => {
    if (user.isSSO) {
      return query.get(field) !== "";
    }
    return false;
  };

  useEffect(() => {
    if (status && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, status]);

  const getAge = (dateString: string) => {
    const today = new Date(),
      birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isOver18 = () => {
    if (values.eligibility && values.eligibility.year) {
      const age = getAge(
        `${values.eligibility?.month}/${values.eligibility?.day}/${values.eligibility?.year}`
      );
      if (!isNaN(age) && age >= 18) return true;
      return false;
    }
  };

  const handleCorpCodeChange = (selectedCorpCode: CorpCodeOption | null) => {
    setCorpCodeSelected(selectedCorpCode);
    setIsCorpCodeValid(true);
    setFieldValue("eligibility.corpCode", selectedCorpCode!.value);
  };

  const handleCorpCodeBlur = () => {
    if (corpCodeSelected === null && isCorpCodeValid === false) {
      return;
    }
    if (corpCodeSelected === null && isCorpCodeValid === true) {
      setIsCorpCodeValid(false);
    }
    if (corpCodeSelected !== null && isCorpCodeValid === true) {
      return;
    }
    if (corpCodeSelected !== null && isCorpCodeValid === false) {
      setIsCorpCodeValid(true);
    }
  };

  const handleCheckEligibility = async () => {
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
    const vals = values.eligibility!;
    const eligibilityEndpoint = isDependentToPrimary
      ? "/client-eligibility"
      : "/eligibility";
    setStatus("loading");
    setMessage("We're confirming your information");

    axiosInstance
      .post(eligibilityEndpoint, {
        firstName: vals.firstName,
        lastName: vals.lastName,
        dateOfBirth: `${vals.year}-${vals.month}-${vals.day}`,
        email: vals.email,
        zip: vals.zip,
        subscriberId: vals.subscriberId,
        isDependent: false,
        createLogin: true,
        clientSpecificData: {
          clientCode: "hcsc",
          groupNumber: vals.groupNumber,
          corporationCode: vals.corpCode,
        },
      })
      .then((response) => {
        switch (response.data?.message) {
          case ELIGIBILITY_MESSAGE.Eligible:
            setFieldValue("eligibility.completed", true);
            setFieldValue("eligibility.consumerId", response.data.consumerId);
            setFieldValue(
              "eligibility.clientMemberId",
              response.data.clientMemberId
            );
            setFieldValue(
              "eligibility.clientCode",
              response.data.clientSpecificData.clientCode
            );
            setFieldValue(
              "eligibility.isFitnessWorksEligible",
              response.data.clientSpecificData.isFitnessWorksEligible
            );
            setFieldValue("eligibility.isPayroll", false);
            setClientCode(response.data.clientSpecificData.clientCode);

            setFieldValue(
              "eligibility.groupId",
              response.data.clientSpecificData?.groupId
            );
            setFieldValue(
              "eligibility.planName",
              response.data.clientSpecificData?.planName
            );
            setFieldValue(
              "eligibility.subProduct",
              response.data.clientSpecificData?.subProduct
            );
            setFieldValue(
              "eligibility.divisionId",
              response.data.clientSpecificData?.divisionId
            );
            setFieldValue(
              "eligibility.groupName",
              response.data.clientSpecificData?.groupName
            );
            setFieldValue(
              "eligibility.fitnessOptIn",
              response.data.clientSpecificData?.fitnessOptIn
            );
            setFieldValue(
              "eligibility.groupIndividualIndicator",
              response.data.clientSpecificData?.groupIndividualIndicator
            );
            setFieldValue(
              "eligibility.lineOfBusiness",
              response.data.clientSpecificData?.lineOfBusiness
            );
            setFieldValue(
              "eligibility.medicareType",
              response.data.clientSpecificData?.medicareType
            );
            setFieldValue(
              "eligibility.misc3",
              response.data.clientSpecificData?.misc3
            );
            setFieldValue(
              "eligibility.misc6",
              response.data.clientSpecificData?.misc6
            );
            setFieldValue(
              "eligibility.misc7",
              response.data.clientSpecificData?.misc7
            );

            setUser({
              status: USER_STATUS.ELIGIBLE,
              isPayroll:
                response.data.payrollStatus === USER_PAYROLL_STATUS.Payroll,
            });
            if (response.data?.clientSpecificData.isFitnessWorksEligible) {
              setStatus("");
              setShowRegistrationModel(true);
            } else {
              setStatus("CONFIRMED");
            }
            break;
          case ELIGIBILITY_MESSAGE.NotEligible:
            setStatus("DENIED");
            setMessage(
              "The information you provided could not be confirmed as eligible. Please review your information, and try again."
            );
            break;
          case ELIGIBILITY_MESSAGE.EmailUnavailable:
            setStatus("NOEMAIL");
            setMessage(
              "The email address you entered is already in use. Please use a different email address for your account creation."
            );
            break;
          case ELIGIBILITY_MESSAGE.LoginCreated:
            setStatus(ELIGIBILITY_MESSAGE.LoginCreated);
            // setMessage(
            //   "Your Account has been created, please check your email to complete account creation."
            // );
            break;
          case ELIGIBILITY_MESSAGE.IsSSO:
            setStatus(ELIGIBILITY_MESSAGE.IsSSO);
            break;
          case ELIGIBILITY_MESSAGE.ExistingLoginFound:
            setStatus("EXISTS");
            setMessage(
              "Our Records indicate that you already have an account with us. Please login or reset your password to access your account."
            );
            break;
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
        setMessage(
          "The information you provided could not be confirmed as eligible. Please review your information, and try again."
        );
        setStatus("DENIED");
      });
  };

  const handleHandoff = () => {
    if (next) {
      next();
    }
  };

  const onFwError = () => {
    setShowRegistrationModel(false);
    setStatus("ERROR-CHECKOUT");
  };

  const scrollTo = (elementName) => {
    const el = document.getElementsByName(elementName);
    el[0].scrollIntoView();
  };

  const handlePrivacyPolicyKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setFieldValue(
        "eligibility.privacyPolicy",
        !values.eligibility!.privacyPolicy
      );
    }
  };

  const handleCorpCodeKeyDown: React.KeyboardEventHandler<HTMLElement> = (
    event
  ) => {
    if (event.key === "Enter" && selectRef.current) {
      selectRef.current.select.openMenu("first");
    }
  };

  const isErrorFound = () => {
    const eligibility = values.eligibility as {
      month?: string;
      day?: string;
      year?: string;
      privacyPolicy?: boolean;
    };

    return (
      Boolean(errors.eligibility !== undefined) ||
      (eligibility.month &&
        typeof eligibility.month === "string" &&
        eligibility.month.length < 2) ||
      (eligibility.day &&
        typeof eligibility.day === "string" &&
        eligibility.day.length < 2) ||
      (eligibility.year &&
        typeof eligibility.year === "string" &&
        eligibility.year.length < 4) ||
      !isOver18() ||
      eligibility.privacyPolicy !== true
    );
  };

  return (
    <div className="eligibility-form-container">
      <StatusModal
        show={status !== ""}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
        progress={progress}
        clientCode={clientCode}
      />
      {showRegistrationModel && <FwEligibilityModal onFail={onFwError} />}
      <Header
        title={
          user.isSSO ? `Confirm Your Information` : `Check Your Eligibility`
        }
        subtitle={
          !user.isSSO
            ? `Please fill out the information below to see if you're eligible`
            : ""
        }
      />
      <Card
        className="eligibility-form"
        style={{ padding: "24px", marginTop: 20 }}
      >
        <Card.Body style={{ padding: "24px" }}>
          <Card.Title style={{ color: "black" }}>PERSONAL INFO</Card.Title>
          <p style={{ marginBottom: "5px" }}>
            We will use the info below to authenticate your account
          </p>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={4}
              lg={3}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={35}
                name="eligibility.firstName"
                value={values.eligibility!.firstName as string}
                placeholder="Enter first name..."
                disabled={
                  lockSSOField("firstName") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  getIn(touched, "eligibility.firstName") &&
                  getIn(errors, "eligibility.firstName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.firstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={60}
                name="eligibility.lastName"
                value={values.eligibility!.lastName as string}
                placeholder="Enter last name..."
                disabled={
                  lockSSOField("lastName") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  getIn(touched, "eligibility.lastName") &&
                  getIn(errors, "eligibility.lastName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.lastName")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label className="form-control-sm col-form-label p-0 eligibility-form__label">
            Date of Birth <span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="month"
              xs={6}
              sm={3}
              md={2}
              xl={1}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0">
                Month
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.month"
                placeholder="MM"
                value={values.eligibility!.month}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.month") &&
                    getIn(errors, "eligibility.month")) ||
                  (getIn(touched, "eligibility.month") &&
                    (values.eligibility?.month as string).length !== 2) ||
                  (getIn(touched, "eligibility.month") &&
                    isNaN(Number(values.eligibility?.month)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={6} sm={3} md={2} xl={1}>
              <Form.Label className="form-control-sm col-form-label p-0">
                Day
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.day"
                placeholder="DD"
                value={values.eligibility!.day}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.day") &&
                    getIn(errors, "eligibility.day")) ||
                  (getIn(touched, "eligibility.day") &&
                    (values.eligibility?.day as string).length !== 2) ||
                  (getIn(touched, "eligibility.day") &&
                    isNaN(Number(values.eligibility?.day)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={8} md={3} lg={2}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-0">
                Year
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.year"
                value={values.eligibility!.year}
                disabled={
                  lockSSOField("dob") ||
                  impersonateLockForm ||
                  isDependentToPrimary
                }
                isInvalid={
                  (getIn(touched, "eligibility.year") &&
                    getIn(errors, "eligibility.year")) ||
                  (getIn(touched, "eligibility.year") &&
                    (values.eligibility?.year as string).length !== 4) ||
                  (getIn(touched, "eligibility.year") &&
                    isNaN(Number(values.eligibility?.year))) ||
                  (getIn(touched, "eligibility.year") && !isOver18())
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="YYYY"
              />
            </Form.Group>
          </Row>
          {getIn(touched, "eligibility.year") && !isOver18() && (
            <div className="invalid-feedback-dob">
              Must be 18 years or older.
            </div>
          )}
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={5} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                {values.eligibility!.hasBlue365 === "true"
                  ? "Blue365 Email Address"
                  : "Email Address"}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.email"
                value={values.eligibility!.email as string}
                placeholder="Enter email..."
                isInvalid={
                  getIn(touched, "eligibility.email") &&
                  getIn(errors, "eligibility.email")
                }
                disabled={isDependentToPrimary}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => scrollTo("eligibility.day")}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                ZIP Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.zip"
                value={values.eligibility!.zip}
                placeholder="Enter ZIP..."
                isInvalid={
                  getIn(touched, "eligibility.zip") &&
                  getIn(errors, "eligibility.zip")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.zip")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Card.Title style={{ color: "black", marginTop: "20px" }}>
            HEALTH PLAN INFO
          </Card.Title>
          <p>Please enter your health plan info below to confirm eligibility</p>
          <br />
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={12} sm={9} md={2} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Corp Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Select
                classNamePrefix="custom-select"
                name="eligibility.corpCode"
                placeholder={
                  <div style={{ color: "#b6b6b6" }}>Select Corp Code</div>
                }
                value={corpCodeSelected}
                options={corpCodeOptions}
                isInvalid={!isCorpCodeValid}
                onChange={handleCorpCodeChange}
                onBlur={handleCorpCodeBlur}
                styles={customStyles}
                isDisabled={lockSSOField("corpCode")}
                onKeyDown={handleCorpCodeKeyDown}
                menuPortalTarget={document.body}
                ref={selectRef}
              />
              {!isCorpCodeValid && (
                <div className="invalid-select">Corp Code is required.</div>
              )}
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={9} md={5} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Subscriber ID
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.subscriberId"
                value={values.eligibility!.subscriberId as string}
                isInvalid={
                  getIn(touched, "eligibility.subscriberId") &&
                  getIn(errors, "eligibility.subscriberId")
                }
                disabled={lockSSOField("subscriberId")}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.subscriberId")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={9} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Group #<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="eligibility.groupNumber"
                value={values.eligibility!.groupNumber as string}
                isInvalid={
                  getIn(touched, "eligibility.groupNumber") &&
                  getIn(errors, "eligibility.groupNumber")
                }
                disabled={lockSSOField("groupNumber")}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => scrollTo("eligibility.groupNumber")}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.groupNumber")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxPrivacyPolicy"
            >
              <Row className="eligibility-form__row">
                <Col
                  xs={1}
                  style={{ width: "20px", paddingTop: "4px", marginRight: "0" }}
                >
                  <Form.Check
                    id="privacy"
                    value="true"
                    isInvalid={
                      getIn(touched, "eligibility.privacyPolicy") &&
                      getIn(errors, "eligibility.privacyPolicy")
                    }
                    name="eligibility.privacyPolicy"
                    checked={values.eligibility!.privacyPolicy === true}
                    inline
                    style={{ paddingLeft: 0 }}
                    onChange={handleChange}
                    onKeyDown={handlePrivacyPolicyKeyDown}
                  />
                </Col>
                <Col xs={9} sm={8} style={{ padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "0.8rem" }}>
                    <span className="required-field-marker">*</span>By providing
                    your email address and/or any other personal information, as
                    defined under applicable law, you acknowledge that you are
                    agreeing to our use of your information as provided in our{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Col>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.privacyPolicy")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxContactInfo"
            >
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.contactInfo")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <FooterCard>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 5 }}></Col>
            <Col sm={5} lg={3}>
              <>
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  onClick={handleCheckEligibility}
                  disabled={
                    user.isSSO
                      ? isErrorFound()
                      : Object.keys(touched).length === 0
                      ? true
                      : isErrorFound()
                  }
                >
                  {user.isSSO ? "Confirm Information" : "Check My Eligibility"}
                </Button>
              </>
            </Col>
          </Row>
        </Container>
      </FooterCard>
    </div>
  );
};

export default Eligibility;
