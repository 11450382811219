import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const CreditBalance = ({ balance }: { balance: string }) => {
  return (
    <Card className="creditBalance mb-3">
      <Row>
        <Col xs={12} lg={12} className="p-0">
          <Card.Body className="title">
            <h6>
              Account Credits Balance: <span>{"$" + balance}</span>
            </h6>
          </Card.Body>
          <Card.Body className="creditBalance__body">
            Account credits will be applied automatically before charging your
            default payment method.
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default CreditBalance;
