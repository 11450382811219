import React, { Fragment, useEffect, useState } from "react";
import Section from "../components/terms/Section";
import { ProgramAgreementType, ProgramAgreementSection } from "../types";
import { convertToAgreement, tags } from "../utility/contentful";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";
import { Container } from "react-bootstrap";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import PoweredBy from "../components/shared/PoweredBy";
import BasicNavHeader from "../components/navigation/BasicNavHeader";
import useRouteQuery from "../hooks/useRouteQuery";

const FitnessProgramAgreement = ({ home }: { home: string }) => {
  const [content, setContent] = useState<ProgramAgreementType | null>(null);
  const { config } = useConfig();
  const { getProgramAgreement } = useContentful(config);
  const query = useRouteQuery();

  useEffect(() => {
    (async () => {
      getProgramAgreement(tags[query.get("clientCode") || ""]).then((res) => {
        if (res) {
          setContent(convertToAgreement(res.items[0]));
        }
      });
    })();
    // eslint-disable-next-line
  }, []);
  if (!content) {
    return null;
  }
  return (
    <Fragment>
      <BasicNavHeader showAccount={true} />
      <Container>
        <p>
          <br></br>
          <a href={home} className="a-link">
            Home
          </a>
          {"  "}Privacy Policy
        </p>
        <br></br>
        <br></br>
        {/* <h1>PRIVACY POLICY AND TERMS OF USE</h1> */}
        {/* <span>Last updated: {content.lastUpdated}</span> */}
        {content.sections.map((section: ProgramAgreementSection) => (
          <Section {...section} key={section.header} />
        ))}
        <br></br>
        <br></br>
      </Container>
      <div style={{ backgroundColor: "var(--primary)" }}>
        <Container>
          <LegalDisclaimer></LegalDisclaimer>
          <PoweredBy></PoweredBy>
        </Container>
      </div>
    </Fragment>
  );
};
export default FitnessProgramAgreement;
