import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" role="status" aria-hidden="true" />
    </div>
  );
};

export default Login;
