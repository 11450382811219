import { useEffect, useState } from "react";

const useGetScreenSize = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1170);

  const adjustScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 1170);
  };
  useEffect(() => {
    window.addEventListener("resize", adjustScreenSize);
    return () => {
      window.removeEventListener("resize", adjustScreenSize);
    };
  }, []);

  return { isSmallScreen };
};

export default useGetScreenSize;
