import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  searchInputWrapper: {
    backgroundColor: 'white',
    maxHeight: 58,
    transition: 'max-height 0.3s ease-in-out',
  },

  searchInputWrapperActive: {
    maxHeight: 525,
  },

  searchForm: {
    marginBottom: 0,
    // Set min height so input doesn't shift layout when it loads
    minHeight: 38,
    zIndex: 1,
    '& ::-webkit-input-placeholder': {
      color: '#aaa',
    },
    '& :-ms-input-placeholder': {
      color: '#aaa',
    },
    '& ::placeholder': {
      color: '#aaa',
    },
  },

  searchError: {
    color: '#c61700',
    fontSize: '0.9rem',
    marginTop: '0.75rem',

    '& > p:last-child': {
      marginBottom: 0,
    },
  },

  autoCompleteDropDownItem: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    padding: '10px',
    margin: '0 0 10px',
    textAlign: 'left',
    width: '100%',
    boxShadow: '0 2px 4px rgba(221, 221, 221, 0.7)',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: 5,
    transition: 'box-shadow 0.15s ease-in-out',
    animation: '$fadeIn 0.3s normal cubic-bezier(0.215, 0.61, 0.355, 1)',
    '&:first-child': {
      marginTop: 20,
    },
    '&:hover, &:focus, &:active': {
      boxShadow: `0 0 0 3px ${theme.primary.color.css()}`,
    },
  },

  autoCompleteDropDownItemActive: {
    backgroundColor: '#fafafa',
    boxShadow: `0 0 0 3px ${theme.primary.color.css()}`,
  },

  searchInputContainer: {
    position: 'relative',

    '&:before': {
      content: '""',
      height: 18,
      width: 18,
      top: 11,
      left: 10,
      backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3ESlice%3C%2Ftitle%3E%3Cpath%20d%3D%22M12.5%2011h-.79l-.28-.27C12.41%209.59%2013%208.11%2013%206.5%2013%202.91%2010.09%200%206.5%200S0%202.91%200%206.5%202.91%2013%206.5%2013c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L17.49%2016l-4.99-5zm-6%200C4.01%2011%202%208.99%202%206.5S4.01%202%206.5%202%2011%204.01%2011%206.5%208.99%2011%206.5%2011z%22%20fill%3D%22%23${theme.primary.color
        .toString()
        .slice(1)}%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E")`,
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
    },
  },

  searchInput: {
    width: '100%',
    padding: '10px',
    paddingLeft: '40px',
    paddingRight: '40px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',

    // Remove native IE/Edge Clear button
    '&::-ms-clear': {
      display: 'none',
    },

    '&:focus, &:active': {
      boxShadow: `0 0 0 0.2rem ${theme.primary.color.alpha(0.5).css()}`,
      outline: 'none',
    },
  },

  currentLocationIcon: {
    marginRight: 5,
    color: theme.primary.color.css(),
  },

  clearButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    padding: '0 15px',
    color: '#aaa',
    transition: 'color 0.2s ease-in-out',

    '&:hover, &:active, &:focus': {
      color: '#000',
    },
  },

  visuallyHidden: {
    // Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(100%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px) scale(0.95)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0) scale(1)',
    },
  },
}))

export default useStyles
