import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { UserContext, USER_STATUS } from "../../../../context/UserProvider";
import useGetPrograms from "../../../../hooks/useGetPrograms";
import styles from "./LandingPackageSelectionMOO.module.scss";
import { Product } from "../../forms/Package";
import PackageCardMOO from "../PackageCardMOO";

const LandingPackageSelectionMOO = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { loading: productsLoading, productRatePlans: products } =
    useGetPrograms(false, false);
  const [product, setProduct] = useState<null | Product>(null);

  // A no-op function for event handlers that are required but not used.
  const noop = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  const handleEligibility = () => {
    history.push("/enroll/eligibility");
  }

  useEffect(() => {
    if (products && products.length > 0) {
      setProduct(products[0])
    }
  }, [products])

  return (
    <div className="d-block">
      {productsLoading ? (
        <div className="d-flex justify-content-center align-items-center m-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <h1 className={styles.title}>One membership to rule them all</h1>
          <h4 className={styles.subTitle}>
            Nationwide gym access (no contract required) and premium digital content so you can work out whenever you want, wherever you are.
          </h4>
          <div className="package-card-container">
            {product && (
              <PackageCardMOO
                productInfo={product}
                price={product.recurringPrice ?? 0}
                handleChange={noop}
                selected=""
                loading={false}
                name={product.name}
                showSelectButton={false}
              />
            )}
          </div>
          <h1 className={styles.title}>If you’re eligible</h1>
          <h4 className={styles.subTitle}>
            Once we confirm you’re eligible, you’ll have access to thousands of wellness discounts and our FREE companion app with personalized weekly plans.
          </h4>
          <div className="package-card-container">
            {product && (
              <PackageCardMOO
                productInfo={product}
                price={product.recurringPrice ?? 0}
                handleChange={noop}
                selected=""
                loading={false}
                name={product.name}
                onlyDescription={true}
                showSelectButton={false}
                tagline="Gyms &amp; digital access not included"
              />
            )}
          </div>
          <div className={styles.eligibleButton}>
            <Button
              variant="primary"
              className="nav-btn-enroll landing-page"
              onClick={
                user.status === USER_STATUS.PENDING ? handleEligibility : undefined
              }
            >
              Check My Eligibility
              <FontAwesomeIcon
                icon={faChevronRight}
                className="chevron-right"
              />
            </Button>
          </div>
        </> 
      )}
    </div>
  );
};

export default LandingPackageSelectionMOO;
