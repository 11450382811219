import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import NavBar from "../components/navigation/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import MigrationImg from "../resources/Migration.svg";
import { changeUsernameMigration } from "../api/identity/Queries";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AccountContext } from "../context/AccountProvider";
import { emailRegex } from "../utility/Constants";
import { useConfig } from "../configuration/useConfig";

const Migration = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const { member } = useContext(AccountContext);
  const history = useHistory();
  const { config } = useConfig();
  useEffect(() => {
    const hasMigrated = sessionStorage.getItem("has_migrated");
    const ui = false;
    if (!ui || hasMigrated == "true") {
      history.push("/account");
    } else {
      setPageLoading(false);
    }
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: { email: string }) => {
    try {
      setLoading(true);
      await changeUsernameMigration(values.email);
      sessionStorage.setItem("has_migrated", "true");
      sessionStorage.setItem(
        "userinfoModified",
        JSON.stringify({
          email: values.email,
        })
      );

      history.push("/account");
    } catch (e: unknown) {
      if ((e as { code: number }).code === 6) {
        setDuplicate(true);
      } else {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const termedAttributes = [
    "Flexible Gym Packages",
    "Thousands of online classes",
    "Digital-Only Package",
  ];
  const termedAttributesPrimeOnDemand = [
    "Flexible Gym Packages",
    "Thousands of online classes, including Prime On-Demand™",
    "Digital-Only Package",
  ];

  const attributes = [
    "Multiple fitness plan options",
    "Access to thousands of online classes",
    "The same great network of gyms",
  ];

  const Subtitle = () => {
    if (member?.eligibilities[0].currentSubscriptionStatus !== "Cancelled") {
      return (
        <p className="d-inline text-align-justify">
          Confirm your email and re-enroll to access the{" "}
          <b>all new Fitness Your Way</b>.
        </p>
      );
    }
    return (
      <p className="d-inline text-align-justify">
        You&apos;ve been upgraded to the{" "}
        <span style={{ color: "#3cabdd" }}>
          <b>
            <i>
              <u>POWER</u>
            </i>
          </b>
        </span>{" "}
        plan (a $39/mo value) for the same price that you currently pay.
      </p>
    );
  };

  const AttributeList = () => {
    let list = attributes;
    if (member?.eligibilities[0].currentSubscriptionStatus !== "Cancelled") {
      list =
        config["primeOnDemand.enable"] == "true"
          ? termedAttributesPrimeOnDemand
          : termedAttributes;
    }
    return (
      <ul className="migration-feature-list">
        {list.map((a) => (
          <li key={a}>{a}</li>
        ))}
      </ul>
    );
  };

  const Hero = () => {
    if (member?.eligibilities[0].currentSubscriptionStatus !== "Cancelled") {
      return (
        <h1 className="display-4">
          <strong>Welcome Back!</strong>
        </h1>
      );
    }
    return (
      <h1 className="display-4">
        <strong>We upgraded your plan!</strong>
      </h1>
    );
  };
  return (
    <>
      {pageLoading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <NavBar logo />
          <Container fluid="xl">
            <Row className="pt-5 flex-wrap-reverse">
              <Col xs={12} md={6}>
                <div>
                  <Hero />
                  <div className="d-flex flex-nowrap mt-4">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="mt-1"
                      style={{ marginRight: "8px" }}
                    />
                    <Subtitle />
                  </div>
                </div>
                <h5 className="mt-4">
                  <strong>Here&apos;s what&apos;s new:</strong>
                </h5>
                <AttributeList />
                <hr />
                <h3 className="mt-4">
                  <strong>Confirm your email address</strong>
                </h3>
                <p className="mb-2">
                  You will use your email address to log in to your account
                  moving forward.
                </p>
                <p>
                  <i>Your password will remain the same.</i>
                </p>
                <Row className="my-4">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={yup.object().shape({
                      email: yup
                        .string()
                        .matches(
                          new RegExp(emailRegex),
                          "Please enter a valid email address"
                        )
                        .required(),
                    })}
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                  >
                    {({ handleChange, handleBlur, values, errors }) => (
                      <FormikForm className="p-0">
                        <Form.Group
                          as={Col}
                          xs={12}
                          sm={8}
                          xl={6}
                          className="mb-3"
                        >
                          <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                            Email Address
                            <span className="required-field-marker">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={errors.email !== undefined}
                          />
                          <Form.Control.Feedback type="invalid">
                            Invalid Email Address
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                          variant="primary"
                          className="nav-btn-enroll font-weight-bold"
                          style={{ whiteSpace: "nowrap" }}
                          type="submit"
                          disabled={errors.email !== undefined}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            "Confirm Email & Continue"
                          )}
                        </Button>
                        {duplicate && (
                          <p className="text-danger mt-2">
                            This email is associated with an existing account.
                            Please login if this is your account or enter a
                            different email
                          </p>
                        )}
                        {error && (
                          <p className="text-danger mt-2">
                            There was an error migrating your email address.
                          </p>
                        )}
                      </FormikForm>
                    )}
                  </Formik>
                </Row>
              </Col>
              <Col className="d-flex align-items-center">
                <img
                  src={MigrationImg}
                  alt="migrate"
                  className="mw-100
            "
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Migration;
