import { Fragment, useContext, useEffect, useState } from "react";
import { IFeatureFields } from "../@types/generated/contentful";
import FeatureFactory from "../components/features/FeatureFactory";
import PoweredBy from "../components/shared/PoweredBy";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import { EntryCollection, Entry } from "contentful";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";
import { UserContext } from "../context/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";
import LandingPackageSelection from "../components/wizard/components/LandingPackageSelection/LandingPackageSelection";
import LandingPackageSelectionMOO from "../components/wizard/components/LandingPackageSelection/LandingPackageSelectionMOO";

const Landing = ({ onOpen }: { onOpen?: (e: unknown) => void }) => {
  const [content, setContent] =
    useState<EntryCollection<IFeatureFields> | null>(null);
  const config = useConfig();
  const { getFeatureFields } = useContentful(config.config);

  const { isLoading: auth0Loading, isAuthenticated } = useAuth0();
  const { user } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      getFeatureFields().then((res) =>
        setContent(res as EntryCollection<IFeatureFields>)
      );
    })();
  }, []);

  let fragmentIndex = 0;
  if (content?.items) {
    fragmentIndex = config.config["client"] === "mutual-of-omaha" ? 6 : content.items.length / 2;
  }

  return (
    <Fragment>
      {content?.items
        .filter(
          (_: Entry<IFeatureFields>, index: number) =>
            index < fragmentIndex
        )
        .map((item: Entry<IFeatureFields>, index: number) => (
          <FeatureFactory
            type={item.fields.type ?? ""}
            data={item}
            key={item.fields.type + "-" + index ?? ""}
            params={{ onClick: onOpen }}
            index={index}
          />
        ))}
      {content &&
        ((!auth0Loading && isAuthenticated) || user.isSSO) &&
        config.config["client"] === "hcsc" && <LandingPackageSelection />}
      {content && config.config["client"] === "mutual-of-omaha" && 
        <LandingPackageSelectionMOO />}
      {content?.items
        .filter(
          (_: Entry<IFeatureFields>, index: number) =>
            index >= fragmentIndex
        )
        .map((item: Entry<IFeatureFields>, index: number) => (
          <FeatureFactory
            type={item.fields.type ?? ""}
            data={item}
            key={item.fields.type + "-" + index ?? ""}
            params={{ onClick: onOpen }}
            index={index}
          />
        ))}
      <div className="footer-bottom-background">
        <LegalDisclaimer />
        <PoweredBy />
      </div>
    </Fragment>
  );
};

export default Landing;
