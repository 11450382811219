import { useContext } from "react";
import { AccountContext } from "../context/AccountProvider";

export function useMembership() {
  const { member } = useContext(AccountContext);

  if (!member) {
    throw new Error(
      "Member is not defined in AccountContext. Cannot use membership without member being set."
    );
  }

  const { eligibilities, ...properties } = member;

  const [eligibility] = eligibilities;

  return {
    ...properties,
    eligibilities, // TODO - deprecate this if possible in favor of eligibility (below). Leaving it on the model to prevent downstream problems where this is referenced.
    eligibility,
  };
}

export default useMembership;
