import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { useEffect, useState } from "react";
import { ILegalBullet } from "../../@types/generated/contentful";
import { useConfig } from "../../configuration/useConfig";
import useContentful from "../../hooks/useContentful";

const LegalDisclaimer = () => {
  const [bullets, setBullets] = useState<ILegalBullet[] | null>(null);
  const { config } = useConfig();

  const { getLegalDisclaimers } = useContentful(config);
  useEffect(() => {
    (async () => {
      getLegalDisclaimers().then((res) => {
        if (res) {
          setBullets(res.items?.[0]?.fields?.legalBullets);
        }
      });
    })();
  }, []);
  if (!bullets) {
    return null;
  }
  return (
    <div className="legal-disclaimer">
      <div className="legal-disclaimer__header-container">
        <h4>
          <b>Legal Disclaimer</b>
        </h4>
        <hr></hr>
      </div>
      <div className="legal-disclaimer__flex">
        {bullets &&
          bullets.map((b, index: number) => (
            <div
              className="legal-disclaimer__flex-item"
              key={`disclaimer-${index}`}
            >
              <h2>{documentToReactComponents(b.fields.header1!)}</h2>
              <div>{documentToReactComponents(b.fields.description1!)}</div>
            </div>
          ))}
      </div>
      {config.client === "hcsc" ? <hr></hr> : null}
    </div>
  );
};

export default LegalDisclaimer;
