import { Card, Col, Row } from "react-bootstrap";
import { EligibilityFlow, FormikWizardState } from "../types";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserProvider";
import { Field, useFormikContext } from "formik";

//import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import StatusModal from "../components/StatusModal";
import useRouteQuery from "../../../hooks/useRouteQuery";
import FooterButtons from "../components/FooterButtons";
import ManageMembers from "../components/ManageMembers";
import moment from "moment";
import Summary from "../components/Summary";
import useGetScreenSize from "../../../hooks/useGetScreenSize";

const Members = ({
  next,
  previous,
}: {
  next?: () => void;
  previous?: () => void;
}) => {
  const { values, setFieldValue } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();

  const { user, setUser } = useContext(UserContext);
  const [status, setStatus] = useState("");
  const [message] = useState("");
  const [cardOneRef] = useState<HTMLDivElement | null>(null);
  const [, setRefs] = useState<(HTMLDivElement | null)[]>([]);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isImpersonated, setImpersonateLockForm] = useState<boolean>(false);
  const query = useRouteQuery();
  const [, setIsDependentToPrimary] = useState(false);
  const [EmailValidations, setEmailValidations] = useState(true);
  const [changeEmailChildCall, setchangeEmailChildCall] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [, setShowUnderageBanner] = useState(false);
  const [, setValidDependentEmails] = useState<[]>([]);

  const { isSmallScreen } = useGetScreenSize();

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  useEffect(() => {
    if (query.get("isSSO") === "true") {
      setUser({ isSSO: true });
    }
  }, []);

  useEffect(() => {
    setImpersonateLockForm(isImpersonated && window.location.search.length > 0);
    if (isImpersonated && query.get("firstName")) {
    } else if (user.isSSO) {
      moment(query.get("dob")).format();
    } else if (
      user.AccountSummaryMember?.eligibilities[0].currentSubscriptionStatus ===
        "Cancelled" &&
      !user.AccountSummaryMember?.eligibilities[0].isPrimary
    ) {
      setIsDependentToPrimary(true);
    }
  }, [user.isSSO, isImpersonated]);

  useEffect(() => {
    if (values.membership?.completed && next) {
      next();
    }
  }, [values.membership?.completed]);

  useEffect(() => {
    if (status && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, status]);

  const handleHandoff = () => {
    if (next) {
      next();
    }
  };

  const handleNextWrapper = async () => {
    // execute the useeffect inside ManagePlan component.
    // inside useeffect this make a callback of handleNext here in Members component.
    //Using the reverse logic that we use to disable the continue button to see if we should set this page as completed or not for now
    if (
      next &&
      ((values.eligibility?.completed && values.membership?.fitnessPolicy) ||
        (values.eligibility?.completed && !EmailValidations))
    ) {
      setFieldValue("membership.completed", true);
    }
    setchangeEmailChildCall(true);
  };

  return (
    <div className="eligibility-form-container members-eligibility-form-container">
      {/* <RegistrationModal open={true} /> */}
      <StatusModal
        show={status !== ""}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
        progress={progress}
      />
      <Row className="eligibility-form__row">
        <Col xs={12} lg={8}>
          <Header
            title={`Members & Emails`}
            subtitle={
              "Please confirm your email address on file for the primary member and add any family members. You must enter an email address for each family member. Remember, gym package family bundles unlock special discounts!"
            }
          />
          {isSmallScreen && (
            <Col xs={12} style={{ marginBottom: "10px" }}>
              <Summary />
            </Col>
          )}
          <ManageMembers
            setEmailValidations={setEmailValidations}
            changeEmailChildCall={changeEmailChildCall}
            setchangeEmailChildCall={setchangeEmailChildCall}
            // callBackHandleNext={handleNext}
            setCanSubmit={setCanSubmit}
            setShowUnderageBanner={setShowUnderageBanner}
            setValidDependentEmails={setValidDependentEmails}
          />
        </Col>
        <Col className="col-lg-1">&nbsp;</Col>
        {!isSmallScreen && (
          <Col lg={1} xl={2} mt-1 className="col-lg-3">
            <Summary />
          </Col>
        )}
      </Row>
      <Row className="manage-members">
        <h3>Agree to Our Terms</h3>

        <Col className="col-lg-8">
          <Card>
            <Card.Body>
              <Card.Text>
                <div className="form-check">
                  <Field
                    className="form-check-input-members"
                    type="checkbox"
                    name="membership.fitnessPolicy"
                  />
                  <span className="required-field-marker">*</span>
                  By checking this box, I acknowledge that I have read and agree
                  to comply with the terms and conditions of the{" "}
                  <a
                    href="/fitness-program-agreement"
                    target="_blank"
                    className="a-link"
                  >
                    Fitness Program Agreement
                  </a>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={2}></Col>
      </Row>

      <FooterButtons
        onSubmit={handleNextWrapper}
        onBack={previous}
        submitDisabled={
          !values.membership?.fitnessPolicy || !canSubmit || !EmailValidations
        }
      />
    </div>
  );
};

export default Members;
