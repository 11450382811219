import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFemale } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "react-jss";

import { useStyles, selectStyles } from "./styles";
import { distanceOptions, amenitiesOptions } from "./data";
import { LocationsContext } from "../../../context/LocationsProvider";
import { Button } from "react-bootstrap";
//TODO: Default search radius -> API Handles this now
const Filters = (props) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  const {
    setRadius,
    setAmenities,
    setCurrentStudio,
    locationDetail,
    studioDetail,
    womenOnlyFilter,
    setWomenOnlyFilter,
  } = useContext(LocationsContext);
  //TODO: Replace with Ameneties coming from Networks, when we get the option to pull them using our current auth token
  // const [availableAmenityOptions, setAmenityOptions] = useState([]);
  const [displayClear, setDisplayClear] = useState(false);

  //TODO: Replace with Ameneties coming from Networks, when we get the option to pull them using our current auth token
  // useEffect(() => {
  //   if (amenitiesList) {
  //     setAmenityOptions(amenitiesList.map((a) => ({ label: a, value: a })));
  //   }
  // }, [amenitiesList]);
  //
  // Distance Filter
  //
  const [distanceFilter, setDistanceFilter] = useState();

  const handleDistanceChange = (selectedDistance) => {
    if (selectedDistance) {
      setDistanceFilter(selectedDistance);
      setRadius(selectedDistance.value);
    } else {
      // Reset to default
      setDistanceFilter(null);
      setRadius(20);
    }
  };

  //
  // Studio Filter
  //
  const [studioFilter, setStudioFilter] = useState("");

  //
  // Women Only Filter
  //
  // const [womenOnlyFilter, setWomenOnlyFilter] = useState(false);
  const handleWomenFilter = () => {
    setWomenOnlyFilter(!womenOnlyFilter);
  };

  //
  // Amenities Filter
  //
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const handleAmenityChange = (selectedAmenities) => {
    setAmenities(selectedAmenities.map((a) => a.value));
    setAmenitiesFilter(selectedAmenities);
  };

  // const [typeFilter, setTypeFilter] = useState("all");
  // const handleTypeChange = (selectedType) => {
  //   setTypeFilter(selectedType);
  // };

  // Amenities Group Label
  /*const amenitiesGroupLabel = (data) => (
    <div className={classes.groupStyles}>
      <span>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );*/
  useEffect(() => {
    let defaultsFilters = {
      amenitiesFilter: [],
      womenOnlyFilter: false,
      distanceFilter: undefined,
      studioFilter: "",
    };
    let clearFiltersButton = false;
    if (distanceFilter !== defaultsFilters.distanceFilter) {
      if (distanceFilter !== null) {
        clearFiltersButton = true;
      }
    }
    if (studioFilter !== defaultsFilters.studioFilter) {
      if (studioFilter !== null) {
        clearFiltersButton = true;
      }
    }
    if (womenOnlyFilter !== defaultsFilters.womenOnlyFilter) {
      clearFiltersButton = true;
    }
    if (amenitiesFilter.length !== defaultsFilters.amenitiesFilter.length) {
      clearFiltersButton = true;
    }

    setDisplayClear(clearFiltersButton);
  }, [distanceFilter, amenitiesFilter, womenOnlyFilter, studioFilter]);

  const clearFilters = () => {
    setAmenities([]);
    setAmenitiesFilter([]);
    setWomenOnlyFilter(false);
    setDistanceFilter(null);
    setStudioFilter(null);
    setCurrentStudio("");
    setRadius(20);
    document.activeElement.blur();
  };

  return (
    <div className={classes.filtersContainer}>
      <span className={classes.label}>Filter By</span>
      <Select
        className={classes.reactSelectContainer}
        classNamePrefix="react-select"
        isClearable
        placeholder="Distance"
        value={distanceFilter}
        onChange={handleDistanceChange}
        options={distanceOptions}
        styles={selectStyles(theme)}
        isDisabled={
          props.isStudio
            ? Object.keys(studioDetail).length !== 0
            : Object.keys(locationDetail).length !== 0
        }
      />
      {props.isStudio ? (
        <></>
      ) : (
        <Select
          className={classes.reactSelectContainer}
          classNamePrefix="react-select"
          placeholder="Amenities"
          isMulti
          isSearchable
          //formatGroupLabel={amenitiesGroupLabel}
          value={amenitiesFilter}
          onChange={handleAmenityChange}
          options={amenitiesOptions}
          styles={selectStyles(theme)}
          isDisabled={Object.keys(locationDetail).length !== 0}
        />
      )}

      {/* <Select
        className={classes.reactSelectContainer}
        classNamePrefix="react-select"
        placeholder="Type"
        value={typeFilter}
        onChange={handleTypeChange}
        options={typeOptions}
        styles={selectStyles(theme)}
        isDisabled={Object.keys(locationDetail).length !== 0}
      /> */}
      {props.isStudio ? null : (
        <label
          className={`
          ${classes.womenOnlyFilter}
          ${womenOnlyFilter ? classes.womenFilterIsActive : ""}
          ${
            (
              props.isStudio
                ? Object.keys(studioDetail).length !== 0
                : Object.keys(locationDetail).length !== 0
            )
              ? classes.womenFilterIsDisabled
              : ""
          }
        `}
          htmlFor="womensFilter"
        >
          <input
            className={classes.womenFilterInput}
            onChange={handleWomenFilter}
            id="womensFilter"
            type="checkbox"
            disabled={
              Object.keys(locationDetail).length !== 0 ||
              Object.keys(studioDetail).length !== 0
            }
            checked={womenOnlyFilter}
          />
          <svg
            className={classes.womenCheckIcon}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 10 50 L 40 86 L 90 10"
              strokeDasharray="140"
              strokeDashoffset="140"
            ></path>
          </svg>
          <div className={classes.womenFilterTextWrapper}>
            <FontAwesomeIcon icon={faFemale} />
            <span className={classes.womenFilterText}>Women Only</span>
          </div>
        </label>
      )}
      {displayClear ? (
        <Button
          onClick={(e) => clearFilters(e)}
          className={classes.clearFiltersButton}
          variant="outline-primary"
        >
          Clear Filters
        </Button>
      ) : (
        <Button
          className={classes.invisibleClearFiltersButton}
          variant="outline-primary"
        >
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default Filters;
