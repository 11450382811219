import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import useGetPrograms from "../../../../hooks/useGetPrograms";
import PackageCard from "../PackageCard";
import { Product } from "../../forms/Package";
import styles from "./LandingPackageSelection.module.scss";
import { useConfig } from "../../../../configuration/useConfig";
import BinaryToggle from "../../../shared/BinaryToggle/BinaryToggle";

const LandingPackageSelection = () => {
  const { loading: productsLoading, productRatePlans: products } =
    useGetPrograms(false, false);
  const { clientConfig } = useConfig();
  const [selectedToggle, setSelectedToggle] = useState<string>("");
  const [
    isIndividualStudioPurchaseAllowed,
    setIsIndividualStudioPurchaseAllowed,
  ] = useState<boolean>(false);

  const toggleOptions = [
    { name: "Classic Gyms", value: "classic" },
    { name: "Luxury Gyms", value: "luxury" },
  ];

  useEffect(() => {
    setSelectedToggle(toggleOptions[0].value);
  }, []);

  useEffect(() => {
    setIsIndividualStudioPurchaseAllowed(
      !!clientConfig.isIndStudioPurchaseAllowed
    );
  }, [clientConfig]);

  const filterClassic = (product: Product) => {
    return product.tier < 4 && product.tier > 0;
  };

  const filterLuxury = (product: Product) => {
    return product.tier >= 4;
  };

  // A no-op function for event handlers that are required but not used.
  const noop = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  return (
    <div className={styles.root}>
      <h2 className={`${styles.headerText} mb-3`}>Gym Packages</h2>
      <h3 className={`${styles.subHeaderText} mb-4`}>
        No long-term commitments
      </h3>

      {clientConfig.isHomeGymSelectionAllowed && (
        <BinaryToggle
          options={toggleOptions}
          selectedToggle={selectedToggle}
          setSelectedToggle={setSelectedToggle}
        />
      )}

      {productsLoading ? (
        <div className="d-flex justify-content-center align-items-center m-2">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="package-card-container row">
          {products
            .filter(selectedToggle === "luxury" ? filterLuxury : filterClassic)
            .map((product) => {
              return (
                <div className="col mb-4 ml-1 mr-1" key={product.name}>
                  <PackageCard
                    productInfo={product}
                    price={product.recurringPrice ?? 0}
                    handleChange={noop}
                    selected=""
                    loading={false}
                    name={product.name}
                    className={product.name}
                    isPayroll={false} // TODO we need access to user payroll status here
                    isIndividualStudioPurchaseAllowed={
                      isIndividualStudioPurchaseAllowed
                    }
                  />
                </div>
              );
            })}
        </div>
      )}
      {!productsLoading &&
        products.find((product) => product.tier === 0) !== undefined && (
          <>
            <h2 className={`${styles.headerText} mb-3`}>
              Digital Only Package
            </h2>
            <h3 className={`${styles.subHeaderText} mb-4`}>
              Work out whenever you want, wherever you are.
            </h3>
            {productsLoading ? (
              <div className="d-flex justify-content-center align-items-center m-2">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="package-card-container row mb-4">
                {products.map((product) => {
                  if (product.tier === 0) {
                    return (
                      <div className="row" key={product.name}>
                        <PackageCard
                          productInfo={product}
                          price={product.recurringPrice ?? 0}
                          handleChange={noop}
                          selected=""
                          loading={false}
                          name={product.name}
                          className={product.name}
                          isPayroll={false} // TODO we need access to user payroll status here
                          isIndividualStudioPurchaseAllowed={
                            isIndividualStudioPurchaseAllowed
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default LandingPackageSelection;
