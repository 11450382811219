import React, { useContext, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import axiosInstance from "../../axios.instance";
import { AccountContext } from "../../context/AccountProvider";
import { differenceInDays, format } from "date-fns";
import LocationWaitlistBanner from "../account/LocationWaitlistBanner";

// const HomeGymIcon = () => {
//   return (
//     <svg
//       style={{ width: "40px", margin: "-6px 5px 0 0" }}
//       viewBox="0 0 782.208 752"
//     >
//       <path
//         id="Combined_Shape"
//         data-name="Combined Shape"
//         d="M664.877,752H117.331V398.116H0L391.1,0l391.1,398.116H664.877V752ZM462.539,578.715l.015.017,24.839,27.732,37.214-41.525,24.855,27.749,24.855-27.749-24.855-27.771,37.194-41.525L561.8,467.893l24.855-27.749L561.8,412.395l-62.049,69.275L350.781,315.349l62.049-69.275-24.855-27.749-24.855,27.749-24.855-27.749-37.194,41.525L276.2,232.1l-24.855,27.749L276.2,287.6,239,329.147,263.858,356.9,239,384.646l24.855,27.749,62.068-69.275,148.952,166.3-62.049,69.3,24.855,27.749,24.839-27.732Z"
//         fill="#072c4c"
//       />
//     </svg>
//   );
// };

const Marker = () => {
  return (
    <svg
      viewBox="0 0 15 24"
      width="44"
      height="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillRule="evenodd">
          <path
            d="M8 0C3.589 0 0 3.768 0 8.4 0 14.7 8 24 8 24s8-9.3 8-15.6C16 3.768 12.411 0 8 0z"
            fill="#82B266"
          />
          <circle fill="#FFF" cx="8" cy="7.5" r="3" />
        </g>
      </g>
    </svg>
  );
};

// const CheckCircle = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="40"
//       height="40"
//       fill="currentColor"
//       className="check-circle-fill"
//       viewBox="0 0 16 16"
//     >
//       <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
//     </svg>
//   );
// };
const ConfirmLuxuryGymModal = ({
  show,
  setShowModal,
  locationId,
  locationName,
  locationStreet,
  locationState,
  locationZip,
  locationCity,
  onRefresh,
  setOpenChooseLuxuryGymModal,
  setShowSuccessToast,
  showMarketingNotes,
}: {
  show: boolean;
  setShowModal: (val: boolean) => void;
  locationId: string;
  locationName: string;
  locationStreet: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  onRefresh: () => Promise<void>;
  setOpenChooseLuxuryGymModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessToast: React.Dispatch<React.SetStateAction<boolean>>;
  showMarketingNotes: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { member } = useContext(AccountContext);
  const numberOfGyms = useMemo(() => {
    const memberLuxuryGyms = member?.eligibilities[0].luxuryGyms?.filter(
      (gym) => differenceInDays(new Date(gym.endDate), new Date()) >= 0
    );
    return memberLuxuryGyms ? memberLuxuryGyms?.length : 0;
  }, [member]);

  const firstDayOfNextMonth = format(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    "MM/dd/yyyy"
  );

  const handleConfirmLuxuryGym = async () => {
    setIsLoading(true);
    axiosInstance
      .put(
        `/account/luxury-gym/${locationId}`,
        {},
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(async () => {
        await onRefresh();
        setIsLoading(false);
        setShowModal(false);
        setOpenChooseLuxuryGymModal(false);
        setShowSuccessToast(true);
      })
      .catch((e) => {
        console.log(e.toJSON());
        setIsLoading(false);
        throw new Error("Error setting Luxury gym");
      });
  };
  return (
    <Modal show={show} centered dialogClassName="modal-90w">
      <div className="setHomeGymModal">
        {/* <Modal.Title>
          <h2>Confirm Your Luxury Gym Choice</h2>
        </Modal.Title> */}
        <>
          <>
            <Modal.Body>
              <div className="setHomeGymModal__details">
                <Marker />
                <h2>Confirm Your Luxury Gym Choice</h2>
                <p className="setHomeGymModal__title m-0">{locationName}</p>
                <p className="setHomeGymModal__subtitle m-0">
                  {locationStreet} <br />
                  {locationCity} {locationState}, {locationZip}
                </p>
              </div>
              {numberOfGyms === 0 ? (
                <p>
                  You&lsquo;re about to select the above location as your Luxury
                  gym. Once you confirm, you will have immediate access. You can
                  still enjoy unlimited access to all Classic (non-luxury)
                  locations. If you&lsquo;d like to change your luxury gym, you
                  can do so at any time on the account home page.
                </p>
              ) : (
                <div>
                  <p style={{ marginBottom: `10px` }}>
                    You&lsquo;re about to select the above location as your
                    Luxury gym. Once you confirm, you will have access starting{" "}
                    <b>{firstDayOfNextMonth}</b>. In the meantime, you can still
                    enjoy unlimited access to all Classic (non-luxury)
                    locations.
                  </p>
                  <span className="luxury-gym-note">
                    <b>Note:</b> You&lsquo;ll still have access to your current
                    luxury gym until <b>{firstDayOfNextMonth}.</b>
                  </span>
                </div>
              )}
              {showMarketingNotes && (
                <LocationWaitlistBanner
                  chooseLuxuryGymModal={true as boolean}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                <button
                  onClick={() => setShowModal(false)}
                  className="col btn btn-outline-primary ml-2"
                >
                  Discard Luxury Gym Choice
                </button>
                <button
                  disabled={isLoading}
                  onClick={() => handleConfirmLuxuryGym()}
                  className="col mx-2 btn btn-secondary "
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    `Confirm Luxury Gym Choice`
                  )}
                </button>
              </div>
            </Modal.Footer>
          </>
        </>
      </div>
    </Modal>
  );
};

export default ConfirmLuxuryGymModal;
