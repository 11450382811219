import React, { Fragment } from "react";
import { TermsTableRow } from "../../types";

const TermsTable = ({ rows }: { rows: TermsTableRow[] }) => {
  return (
    <Fragment>
      <table className="terms__table">
        <tbody>
          {rows.map((row: TermsTableRow, i: number) => (
            <tr key={i}>
              {row.columnOneSpan >= 0 && (
                <td colSpan={row.columnOneSpan}>{row.columnOne}</td>
              )}
              {row.columnTwoSpan >= 0 && (
                <td colSpan={row.columnTwoSpan}>{row.columnTwo}</td>
              )}
              {row.columnThreeSpan >= 0 && (
                <td colSpan={row.columnThreeSpan}>
                  <div>{row.columnThree}</div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};
export default TermsTable;
