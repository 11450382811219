import React from "react";
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
} from "react-bootstrap";
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Spinner } from "react-bootstrap";
// import { LoadingSpinner } from "./LoadingSpinner";

const ModalContainer = ({
  isOpen,
  setIsOpen,
  children,
  cancelText,
  submitText,
  isLoading = false,
  selfClosing = false,
  submit,
  cancelAction,
  title,
  singleButton = false,
  titleSubText,
  disclaimer,
  isDismissable = false, //default
}: {
  isOpen: boolean;
  setIsOpen: (e) => unknown;
  children: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  cancelText: string;
  submitText: string;
  isLoading?: boolean;
  loaderText?: string;
  selfClosing?: boolean;
  submit: () => unknown;
  cancelAction?: () => unknown;
  title: string;
  size?: string;
  singleButton?: boolean;
  titleSubText?: string;
  disclaimer?: boolean | JSX.Element;
  isDismissable?: boolean;
}) => {
  const handleCloseButtonClick = () => {
    if (isDismissable && !selfClosing) {
      setIsOpen(!isOpen);
    }
  };

  const closeButton = isDismissable && (
    <button
      type="button"
      className="modal-close"
      aria-label="Close"
      onClick={handleCloseButtonClick}
    >
      {isDismissable && <span aria-hidden="true">✕</span>}
    </button>
  );

  const setBackdrop = (val) => {
    return isDismissable ? val : "static";
  };

  const setKeyboard = (val) => {
    return isDismissable ? val : false;
  };

  return (
    <Modal
      centered
      className="modal-container"
      show={isOpen}
      backdrop={setBackdrop(true)}
      keyboard={setKeyboard(true)}
      toggle={!selfClosing && (() => setIsOpen(!isOpen))} //!selfClosing && (() => setIsOpen(!isOpen))
    >
      <ModalTitle className="modal-container-title">
        {/* <ModalTitle close={closeButton}> */}
        {closeButton}
        {title}
        {titleSubText && <p className="font-small m-0">{titleSubText}</p>}
      </ModalTitle>
      <ModalBody>
        {isLoading ? <Spinner animation="border" /> : children}
        {/* {isLoading ? <LoadingSpinner additionalText={loaderText} /> : children} */}
      </ModalBody>
      {submit && !isLoading && (
        <ModalFooter>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            {!isLoading && singleButton ? (
              <Button
                disabled={isLoading}
                onClick={() => submit()}
                // disabled={disabled}
                className="btn btn-primary col"
              >
                {submitText}
              </Button>
            ) : (
              <div className="modal-container-buttons">
                {!isLoading && (
                  <Button
                    disabled={isLoading}
                    onClick={
                      !cancelAction
                        ? () => setIsOpen(!isOpen)
                        : () => cancelAction()
                    }
                    className="btn bg-white text-primary col"
                  >
                    {cancelText}
                  </Button>
                )}

                {!isLoading && (
                  <Button
                    disabled={isLoading}
                    onClick={() => submit()}
                    // disabled={disabled}
                    className="btn btn-primary col"
                  >
                    {submitText}
                  </Button>
                )}
              </div>
            )}
          </div>
        </ModalFooter>
      )}
      {disclaimer}
    </Modal>
  );
};
export default ModalContainer;
