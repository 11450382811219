import React from "react";
import moment from "moment";
import { IActivity } from "./ActivityLog";
import { momentStartOfDay } from "../../../utility/util";

interface IProps {
  activity: IActivity;
}

const ActivityListItem = ({ activity }: IProps) => (
  <div className="activity-log__activity-item">
    <div className="d-lg-flex d-none align-items-center p-1 justify-content-between mb-1">
      <p className="m-0 ms-5 p-0 col">{activity.location}</p>
      <p className="m-0 p-0 col">{activity.class ?? ""}</p>
      <p className="m-0 p-0 col">
        {momentStartOfDay(activity.activityDate).format("MM/DD/YYYY")}
      </p>
      <p className="m-0 p-0 col">
        {moment(activity.activityTime, "HH:mm:ss").format("LT")}
      </p>
      <p className="m-0 p-0 col">{activity.type}</p>
      <div className="mini-avatar d-flex justify-content-center align-items-center" />
    </div>
    <div className="card bg-light mb-3 d-lg-none">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Location:</p>
          <p className="pr-4">{activity.location}</p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Class:</p>
          <p className="pr-4">
            {activity.class ? activity.class : "Not found"}
          </p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Date:</p>
          <p className="pr-4">
            {momentStartOfDay(activity.activityDate).format("MM/DD/YYYY")}
          </p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Time:</p>

          <p className="pr-4">
            {moment(activity.activityTime, "HH:mm:ss").format("LT")}
          </p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Type:</p>
          <p className="pr-4">{activity.type}</p>
        </div>
      </div>
    </div>
  </div>
);

export { ActivityListItem };
