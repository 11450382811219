// import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getProductBundles, IProductBundleData } from "../api/platform/Queries";
// import { ClientConfigContext } from "../context/ClientConfigProvider";

//TODO - update backend to use the client not config.network.clientCode
const usePackagePricing = () => {
  const [bundles, setBundles] = useState<IProductBundleData | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const resp: IProductBundleData = await getProductBundles();
        // config["network.clientCode"] ?? ""
        setBundles(resp);
        setLoading(false);
      } catch (e: unknown) {
        setError(true);
      }
    })();
  }, []);
  return { bundles, loading, error };
};

export default usePackagePricing;
