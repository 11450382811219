import React from "react";
import { Alert } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LocationWaitlistBanner = ({
  chooseLuxuryGymModal,
  marketingNotes,
}: {
  chooseLuxuryGymModal: boolean;
  marketingNotes?: string;
}) => {
  return (
    <Alert className="location-waitlist-banner">
      <Alert.Heading>
        <span className="location-waitlist-banner waitlist-icon">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <b className="location-waitlist-banner waitlist-title">
          {" "}
          Important Information
        </b>
      </Alert.Heading>
      {chooseLuxuryGymModal ? (
        <div className="location-waitlist-banner waitlist-body">
          Access to this location is currently on a waitlist. Contact the gym
          for access before confirming it as your luxury gym.{" "}
        </div>
      ) : (
        <div className="location-waitlist-banner waitlist-body">
          {marketingNotes}
        </div>
      )}
    </Alert>
  );
};

export default LocationWaitlistBanner;
