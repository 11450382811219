import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CsrBanner = ({ agent, user }: { agent: string; user: string }) => {
  return (
    <div className="csr-banner">
      <div className="csr-banner__header">
        <FontAwesomeIcon size="2x" icon={faPhoneSquareAlt} />
      </div>
      <div className="csr-banner__container">
        <div>
          <h5 style={{ display: "inline" }}>CSR Agent:</h5>{" "}
          <span>
            <mark>
              <b>{agent}</b>
            </mark>
          </span>
        </div>
        <div>
          <h5 style={{ display: "inline" }}>User:</h5>{" "}
          <span>
            <mark>
              <b>{user}</b>
            </mark>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CsrBanner;
