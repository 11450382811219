import React, { useContext, useEffect, ReactNode } from "react";
import { TokenContext } from "./Auth0TokenProvider";
import axiosInstance from "../../../src/axios.instance";
import { axiosNetworkInstance } from "../../../src/axios.instance";
import { useConfig } from "../../configuration/useConfig";
interface AxiosInterceptorProps {
  children: ReactNode;
}
const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ children }) => {
  const token = useContext(TokenContext);
  const dynamicConfig = useConfig();

  useEffect(() => {
    // Interceptor for Authorization header
    const axiosInstanceInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Interceptor for AWS Networks API
    const AxiosNetworkInterceptor =
      axiosNetworkInstance.interceptors.request.use(
        (config) => {
          config.headers[`tivity-client`] = `${dynamicConfig.config["client"]}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    return () => {
      axiosInstance.interceptors.request.eject(axiosInstanceInterceptor);
      axiosInstance.interceptors.request.eject(AxiosNetworkInterceptor);
    };
  }, [token]);
  return <>{children}</>;
};
export default AxiosInterceptor;
