import React, { useState, useEffect, Fragment } from "react";
import ActivityFilters from "./ActivityFilters";
import ActivityList from "./ActivityList";
import { ActivityPagination } from "./ActivityPagination";
import { momentStartOfDay } from "../../../utility/util";
import axiosInstance from "../../../axios.instance";

export interface IActivity {
  activeLivingMemberId: string;
  class?: string;
  location: string;
  activityTime: string;
  activityDate: string;
  type: string;
  visitType: string;
}
type IState = IActivity[];

const ActivityLog = () => {
  const start = momentStartOfDay();
  const end = momentStartOfDay();
  const defaultStartDate = start.subtract(1, "y").format("l");
  const defaultEndDate = end.format("l");
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);
  const [eventType, setEventType] = useState("");
  const [selectedActivities, setSelectedActivities] = useState<IState>([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);
  const getActivities = async (startDate?: string, endDate?: string) => {
    axiosInstance
      .get(`/account/activity?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        setLoadingActivityLog(true);
        if (response) {
          const activityList = response.data;
          const filteredActivities = activityList.filter((activity) => {
            // Convert activity date to a date object if it's not already one
            const activityDate =
              activity.activityDate instanceof Date
                ? activity.activityDate
                : new Date(activity.activityDate);

            // Check if the activity date is within the date range
            if (
              activityDate >= new Date(selectedStartDate) &&
              activityDate <= new Date(selectedEndDate)
            ) {
              return true;
            }

            return false;
          });
          setLoadingActivityLog(false);
          setSelectedActivities(filteredActivities);
        }
      })
      .catch((error) => {
        setSelectedActivities([]);
        setLoadingActivityLog(false);
        console.log(error);
      });
  };

  const filteredActivitiesByType = async (type?: string) => {
    setLoadingActivityLog(true);
    await getActivities(defaultStartDate, defaultEndDate);

    const filteredActivities = selectedActivities.filter((activity) => {
      if (activity.type == type) {
        return true;
      } else if (type == "") {
        return true;
      }
      return false;
    });
    setSelectedActivities(filteredActivities);
    setLoadingActivityLog(false);
  };

  const clearFilter = () => {
    setLoadingActivityLog(true);
    setCurrentPageNum(1);
    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
    setEventType("");
    getActivities(defaultStartDate, defaultEndDate);
  };

  const applyFilters = () => {
    setLoadingActivityLog(true);
    getActivities(selectedStartDate, selectedEndDate);
  };

  useEffect(() => {
    getActivities(selectedStartDate, selectedEndDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currentActivityPage")) {
      const currentActivityPage = localStorage.getItem("currentActivityPage");
      setCurrentPageNum(Number(currentActivityPage));
    }
    return () => {
      localStorage.removeItem("currentActivityPage");
    };
  }, []);

  return (
    <Fragment>
      <div className="my-3 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column custom-width">
        <ActivityFilters
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          eventType={eventType}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          setEventType={setEventType}
          applyFilters={applyFilters}
          clearFilter={clearFilter}
          filteredActivitiesByType={filteredActivitiesByType}
          setCurrentPageNum={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
        {loadingActivityLog ? (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>Fetching Your Activity Logs... </h3>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : !selectedActivities || selectedActivities.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>No activities reported at this time.</h3>
          </div>
        ) : selectedActivities && selectedActivities.length > 0 ? (
          <>
            <ActivityList
              activities={selectedActivities}
              currentPageNum={currentPageNum}
            />
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center m-5">
            <h3>No activities reported at this time.</h3>
          </div>
        )}
        {!loadingActivityLog && selectedActivities?.length > 0 && (
          <ActivityPagination
            activities={selectedActivities}
            currentPageNum={currentPageNum}
            setCurrentPageNum={setCurrentPageNum}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ActivityLog;
