import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  '@keyframes load': {
    from: {
      top: 60,
      left: 60,
      width: 0,
      height: 0,
      opacity: 1,
    },
    to: {
      top: -1,
      left: -1,
      width: 122,
      height: 122,
      opacity: 0,
    },
  },
  loader: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 124,
    height: 124,
    marginTop: -62,
    marginLeft: -62,
  },
  loaderDotOne: {
    position: 'absolute',
    border: `4px solid ${theme.primary.color}`,
    opacity: 1,
    borderRadius: '50%',
    animation: '$load 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
  },
  loaderDotTwo: {
    animationDelay: '-0.5s',
  },
}))

export default useStyles
