import { Form, Col } from "react-bootstrap";
import React, { ChangeEvent, useContext, useState } from "react";
import { UserContext } from "../../../context/UserProvider";
import ProfileToast from "../../../utility/ProfileToast";
import {
  Formik,
  Form as FormikForm,
  FormikHelpers,
  FormikProps,
  getIn,
} from "formik";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { emailRegex } from "../../../utility/Constants";
import { useConfig } from "../../../configuration/useConfig";
import axiosInstance from "../../../axios.instance";
interface IEmailChange {
  email: string;
  confirmEmail: string;
}

const ChangeEmail = () => {
  const { user } = useContext(UserContext);
  if (!user.info) throw new Error("No user info");
  const userInfo = user.info;
  const { logout } = useAuth0();
  const [initialState] = useState<IEmailChange>({
    email: "",
    confirmEmail: "",
  });
  const [, setFormErrors] = useState({});
  const [newEmail] = useState("");
  const { client } = useConfig();
  const emailValidation = yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .required("Required")
        .defined("Please enter a valid email address")
        .test(
          "emails-match",
          "An account already exists with that email. Please enter a different email address",
          function (value) {
            return userInfo?.email !== value;
          }
        ),
      confirmEmail: yup
        .string()
        .test("emails-match", "Emails must match", function (value) {
          return this.parent.email === value;
        }),
    })
    .defined();

  const logoutParams = {
    returnTo: window.location.origin + "/account",
  };
  const handleLogOutLogin = async () => {
    logout({
      logoutParams,
    });
  };

  const handleSubmit = async (
    values: IEmailChange,
    formikHelpers: FormikHelpers<IEmailChange>
  ) => {
    setFormErrors(emailValidation.values);
    await axiosInstance
      .patch(
        `account/member`,
        {
          email: values.email,
        },
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          ProfileToast("Your account was successfully updated", true, client);
          handleLogOutLogin();
          formikHelpers.resetForm();
        }
      })
      .catch((e) => {
        if (e.response.status === 409) {
          formikHelpers.setFieldError(
            "email",
            "An account already exists with that email. Please enter a different email address."
          );
        } else {
          ProfileToast("Your email could not be updated.", false, client);
          formikHelpers.resetForm();
        }
        console.log(e);
      });
  };

  const handleEmailChange = (
    e: ChangeEvent<HTMLElement>,
    props: FormikProps<IEmailChange>
  ) => {
    const { setFieldError, handleChange } = props;
    // clear non-validation errors (such as account already exists)
    if (props.errors.email) {
      setFieldError("email", "");
    }
    // pass change event through to formik's default handler
    handleChange(e);
  };

  return (
    <div className="edit-profile">
      <span>Change Email Address</span>
      <p className="mb-0">
      We use this email address to notify you about changes to your account. Please note, updating your email address will require you log back in with your username and password
      </p>
      <Formik
        validationSchema={emailValidation}
        initialValues={initialState}
        enableReinitialize
        onSubmit={(
          values: IEmailChange,
          formikHelpers: FormikHelpers<IEmailChange>
        ) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(props) => (
          <FormikForm style={{ marginLeft: "14px" }}>
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                CURRENT EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="currentEmail"
                id="currentEmail"
                disabled={true}
                value={newEmail === "" ? userInfo?.email : newEmail}
                className="edit-profile__input"
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                NEW EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                id="email"
                onChange={(e) => handleEmailChange(e, props)}
                required
                className="edit-profile__input"
                onBlur={props.handleBlur}
                value={props.values.email}
                isInvalid={
                  getIn(props.touched, "email") && getIn(props.errors, "email")
                }
              />
            </Form.Group>
            {props.touched.email === true && props.errors.email ? (
              <div className="form-requirements invalid extra-margin">
                {props.errors.email}
              </div>
            ) : null}
            <Form.Group as={Col} xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                CONFIRM NEW EMAIL
              </Form.Label>
              <Form.Control
                type="text"
                name="confirmEmail"
                id="confirmEmail"
                onChange={props.handleChange}
                required
                className="edit-profile__input"
                onBlur={props.handleBlur}
                value={props.values.confirmEmail}
                isInvalid={
                  getIn(props.touched, "confirmEmail") &&
                  getIn(props.errors, "confirmEmail")
                }
              />
            </Form.Group>
            {props.touched.confirmEmail === true &&
            props.errors.confirmEmail ? (
              <div className="form-requirements invalid extra-margin">
                {props.errors.confirmEmail}
              </div>
            ) : null}
            <p style={{ maxWidth: "830px", marginLeft: "-5px" }}>
              By providing your email address and/or any other personal
              information, as defined under applicable law, you acknowledge that
              you are agreeing to our use of your information as provided in our{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Privacy Policy
              </a>
              .
            </p>
            <button
              disabled={!(props.isValid && props.dirty)}
              type="submit"
              className="nav-btn-enroll font-weight-bold btn btn-primary"
              style={{ marginLeft: "-5px" }}
            >
              Apply Email Change
            </button>
            <div className="vp__personal-info my-4" />
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default ChangeEmail;
