import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Section from "../components/faq/Section";
import StillHaveQuestions from "../components/faq/StillHaveQuestions";
import { convertToFAQStillHaveQuestions } from "../utility/contentful";
import { EntryCollection, Entry } from "contentful";
import {
  IFaqSectionFields,
  IStillHaveQuestionsFields,
} from "../@types/generated/contentful";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import PoweredBy from "../components/shared/PoweredBy";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";

const FAQ = () => {
  const [content, setContent] =
    useState<EntryCollection<IFaqSectionFields> | null>(null);
  const [bottom, setBottom] =
    useState<EntryCollection<IStillHaveQuestionsFields> | null>(null);
  const config = useConfig();
  const { getFAQs, getStillHaveQuestions } = useContentful(config.config);

  useEffect(() => {
    (async () => {
      getFAQs().then((res) => {
        if (res) {
          setContent(res);
        }
      });
      getStillHaveQuestions().then((res) => {
        if (res) {
          setBottom(res);
        }
      });
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Container className="faq-page faq-page__container">
        <h1 className="faq-page faq-page__header">
          Frequently Asked Questions
        </h1>
        {content?.items.map((item: Entry<IFaqSectionFields>, i: number) => (
          <Section data={item} key={i} />
        ))}
        <div className="faq-page faq-page__bottom">
          {bottom?.items.map(
            (item: Entry<IStillHaveQuestionsFields>, i: number) => (
              <StillHaveQuestions
                {...convertToFAQStillHaveQuestions(item)}
                key={i}
              />
            )
          )}
        </div>
      </Container>
      <LegalDisclaimer />
      <PoweredBy />
    </Fragment>
  );
};
export default FAQ;
