import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";

import ButtonProvider from "./context/ButtonProvider";
import SomethingWentWrong from "./pages/SomethingWentWrong";
import ThemeProvider from "./context/ThemeProvider";
import UserProvider from "./context/UserProvider";
const Boot = ({ children }: { children: React.ReactNode }) => {
  const [error] = useState(false);

  //set api routes/query parameters
  useEffect(() => {
    //url parameters setup and session id setup
    // axiosInstance
    // .get("/account")
    const params = new URLSearchParams(window.location.search);
    const notificationParam = params.get("notify");
    if (notificationParam) {
      sessionStorage.setItem("notification", notificationParam);
    }
    const sessionIdParam = params.get("pipeline-session-id");
    if (sessionIdParam) {
      sessionStorage.setItem("pipeline-session-id", sessionIdParam);
    } else {
      let sessionId = sessionStorage.getItem("pipeline-session-id");
      if (!sessionId) {
        sessionId = uuidv4();
        sessionStorage.setItem("pipeline-session-id", sessionId);
      }
    }
    if (sessionIdParam || notificationParam) {
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }, []);
  if (error) {
    return <SomethingWentWrong />;
  }

  return (
    <ThemeProvider>
      {/* <ImpersonationProvider> */}
      <UserProvider>
        <ButtonProvider>{children}</ButtonProvider>
      </UserProvider>
      {/* </ImpersonationProvider> */}
    </ThemeProvider>
  );
};

export default Boot;
