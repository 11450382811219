import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://member-portal-api.sandbox.tivity.health/clients/walmart",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

export const axiosMemberInstance = axios.create({
  // baseURL: "https://member-portal-api.sandbox.tivity.health/members",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

export const axiosMemberCardInstance = axios.create({
  // baseURL: "https://member-card-api.sandbox.tivity.health/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

export const axiosNetworkInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosBaseInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
