import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { AccountContext } from "../../context/AccountProvider";
import { Alert } from "react-bootstrap";
import ArrowRotateLeftSolidGold from "../../resources/ArrowRotateLeftSolidGold.svg";
import DiscardCancellationModal from "./DiscardCancellationModal";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMembership from "../../hooks/useMembership";
import useGetDependents from "../../hooks/useGetDependents";
import { getUnderageDependents } from "../../utility/checkIfUnderAge";
import { useConfig } from "../../configuration/useConfig";
import { momentEndOfDate } from "../../utility/util";

const DiscardCancelBanner = () => {
  const { member } = useContext(AccountContext);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [hasUnderageDependents, setHasUnderageDependents] =
    useState<boolean>(false);
  const { refresh } = useContext(AccountContext);
  const { loading: dependentsLoading, dependents } = useGetDependents();
  const { clientConfig, config } = useConfig();

  useEffect(() => {
    if (dependents && clientConfig.minBuyerMemberAge) {
      const underAgeDependents = getUnderageDependents(
        dependents,
        clientConfig.minBuyerMemberAge
      );
      setHasUnderageDependents(underAgeDependents ? true : false);
    }
  }, [dependents, clientConfig]);

  const currentEligibility =
    config["client"] === "walmart"
      ? member?.eligibilities[0]
      : member?.eligibilities.find((e) => e.productCode === "226");
  const today = momentEndOfDate().toDate();
  const termDate = moment(currentEligibility?.memberTermDate).toDate();

  const outputPrimaryDiscardText = () => {
    if (termDate > today) {
      return (
        <>
          You will no longer have access to the program effective{" "}
          <b>
            {moment(currentEligibility?.memberTermDate).format("MM/DD/YYYY")}
          </b>
          , as it appears you canceled your subscription. You may re-enroll in
          the program after the effective date, or discard your cancellation to
          remain an active member.
        </>
      );
    }

    return (
      <>
        You will no longer have access to the program effective{" "}
        <b>{moment(currentEligibility?.memberTermDate).format("MM/DD/YYYY")}</b>
        , as it appears you canceled your subscription. You may re-enroll in the
        program after the effective date.
      </>
    );
  };

  return (
    <Alert className="discard-cancel-banner">
      <Alert.Heading>
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        Attention
      </Alert.Heading>
      <div className="discard-cancel-banner__body">
        {currentEligibility?.isPrimary ? (
          <div>
            {outputPrimaryDiscardText()}
            {termDate > today && (
              <>
                <br />
                <button
                  className="discard-cancel-banner__discard-button"
                  onClick={() => setIsDiscarding(true)}
                >
                  <img
                    className="discard-cancel-banner__back-arrow"
                    alt="arrow"
                    src={ArrowRotateLeftSolidGold}
                  />
                  Discard Cancellation
                </button>
              </>
            )}
          </div>
        ) : (
          <div>
            You will no longer have access to the program effective{" "}
            <b>
              {moment(currentEligibility?.memberTermDate).format("MM/DD/YYYY")}
            </b>
            , as it appears your subscription was canceled by the primary member
            in your family bundle. If you want your own membership, you may
            re-enroll in the program as a primary member after the effective
            cancellation date.{" "}
          </div>
        )}
      </div>

      {isDiscarding && (
        <DiscardCancellationModal
          member={useMembership()}
          onClose={(success) => {
            if (success) {
              refresh();
            }
            setIsDiscarding(false);
          }}
          hasUnderageDependents={hasUnderageDependents}
          isLoadingDependents={dependentsLoading}
        />
      )}
    </Alert>
  );
};

export default DiscardCancelBanner;
