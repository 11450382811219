import { useState } from "react";
import { axiosNetworkInstance } from "../axios.instance";
// TODO: Other query parameters
const useStudioCount = () => {
  const [studioCount, setStudioCount] = useState<unknown>(0);
  
  const getStudioCount = {
    run: async () => {
        await axiosNetworkInstance
          .get(`/location-summary?programCode=studio`, {
            headers: {
            },
          })
          .then((response) => {
            if (response.data) {
              setStudioCount(response.data.data.activeLocationCount);
            }
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
    },
  };
  return {
    studioCount,
    getStudioCount
  };
  
};

export default useStudioCount;