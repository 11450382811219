import * as yup from "yup";
import * as valid from "card-validator";
import { emailRegex } from "../../../utility/Constants";
import {
  IAddressFields,
  IAddressForm,
  ICheckoutForm,
  IEligibilityFormProfileWalmart,
  IEligibilityFormProfileHCSC,
  IMembershipForm,
  IPackageSelectForm,
  IEligibilityFormProfileFYW,
  IEligibilityFormProfileMW,
} from "../types";
import { AccountAddress } from "../../../utility/member-portal-api-types";

export namespace ModelValidations {
  export const WalmartValidation: yup.SchemaOf<IEligibilityFormProfileWalmart> =
    yup
      .object()
      .shape({
        firstName: yup
          .string()
          .matches(
            /^([A-Za-zñÑàáäÀÁÂèéêÈÉÊìíîÌÍÎòóôÒÓÔùúûÙÚÛ]|-|\.|'|\s)+$/,
            "Name can only contain letters, hyphens, apostrophes or periods"
          )
          .defined("First name must be defined."),
        lastName: yup
          .string()
          .matches(
            /^([A-Za-zñÑàáäÀÁÂèéêÈÉÊìíîÌÍÎòóôÒÓÔùúûÙÚÛ]|-|\.|'|\s)+$/,
            "Name can only contain letters, hyphens, apostrophes or periods"
          )
          .defined("Last name must be defined."),
        year: yup
          .string()
          .matches(/^[0-9]+$/, " ")
          .min(4, " ")
          .max(4, " ")
          .required(" ")
          .defined(" "),
        month: yup
          .string()
          .matches(/^[0-9]+$/, " ")
          .min(2, " ")
          .max(2, " ")
          .required(" ")
          .defined(" "),
        day: yup
          .string()
          .matches(/^[0-9]+$/, " ")
          .min(2, " ")
          .max(2, " ")
          .required(" ")
          .defined(" "),
        email: yup
          .string()
          .matches(new RegExp(emailRegex), "Please enter a valid email address")
          .defined("Email must be defined."),
        zip: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(5, "Must be exactly 5 digits")
          .max(5, "Must be exactly 5 digits")
          .defined("Zip code must be defined."),
        associateIdentificationNumber: yup
          .string()
          .matches(
            /^[0-9]+$/,
            "Please enter a Associate Identification Number using numbers (no spaces or special characters)"
          )
          .defined("Associate Identification Number must be defined."),
        isDependent: yup.bool().required(),
        createLogin: yup.bool().required(),
      })
      .defined();

  export const HCSCValidation: yup.SchemaOf<IEligibilityFormProfileHCSC> = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("First name must be defined."),
      lastName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("Last name must be defined."),
      year: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(4, " ")
        .max(4, " ")
        .required(" ")
        .defined(" "),
      month: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      day: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .defined("Email must be defined."),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined("Zip code must be defined."),
      corpCode: yup.string().required("Corp Code is required.").defined(),
      subscriberId: yup
        .string()
        .required("Subscriber ID is required.")
        .defined(),
      groupNumber: yup.string().required("Group # is required.").defined(),
    })
    .defined();

    export const FYWValidation: yup.SchemaOf<IEligibilityFormProfileFYW> = yup
    .object()
    .shape({
      gender: yup.string().matches(/^[MF]$/).defined("Gender must be defined."),
      firstName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("First name must be defined."),
      lastName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("Last name must be defined."),
      year: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(4, " ")
        .max(4, " ")
        .required(" ")
        .defined(" "),
      month: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      day: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .defined("Email must be defined."),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined("Zip code must be defined."),
      isBSCA: yup.bool().required(),
      isDependent: yup.string().when('isBSCA', {
        is: false,
        then:yup.string().required("Relationship is required.").defined(),
        otherwise: yup.string().notRequired(),
      }),
      coverageType: yup.string().when('isBSCA', {
        is: false,
        then:yup.string().required("Coverage Type is required.").defined(),
        otherwise: yup.string().notRequired(),
      }),
      subscriberId: yup
        .string()
        .required("Subscriber ID is required.")
        .defined(),
      groupNumber: yup.string().when('isBSCA', {
        is: true,
        then:yup.string().required("Group number is required.").defined(),
        otherwise: yup.string().notRequired(),
      }),
      //Examples for future use
      // contactMethod: yup.string().required('Contact method is required.'),
      // phoneNumber: yup.string().when('contactMethod', {
      //   is: 'phone',
      //   then: yup.string().required('Phone number is required.').matches(/^[0-9]+$/, 'Phone number must be digits only.'),
      //   otherwise: yup.string().notRequired(),
      // }),
      // email: yup.string().when('contactMethod', {
      //   is: 'email',
      //   then: yup.string().required('Email is required.').email('Must be a valid email address.'),
      //   otherwise: yup.string().notRequired(),
      // }),
    })
    .defined();

    export const MWValidation: yup.SchemaOf<IEligibilityFormProfileMW> = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("First name must be defined."),
      lastName: yup
        .string()
        .matches(
          /^([A-Za-z]|-|\.|'|\s)+$/,
          "Name can only contain letters, hyphens, apostrophes or periods"
        )
        .defined("Last name must be defined."),
      year: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(4, " ")
        .max(4, " ")
        .required(" ")
        .defined(" "),
      month: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      day: yup
        .string()
        .matches(/^[0-9]+$/, " ")
        .min(2, " ")
        .max(2, " ")
        .required(" ")
        .defined(" "),
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .defined("Email must be defined."),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined("Zip code must be defined."),
    })
    .defined();

  export const PackageValidation: yup.SchemaOf<IPackageSelectForm> = yup
    .object()
    .shape({
      sku: yup.string().min(1).defined("Name must be defined."),
    })
    .defined();

  export const MembershipValidation: yup.SchemaOf<IMembershipForm> = yup
    .object()
    .shape({
      email: yup
        .string()
        .matches(new RegExp(emailRegex), "Please enter a valid email address")
        .max(255)
        .defined("Email must be defined."),
      password: yup
        .string()
        .required()
        .min(8)
        .matches(RegExp("(.*[a-z].*)"), "Lowercase")
        .matches(RegExp("(.*[A-Z].*)"), "Uppercase")
        .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Special")
        .defined("Password must be defined."),
    })
    .defined();

  export const AddressFieldsValidation: yup.SchemaOf<IAddressFields> = yup
    .object()
    .shape({
      city: yup.string().defined(),
      state: yup.string().defined(),
      street1: yup.string().defined(),
      street2: yup.string(),
      zip: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .defined(),
    })
    .defined();

  export const AddressAccountFieldsValidation: yup.SchemaOf<AccountAddress> =
    yup
      .object()
      .shape({
        city: yup.string().defined(),
        state: yup.string().defined(),
        address1: yup.string().defined(),
        address2: yup.string(),
        postalCode: yup
          .string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(5, "Must be exactly 5 digits")
          .max(5, "Must be exactly 5 digits")
          .defined(),
      })
      .defined();

  export const AddressFieldsValidationNotRequired: yup.SchemaOf<IAddressFields> =
    yup
      .object()
      .shape({
        city: yup.string(),
        state: yup.string(),
        street1: yup.string(),
        street2: yup.string(),
        zip: yup.string(),
      })
      .defined();

  export const AddressValidation: yup.SchemaOf<IAddressForm> = yup
    .object()
    .shape({
      home: AddressFieldsValidation.required(),
      billing: yup.object().when("billingRequired", {
        is: true,
        then: AddressFieldsValidation as yup.SchemaOf<IAddressFields>,
        otherwise:
          AddressFieldsValidationNotRequired as yup.SchemaOf<IAddressFields>,
      }) as yup.SchemaOf<IAddressFields>,
      billingRequired: yup.boolean().required(),
      completed: yup.boolean().defined(),
    })
    .defined();

  export const CheckoutValidation: yup.SchemaOf<ICheckoutForm> = yup
    .object()
    .shape({
      number: yup
        .string()
        .test(
          "test-card-number",
          "Credit card number is invalid.",
          (value: unknown) => valid.number(value).isValid
        )
        .defined(),
      expiration: yup
        .string()
        .test(
          "test-card-number",
          "Expiration is not valid.",
          (value: unknown) => valid.expirationDate(value).isValid
        )
        .defined(),
      cvv: yup
        .string()
        .test(
          "test-card-number",
          "CVV is not valid.",
          (value: unknown) => valid.cvv(value).isValid
        )
        .defined(),
      name: yup.string().defined(),
      paymentMethod: yup.string().defined(),
      fitnessPolicy: yup.boolean().defined(),
    })
    .defined();
}
